import React from "react";
import PropTypes from "prop-types";
import useStyles from "components/Modals/index.style";
import { Box, Button, Dialog, DialogContent, DialogTitle } from "@mui/material";
import { PageLoader, FormField } from "components";
import { Formik, Form } from "formik";
import * as yup from "yup";
import { head, last, split } from "lodash";
import Alert from "@mui/material/Alert";
import { useGetUserQuery, useUserUpdateMutation } from "services/api";

const validationSchema = yup.object({
  name: yup.string().required("Name is required"),
  email: yup
    .string()
    .required("Email is required")
    .email("Must be a valid email"),
  password: yup
    .string()
    .min(8, "Must be at least 8 characters long")
    .max(25, "Can't be longer than 25 characters long"),
  confirmPassword: yup
    .string()
    .min(8, "Must be at least 8 characters long")
    .max(25, "Can't be longer than 25 characters long")
    .oneOf([yup.ref("password"), null], "Passwords must match"),
});

const MySettings = ({ open, handleDialogClose }) => {
  const classes = useStyles();
  const { data: userInfo } = useGetUserQuery();
  const [userUpdate, { isLoading: isUpdating, isError, error, isSuccess }] =
    useUserUpdateMutation();

  const handleSubmit = ({ name, password }) => {
    let updateParams = {
      first_name: head(split(name, " ")),
      last_name: last(split(name, " ")),
    };
    if (password) {
      updateParams = {
        ...updateParams,
        password,
      };
    }
    userUpdate({ user: updateParams });
  };

  if (!userInfo) {
    return <PageLoader />;
  }

  const initial = {
    name: `${userInfo.firstName} ${userInfo.lastName}`,
    email: userInfo.email,
    password: userInfo.password,
    confirmPassword: userInfo.password,
  };

  return (
    <Dialog open={open} onClose={handleDialogClose}>
      <DialogTitle className={classes.dialogTitle}>MY SETTINGS</DialogTitle>
      <DialogContent dividers>
        <Formik
          initialValues={initial}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          <Form>
            <Box className={classes.dialogWrapper}>
              <FormField
                name="name"
                label="Name"
                placeholder="Enter Name"
                disabled={isUpdating}
              />
              <FormField
                disabled // Updating email is not allowed
                name="email"
                label="E-mail Address"
                placeholder="someone@domain.com"
              />
              <FormField
                name="password"
                type="password"
                label="Password"
                disabled={isUpdating}
                autoComplete="current-password"
                placeholder="Enter a password"
              />
              <FormField
                name="confirmPassword"
                type="password"
                label="Confirm Password"
                disabled={isUpdating}
                autoComplete="current-password"
                placeholder="Enter a confirm password"
              />
              {!!isError && (
                <Alert severity="error">{error.data?.message}</Alert>
              )}
              {isSuccess && (
                <Alert severity="success">Updated user successfully</Alert>
              )}
              <Box
                display="flex"
                alignItems="flex-end"
                justifyContent="flex-end"
                mt={5}
              >
                <Button onClick={handleDialogClose}>Cancel</Button>
                <Box ml={2}>
                  <Button
                    color="primary"
                    variant="contained"
                    type="submit"
                    disabled={isUpdating}
                  >
                    Save
                  </Button>
                </Box>
              </Box>
            </Box>
          </Form>
        </Formik>
      </DialogContent>
    </Dialog>
  );
};

export default MySettings;

MySettings.propTypes = {
  open: PropTypes.bool.isRequired,
  handleDialogClose: PropTypes.func.isRequired,
};
