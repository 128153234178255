import React, { forwardRef } from "react";
import PropTypes from "prop-types";
import { useFormikContext } from "formik";
import Button from "@mui/material/Button";

const FormSubmitButton = forwardRef(({ disabled, ...rest }, ref) => {
  const { isSubmitting } = useFormikContext();
  return (
    <Button
      ref={ref}
      {...rest}
      type="submit"
      disabled={disabled || isSubmitting}
    />
  );
});

FormSubmitButton.propTypes = {
  disabled: PropTypes.bool,
};

FormSubmitButton.displayName = "FormSubmitButton";

export default FormSubmitButton;
