import React from "react";
import { Box, IconButton, SvgIcon } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Download } from "@mui/icons-material";

const useStyles = makeStyles((theme) => ({
  buttonContainer: {
    backgroundColor: "#023E6E",
    color: "#FFFFFF",
    borderRadius: "4px",
    "&:hover": {
      cursor: "pointer",
    },
  },
  iconStyles: {
    background: "transparent",
    color: theme.palette.primary.main,
  },
  downloadSVG: {
    color: "#001845",
  },
}));

const ActionButtons = ({ data }) => {
  const classes = useStyles();

  if (!data.invoicePdf) {
    return null;
  }

  return (
    <Box>
      <IconButton
        className={`${classes.buttonContainer} ${classes.iconStyles}`}
        fontSize="large"
        href={data.invoicePdf}
      >
        <SvgIcon className={classes.downloadSVG}>
          <Download />
        </SvgIcon>
      </IconButton>
    </Box>
  );
};

export default ActionButtons;
