import React from "react";
import Channels from "../Channels/Channels";
import ChatPanel from "../ChatPanel/ChatPanel";
import Settings from "./Settings/ChatGroupsSettings";
import { CHAT } from "utils/constants";

const Groups = () => {
  return (
    <>
      <Channels groupingLevel={CHAT.ROOM_GROUPING_LEVEL.GROUP} />
      <ChatPanel listMessageType={CHAT.LIST_MESSAGE_TYPE.ALL} />
      <Settings />
    </>
  );
};

export default Groups;
