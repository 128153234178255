import theme from "theme/theme";
import { ACTIVITY_TYPES } from "utils/constants";
import { Person, Comment, PeopleAlt, Error } from "@mui/icons-material";

export const getActivityIconByType = (type) => {
  switch (type) {
    case ACTIVITY_TYPES.GROUP_NEW_LEAD:
      return (
        <Person fontSize="large" htmlColor={theme.palette.primary.light} />
      );
    case ACTIVITY_TYPES.CHAT_NEW_LEAD_MESSAGE:
      return (
        <Comment fontSize="large" htmlColor={theme.palette.primary.light} />
      );
    case ACTIVITY_TYPES.GROUP_NEW_INVITE:
      return (
        <PeopleAlt fontSize="large" htmlColor={theme.palette.primary.light} />
      );
    case ACTIVITY_TYPES.GROUP_REMOVED:
      return <Error fontSize="large" htmlColor={theme.palette.error.main} />;
    case ACTIVITY_TYPES.GROUP_CANCELLED:
      return <Error fontSize="large" htmlColor={theme.palette.error.main} />;
    case ACTIVITY_TYPES.GROUP_DELETED:
      return <Error fontSize="large" htmlColor={theme.palette.error.main} />;
    case ACTIVITY_TYPES.GROUP_NEW_USER_ADDED:
    case ACTIVITY_TYPES.GROUP_NEW_USER:
      return (
        <PeopleAlt fontSize="large" htmlColor={theme.palette.primary.light} />
      );
    case ACTIVITY_TYPES.OPPORTUNITY_NEW_USER_ADDED:
    case ACTIVITY_TYPES.OPPORTUNITY_NEW_USER:
      return <PeopleAlt htmlColor={theme.palette.primary.light} />;
    // TODO add business notifications once we consult with BE
    default:
      return null;
  }
};

export const getActivityTextByType = (type, data) => {
  if (!type || !data) return null;

  if (type === ACTIVITY_TYPES.GROUP_NEW_LEAD && data.leadNew) {
    return [
      data?.leadNew?.groupName,
      `New lead at ${data?.leadNew?.leadCompany}`,
    ];
  }

  if (type === ACTIVITY_TYPES.CHAT_NEW_LEAD_MESSAGE && data.chatLeadMessage) {
    return [
      data?.chatLeadMessage?.groupName,
      `New lead comment at ${data?.chatLeadMessage?.leadName}`,
    ];
  }

  if (
    type === ACTIVITY_TYPES.CHAT_NEW_OPPORTUNITY_MESSAGE &&
    data.chatOpportunityMessage
  ) {
    return [
      data?.chatOpportunityMessage?.opportunityName,
      `New chat message from ${data?.chatOpportunityMessage?.userName}`,
    ];
  }

  // Deprecated? We use invite links now (no invitee data)
  if (type === ACTIVITY_TYPES.GROUP_NEW_INVITE && data.groupInvite) {
    return [data?.groupInvite?.invitedByName, "invited you to a group"];
  }

  if (type === ACTIVITY_TYPES.GROUP_CANCELLED && data.groupCancel) {
    return [data?.groupCancel?.groupName, "This group will be cancelled soon"];
  }

  if (type === ACTIVITY_TYPES.GROUP_DELETED && data.groupDelete) {
    return [data?.groupDelete?.groupName, "This group no longer exists"];
  }

  if (type === ACTIVITY_TYPES.GROUP_REMOVED && data.groupRemove) {
    return [
      data?.groupRemove?.groupName,
      "You have been removed from this group",
    ];
  }

  if (type === ACTIVITY_TYPES.GROUP_NEW_USER && data.groupNewUser) {
    return [
      data?.groupNewUser?.groupName,
      `${data?.groupNewUser?.newUserName} has joined this group`,
    ];
  }

  if (type === ACTIVITY_TYPES.GROUP_NEW_USER_ADDED && data.groupNewUserAdded) {
    return [
      data?.groupNewUserAdded?.groupName,
      `${data?.groupNewUserAdded?.newUserName} has been added this group`,
    ];
  }

  if (type === ACTIVITY_TYPES.OPPORTUNITY_NEW_USER && data.opportunityNewUser) {
    return [
      data?.opportunityNewUser?.opportunityName,
      `${data?.opportunityNewUser?.userName} has joined this opportunity`,
    ];
  }

  if (
    type === ACTIVITY_TYPES.OPPORTUNITY_NEW_USER_ADDED &&
    data.opportunityNewUserAdded
  ) {
    return [
      data?.opportunityNewUserAdded?.opportunityName,
      `${data?.opportunityNewUserAdded?.userName} has been added to this opportunity`,
    ];
  }

  return [];
};

export const handleGroupActivityTitle = (type, isGroupActivity) => {
  if (!isGroupActivity) return null;

  switch (type) {
    case ACTIVITY_TYPES.GROUP_NEW_LEAD:
      return "New Lead";
    case ACTIVITY_TYPES.GROUP_NEW_USER:
      return "New User";
    case ACTIVITY_TYPES.CHAT_NEW_GROUP_MESSAGE:
      return "New Message";
    case ACTIVITY_TYPES.CHAT_NEW_LEAD_MESSAGE:
      return "New Lead Comment";
  }

  return null;
};
