import React, { useState, useRef, useEffect } from "react";
import { api } from "services/api";
import { CHAT } from "utils/constants";

const ChatPanel = ({ roomId }) => {
  const [messages, setMessages] = useState([]); // eslint-disable-line
  const [triggerGetMessages, { data: messagesData }] =
    api.endpoints.getChatRoomMessageList.useLazyQuery();
  const offsetRef = useRef(0);
  const limit = 50;
  useEffect(() => {
    if (roomId) {
      triggerGetMessages({
        roomId,
        listMessageType: CHAT.LIST_MESSAGE_TYPE.ALL,
        offset: offsetRef.current,
        limit,
      });
    }
  }, [roomId]);

  useEffect(() => {
    if (messagesData?.messages) {
      setMessages(messagesData?.messages);
      offsetRef.current += limit;
    }
  }, [messagesData]);
  return <></>;
};

export default ChatPanel;
