import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Box } from "@mui/material";
import {
  Container,
  PageTitle,
  PageLoader,
  Button,
  TabBar,
  Empty,
  GroupLeadListGrid,
  BackButton,
} from "components";
import useStyles from "../Group.style";
import { useParams } from "react-router";
import { api, useDeleteLeadMutation, useGetGroupQuery } from "services/api";
import { Settings, PersonAdd } from "@mui/icons-material";
import { getRedirectUrl, ROUTES } from "utils/constants";
import AddEditLead from "components/Modals/AddEditLead";
import { useHistory } from "react-router-dom";
import { getGroupTabs } from "../Group.utils";
import RemoveDialog from "components/Modals/RemoveDialog";

const GroupLeads = () => {
  const classes = useStyles();
  const { group_id: groupId } = useParams();
  const { data: groupData } = useGetGroupQuery(groupId, { skip: !groupId });
  const [showAddEditLead, setShowAddEditLead] = useState(false);
  const [showRemoveLead, setShowRemoveLead] = useState(false);
  const [currentLead, setCurrentLead] = useState(null);
  const [refreshGrid, setRefreshGrid] = useState(false);
  const [triggerGetLeadList, { isLoading: isLoadingLeads, data: leadsData }] =
    api.endpoints.getLeadList.useLazyQuery({
      refetchOnFocus: true,
      refetchOnReconnect: true,
    });
  const [deleteLead, { isSuccess: isRemovedLead }] = useDeleteLeadMutation();
  const history = useHistory();
  const startPosRef = useRef(0);
  const getNext = 50;
  const tabs = useMemo(() => getGroupTabs(groupId), [getGroupTabs, groupId]);

  useEffect(() => {
    if (isRemovedLead) {
      handleCloseRemoveLead();
      startPosRef.current = 0;
      setTimeout(() => {
        setRefreshGrid(true);
      }, 500);
    }
  }, [isRemovedLead]);

  const renderEmpty = () => (
    <Empty
      image="/images/group/group_leads_empty.png"
      title={"Looks like there is\nno one in here..."}
      actions={[
        {
          heading: "Start by adding a lead to your group",
          text: "ADD LEAD",
          onClick: handleAddLead,
          fullWidth: false,
          color: "primary",
        },
      ]}
    />
  );

  const onLoadMoreLeads = useCallback(async () => {
    let leadsResp = await triggerGetLeadList({
      groupId,
      startPos: startPosRef.current,
      getNext,
      sortBy: "ACTIVITY",
    }).unwrap();
    const { totalCount } = leadsResp;
    if (startPosRef.current < totalCount) {
      startPosRef.current += getNext;
    }
    return leadsResp;
  }, [triggerGetLeadList]);

  const handleAddLead = () => {
    setRefreshGrid(false);
    setCurrentLead(null);
    setShowAddEditLead(true);
  };

  const onEditLead = (lead) => {
    setRefreshGrid(false);
    setCurrentLead(lead);
    setShowAddEditLead(true);
  };

  const handleCloseAddEditLead = () => {
    setShowAddEditLead(false);
  };

  const onAddedOrEditedLead = () => {
    handleCloseAddEditLead();
    startPosRef.current = 0;
    setTimeout(() => {
      setRefreshGrid(true);
    }, 500);
  };

  const onRemoveLead = (lead) => {
    setCurrentLead(lead);
    setShowRemoveLead(true);
  };

  const onClickLead = (lead) => {
    history.push(
      getRedirectUrl(
        getRedirectUrl(ROUTES.GROUP_LEAD_DETAILS, groupId),
        lead.leadId
      )
    );
  };

  const handleOpenSettings = () => {
    history.push(getRedirectUrl(ROUTES.GROUP_SETTINGS, groupId));
  };

  const handleCloseRemoveLead = () => {
    setShowRemoveLead(false);
  };

  const onConfirmedRemove = (lead) => {
    deleteLead(lead.leadId);
  };

  const handleBack = () => {
    history.push(ROUTES.GROUP_LIST);
  };

  if (!groupData) {
    return <PageLoader />;
  }

  return (
    <Container>
      <Box className={classes.titleBox}>
        <Box className={classes.backButtonWrapper}>
          <BackButton onClick={handleBack} />
        </Box>
        <PageTitle title={groupData.name} />
        <Box className={classes.buttonWrapper}>
          <Button
            startIcon={<PersonAdd />}
            color="secondary"
            fullWidth={false}
            text="CREATE LEAD"
            variant="outlined"
            size="small"
            onClick={handleAddLead}
          />
          <Button
            startIcon={<Settings />}
            color="secondary"
            fullWidth={false}
            text="SETTINGS"
            variant="outlined"
            size="small"
            onClick={handleOpenSettings}
          />
        </Box>
      </Box>
      <Box display="flex" flex={1} flexDirection="column" mt={6}>
        <TabBar tabs={tabs} selectedId={0} />
        {!isLoadingLeads && leadsData?.totalCount === 0 && renderEmpty()}
        <Box
          display={
            !isLoadingLeads && leadsData?.totalCount > 0 ? "flex" : "none"
          }
          className={classes.tableWrapper}
        >
          <GroupLeadListGrid
            onEditLead={onEditLead}
            onRemoveLead={onRemoveLead}
            onLoadMoreLeads={onLoadMoreLeads}
            refresh={refreshGrid}
            onClickRow={onClickLead}
          />
        </Box>
      </Box>
      {showAddEditLead && (
        <AddEditLead
          lead={currentLead}
          open={showAddEditLead}
          onClose={handleCloseAddEditLead}
          onSuccess={onAddedOrEditedLead}
        />
      )}
      {showRemoveLead && (
        <RemoveDialog
          open={showRemoveLead}
          onClose={handleCloseRemoveLead}
          title="Remove lead"
          body={"remove lead " + currentLead?.fullName + "?"}
          data={currentLead}
          onRemove={onConfirmedRemove}
        />
      )}
    </Container>
  );
};

export default GroupLeads;
