import React, { useCallback, useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { Box, Button, Hidden, SvgIcon, Typography } from "@mui/material";
import { PersonAdd } from "@mui/icons-material";
import useStyles from "../index.style";
import AddEditBusinessUser from "components/Modals/AddEditBusinessUser";
import RemoveUser from "components/Modals/RemoveUser";
import { useSelector, useDispatch } from "react-redux";
import { showAddNewUserDialog, hideAddNewUserDialog } from "redux/slices/Modal";
import { PageLoader, CSVLink, UsersByBusinessGrid } from "components";
import { BusinessUserRoleType } from "utils/constants";
import { useGetBusinessUserQueryState } from "services/business";
import { api, useGetBillingQuery } from "services/api";
import { ReactComponent as CSVExportIcon } from "assets/icons/csv_export.svg";
import moment from "moment";

const csvHeaders = [
  { label: "Name", key: "name" },
  { label: "Email Address", key: "email" },
  { label: "Role", key: "role" },
  { label: "Status", key: "status" },
  { label: "Added At", key: "addedAt" },
];

const AdminManageUsers = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const history = useHistory();
  const { showAddNewUser } = useSelector(({ modal }) => modal);
  const [showEditUser, setShowEditUser] = useState(false);
  const [showRemoveUser, setShowRemoveUser] = useState(false);
  const [currentUser, setCurrentUser] = useState(null);
  const [refreshGrid, setRefreshGrid] = useState(false);
  const [csvData, setCSVData] = useState([]);
  const userId = useSelector((state) => state.user.userId);
  const totalCountRef = useRef(0);
  const userStartPosRef = useRef(0);
  const invitedStartPosRef = useRef(0);
  const { data: billingInfo } = useGetBillingQuery(null, {
    refetchOnMountOrArgChange: true,
  });

  const { data: bmUser, isLoading: isBmLoading } =
    useGetBusinessUserQueryState(userId);

  const isBmUser = !!bmUser;
  const isBmAndAdminUser = bmUser?.role === BusinessUserRoleType.ADMIN;
  const businessId = bmUser?.business?.businessId;

  const [triggerGetBusinessUsers] =
    api.endpoints.getBusinessUsers.useLazyQuerySubscription({
      refetchOnFocus: true,
      refetchOnReconnect: true,
    });

  useEffect(() => {
    if (!isBmLoading && (!isBmUser || !businessId)) {
      history.push("/");
    }
  }, [history, isBmLoading, isBmUser, businessId]);

  const onLoadMoreUsers = useCallback(async () => {
    try {
      if (!businessId) {
        return {
          businessUsers: [],
          totalCount: 0,
        };
      }
      const resp = await triggerGetBusinessUsers({
        businessId,
        userStartPos: userStartPosRef.current,
        invitedStartPos: invitedStartPosRef.current,
        nextCount: 50,
      }).unwrap();

      const { totalCount, usersCount, invitedCount } = resp;
      totalCountRef.current = totalCount;
      userStartPosRef.current += usersCount;
      invitedStartPosRef.current += invitedCount;
      return resp;
    } catch (e) {
      throw new Error(e);
    }
  }, [businessId, triggerGetBusinessUsers]);

  const onAddNewUser = () => {
    setRefreshGrid(false);
    dispatch(showAddNewUserDialog());
  };

  const onEditUser = useCallback((data) => {
    setShowEditUser(true);
    setCurrentUser(data);
    setRefreshGrid(false);
  }, []);

  const onAddEditSuccess = (type) => {
    if (type === "ADD") {
      dispatch(hideAddNewUserDialog());
    } else if (type === "EDIT") {
      setShowEditUser(false);
      setCurrentUser(null);
    }
    totalCountRef.current = 0;
    userStartPosRef.current = 0;
    invitedStartPosRef.current = 0;
    setTimeout(() => {
      setRefreshGrid(true);
    }, 500); // 0.5s little trick to refresh the grid. For some reason, grid renders the cached component instead of refresh.
  };

  const handleClickCSV = async (_, done) => {
    const data = await triggerGetBusinessUsers({
      businessId,
      userStartPos: null,
      invitedStartPos: null,
      nextCount: null,
    }).unwrap();
    const { businessUsers } = data;
    const csvData = businessUsers.map((bU) => ({
      name: bU.user.firstName + " " + bU.user.lastName,
      email: bU.user.email,
      role: bU.role,
      status: bU.status,
      addedAt: moment(bU.startTime).format("MM/DD/YYYY HH:mm"),
    }));
    setCSVData(csvData);
    done(true);
  };

  const getCSVData = () => csvData;

  if (isBmLoading || !businessId) {
    return <PageLoader />;
  }

  return (
    <Box className={classes.inBuildAppCard}>
      <Box className={classes.groupTitleBox}>
        <Box display="flex" justifyContent="center" flex={1}>
          <span className={classes.pageTitle}>Users</span>
        </Box>
        {isBmAndAdminUser && billingInfo?.active && (
          <Box className={classes.titleBox}>
            <Button
              variant="outlined"
              aria-controls="simple-menu"
              aria-haspopup="true"
              onClick={onAddNewUser}
            >
              <Hidden smDown>
                <PersonAdd
                  className={`${classes.buttonContainer} ${classes.iconStyles} ${classes.iconButtonIconStyles}`}
                  fontSize="medium"
                />
              </Hidden>
              <Typography color="textSecondary" variant="body2">
                ADD USER
              </Typography>
            </Button>
            <CSVLink
              data={getCSVData}
              filename="business_users.csv"
              asyncOnClick={true}
              onClick={handleClickCSV}
              headers={csvHeaders}
            >
              <Button
                variant="outlined"
                aria-controls="simple-menu"
                aria-haspopup="true"
              >
                <Hidden smDown>
                  <SvgIcon>
                    <CSVExportIcon />
                  </SvgIcon>
                </Hidden>
                <Typography color="textSecondary" variant="body2">
                  EXPORT TO CSV
                </Typography>
              </Button>
            </CSVLink>
          </Box>
        )}
      </Box>
      <Box className={classes.tableWrapper}>
        <UsersByBusinessGrid
          businessId={businessId}
          onEditUser={onEditUser}
          onLoadMoreUsers={onLoadMoreUsers}
          refresh={refreshGrid}
        />
      </Box>
      {showAddNewUser && (
        <AddEditBusinessUser
          open={showAddNewUser}
          onClose={() => dispatch(hideAddNewUserDialog())}
          onAddEditSuccess={onAddEditSuccess}
        />
      )}
      {showEditUser && currentUser && (
        <AddEditBusinessUser
          open
          user={currentUser}
          onClose={() => setShowEditUser(false)}
          onAddEditSuccess={onAddEditSuccess}
        />
      )}
      {showRemoveUser && currentUser && (
        <RemoveUser
          open
          user={currentUser}
          onClose={() => setShowRemoveUser(false)}
        />
      )}
    </Box>
  );
};

export default AdminManageUsers;
