import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  showCreateGroup: false,
  showJoinGroup: false,
  showFeedback: false,
  showPayment: false,
  showPaymentSuccess: false,
  showUpdatePayment: false,
  showAddNewUser: false,
  showMySettings: false,
  showInviteUsers: false,
  showHelp: false,
  showAddNewLead: false,
  prevModalData: null,
  error: null,
  loading: false,
};

const modalSlices = createSlice({
  name: "modal",
  initialState,
  reducers: {
    showCreateGroupModal: (state) => {
      state.showCreateGroup = true;
    },
    hideCreateGroupModal: (state) => {
      state.showCreateGroup = false;
    },
    showJoinGroupDialog: (state) => {
      state.showJoinGroup = true;
    },
    hideJoinGroupDialog: (state) => {
      state.showJoinGroup = false;
    },
    showFeedbackDialog: (state, action) => {
      state.showFeedback = action.payload;
    },
    showPaymentDialog: (state, action) => {
      state.showPayment = true;
      state.prevModalData = action.payload;
    },
    hidePaymentDialog: (state) => {
      state.showPayment = false;
    },
    showPaymentSuccessDialog: (state) => {
      state.showPaymentSuccess = true;
    },
    hidePaymentSuccessDialog: (state) => {
      state.showPaymentSuccess = false;
    },
    showUpdatePaymentDialog: (state) => {
      state.showUpdatePayment = true;
    },
    hideUpdatePaymentDialog: (state) => {
      state.showUpdatePayment = false;
    },
    showAddNewUserDialog: (state) => {
      state.showAddNewUser = true;
    },
    hideAddNewUserDialog: (state) => {
      state.showAddNewUser = false;
    },
    showInviteUsersDialog: (state, action) => {
      state.showInviteUsers = action.payload;
    },
    showMySettingsDialog: (state, action) => {
      state.showMySettings = action.payload;
    },
    showHelpDialog: (state, action) => {
      state.showHelp = action.payload;
    },
    showAddNewLeadDialog: (state, action) => {
      state.showAddNewLead = action.payload;
    },
  },
});

export const {
  showCreateGroupModal,
  hideCreateGroupModal,
  showJoinGroupDialog,
  hideJoinGroupDialog,
  showFeedbackDialog,
  showPaymentDialog,
  hidePaymentDialog,
  showPaymentSuccessDialog,
  hidePaymentSuccessDialog,
  showUpdatePaymentDialog,
  hideUpdatePaymentDialog,
  showAddNewUserDialog,
  hideAddNewUserDialog,
  showInviteUsersDialog,
  showMySettingsDialog,
  showHelpDialog,
  showAddNewLeadDialog,
} = modalSlices.actions;

export default modalSlices.reducer;
