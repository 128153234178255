import React from "react";
import { makeStyles } from "@mui/styles";
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";

export const imagePath = {
  salesforce: "/images/dashboard/crms/salesforce.png",
  hubspot: "/images/dashboard/crms/hubspot.png",
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    backgroundColor: "white",
    boxShadow: "none",
    "&:nth-child(1)": {
      marginRight: 8,
    },
    "&:nth-child(2)": {
      marginLeft: 8,
    },
    paddingLeft: 16,
    paddingRight: 16,
    [theme.breakpoints.down("xs")]: {
      padding: 8,
    },
  },
  media: {
    height: 80,
    backgroundSize: "auto",
    paddingTop: "8px",
    paddingBottom: "8px",
    objectFit: "contain",
  },
}));

export default function CrmCard({ crm, title }) {
  const classes = useStyles();

  return (
    <Card className={classes.root}>
      <CardMedia
        className={classes.media}
        component="img"
        src={imagePath[crm]}
        title={title}
      />
    </Card>
  );
}
