import React, { useContext, useEffect } from "react";
import {
  ThemeProvider,
  createTheme,
  StyledEngineProvider,
} from "@mui/material/styles";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { AppContext } from "components";
import AppLayout from "../AppLayout/AppLayout";
import CssBaseline from "@mui/material/CssBaseline";
import { useDispatch } from "react-redux";
import { isAdBlockDetected } from "redux/slices/App";

const AppWrapper = ({ children }) => {
  const { theme, direction } = useContext(AppContext);
  const dispatch = useDispatch();
  useEffect(() => {
    if (direction === "rtl") {
      document.body.setAttribute("dir", "rtl");
    } else {
      document.body.setAttribute("dir", "ltr");
    }
  }, [direction]);

  useEffect(() => {
    detectAdBlocker();
  }, [dispatch]);

  const detectAdBlocker = () => {
    fetch("https://connect.facebook.net/en_US/fbevents.js", {
      mode: "no-cors",
    }).catch((error) => {
      if (error.message.match(/Failed to fetch/)) {
        // Track ad block and put into redux state for reference later
        dispatch(isAdBlockDetected(true));
      }
    });
  };

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={createTheme(theme)}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <CssBaseline />
          <AppLayout>{children}</AppLayout>
        </LocalizationProvider>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

export default AppWrapper;
