import React from "react";
import PropTypes from "prop-types";
import { Field } from "formik";
import TextField from "./TextField";
import CheckboxField from "./CheckboxField";

const types = {
  TEXT: "text",
  CHECKBOX: "checkbox",
};

const getComponent = (type) => {
  switch (type) {
    case types.CHECKBOX:
      return CheckboxField;
    default:
      return TextField;
  }
};

const FormField = ({ componentType, component, ...rest }) => (
  <Field component={component || getComponent(componentType)} {...rest}>
    {rest.children}
  </Field>
);

FormField.propTypes = {
  componentType: PropTypes.string,
  component: PropTypes.elementType,
};

FormField.defaultProps = {
  componentType: types.TEXT,
  component: undefined,
};

FormField.componentTypes = types;

export default FormField;
