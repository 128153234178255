import { bugsnagNotify } from "services/bugsnag";

const ratingEndpoints = (builder) => ({
  getRatingQuality: builder.query({
    query: (leadId) => ({
      url: "/rating/quality/get",
      method: "POST",
      data: { lead_id: leadId },
    }),
    async onQueryStarted(_, { queryFulfilled }) {
      try {
        await queryFulfilled;
      } catch (e) {
        bugsnagNotify(
          new Error(e?.error?.data?.message ?? "Rating get quality failed."),
          e
        );
      }
    },
  }),
  addRatingRelevancy: builder.mutation({
    query: ({ leadId, groupId, rating }) => ({
      url: "/rating/relevancy/rate",
      method: "POST",
      data: { lead_id: leadId, group_id: groupId, rating },
    }),
    async onQueryStarted(_, { queryFulfilled }) {
      try {
        await queryFulfilled;
      } catch (e) {
        bugsnagNotify(
          new Error(e?.error?.data?.message ?? "Add rating relevancy failed."),
          e
        );
      }
    },
  }),
  addRatingQuality: builder.mutation({
    query: ({ leadId, rating }) => ({
      url: "/rating/quality/rate",
      method: "POST",
      data: { lead_id: leadId, rating },
    }),
    async onQueryStarted(_, { queryFulfilled }) {
      try {
        await queryFulfilled;
      } catch (e) {
        bugsnagNotify(
          new Error(e?.error?.data?.message ?? "Add rating quality failed."),
          e
        );
      }
    },
  }),
  getUserRatings: builder.query({
    query: (userIds) => ({
      url: "/rating/user",
      method: "POST",
      data: { user_ids: userIds },
    }),
    async onQueryStarted(_, { queryFulfilled }) {
      try {
        await queryFulfilled;
      } catch (e) {
        // Bugsnag report
      }
    },
  }),
});

export default ratingEndpoints;
