import React, { useEffect, useRef, useState } from "react";
import { api } from "services/api";
import { CHAT } from "utils/constants";

const Panel = ({ roomId }) => {
  const [messages, setMessages] = useState([]); // eslint-disable-line
  const [triggerGetMessages, { data: messagesData }] =
    api.endpoints.getChatRoomMessageList.useLazyQuery();
  const offsetRef = useRef(0);
  const limit = 50;
  useEffect(() => {
    triggerGetMessages({
      roomId,
      listMessageType: CHAT.LIST_MESSAGE_TYPE.ALL,
      offset: offsetRef.current,
      limit,
    });
  }, []);

  useEffect(() => {
    if (messagesData?.messages) {
      setMessages(messagesData?.messages);
      offsetRef.current += limit;
    }
  }, [messagesData]);

  return <></>;
};

export default Panel;
