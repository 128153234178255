import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme) => ({
  root: {
    display: "grid",
    gridTemplateRows: "auto auto 1fr auto",
    background: theme.palette.background.paper,
    width: "100%",
    height: "100%",
    paddingLeft: theme.spacing(8),
    paddingRight: theme.spacing(8),
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    zIndex: 1,
  },
  header: {
    display: "grid",
    gridTemplateColumns: "auto 1fr auto",
    paddingBottom: theme.spacing(2),

    "& .left": {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",

      "& .content-wrapper": {
        marginLeft: theme.spacing(6),
      },
    },

    "& .middle": {
      color: theme.palette.primary.main,
      fontSize: 24,
      textTransform: "uppercase",
      fontWeight: 800,
      display: "flex",
      justifyContent: "center",
    },
  },
}));
