import { getRedirectUrl, ROUTES } from "utils/constants";
import { AutoFixHigh, Message, Badge } from "@mui/icons-material";

export const getOpportunityTabs = (opportunityId) => [
  {
    id: "information",
    label: "Information",
    icon: <AutoFixHigh />,
    link: getRedirectUrl(ROUTES.OPPORTUNITY_INFORMATION, opportunityId),
  },
  {
    id: "messages",
    label: "Messages",
    icon: <Message />,
    link: getRedirectUrl(ROUTES.OPPORTUNITY_MESSAGE_LIST, opportunityId),
  },
  {
    id: "contacts",
    label: "Contacts",
    icon: <Badge />,
    link: getRedirectUrl(ROUTES.OPPORTUNITY_CONTACT_LIST, opportunityId),
  },
];
