import { createApi } from "@reduxjs/toolkit/query/react";
import axios from "axios";
import * as endpoints from "./endpoints";
import { baseURL } from "utils/env";

const axiosBaseQuery = async (config) => {
  // TODO: remove this hack once you fully integrate baackend admin user
  // if (config.url === '/business/get_by_user') {
  //   return {
  //     data: {
  //       role: 'ADMIN',
  //       business: { businessId: 1 },
  //     },
  //   };
  // }

  const accessToken = localStorage.getItem("access_token");
  const newConfig = {
    baseURL,
    ...config,
  };

  if (accessToken) {
    newConfig.headers = {
      ...newConfig.headers,
      Authorization: `bearer ${accessToken}`,
    };
  }

  try {
    const result = await axios(newConfig);
    return { data: result.data };
  } catch (axiosError) {
    let err = axiosError;
    return {
      error: { status: err.response?.status, data: err.response?.data },
    };
  }
};

export const api = createApi({
  reducerPath: "api",
  baseQuery: axiosBaseQuery,
  tagTypes: [
    "Activity",
    "BusinessByUser",
    "BusinessUser",
    "Users",
    "Billings",
    "Chat",
    "Group",
    "GROUP_NOTIFICATION",
    "Feedback",
    "Lead",
    "Product",
    "Rating",
    "Opportunity",
    "Contact",
    "Notification",
  ],
  endpoints: (builder) =>
    Object.values(endpoints)
      .map((e) => e(builder))
      .reduce((prev, cur) => ({ ...prev, ...cur }), {}),
});

// Activity service
export const { useGetGlobalActivitiesQuery, useGetGroupActivitiesQuery } = api;

// User service
export const {
  useEmailSignUpMutation,
  useLoginMutation,
  useLinkedinLoginMutation,
  useGetLinkedinLoginUrlQuery,
  useUserUpdateMutation,
  useResetPasswordMutation,
  useEmailConfirmActionMutation,
  useGetUserQuery,
  useGetUserListQuery,
  useSearchUserByNameQuery,
  useGetGroupNotificationSettingsQuery,
  useUpdateGroupNotificationMutation,
  useGetUserListByGroupQuery,
  useGoogleLoginMutation,
  useAppleLoginMutation,
  useScheduleDeleteUserMutation,
} = api;

// Business service
export const {
  useGetBusinessUserQuery,
  useAddUserToBusinessMutation,
  useUpdateBusinessUserMutation,
  useRemoveBusinessUserMutation,
} = api;

// CRM service
export const { useCheckCrmIntegrationExistQuery } = api;

// Billing service
export const {
  useCreateStripeCustomerMutation,
  useUpdatePaymentMutation,
  useStartSubscriptionMutation,
  useGetStripeInvoicesQuery,
  useGetStripePaymentMethodQuery,
  useGetBillingQuery,
} = api;

// Chat services
export const {
  useGetChatLeadListMessagesQuery,
  useSendChatLeadMessageMutation,
  useGetChatRoomListQuery,
  useGetChatRoomMessageListQuery,
  useGetChatRoomDetailsQuery,
} = api;

// Group services
export const {
  useGetGroupQuery,
  useGetGroupListQuery,
  useVerifyGroupExistQuery,
  useAddGroupMutation,
  useCreatePrivateGroupMutation,
  useInviteGroupUserMutation,
  useAddInvitedGroupUserMutation,
  useLeaveGroupMutation,
  useRemoveGroupMutation,
  useUpdateGroupMutation,
  useUpdateGroupUserMutation,
  useGetGroupStatsQuery,
} = api;

// Feedback services
export const { useSubmitFeedbackMutation } = api;

// Lead services
export const {
  useGetLeadListQuery,
  useGetLeadQuery,
  useCreateLeadMutation,
  useUpdateLeadMutation,
  useAssignUserLeadMutation,
  useGetLeadGroupStatsQuery,
  useDeleteLeadMutation,
} = api;

// Product services
export const { useGetProductListQuery, useGetProductSubCategoryListQuery } =
  api;

// Rating services
export const {
  useGetRatingQualityQuery,
  useAddRatingRelevancyMutation,
  useAddRatingQualityMutation,
  useGetUserRatingsQuery,
} = api;

// Target services
export const { useGetTargetListQuery } = api;

// Opportunity services
export const {
  useGetOpportunityListQuery,
  useGetOpportunityQuery,
  useCreateOpportunityMutation,
  useUpdateOpportunityMutation,
  useDeleteOpportunityMutation,
  useCreateOppContactMutation,
  useGetOppContactQuery,
  useUpdateOppContactMutation,
  useDeleteOppContactMutation,
  useInviteOpportunityUserMutation,
  useAddInvitedOpportunityUserMutation,
} = api;

// Notification services
export const {
  useGetNotificationSettingQuery,
  useToggleNotificationSettingMutation,
} = api;
