import React, { useState } from "react";
import MenuItem from "@mui/material/MenuItem";
import { Box } from "@mui/material";
import { AppTextInput } from "components";
import {
  useGetProductListQuery,
  useGetProductSubCategoryListQuery,
} from "services/api";

const ProductSelect = ({ value, setValue, ...props }) => {
  const [selectedCategory, setSelectedCategory] = useState(0);
  const {
    data: productsData,
    isSuccess: isProductsFetchSuccess,
    isLoading: isProductsLoading,
    isFetching: isProductsFetching,
  } = useGetProductListQuery(selectedCategory, {
    skip: selectedCategory === 0,
  });
  const { data: subCategoriesData, isSuccess: isSubCategoriesFetchSuccess } =
    useGetProductSubCategoryListQuery();

  return (
    <Box>
      <AppTextInput
        label="Category"
        variant="outlined"
        fullWidth
        select
        id="category"
        name="category"
        placeholder="Category"
        margin="normal"
        size="medium"
        value={selectedCategory}
        onChange={(event) => setSelectedCategory(event.target.value)}
        InputLabelProps={{ shrink: true }}
        {...props}
      >
        {isSubCategoriesFetchSuccess &&
          subCategoriesData?.subcategories.map((cat, index) => (
            <MenuItem key={`cat-${index}`} value={cat.subcategoryId}>
              {cat.subcategory}
            </MenuItem>
          ))}
      </AppTextInput>
      <AppTextInput
        label="Product"
        variant="outlined"
        fullWidth
        select
        id="product"
        name="product"
        placeholder="Product"
        margin="normal"
        size="medium"
        value={value}
        disabled={isProductsLoading || isProductsFetching}
        onChange={(event) => setValue(event.target.value)}
        InputLabelProps={{ shrink: true }}
        {...props}
      >
        {isProductsFetchSuccess &&
          productsData?.products.map((product, index) => (
            <MenuItem key={`prod-${index}`} value={product.productId}>
              {product.product}
            </MenuItem>
          ))}
      </AppTextInput>
    </Box>
  );
};

export default ProductSelect;
