import Bugsnag from "@bugsnag/js";
import BugsnagPluginReact from "@bugsnag/plugin-react";
import { apiEnv, bugsnagApiKey, isLocalDev } from "utils/env";
import packageJson from "../../package.json";

const baseReport = {
  history: [],
  state: {},
};

export function bugsnagUpdateReduxMetadata(state, action) {
  baseReport.state = state;
  baseReport.history.push(action);
  // Remove the first entry each time a new action is added to the stack once we
  // reach 100 items in the history to prevent large memory usage (base64 values
  // can be quite large)
  if (baseReport.history.length > 100) baseReport.history.shift();
}

export function bugsnagOnError(event, metadata) {
  event.app.releaseStage = apiEnv;

  // Add redux state and history
  event.addMetadata("Redux State", baseReport.state);
  event.addMetadata("Redux History", baseReport.history);

  // Add user info
  const { userId, userInfo: { email, firstName, lastName } = {} } =
    baseReport?.state?.user || {};
  if (userId) event.setUser(userId, email, `${firstName} ${lastName}`);

  // Add metadata
  if (metadata) {
    for (const [key, value] of Object.entries(metadata)) {
      event.addMetadata(key, value);
    }
  }
}

export function bugsnagNotify(error, metadata) {
  if (isLocalDev) {
    /* eslint-disable no-console */
    console.warn("Bugsnag error event not sent due to local dev mode");
    console.error(error, metadata);
    /* eslint-enable no-console */
  } else {
    Bugsnag.notify(error, (event) => bugsnagOnError(event, metadata));
  }
}

Bugsnag.start({
  apiKey: bugsnagApiKey,
  plugins: [new BugsnagPluginReact()],
  appVersion: packageJson?.version,
  releaseStage: apiEnv,
});
