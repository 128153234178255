import React, { useState, useEffect, useMemo, useCallback } from "react";
import useStyles from "./Grid.style";
import clsx from "clsx";
import { AgGridReact } from "ag-grid-react";
import PropTypes from "prop-types";
import theme from "theme/theme";
import { AvatarWithBadge, RatingBar } from "components";

const Grid = ({
  columnDefs,
  createDataSource,
  options,
  customComponents,
  refresh = false,
  serverSide = true,
  rowData,
}) => {
  const { ...props } = options || {};
  const classes = useStyles();
  const [gridApi, setGridApi] = useState(null);

  useEffect(() => {
    if (refresh && gridApi) {
      gridApi.refreshServerSideStore({ purge: false });
    }
  }, [refresh, gridApi]);

  const onGridReady = useCallback(
    (params) => {
      setGridApi(params.api);
      if (!!createDataSource && serverSide) {
        params.api.setServerSideDatasource(createDataSource());
      }
    },
    [createDataSource, serverSide]
  );

  const serverSideProps = useMemo(() => {
    return serverSide
      ? {
          rowModelType: "serverSide",
          onGridReady: onGridReady,
          serverSideInfiniteScroll: true,
        }
      : {};
  }, [onGridReady, serverSide]);

  const rowStyle = useMemo(
    () => ({
      backgroundColor: theme.palette.common.white,
      boxShadow: "0px 1px 3px rgba(0, 0, 0, 0.2)",
      maxHeight: "62px",
    }),
    []
  );

  return (
    <div className={clsx(classes.gridRoot, "ag-theme-alpine")}>
      <AgGridReact
        columnDefs={columnDefs}
        paginationAutoPageSize
        pagination
        animateRows
        paginationPageSize={50}
        rowHeight={70}
        cacheBlockSize={50}
        rowStyle={rowStyle}
        rowData={!serverSide && rowData}
        alwaysShowHorizontalScroll={true}
        frameworkComponents={{
          userRatingCellRenderer: RatingBar,
          avatarCellRenderer: AvatarWithBadge,
          ...customComponents,
        }}
        style={{ width: "100%" }}
        {...serverSideProps}
        {...props}
      />
    </div>
  );
};

Grid.propTypes = {
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      field: PropTypes.string.isRequired,
      title: PropTypes.string,
    })
  ),
  createDataSource: PropTypes.func,
  options: PropTypes.object,
  refresh: PropTypes.bool,
};

export default Grid;
