import { Box } from "@mui/material";
import React from "react";
import { Text } from "components";
import useStyles from "./ListItemCardBadge.style";

const ListItemCardBadge = ({ title }) => {
  const classes = useStyles();
  return (
    <Box className={classes.container}>
      <Text className={classes.badgeTitle}>{title}</Text>
    </Box>
  );
};

export default ListItemCardBadge;
