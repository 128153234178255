import { createSelector } from "reselect";

const leadStateSliceSelector = (state) => state.lead;

export const leadCurrentSelector = createSelector(
  leadStateSliceSelector,
  (leadSlice) => leadSlice.currentLead
);

export const leadsSelectedSelector = createSelector(
  leadStateSliceSelector,
  (leadSlice) => leadSlice.selectedLeads
);
