import React, { useEffect, useState } from "react";
import { Box, Snackbar } from "@mui/material";
import {
  AuthBox,
  AuthWrapper,
  AppLogo,
  Input,
  Button,
  LegalFooter,
} from "components";
import useStyles from "./SignUp.style";
import { Formik, Form } from "formik";
import * as yup from "yup";
import { useEmailSignUpMutation } from "services/api";
import { useLocation } from "react-router";

const schema = yup.object().shape({
  firstName: yup.string().required("First name is required"),
  lastName: yup.string().trim().required("Last name is required"),
  email: yup
    .string()
    .trim()
    .email("Must be a valid email address")
    .required("Email is required"),
  password: yup
    .string()
    .trim()
    .min(8, "Must be at least 8 characters long")
    .max(50, "Must be less than 50 characters long")
    .required("Password is required"),
  passwordConfirmation: yup
    .string()
    .required("Password confirmation is required")
    .oneOf([yup.ref("password")], "Passwords does not match"),
});

const SignUp = () => {
  const classes = useStyles();
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [showSnackBarError, setShowSnackBarError] = useState(false);
  const [emailSignUp, { isLoading: submitting, isSuccess, isError }] =
    useEmailSignUpMutation();
  const [initialValues, setInitialValues] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    passwordConfirmation: "",
  });
  const location = useLocation();
  console.log("State: ", JSON.stringify(location.state));
  useEffect(() => {
    if (isSuccess) {
      setShowSnackbar(true);
    }
    if (isError) {
      setShowSnackBarError(true);
    }
  }, [isSuccess, isError]);

  useEffect(() => {
    if (location.state) {
      const { email } = location.state;
      setInitialValues((prev) => ({ ...prev, email }));
    }
  }, [location, setInitialValues]);

  const handleSnackbarClose = () => {
    setShowSnackbar(false);
    setShowSnackBarError(false);
  };

  const handleSubmit = (values) => {
    let params = { ...values };
    console.log("State: ", JSON.stringify(location.state));
    if (location.state) {
      const { inviteCode, groupId, action } = location.state;
      if (action === "GROUP_INVITE") {
        params = {
          ...params,
          groupInviteCode: inviteCode,
          groupId: groupId,
        };
      }
    }
    emailSignUp(params);
  };

  return (
    <AuthWrapper variant="bgColor">
      <AuthBox>
        <AppLogo size="md" />
        <Formik
          initialValues={initialValues}
          enableReinitialize
          validationSchema={schema}
          onSubmit={handleSubmit}
        >
          {({ values, errors, touched, handleChange, handleBlur }) => (
            <Form className={classes.wrapper}>
              <Box flex={1} marginRight={4}>
                <Input.Text
                  label="First Name"
                  inputId="firstName"
                  touched={touched}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  errors={errors}
                  values={values}
                  disabled={submitting}
                />
                <Input.Text
                  label="Last Name"
                  inputId="lastName"
                  touched={touched}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  errors={errors}
                  values={values}
                  disabled={submitting}
                />
                <Input.Text
                  label="Email"
                  inputId="email"
                  touched={touched}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  errors={errors}
                  values={values}
                  disabled={submitting}
                />
              </Box>
              <Box flex={1} marginLeft={4}>
                <Input.Text
                  label="Password"
                  inputId="password"
                  touched={touched}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  errors={errors}
                  values={values}
                  disabled={submitting}
                  secureTextEntry
                />
                <Input.Text
                  label="Password confirmation"
                  inputId="passwordConfirmation"
                  touched={touched}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  errors={errors}
                  values={values}
                  disabled={submitting}
                  secureTextEntry
                />
                <Button
                  text="CREATE MY ACCOUNT"
                  loading={submitting}
                  type="submit"
                  disabled={submitting}
                  containerStyle={classes.submitBtn}
                />
                <Snackbar
                  open={showSnackbar}
                  autoHideDuration={3000}
                  className={classes.snackbar}
                  onClose={handleSnackbarClose}
                  message="Your account has been created. Check your e-mail for activation."
                />
                <Snackbar
                  open={showSnackBarError}
                  autoHideDuration={3000}
                  className={classes.snackbar}
                  onClose={handleSnackbarClose}
                  message="Your account can not be created, please contact support."
                />
              </Box>
            </Form>
          )}
        </Formik>
        <LegalFooter />
      </AuthBox>
    </AuthWrapper>
  );
};

export default SignUp;
