import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  container: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    backgroundColor: theme.palette.background.lightGray,
    borderRadius: 4,
    boxShadow:
      "0px 1px 3px rgba(0, 0, 0, 0.2), 0px 2px 1px rgba(0, 0, 0, 0.12), 0px 1px 1px rgba(0, 0, 0, 0.14)",
    position: "relative",
    overflow: "hidden",
    padding: 24,
  },
}));

export default useStyles;
