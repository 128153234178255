import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  container: {
    padding: "16px 16px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    "& hr": {
      margin: "0px 62px",
    },
    [theme.breakpoints.down("xs")]: {
      // mobile responsive
      flexDirection: "column",
      padding: "16px 16px",
      "& hr": {
        height: "0px",
      },
    },
  },
}));

export default useStyles;
