import React from "react";
import { Box, Typography } from "@mui/material";
import { ReactComponent as StatusActive } from "assets/icons/status_active.svg";
import { ReactComponent as StatusInActive } from "assets/icons/status_inactive.svg";
import { ReactComponent as StatusInvited } from "assets/icons/status_invited.svg";
import { upperFirst } from "lodash";
import { BusinessUserStatusType } from "utils/constants";

const StatusCell = (props) => {
  const renderStatusIcon = () => {
    const status = props.value;
    if (status === BusinessUserStatusType.ACTIVE) {
      return <StatusActive />;
    } else if (status === BusinessUserStatusType.INACTIVE) {
      return <StatusInActive />;
    } else if (status === BusinessUserStatusType.INVITED) {
      return <StatusInvited />;
    }
  };
  const textColor =
    props.value === BusinessUserStatusType.INACTIVE
      ? "textSecondary"
      : "textPrimary";

  return (
    <Box>
      {renderStatusIcon()}
      <Typography color={textColor} variant="body2" display="inline">
        &nbsp;&nbsp;{upperFirst(props.value)}
      </Typography>
    </Box>
  );
};

export default React.memo(StatusCell);
