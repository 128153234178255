import React from "react";
import { CheckboxWithLabel as MaterialCheckboxWithLabel } from "formik-mui";
import Typography from "@mui/material/Typography";

const CheckboxWithLabel = ({ label, ...rest }) => (
  <MaterialCheckboxWithLabel
    type="checkbox"
    color="primary"
    {...rest}
    Label={{
      label: (
        <Typography variant="caption" color="primary">
          {label}
        </Typography>
      ),
    }}
  />
);

export default CheckboxWithLabel;
