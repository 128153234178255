import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  container: {},
  titleBox: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  buttonWrapper: {
    display: "flex",
    position: "absolute",
    right: 24,
    "& button:nth-child(1)": {
      marginRight: 8,
    },
    [theme.breakpoints.down("md")]: {
      "& > button > span": {
        display: "none",
      },
      "& > button > p": {
        fontSize: 12,
      },
    },
  },
  activitiesWrapper: {
    flex: 1,
    display: "flex",
    flexDirection: "row",
    marginTop: 32,
  },
  sectionWrapper: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
    alignItems: "center",
    position: "relative",
  },
  sectionIcon: {
    color: theme.palette.common.dark,
    opacity: 0.45,
  },
  snackbar: {
    position: "absolute",
    left: "30%",
    right: "30%",
    display: "block",
    transform: "translateY(-150%)",
  },
  listContainer: {
    position: "absolute",
    left: 0,
    right: 0,
    bottom: 0,
    top: 40,
  },
}));

export default useStyles;
