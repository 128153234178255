import React from "react";
import { ReactComponent as CloseSVG } from "assets/icons/payment_close.svg";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  IconButton,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { CmtImage } from "components";
import { ReactComponent as PaymentLogo } from "assets/icons/payment_logo.svg";
import { ROUTES } from "utils/constants";
import { useDispatch } from "react-redux";
import { showUpdatePaymentDialog } from "redux/slices/Modal";

const useStyles = makeStyles((theme) => ({
  dialogRoot: {
    position: "relative",
    padding: "42px 8px",
    maxWidth: "none",
    "& .MuiDialogContent-root": {
      display: "flex",
      flexDirection: "row",
      padding: 8,
      "& img": {
        minWidth: 347,
        minHeight: 258,
      },
      "& > div:nth-of-type(2)": {
        "& h1": {
          marginTop: 32,
        },
        "& p": {
          marginTop: 16,
        },
        "& button:nth-of-type(1)": {
          marginTop: 24,
        },
      },
      [theme.breakpoints.down("sm")]: {
        flexDirection: "column",
        padding: "32px 16px",
        "& > div:nth-of-type(2)": {
          marginLeft: 0,
          marginTop: 16,
          textAlign: "center",
        },
        "& img": {
          minWidth: (347 / 3) * 2,
          minHeight: (258 / 3) * 2,
        },
      },
    },
  },
  btnWrapper: {
    marginTop: 40,
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
  },
  closeBtn: {
    position: "absolute",
    left: 0,
    top: 0,
  },
  manageBtn: {
    backgroundColor: "#3FA5FB",
    color: "white",
    "&:hover": {
      backgroundColor: "#3FA5FBE5",
    },
    marginTop: 8,
  },
  contentWrapper: {
    "& > h1": {
      fontWeight: 400,
      fontSize: 32,
    },
  },
  logoImg: {
    width: 356,
    height: 67,
    [theme.breakpoints.down("xs")]: {
      width: 178,
      height: 34,
    },
  },
}));

const PaymentSuccess = ({ open, handleDialog }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const onManageSubscription = () => {
    handleDialog();
    dispatch(showUpdatePaymentDialog());
  };

  return (
    <Dialog
      open={open}
      onClose={handleDialog}
      PaperProps={{ className: classes.dialogRoot }}
    >
      <IconButton className={classes.closeBtn} onClick={handleDialog}>
        <CloseSVG />
      </IconButton>
      <DialogContent>
        <Box
          display="flex"
          flex={1}
          alignItems="center"
          justifyContent="center"
        >
          <CmtImage
            src="/images/dashboard/payment/payment_success.png"
            alt="Payment Success"
          />
        </Box>
        <Box flex={1} className={classes.contentWrapper}>
          <PaymentLogo className={classes.logoImg} />
          <Typography variant="h1" color="textSecondary">
            Woohoo!
          </Typography>
          <Typography variant="body2" color="textSecondary">
            You have an active subscription
          </Typography>
          <Box className={classes.btnWrapper}>
            <Button
              color="secondary"
              variant="contained"
              href={ROUTES.ADMIN_MANAGE_USER}
            >
              Start ADDING NEW USERS
            </Button>
            <Button
              className={classes.manageBtn}
              variant="contained"
              onClick={onManageSubscription}
            >
              Manage My Subscription
            </Button>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default PaymentSuccess;
