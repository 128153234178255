import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";

const imageLinks = {
  ios: "https://linkmaker.itunes.apple.com/images/badges/en-us/badge_appstore-lrg.svg",
  android:
    "https://upload.wikimedia.org/wikipedia/commons/thumb/7/78/Google_Play_Store_badge_EN.svg/320px-Google_Play_Store_badge_EN.svg.png",
};

const useStyles = makeStyles(() => ({
  container: {
    width: (props) => props.width,
    height: (props) => props.height,
    textAlign: "center",
    display: "block",
    marginTop: 32,
    marginLeft: "auto",
    marginRight: "auto",
  },
}));

const MobileStoreButton = ({
  store,
  url,
  height = 40,
  width = 135,
  linkProps,
  ...rest
}) => {
  const classes = useStyles({ width, height });

  const defaultLinkStyles = {
    background: `url(${imageLinks[store]}) no-repeat`,
    backgroundSize: "contain",
    display: "inline-block",
    overflow: "hidden",
    textDecoration: "none",
    height: "100%",
    width: "100%",
    padding: "5px",
  };

  return (
    <div className={classes.container} {...rest}>
      <a
        style={defaultLinkStyles}
        href={url}
        target="_blank"
        rel="noopener noreferrer"
        {...linkProps}
      >
        &nbsp;
      </a>
    </div>
  );
};

MobileStoreButton.propTypes = {
  store: PropTypes.oneOf(["ios", "android"]).isRequired,
  url: PropTypes.string.isRequired,
  height: PropTypes.number,
  width: PropTypes.number,
  linkStyles: PropTypes.object,
  linkProps: PropTypes.object,
};

export default MobileStoreButton;
