import React, { useMemo, useCallback } from "react";
import Grid from "./Grid";
import ActionButtons from "./ActionButtons";
import { Rating } from "@mui/material";
import theme from "theme/theme";

const OpportunityContactListGrid = React.memo(
  ({ onRemoveContact, onEditContact, onClickRow, ...rest }) => {
    const columnDefs = useMemo(
      () => [
        {
          field: "",
          maxWidth: 60,
          cellRendererSelector: () => {
            return {
              component: "avatarCellRenderer",
              params: {
                size: 40,
                hideBadge: true,
              },
            };
          },
        },
        {
          field: "fullName",
          headerName: "Contact Name",
          cellStyle: { color: theme.palette.text.primary },
        },
        {
          field: "company",
          headerName: "Company",
          cellStyle: { color: theme.palette.text.primary },
        },
        {
          field: "title",
          headerName: "Title",
          cellStyle: { color: theme.palette.text.primary },
        },
        {
          field: "notes",
          headerName: "Notes",
          cellStyle: { color: theme.palette.text.primary },
        },
        {
          headerName: "Actions",
          cellRenderer: "actionButtonsCellRenderer",
          cellRendererParams: {
            handleRemove: onRemoveContact,
            handleEdit: onEditContact,
          },
          field: "",
        },
      ],
      [onRemoveContact, onEditContact]
    );

    const customComponents = useMemo(
      () => ({
        actionButtonsCellRenderer: ActionButtons,
        ratingCellRenderer: Rating,
      }),
      [ActionButtons, Rating]
    );

    const onSelectionChanged = useCallback(
      ({ api }) => {
        const selectedRows = api.getSelectedRows();
        onClickRow(selectedRows[0]);
      },
      [onClickRow]
    );

    return (
      <Grid
        columnDefs={columnDefs}
        customComponents={customComponents}
        options={{
          suppressCellFocus: true,
          rowSelection: "single",
          onSelectionChanged: onSelectionChanged,
        }}
        serverSide={false}
        {...rest}
      />
    );
  }
);

OpportunityContactListGrid.displayName = "OpportunityContactListGrid";

export default OpportunityContactListGrid;
