import { initializeApp } from "firebase/app";
import {
  getAdditionalUserInfo,
  getAuth,
  GoogleAuthProvider,
  signInWithPopup,
} from "firebase/auth";

/* eslint-disable no-undef */
const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};
/* eslint-enable no-undef */

export const firebaseApp = initializeApp(firebaseConfig);
export const googleAuthProvider = new GoogleAuthProvider();
googleAuthProvider.addScope("https://www.googleapis.com/auth/userinfo.email");
googleAuthProvider.addScope("https://www.googleapis.com/auth/userinfo.profile");

export const firebaseGoogleLogin = async () => {
  try {
    const auth = getAuth(firebaseApp);
    const result = await signInWithPopup(auth, googleAuthProvider);
    const idToken = await result?.user?.getIdToken();
    const { profile } = getAdditionalUserInfo(result);
    return { idToken, profile };
  } catch (e) {
    const message = e.message;
    throw new Error(message);
  }
};
