import React from "react";
import PropTypes from "prop-types";
import useStyles from "components/Modals/index.style";
import { Box, Link } from "@mui/material";
import CommonDialog from "./CommonDialog";

const CRMRequest = ({ open, handleDialogClose }) => {
  const classes = useStyles();
  const buttons = [
    {
      text: "OK",
      onClick: handleDialogClose,
      variant: "contained",
      color: "primary",
    },
  ];

  const getText = () => {
    return (
      <Box component="span">
        For questions and support for CRM integration, contact the Alliance team
        at{" "}
        <Link
          href="mailto:support@allianceapp.com"
          className={classes.crmEmail}
        >
          <b>support@allianceapp.com</b>
        </Link>
      </Box>
    );
  };

  return (
    <CommonDialog
      id="support"
      title="CRM integration"
      text={getText()}
      open={open}
      handleClose={handleDialogClose}
      dialogContentClass={classes.dialogContent}
      buttons={buttons}
    />
  );
};

export default CRMRequest;

CRMRequest.propTypes = {
  open: PropTypes.bool.isRequired,
  handleDialogClose: PropTypes.func.isRequired,
};
