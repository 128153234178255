import React from "react";
import globalStyles from "../../../theme/GlobalCss";
import { Box } from "@mui/material";
import VerticalMinimal from "./VerticalLayouts/VerticalMinimal/VerticalMinimal";
import { useLocation } from "react-router-dom";

/*
const useStyles = makeStyles(theme => ({
  circularProgressRoot: {
    position: 'absolute',
    left: 0,
    top: 0,
    zIndex: 1,
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));*/

const AppLayout = ({ children }) => {
  // const { layout, setLayout, layoutStyle, setLayoutStyle, themeType, updateThemeType, updateThemeColors } = useContext(
  // AppContext,
  // );
  // const { loadUser } = useSelector(({ auth }) => auth);
  // const dispatch = useDispatch();
  // const classes = useStyles();

  const location = useLocation();
  globalStyles();

  // useEffect(() => {
  //   // dispatch(AuhMethods[CurrentAuthMethod].getAuthUser());
  //   updateThemeType(themeType);
  //   setTemplateLoading(true);
  //
  //   const params = new URLSearchParams(location.search);
  //   if (params.get('layout')) {
  //     setLayout(params.get('layout'));
  //   }
  //
  //   if (params.get('layout-style')) {
  //     setLayoutStyle(params.get('layout-style'));
  //   }
  //
  //   if (params.get('theme-type')) {
  //     updateThemeType(params.get('theme-type'));
  //   }
  //
  //   if (params.get('theme-color')) {
  //     updateThemeColors(THEME_COLORS[params.get('theme-color') - 1]);
  //   }
  // }, []);

  // useEffect(() => {
  //   setLayoutType();
  // }, [layoutStyle]);

  // const setLayoutType = () => {
  //   if (layoutStyle === LAYOUT_STYLES.FULL_WIDTH) {
  //     document.body.classList.remove('layout-type-boxed');
  //     document.body.classList.remove('layout-type-framed');
  //     document.body.classList.add('layout-type-fullwidth');
  //   } else if (layoutStyle === LAYOUT_STYLES.BOXED) {
  //     document.body.classList.remove('layout-type-fullwidth');
  //     document.body.classList.remove('layout-type-framed');
  //     document.body.classList.add('layout-type-boxed');
  //   } else if (layoutStyle === LAYOUT_STYLES.FRAMED) {
  //     document.body.classList.remove('layout-type-boxed');
  //     document.body.classList.remove('layout-type-fullwidth');
  //     document.body.classList.add('layout-type-framed');
  //   }
  // };
  if (
    location.pathname === "/" ||
    location.pathname === "/signin" ||
    location.pathname === "/signup" ||
    location.pathname === "/redirect" ||
    location.pathname === "/forgot-password" ||
    location.pathname.includes("/reset-password") ||
    location.pathname === "/download-app" ||
    location.pathname === "/signup-confirmation" ||
    location.pathname === "/group/user_invited" ||
    location.pathname === "/oauth_login" ||
    location.pathname === "/test-flow"
  ) {
    return (
      <Box display="flex" width={1} style={{ minHeight: "100vh" }}>
        {children}
      </Box>
    );
  }
  return <VerticalMinimal>{children}</VerticalMinimal>;
};

export default AppLayout;
