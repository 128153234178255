import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  titleBox: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  backButtonWrapper: {
    display: "flex",
    position: "absolute",
    left: 24,
  },
  buttonWrapper: {
    display: "flex",
    position: "absolute",
    right: 24,
    [theme.breakpoints.down("md")]: {
      "& > button > span": {
        display: "none",
      },
      "& > button > p": {
        fontSize: 12,
      },
    },
  },
  mr2: {
    marginRight: 8,
  },
  tableWrapper: {
    flex: 1,
    display: "flex",
    marginTop: 32,
  },
  listContainer: {
    width: "100%",
    maxHeight: "calc(100% - 64px)",
  },
}));

export default useStyles;
