import { bugsnagNotify } from "services/bugsnag";

const billingEndpoints = (builder) => ({
  createStripeCustomer: builder.mutation({
    query: (userId, priceId = null) => ({
      url: "/billing/create_customer",
      method: "POST",
      data: { user_id: userId, price_id: priceId },
    }),
    async onQueryStarted(_, { queryFulfilled }) {
      try {
        await queryFulfilled;
      } catch (e) {
        bugsnagNotify(
          new Error(
            e?.error?.data?.message ?? "Create stripe customer failed."
          ),
          e
        );
      }
    },
    invalidatesTags: ["Billings"],
  }),
  updatePayment: builder.mutation({
    query: (paymentMethodId) => ({
      url: "/billing/update_payment",
      method: "POST",
      data: { payment_method_id: paymentMethodId },
    }),
    async onQueryStarted(_, { queryFulfilled }) {
      try {
        await queryFulfilled;
      } catch (e) {
        bugsnagNotify(
          new Error(e?.error?.data?.message ?? "Update payment failed."),
          e
        );
      }
    },
  }),
  startSubscription: builder.mutation({
    query: (subscriptionType) => ({
      url: "/billing/start_stripe_subscription",
      method: "POST",
      data: { subscription_type: subscriptionType },
    }),
    async onQueryStarted(_, { queryFulfilled }) {
      try {
        await queryFulfilled;
      } catch (e) {
        bugsnagNotify(
          new Error(e?.error?.data?.message ?? "Start subscription failed."),
          e
        );
      }
    },
    invalidatesTags: ["Billings"],
  }),
  getStripeInvoices: builder.query({
    query: (userId) => ({
      url: "/billing/get_stripe_invoices",
      method: "POST",
      data: { user_id: userId },
    }),
    async onQueryStarted(_, { queryFulfilled }) {
      try {
        await queryFulfilled;
      } catch (e) {
        bugsnagNotify(
          new Error(e?.error?.data?.message ?? "Get stripe invoices failed"),
          e
        );
      }
    },
    providesTags: [{ type: "Billings", id: "List" }],
  }),
  getStripePaymentMethod: builder.query({
    query: () => ({
      url: "/billing/get_stripe_payment_method",
      method: "POST",
    }),
    async onQueryStarted(_, { queryFulfilled }) {
      try {
        await queryFulfilled;
      } catch (e) {
        bugsnagNotify(
          new Error(
            e?.error?.data?.message ?? "Get stripe payment method failed"
          ),
          e
        );
      }
    },
  }),
  getBilling: builder.query({
    query: () => ({
      url: "/billing/get",
      method: "POST",
    }),
    async onQueryStarted(_, { queryFulfilled }) {
      try {
        await queryFulfilled;
      } catch (e) {
        bugsnagNotify(
          new Error(e?.error?.data?.message ?? "Get billing info failed"),
          e
        );
      }
    },
    providesTags: ["Billings"],
  }),
});

export default billingEndpoints;
