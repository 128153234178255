import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  container: {
    textAlign: "center",
    marginTop: 24,
  },
  termsAndConditionsText: {
    display: "flex",
    flexDirection: "row",
    marginBottom: 16,
  },
  linkText: {
    textDecorationLine: "underline",
    fontSize: 12,
    fontWeight: "bold",
    letterSpacing: 1.5,
    cursor: "pointer",
  },
  text: {
    fontSize: 12,
  },
}));

export default useStyles;
