import { bugsnagNotify } from "services/bugsnag";

const businessEndpoints = (builder) => ({
  getBusinessUser: builder.query({
    query: (userId) => ({
      url: "/business/get_by_user",
      method: "POST",
      data: { user_id: userId },
    }),
    async onQueryStarted(_, { queryFulfilled }) {
      try {
        await queryFulfilled;
      } catch (e) {
        const message =
          e?.error?.data?.message ?? "Get business by user failed";
        const status = e?.error?.status;

        // We don't want to report errors about no business for the user
        if (message === "business not found" || status === 404) return;

        bugsnagNotify(new Error(message), e);
      }
    },
    providesTags: (result, error, userId) => [
      { type: "BusinessUser", id: userId },
    ],
  }),
  getBusinessUsers: builder.query({
    query: ({
      businessId,
      userStartPos = 0,
      invitedStartPos = 0,
      nextCount = null,
      userStatus = null,
      userRole = "ALL_ROLE",
    }) => ({
      url: "business/get_business_users",
      method: "POST",
      data: {
        business_id: businessId,
        start_position_users: userStartPos,
        start_position_invited: invitedStartPos,
        get_next: nextCount,
        user_status: userStatus,
        user_role: userRole,
      },
    }),
    async onQueryStarted(_, { queryFulfilled }) {
      try {
        await queryFulfilled;
      } catch (e) {
        bugsnagNotify(
          new Error(e?.error?.data?.message ?? "Get business users failed"),
          e
        );
      }
    },
    providesTags: (result) =>
      result?.businessUsers
        ? // successful query
          [
            ...result.businessUsers.map(({ user }) => ({
              type: "Users",
              id: user?.userId,
            })),
            { type: "Users", id: "LIST" },
          ]
        : // an error occurred, but we still want to refetch this query when `{ type: 'Users', id: 'LIST' }` is invalidated
          [{ type: "Users", id: "LIST" }],
  }),
  addUserToBusiness: builder.mutation({
    query: (data) => ({
      url: "/business/invite_user",
      method: "POST",
      data,
    }),
    async onQueryStarted(_, { queryFulfilled }) {
      try {
        await queryFulfilled;
      } catch (e) {
        bugsnagNotify(
          new Error(e?.error?.data?.message ?? "Invite user to businessfailed"),
          e
        );
      }
    },
    invalidatesTags: [{ type: "Users", id: "LIST" }],
  }),
  updateBusinessUser: builder.mutation({
    query: (data) => ({
      url: "/business/update_user",
      method: "POST",
      data,
    }),
    async onQueryStarted(_, { queryFulfilled }) {
      try {
        await queryFulfilled;
      } catch (e) {
        bugsnagNotify(
          new Error(e?.error?.data?.message ?? "Update business user failed"),
          e
        );
      }
    },
    invalidatesTags: (result, error, { id }) => [{ type: "Users", id }],
  }),
  removeBusinessUser: builder.mutation({
    query: (data) => ({
      url: "/business/remove_user",
      method: "POST",
      data,
    }),
    async onQueryStarted(_, { queryFulfilled }) {
      try {
        await queryFulfilled;
      } catch (e) {
        bugsnagNotify(
          new Error(
            e?.error?.data?.message ?? "Remove user from business failed"
          ),
          e
        );
      }
    },
    invalidatesTags: [{ type: "Users", id: "LIST" }],
  }),
  getErrorProne: builder.query({
    query: () => "error-prone",
  }),
});

export default businessEndpoints;
