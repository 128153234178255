import axios from "axios";

/* eslint-disable quotes */
export const linkify = (inputText) => {
  var replacedText, replacePattern1, replacePattern2, replacePattern3;

  // URLs starting with http://, https://, or ftp://
  replacePattern1 =
    /(\b(https?|ftp):\/\/[-A-Z0-9+&@#\\/%?=~_|!:,.;]*[-A-Z0-9+&@#\\/%=~_|])/gim;
  replacedText = inputText.replace(
    replacePattern1,
    '<a href="$1" target="_blank">$1</a>'
  );

  // URLs starting with "www." (without // before it, or it'd re-link the ones done above).
  replacePattern2 = /(^|[^\\/])(www\.[\S]+(\b|$))/gim;
  replacedText = replacedText.replace(
    replacePattern2,
    '$1<a href="http://$2" target="_blank">$2</a>'
  );

  // Change email addresses to mailto:: links.
  replacePattern3 = /(([a-zA-Z0-9])+@[a-zA-Z0-9\\-]+?(\.[a-zA-Z]{2,6})+)/gim;
  replacedText = replacedText.replace(
    replacePattern3,
    '<a href="mailto:$1">$1</a>'
  );

  return replacedText;
};

/* eslint-enable no-undef */
export const authorizedPost = async (url, body) => {
  return axios.post(url, body, {
    headers: {
      "Content-Type": "application/json",
      Authorization: "bearer " + localStorage.getItem("access_token"),
    },
  });
};

export const getAddressFromGoogleObject = ({
  streetOne,
  streetTwo,
  city,
  stateAbbrv,
}) => {
  const streetAddress = [streetOne, streetTwo]
    .filter((value) => value)
    .join(" ");

  return [streetAddress, city, stateAbbrv].filter((value) => value).join(", ");
};

export const formatPhoneNumberInUS = (number) => {
  var cleaned = ("" + number).replace(/\D/g, "");
  var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    var intlCode = match[1] ? "+1 " : "";
    return [intlCode, "(", match[2], ") ", match[3], "-", match[4]].join("");
  }
  return null;
};
