import React, { useContext } from "react";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import LayoutContext from "./LayoutContext/LayoutContext";

const SidebarToggleHandler = ({ children, ...restProps }) => {
  const { isOpen, setOpen } = useContext(LayoutContext);

  return (
    <IconButton
      className="Cmt-toggle-menu"
      onClick={() => setOpen(!isOpen)}
      {...restProps}
      size="large"
    >
      {children || <MenuIcon />}
    </IconButton>
  );
};

export default SidebarToggleHandler;
