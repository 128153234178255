import React, { useEffect, useState } from "react";
import { Box, Button, Typography, Snackbar, IconButton } from "@mui/material";
import { Close } from "@mui/icons-material";
import useStyles from "../index.style";
import { CmtImage, PageLoader, CrmCard } from "components";
import { useCheckCrmIntegrationExistQuery } from "services/api";
import CRMRequest from "components/Modals/CRMRequest";

const Crms = () => {
  const classes = useStyles();
  const [crmDetails, setCRMDetails] = useState({
    title: "",
    subTitle1: "",
    subTitle2: "",
    buttonTitle: "",
  });
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [openRequestDialog, setOpenRequestDialog] = useState(false);
  const { data, error, isLoading } = useCheckCrmIntegrationExistQuery();

  useEffect(() => {
    if (!isLoading && data) {
      const title = data.integrationExists
        ? "Can you feel the magic?"
        : "Works like magic!";
      const subTitle1 = data.integrationExists
        ? "You currently has an active CRM integration"
        : "We can integrate your Salesforce or Hubspot CRM with Alliance.";
      const subTitle2 = data.integrationExists
        ? "If you need support with CRM, click the button below and we will contact you shortly."
        : "Click the button below and we will contact you shortly.";
      const buttonTitle = data.integrationExists
        ? "REQUEST CRM INTEGRATION SUPPORT"
        : "REQUEST TO INTEGRATE YOUR CRM";
      setCRMDetails({ title, subTitle1, subTitle2, buttonTitle });
    }
  }, [data, isLoading]);

  useEffect(() => {
    if (error) {
      setOpenSnackbar(true);
    }
  }, [error]);

  const handleSnackbarClose = () => {
    setOpenSnackbar(false);
  };

  const requestCRM = () => {
    setOpenRequestDialog(true);
  };

  const handleCRMRequestClose = () => {
    setOpenRequestDialog(false);
  };

  if (isLoading) {
    return <PageLoader />;
  }

  return (
    <Box className={classes.inBuildAppCard}>
      <Box className={classes.groupTitleBox}>
        <Box display="flex" justifyContent="center">
          <span className={classes.pageTitle}>CRM integration</span>
        </Box>
      </Box>
      <Box className={classes.crmContainer}>
        <Box className={classes.crmLeft}>
          <Box textAlign="center">
            <Typography variant="h1" className={classes.crmTitle}>
              {crmDetails.title}
            </Typography>
            <Typography>{crmDetails.subTitle1}</Typography>
            <Typography>{crmDetails.subTitle2}</Typography>
          </Box>
          {!isLoading && !data?.integrationExists && (
            <Box display="flex" flexDirection="row">
              <CrmCard crm="salesforce" />
              <CrmCard crm="hubspot" />
            </Box>
          )}
          <Button
            fullWidth
            variant="contained"
            color="primary"
            onClick={requestCRM}
          >
            {crmDetails.buttonTitle}
          </Button>
        </Box>
        <Box>
          <CmtImage src={"/images/dashboard/crms/integration.png"} />
        </Box>
      </Box>
      {openRequestDialog && (
        <CRMRequest
          open={openRequestDialog}
          handleDialogClose={handleCRMRequestClose}
        />
      )}
      <Snackbar
        open={openSnackbar}
        autoHideDuration={3000}
        message={error?.data?.message ?? "Unknown error"}
        onClose={handleSnackbarClose}
        action={
          <IconButton
            size="small"
            color="inherit"
            onClick={handleSnackbarClose}
          >
            <Close fontSize="small" />
          </IconButton>
        }
      />
    </Box>
  );
};

export default Crms;
