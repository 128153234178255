import React from "react";
import { Box, IconButton, SvgIcon } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { ReactComponent as EditIcon } from "assets/icons/edit_pencil.svg";

const useStyles = makeStyles((theme) => ({
  pointer: {
    "&:hover": {
      cursor: "pointer",
    },
  },
  buttonContainer: {
    backgroundColor: "#023E6E",
    color: "#FFFFFF",
    borderRadius: "4px",
    "&:hover": {
      cursor: "pointer",
    },
  },
  iconStyles: {
    background: "transparent",
    color: theme.palette.primary.main,
  },
}));

// eslint-disable-next-line no-unused-vars
const UserActionButtons = ({ data, handleEditUser, handleRemoveUser }) => {
  const classes = useStyles();

  if (data.status === "INVITED") {
    // Block updating users for invited users at this time
    return null;
  }

  return (
    <Box>
      <IconButton
        className={`${classes.buttonContainer} ${classes.iconStyles}`}
        fontSize="large"
        onClick={() => handleEditUser(data)}
      >
        <SvgIcon>
          <EditIcon />
        </SvgIcon>
      </IconButton>
      {/* <IconButton
        className={`${classes.buttonContainer} ${classes.iconStyles}`}
        fontSize="large"
        onClick={() => handleRemoveUser(data)}
      >
        <SvgIcon>
          <RemoveIcon />
        </SvgIcon>
      </IconButton> */}
    </Box>
  );
};

export default UserActionButtons;
