import React from "react";
import { Box, IconButton, SvgIcon } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Delete, Edit } from "@mui/icons-material";

const useStyles = makeStyles((theme) => ({
  pointer: {
    "&:hover": {
      cursor: "pointer",
    },
  },
  buttonContainer: {
    backgroundColor: "#023E6E",
    color: "#FFFFFF",
    borderRadius: "4px",
    "&:hover": {
      cursor: "pointer",
    },
  },
  iconStyles: {
    background: "transparent",
    color: theme.palette.primary.main,
  },
}));

// eslint-disable-next-line no-unused-vars
const ActionButtons = ({
  data,
  handleEdit,
  handleRemove,
  color = "primary",
}) => {
  const classes = useStyles();

  if (data.status === "INVITED") {
    // Block updating users for invited users at this time
    return null;
  }

  return (
    <Box>
      {handleEdit && (
        <IconButton
          ref={(ref) => {
            if (!ref) return;
            ref.onclick = (e) => {
              e.stopPropagation();
              handleEdit(data);
            };
          }}
          className={`${classes.buttonContainer} ${classes.iconStyles}`}
          fontSize="large"
        >
          <SvgIcon>
            <Edit color={color} />
          </SvgIcon>
        </IconButton>
      )}
      {handleRemove && (
        <IconButton
          ref={(ref) => {
            if (!ref) return;
            ref.onclick = (e) => {
              e.stopPropagation();
              handleRemove(data);
            };
          }}
          className={`${classes.buttonContainer} ${classes.iconStyles}`}
          fontSize="large"
        >
          <SvgIcon>
            <Delete color={color} />
          </SvgIcon>
        </IconButton>
      )}
    </Box>
  );
};

export default ActionButtons;
