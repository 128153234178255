import React from "react";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { useGetTargetListQuery } from "services/api";

const TargetSelect = ({ value, setValue }) => {
  const { data, isSuccess } = useGetTargetListQuery();

  return (
    <Select
      label="Target"
      value={value}
      fullWidth={true}
      variant="outlined"
      onChange={(event) => setValue(event.target.value)}
    >
      {isSuccess &&
        data.targets?.map((target, index) => (
          <MenuItem key={index} value={target.targetId}>
            {target.target}
          </MenuItem>
        ))}
    </Select>
  );
};

export default TargetSelect;
