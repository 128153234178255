import React from "react";
import { Tab, Tabs } from "@mui/material";
import useStyles from "./TabBar.style";
import { Text } from "components";
import clsx from "clsx";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

const LinkTab = ({ href, ...restProps }) => (
  <Tab LinkComponent={Link} to={href} {...restProps} />
);

const TabBar = ({
  tabs,
  selectedId,
  containerStyle,
  buttonStyle,
  buttonLabelStyle,
  onChangeTab,
}) => {
  const classes = useStyles();

  return (
    <Tabs
      variant="fullWidth"
      value={selectedId}
      onChange={onChangeTab}
      className={clsx(classes.tabBarContainer, containerStyle)}
      TabIndicatorProps={{ hidden: true }}
    >
      {tabs.map((tab) => (
        <LinkTab
          key={tab.id}
          className={clsx(classes.tabBarButton, buttonStyle)}
          icon={tab.icon}
          label={
            <Text
              variant="button"
              className={clsx(classes.tabBarButtonLabel, buttonLabelStyle)}
            >
              {tab.label}
            </Text>
          }
          classes={{
            selected: classes.selected,
          }}
          href={tab.link}
        />
      ))}
    </Tabs>
  );
};

TabBar.propTypes = {
  tabs: PropTypes.arrayOf(PropTypes.object).isRequired,
  selectedId: PropTypes.number,
  containerStyle: PropTypes.string,
  buttonStyle: PropTypes.string,
  buttonLabelStyle: PropTypes.string,
  onChangeTab: PropTypes.func,
};

TabBar.defaultProps = {
  selectedId: 0,
};

export default TabBar;
