import { makeStyles } from "@mui/styles";
import theme from "theme/theme";

const useStyles = makeStyles(() => ({
  activeIndicator: {
    width: 4,
    backgroundColor: theme.palette.info.light,
  },
  avatar: {
    marginRight: 16,
    backgroundColor: "#F7F7F7",
    alignSelf: "center",
  },
  avatarLabel: {
    fontFamily: "Poppins",
    fontWeight: 500,
    fontSize: 20,
    color: theme.palette.common.dark,
    background: "#F7F7F7",
    opacity: 0.9,
  },
  title: {
    fontSize: 16,
    fontFamily: "Poppins",
    fontWeight: 600,
    color: theme.palette.common.dark,
    marginLeft: 8,
  },
  subtitle: {
    fontSize: 12,
    fontWeight: 400,
    color: theme.palette.common.dark,
    opacity: 0.5,
    marginLeft: 8,
  },
  caption: {
    marginLeft: 8,
    fontSize: 12,
    fontWeight: 400,
    alignSelf: "center",
    color: theme.palette.common.dark,
    opacity: 0.5,
  },
  flexGrow: {
    flexBasis: 1,
    flexGrow: 1,
  },
  iconContainer: {
    marginLeft: 8,
    alignSelf: "flex-start",
  },
  container: {
    display: "flex",
    borderRadius: 4,
    cursor: "pointer",
  },
  cardContent: {
    padding: 16,
    flex: 1,
    "&:last-child": {
      paddingBottom: 16,
    },
  },
}));

export default useStyles;
