import React from "react";
import { useGetNotificationSettingQuery } from "services/api";
import { NOTIFICATION } from "utils/constants";

const ChatBusinessSettings = ({ businessId }) => {
  const { data: notificationSettingData } = useGetNotificationSettingQuery({ // eslint-disable-line
    deliveryMethod: NOTIFICATION.DELIVERY_METHOD.ALL,
    service: NOTIFICATION.SERVICE.BUSINESS,
    serviceId: businessId,
    action: NOTIFICATION.ACTION.ALL,
  });
  return <></>;
};

export default ChatBusinessSettings;
