import { Dialog, DialogTitle, IconButton } from "@mui/material";
import React from "react";
import { makeStyles } from "@mui/styles";
import { ReactComponent as CloseSVG } from "assets/icons/payment_close.svg";
import PaymentForm from "./Payment/PaymentForm";
import { useGetStripePaymentMethodQuery } from "services/api";

const useStyles = makeStyles((theme) => ({
  dialogRoot: {
    position: "absolute",
    margin: 0,
    right: 0,
    top: theme.statics.headerHeight,
    bottom: 0,
    maxWidth: "none",
    width: "30%",
    height: "auto",
    "& .MuiDialogContent-root": {
      display: "flex",
    },
    [theme.breakpoints.down("sm")]: {
      width: "75%",
      padding: 16,
    },
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
  closeBtn: {
    position: "absolute",
    left: 0,
    top: 0,
  },
  dialogTitleRoot: {
    "& > h2": {
      fontSize: 24,
      color: theme.palette.common.dark,
      justifyContent: "center",
      fontWeight: 400,
      display: "flex",
    },
  },
  hr: {
    marginLeft: 16,
    marginRight: 16,
    opactiy: "#0000001F",
  },
}));

const ManagePayment = ({ open, handleClose }) => {
  const classes = useStyles();
  const { data: paymentInfo } = useGetStripePaymentMethodQuery(null, {
    refetchOnMountOrArgChange: true,
  });

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      PaperProps={{ className: classes.dialogRoot }}
      hideBackdrop
    >
      <IconButton
        className={classes.closeBtn}
        onClick={handleClose}
        size="large"
      >
        <CloseSVG />
      </IconButton>
      <DialogTitle className={classes.dialogTitleRoot}>
        PAYMENT INFORMATION
      </DialogTitle>
      <hr className={classes.hr} />
      {!!paymentInfo && (
        <PaymentForm flex={1} paymentInfo={paymentInfo?.paymentMethodDetails} />
      )}
    </Dialog>
  );
};

export default ManagePayment;
