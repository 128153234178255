import React from "react";
import { List } from "@mui/material";
import NavMenuItem from "./NavMenuItem";
import NavSection from "./NavSection";
import NavCollapse from "./NavCollapse";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  sideNavMenu: {
    paddingTop: "10px",
    paddingBottom: "10px",
    position: "relative",
    display: "flex",
    flexDirection: "column",
    height: "100%",
  },
}));

const CmtVertical = (props) => {
  const { menuItems } = props;
  const classes = useStyles();
  return (
    <List component="nav" disablePadding className={classes.sideNavMenu}>
      {menuItems.map((item, index) => {
        switch (item.type) {
          case "section":
            return <NavSection {...item} key={index} />;
          case "collapse":
            return <NavCollapse {...item} key={index} />;
          case "item":
            return <NavMenuItem {...item} key={index} />;
          default:
            return null;
        }
      })}
    </List>
  );
};

export default CmtVertical;
