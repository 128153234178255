import ContactApp from "./ContactApp";
import User from "./User";
import Lead from "./Lead";
import Modal from "./Modal";
import App from "./App";

const reducers = {
  app: App,
  contactApp: ContactApp,
  user: User,
  lead: Lead,
  modal: Modal,
};

export default reducers;
