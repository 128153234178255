import { makeStyles } from "@mui/styles";
import theme from "theme/theme";

const useStyles = makeStyles(() => ({
  tabBarContainer: {},
  tabBarButton: {
    flex: 1,
    maxWidth: "none",
  },
  tabBarButtonLabel: {
    color: theme.palette.common.dark,
    opacity: 0.9,
  },
  selected: {
    color: `${theme.palette.common.dark} !important`,
    opacity: 0.9,
    backgroundColor: "white",
  },
}));

export default useStyles;
