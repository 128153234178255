import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  dialogTitleText: {
    fontWeight: "bold",
    fontSize: "1.5rem",
    fontStyle: "normal",
    lineHeight: "2rem",
    textAlign: "center",
    color: theme.palette.common.black,
  },
  dialogContent: {
    width: theme.spacing(100),
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "1.5rem",
    lineHeight: "2rem",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    verticalAlign: "middle",
  },
  dialogContentText: {
    width: theme.spacing(80),
  },
}));

export default useStyles;
