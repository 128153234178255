import React from "react";
import axios from "axios";
import ReactDOM from "react-dom";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import { ACCESS_TOKEN, REFRESH_TOKEN } from "utils/constants";
import { baseURL } from "utils/env";

axios.interceptors.response.use(
  undefined /* dont need an interceptor for success response */,
  function (error) {
    // hit error
    if (error.response && error.response.status === 401) {
      // error is a 401
      let refreshToken = localStorage.getItem(REFRESH_TOKEN);
      if (refreshToken !== null) {
        // we have a refresh token, sending refresh req
        // exchange the refresh token for a new access token
        axios
          .post(`${baseURL}/user/refresh_token`, {
            refresh_token: refreshToken,
          })
          .then((response) => {
            if (response.status === 200) {
              // response success, setting new tokens
              localStorage.setItem(ACCESS_TOKEN, response.data.accessToken);
              localStorage.setItem(REFRESH_TOKEN, response.data.refreshToken);
              // reloading page
              window.location.reload();
            } else {
              // response was an error, logging out
              window.location.href = "/signout";
            }
          });
      } else {
        // no refresh token found, logging out
        // redirect to /signin
        window.location.href = "/signin";
      }
    }
    return Promise.reject(error);
  }
);

ReactDOM.render(<App />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
