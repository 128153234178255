import { Person, Group, Message } from "@mui/icons-material";
import { getRedirectUrl, ROUTES } from "utils/constants";

export const getGroupTabs = (groupId) => {
  return [
    {
      id: "leads",
      label: "Leads",
      icon: <Person />,
      link: getRedirectUrl(ROUTES.GROUP_LEAD_LIST, groupId),
    },
    {
      id: "users",
      label: "Users",
      icon: <Group />,
      link: getRedirectUrl(ROUTES.GROUP_USER_LIST, groupId),
    },
    {
      id: "messages",
      label: "Messages",
      icon: <Message />,
      link: getRedirectUrl(ROUTES.GROUP_MESSAGE_LIST, groupId),
    },
  ];
};
