import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  container: {
    display: "flex",
    flex: 1,
    flexGrow: 1,
    width: "100%",
    flexDirection: "column",
    overflow: "auto",
  },
  item: {
    width: "100%",
  },
}));

export default useStyles;
