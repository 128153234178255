import React, {
  useCallback,
  useRef,
  useMemo,
  useState,
  useEffect,
} from "react";
import { useHistory, useParams } from "react-router";
import { Box, Snackbar, IconButton } from "@mui/material";
import { Settings, Drafts, Close } from "@mui/icons-material";
import useStyles from "../Group.style";
import {
  Button,
  Container,
  PageLoader,
  PageTitle,
  TabBar,
  Empty,
  GroupUserListGrid,
  BackButton,
} from "components";
import {
  api,
  useGetGroupQuery,
  useInviteGroupUserMutation,
  useLeaveGroupMutation,
} from "services/api";
import { getRedirectUrl, ROUTES } from "utils/constants";
import InviteUsers from "components/Modals/InviteUsers";
import RemoveDialog from "components/Modals/RemoveDialog";
import { getGroupTabs } from "../Group.utils";

const Users = () => {
  const classes = useStyles();
  const { group_id: groupId } = useParams();
  const { data: groupData } = useGetGroupQuery(groupId);
  const [
    triggerGetGroupUsers,
    { isLoading: isLoadingGroupUsers, data: usersData },
  ] = api.endpoints.getUserListByGroup.useLazyQuery({
    refetchOnFocus: true,
    refetchOnReconnect: true,
  });
  const [triggerGetUserRatings] =
    api.endpoints.getUserRatings.useLazyQuerySubscription({
      refetchOnFocus: true,
      refetchOnReconnect: true,
    });
  const [, { isSuccess: inviteSuccess, reset }] = useInviteGroupUserMutation({
    fixedCacheKey: "inviteGroupUser",
  });
  const [leaveGroup, { isLoading: isRemovingUser, isSuccess: isRemovedUser }] =
    useLeaveGroupMutation();
  const [currentUser, setCurrentUser] = useState(null);
  const [refreshGrid, setRefreshGrid] = useState(false);
  const [showInviteUsers, setShowInviteUsers] = useState(false);
  const [showRemoveUserDialog, setShowRemoveUserDialog] = useState(false);
  const [snackbarMsg, setSnackbarMsg] = useState("");
  const [showSnackbar, setShowSnackbar] = useState(false);
  const history = useHistory();
  const totalCountRef = useRef(0);
  const startPosRef = useRef(0);
  const getNext = 50;
  const tabs = useMemo(() => getGroupTabs(groupId), [getGroupTabs, groupId]);

  useEffect(() => {
    if (isRemovedUser) {
      handleCloseRemoveDialog();
      setTimeout(() => {
        setRefreshGrid(true);
      }, 500);
    }
  }, [isRemovedUser]);

  useEffect(() => {
    if (inviteSuccess) {
      setSnackbarMsg("You have sent the invitation link successfully.");
      setShowSnackbar(true);
      reset();
    }
  }, [inviteSuccess]);

  const onLoadMoreUsers = useCallback(async () => {
    try {
      if (!groupId) {
        return {
          users: [],
          totalCount: 0,
        };
      }
      const resp = await triggerGetGroupUsers({
        groupId,
        startPos: startPosRef.current,
        getNext: getNext,
      }).unwrap();
      const { totalCount, users } = resp;
      // Get ratings for users
      const userIds = users.map((user) => user.userId);
      const userRatingsData = await triggerGetUserRatings(userIds).unwrap();
      const { userRatings } = userRatingsData;

      totalCountRef.current = totalCount;
      if (totalCount > startPosRef.current + getNext) {
        startPosRef.current += getNext;
      }
      return {
        users: users.map((user) => ({
          ...user,
          rating: userRatings.find((ur) => ur.userId === user.userId).rating,
        })),
        totalCount,
      };
    } catch (e) {
      throw new Error(e);
    }
  }, [groupId]);

  const handleInviteUser = () => {
    setShowInviteUsers(true);
  };

  const handleCloseInviteUsers = () => {
    setShowInviteUsers(false);
  };

  const handleOpenSettings = () => {
    history.push(getRedirectUrl(ROUTES.GROUP_SETTINGS, groupId));
  };

  const onRemoveUser = (user) => {
    setCurrentUser(user);
    setShowRemoveUserDialog(true);
  };

  const handleCloseRemoveDialog = () => {
    setCurrentUser(null);
    setShowRemoveUserDialog(false);
  };

  const onConfirmedRemoveUser = (user) => {
    leaveGroup({ groupId, userId: user.userId });
  };

  const onClickUser = () => {};

  const renderEmpty = () => (
    <Empty
      image="/images/group/group_users_empty.png"
      title={"Looks like there is\nno one in here..."}
      actions={[
        {
          heading: "Start by adding a user to your group",
          text: "INVITE USERS",
          onClick: handleInviteUser,
          fullWidth: false,
          color: "primary",
        },
      ]}
    />
  );

  const handleBack = () => {
    history.push(ROUTES.GROUP_LIST);
  };

  const handleSnackbarClose = () => {
    setSnackbarMsg("");
    setShowSnackbar(false);
  };

  if (!groupData) {
    return <PageLoader />;
  }

  return (
    <Container>
      <Box className={classes.titleBox}>
        <Box className={classes.backButtonWrapper}>
          <BackButton onClick={handleBack} />
        </Box>
        <PageTitle title={groupData.name} />
        <Box className={classes.buttonWrapper}>
          <Button
            startIcon={<Drafts />}
            color="secondary"
            fullWidth={false}
            text="INVITE USER"
            variant="outlined"
            size="small"
            onClick={handleInviteUser}
          />
          <Button
            startIcon={<Settings />}
            color="secondary"
            fullWidth={false}
            text="SETTINGS"
            variant="outlined"
            size="small"
            onClick={handleOpenSettings}
          />
        </Box>
      </Box>
      <Box display="flex" flex={1} flexDirection="column" mt={6}>
        <TabBar tabs={tabs} selectedId={1} />
        {!isLoadingGroupUsers && usersData?.totalCount === 0 && renderEmpty()}
        <Box
          display={
            !isLoadingGroupUsers && usersData?.totalCount > 0 ? "flex" : "none"
          }
          className={classes.tableWrapper}
        >
          <GroupUserListGrid
            onRemoveUser={onRemoveUser}
            onLoadMoreUsers={onLoadMoreUsers}
            refresh={refreshGrid}
            onClickRow={onClickUser}
          />
        </Box>
      </Box>
      {showInviteUsers && (
        <InviteUsers
          open={showInviteUsers}
          handleDialog={handleCloseInviteUsers}
          groupId={parseInt(groupId)}
        />
      )}
      {showRemoveUserDialog && currentUser && (
        <RemoveDialog
          open={showRemoveUserDialog}
          onClose={handleCloseRemoveDialog}
          onRemove={onConfirmedRemoveUser}
          isLoading={isRemovingUser}
          title="Remove user"
          body={"remove " + currentUser.fullName + "?"}
          data={currentUser}
        />
      )}
      <Snackbar
        open={showSnackbar}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
        message={snackbarMsg}
        className={classes.snackbar}
        action={
          <IconButton
            color="inherit"
            size="small"
            onClick={handleSnackbarClose}
          >
            <Close fontSize="small" />
          </IconButton>
        }
      />
    </Container>
  );
};

export default Users;
