import React from "react";
import { Box } from "@mui/material";
import { Text } from "components";
import useStyles from "./TextWithIcon.style";
import clsx from "clsx";
import PropTypes from "prop-types";

const TextWithIcon = ({ icon, title, titleStyle }) => {
  const classes = useStyles();

  return (
    <Box className={classes.container}>
      {icon}
      <Text className={clsx(classes.title, titleStyle)}>{title}</Text>
    </Box>
  );
};

TextWithIcon.propTypes = {
  icon: PropTypes.node,
  title: PropTypes.string,
  titleStyle: PropTypes.string,
};

TextWithIcon.defaultProps = {};

export default TextWithIcon;
