import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  authContent: {
    padding: "52px 52px",
    textAlign: "center",
    display: "flex",
    flexDirection: "row",
    "& hr": {
      margin: "0px 62px",
    },
    [theme.breakpoints.down("xs")]: {
      // mobile responsive
      flexDirection: "column",
      padding: "16px 16px",
      "& hr": {
        height: "0px",
      },
    },
  },
  wrapper: {
    marginTop: 16,
    width: "100%",
    display: "flex",
    flexDirection: "row",
    "& .MuiTextField-root": {
      marginTop: 16,
    },
  },
  ssoContainer: {
    display: "flex",
    flex: 1,
    marginTop: 16,
    flexDirection: "column",
    justifyContent: "space-between",
  },
  logoImg: {
    display: "flex",
    marginTop: "auto",
    marginBottom: "auto",
    [theme.breakpoints.down("xs")]: {
      // mobile responsive
      maxHeight: "150px",
      maxWidth: "150px",
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
  linkedinBtn: {
    fontSize: "18px",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "#013E6E",
    },
  },
  textBottom: {
    fontSize: 11,
    letterSpacing: 0.16,
  },
  submitBtn: {
    marginTop: 16,
  },
  divider: {
    marginLeft: 16,
    marginRight: 16,
  },
  text: {
    fontSize: 14,
  },
  link: {
    fontSize: 14,
    fontWeight: "bold",
  },
  snackbar: {
    position: "absolute",
    left: 64,
    right: 64,
    display: "block",
    transform: "translateX(0%) translateY(200%)",
  },
}));

export default useStyles;
