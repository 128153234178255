import React from "react";
import { CSVDownload } from "react-csv";

const headers = [
  { label: "Id", key: "leadId" },
  { label: "First Name", key: "firstName" },
  { label: "Last Name", key: "lastName" },
  { label: "Email", key: "email" },
  { label: "Phone Number", key: "phone" },
  { label: "Company", key: "company" },
  { label: "Title", key: "title" },
];

const ExportLeadsCSV = ({ data }) => (
  <CSVDownload data={data} target="_blank" headers={headers} />
);

export default ExportLeadsCSV;
