import React from "react";
import { Box } from "@mui/material";
import useStyles from "./Container.style";
import clsx from "clsx";
import PropTypes from "prop-types";

const Container = ({ children, className }) => {
  const classes = useStyles();
  return <Box className={clsx(classes.container, className)}>{children}</Box>;
};

Container.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.array]),
  className: PropTypes.string,
};

export default Container;
