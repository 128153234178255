import { makeStyles } from "@mui/styles";
import theme from "theme/theme";

const useStyles = makeStyles(() => ({
  container: {
    flexDirection: "row",
    paddingTop: 8,
    paddingLeft: 16,
  },
  text: {
    color: (props) =>
      props.active ? theme.palette.primary.main : theme.palette.secondary.main,
    opacity: (props) => (props.active ? 1 : 0.45),
    fontWeight: 600,
    fontSize: 14,
  },
}));

export default useStyles;
