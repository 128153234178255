import React from "react";
import { Card, CardContent, CardHeader, Rating, Box } from "@mui/material";
import ContentCopy from "@mui/icons-material/ContentCopy";
import Email from "@mui/icons-material/Email";
import Link from "@mui/icons-material/Link";
import MapMarker from "@mui/icons-material/Room";
import { Button, Text } from "components";
import useStyles from "./LeadContactInformation.style";
import clsx from "clsx";
import { getAddressFromGoogleObject, formatPhoneNumberInUS } from "utils/utils";
import PropTypes from "prop-types";

const LeadContactInformation = ({
  containerStyle,
  data,
  handlePhonePressed,
  handleEmailPressed,
  handleLinkedPressed,
  handleAddressPressed,
  handleRatingPressed,
}) => {
  const classes = useStyles();
  const {
    firstName,
    lastName,
    phone,
    email,
    linkedin,
    address,
    title,
    company,
    notes,
    leadId,
    isOwner,
    rating,
  } = data;

  return (
    <Card elevation={0} className={clsx(classes.cardContainer, containerStyle)}>
      <CardHeader
        title={`${firstName} ${lastName}`}
        subheader={`${title} @ ${company}`}
        action={
          handleRatingPressed ? (
            <Rating
              value={rating?.rating ?? 0}
              readOnly={isOwner}
              onChange={(e, newVal) => handleRatingPressed(leadId, newVal)}
            />
          ) : null
        }
        classes={{
          title: classes.cardTitle,
          subheader: classes.cardSubtitle,
        }}
      />
      <CardContent className={classes.cardContent}>
        {!!phone && (
          <Box>
            <Button
              endIcon={<ContentCopy />}
              text={formatPhoneNumberInUS(phone)}
              variant="text"
              style={classes.button}
              containerStyle={classes.buttonContainer}
              onClick={() => handlePhonePressed(phone)}
            />
          </Box>
        )}
        {!!email && (
          <Box>
            <Button
              endIcon={<Email />}
              text={email}
              variant="text"
              style={classes.button}
              containerStyle={classes.buttonContainer}
              onClick={() => handleEmailPressed(email)}
            />
          </Box>
        )}
        {!!linkedin && (
          <Box>
            <Button
              endIcon={<Link />}
              text="VIEW ON LINKEDIN"
              variant="text"
              style={classes.button}
              containerStyle={classes.buttonContainer}
              onClick={() => handleLinkedPressed(linkedin)}
            />
          </Box>
        )}
        {!!address && (
          <Box>
            <Button
              endIcon={<MapMarker />}
              text={getAddressFromGoogleObject(address)}
              variant="text"
              style={classes.button}
              containerStyle={classes.buttonContainer}
              onClick={() => handleAddressPressed(address)}
            />
          </Box>
        )}
        {!!notes && (
          <Box className={classes.notesContainer}>
            <Text variant="caption">Notes: </Text>
            <Text className={classes.noteText}>{notes}</Text>
          </Box>
        )}
      </CardContent>
    </Card>
  );
};

LeadContactInformation.propTypes = {
  data: PropTypes.object,
  containerStyle: PropTypes.string,
  handlePhonePressed: PropTypes.func,
  handleEmailPressed: PropTypes.func,
  handleLinkedPressed: PropTypes.func,
  handleAddressPressed: PropTypes.func,
  handleRatingPressed: PropTypes.func,
};

export default LeadContactInformation;
