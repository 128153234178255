import { makeStyles } from "@mui/styles";
const bgImage = "/images/auth/auth-bg-pattern.png";

const useStyles = makeStyles((theme) => ({
  authWrap: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    backgroundImage: `URL(${bgImage})`,
    backgroundPosition: "center center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    position: "relative",
    padding: 20,
    [theme.breakpoints.up("sm")]: {
      padding: 40,
    },
  },
  authCard: {
    position: "relative",
    zIndex: 3,
    maxWidth: (props) => props.maxWidth ?? 800,
    width: "100%",
    boxShadow: "0px 1px 5px rgba(0, 0, 0, 0.2)",
    borderRadius: 4,
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.text.secondary,
  },
}));

export default useStyles;
