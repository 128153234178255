import React from "react";
import { Formik, Form } from "formik";
import * as yup from "yup";
import { makeStyles } from "@mui/styles";
import { FormField, FormSubmitButton } from "components";

const useStyles = makeStyles(() => ({
  emailContainer: {
    marginTop: "40px",
  },
  sendBtn: {
    display: "flex",
    marginLeft: "auto",
    marginTop: "40px",
  },
}));

const initialValues = {
  email: "",
};

const validationSchema = yup.object().shape({
  email: yup
    .string()
    .trim()
    .email("Must be a valid email address")
    .required("Email is required"),
});

const ForgotPasswordForm = ({ disabled, ...rest }) => {
  const classes = useStyles();
  return (
    <Formik
      {...rest}
      initialValues={initialValues}
      validationSchema={validationSchema}
    >
      <Form>
        <FormField
          disabled={disabled}
          name="email"
          label="E-mail Address"
          placeholder="Enter your e-mail address"
          className={classes.emailContainer}
        />
        <FormSubmitButton
          disabled={disabled}
          color="primary"
          variant="contained"
          className={classes.sendBtn}
        >
          SEND E-MAIL
        </FormSubmitButton>
      </Form>
    </Formik>
  );
};

export default ForgotPasswordForm;
