import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  logoImage: {
    marginTop: "auto",
    marginBottom: "auto",
    width: (props) => {
      if (props.size === "sm") {
        return 175;
      } else if (props.size === "md") {
        return 300;
      }
    },
    height: (props) => {
      if (props.size === "sm") {
        return 33;
      } else if (props.size === "md") {
        return 57;
      }
    },
    [theme.breakpoints.down("xs")]: {
      // mobile responsive
      maxHeight: "150px",
      maxWidth: "150px",
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
}));

export default useStyles;
