import React from "react";
import { Dialog, DialogTitle, DialogContent, Box } from "@mui/material";
import useStyles from "./index.style";
import { Text, Button } from "components";
import PropTypes from "prop-types";

const RemoveDialog = ({
  open,
  onClose,
  onRemove,
  title,
  body,
  warning,
  data,
  isLoading,
  cancelButtonTitle,
  confirmButtonTitle,
}) => {
  const classes = useStyles();
  return (
    <Dialog open={open}>
      <DialogTitle className={classes.dialogTitle}>{title}</DialogTitle>
      <DialogContent dividers>
        <Box className={classes.dialogWrapper}>
          <Box>
            Do you really want to&nbsp;
            <Text variant="inherit" color="error" display="inline">
              {body}
            </Text>
          </Box>
          <Box mt={4}>
            <Text color="error">{warning}</Text>
          </Box>
          <Box
            display="flex"
            alignItems="flex-end"
            justifyContent="flex-end"
            mt={4}
          >
            <Button
              text={cancelButtonTitle ?? "Back"}
              fullWidth={false}
              size="small"
              variant="text"
              color="secondary"
              disabled={isLoading}
              onClick={onClose}
            />
            <Button
              text={confirmButtonTitle ?? "Remove"}
              color="error"
              size="small"
              loading={isLoading}
              disabled={isLoading}
              fullWidth={false}
              onClick={() => onRemove(data)}
            />
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

RemoveDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  title: PropTypes.string,
  body: PropTypes.string,
  data: PropTypes.object.isRequired,
  isLoading: PropTypes.bool,
  cancelButtonTitle: PropTypes.string,
  confirmButtonTitle: PropTypes.string,
};

export default RemoveDialog;
