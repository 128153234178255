import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Redirect } from "react-router";
import { useDispatch } from "react-redux";
import {
  Box,
  Button,
  DialogContent,
  Grid,
  Typography,
  Alert as MuiAlert,
} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import useStyles from "components/Modals/index.style";
import DialogTitle from "@mui/material/DialogTitle";
import { showPaymentDialog } from "redux/slices/Modal";
import { Formik, Form } from "formik";
import * as yup from "yup";
import { ROUTES } from "utils/constants";
import { ProductSelect, FormField } from "components";
import { useAddGroupMutation, useGetBillingQuery } from "services/api";

const Alert = (props) => (
  <MuiAlert elevation={6} variant="filled" {...props} style={{}} />
);

const validationSchema = yup.object({
  groupName: yup
    .string()
    .required("Field is required")
    .min(6, "Must be at least 6 characters long")
    .max(100, "Can't be longer than 100 characters long"),
  groupPassword: yup
    .string()
    .required("Field is required")
    .min(6, "Must be at least 6 characters long")
    .max(100, "Can't be longer than 100 characters long"),
});

const JoinExistingGroup = ({ open, handleDialog }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [product, setProduct] = useState("");
  const [requestSent, setRequestSent] = useState(false);
  const [joinSuccess, setJoinSuccess] = useState(false);
  const { data: billingInfo } = useGetBillingQuery();
  const [addGroup, { isLoading: isAddingGroup, error: addGroupError }] =
    useAddGroupMutation();

  useEffect(() => {
    if (requestSent && !isAddingGroup && !addGroupError) {
      setJoinSuccess(true);
    }
  }, [addGroupError, isAddingGroup, requestSent]);

  const handleCancel = () => {
    handleDialog();
  };

  const handleSubmit = ({ groupName, groupPassword }) => {
    if (!billingInfo.hasPaymentMethod) {
      dispatch(
        showPaymentDialog({
          action: "groupAdd",
          data: {
            groupName: groupName,
            privateCode: groupPassword,
            product_id: product,
          },
        })
      );
      handleDialog();
    } else {
      addGroup({ groupName, privateCode: groupPassword, productId: product });
      setRequestSent(true);
    }
  };

  if (joinSuccess) {
    return <Redirect to={ROUTES.GROUP_LEAD_LIST} />;
  }

  return (
    <Dialog open={open} onClose={handleDialog}>
      <DialogTitle className={classes.dialogTitle}>JOIN GROUP</DialogTitle>
      <DialogContent dividers>
        <Typography className={classes.textGeneralSecondary}>
          Please enter group name and password
        </Typography>
        <Formik
          validationSchema={validationSchema}
          initialValues={{ groupName: "", groupPassword: "" }}
          onSubmit={(values) => handleSubmit(values)}
        >
          <Form>
            <Grid
              container
              item
              spacing={3}
              className={classes.dialogWrapperJoinGroup}
            >
              <Grid item xs={12} sm={12}>
                <FormField
                  name="groupName"
                  label="Group Name"
                  placeholder="Enter your group name"
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <FormField
                  name="groupPassword"
                  label="Group Password"
                  placeholder="Enter your group password"
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <Typography className={classes.textGeneralSecondary}>
                  What product do you sell?
                </Typography>
                <ProductSelect
                  value={product}
                  setValue={setProduct}
                  className={`${classes.textField} ${classes.selectProduct}`}
                />
              </Grid>
            </Grid>

            <Box display="flex" justifyContent="flex-end" mb={4}>
              <Button onClick={handleCancel}>Cancel</Button>
              <Box ml={2}>
                <Button
                  variant="contained"
                  type="submit"
                  color="primary"
                  disabled={isAddingGroup}
                >
                  Join
                </Button>
              </Box>
            </Box>
          </Form>
        </Formik>
      </DialogContent>
      {!!addGroupError && (
        <Alert severity="error">{addGroupError?.data.message}</Alert>
      )}
    </Dialog>
  );
};

export default JoinExistingGroup;

JoinExistingGroup.propTypes = {
  open: PropTypes.bool.isRequired,
  handleDialog: PropTypes.func.isRequired,
  joinErr: PropTypes.string,
};
