import React, { useMemo, useCallback } from "react";
import theme from "theme/theme";
import { Grid, ActionButtons } from "components";

const OpportunityListGrid = React.memo(
  ({ onEditOpp, onRemoveOpp, onLoadMoreOpps, onClickRow, ...rest }) => {
    const columnDefs = useMemo(
      () => [
        {
          field: "name",
          headerName: "Opportunity Name",
          width: 250,
          cellStyle: { color: theme.palette.text.primary },
        },
        {
          field: "createdByName",
          headerName: "Owner",
          cellStyle: { color: theme.palette.text.primary },
        },
        {
          field: "notes",
          headerName: "Description",
          cellStyle: { color: theme.palette.text.primary },
        },
        {
          field: "sharedCount",
          headerName: "Shared With",
          cellStyle: { color: theme.palette.text.primary },
        },
        {
          headerName: "Actions",
          cellRenderer: "actionButtonsCellRenderer",
          cellRendererParams: {
            handleEdit: onEditOpp,
            handleRemove: onRemoveOpp,
          },
          field: "",
        },
      ],
      [onEditOpp, onRemoveOpp]
    );

    const createDataSource = useCallback(() => {
      return {
        getRows: async (params) => {
          try {
            const oppsData = await onLoadMoreOpps();
            params.success({
              rowData: oppsData?.opportunities,
              rowCount: oppsData?.totalCount,
            });
          } catch (e) {
            params.fail();
          }
        },
      };
    }, []);

    const customComponents = useMemo(
      () => ({
        actionButtonsCellRenderer: ActionButtons,
      }),
      [ActionButtons]
    );

    const onSelectionChanged = useCallback(
      ({ api }) => {
        const selectedRows = api.getSelectedRows();
        onClickRow(selectedRows[0]);
      },
      [onClickRow]
    );

    return (
      <Grid
        columnDefs={columnDefs}
        customComponents={customComponents}
        createDataSource={createDataSource}
        options={{
          suppressCellFocus: true,
          rowSelection: "single",
          onSelectionChanged: onSelectionChanged,
        }}
        {...rest}
      />
    );
  }
);

OpportunityListGrid.displayName = "OpportunityListGrid";

export default OpportunityListGrid;
