import React, { useMemo } from "react";
import { CmtVertical } from "components";
import PerfectScrollbar from "react-perfect-scrollbar";
import { makeStyles } from "@mui/styles";
import { useDispatch, useSelector } from "react-redux";
import { useGetBusinessUserQueryState } from "services/business";
import {
  ChatMenuItem,
  HelpMenuItem,
  LogoutMenuItem,
  ManageUsersMenuItem,
  MySettingsMenuItem,
  SendFeedBackMenuItem,
  CrmsMenuItem,
  InvoicesMenuItem,
  ManageGroupsMenuItem,
  ManageOpportunitiesMenuItem,
  ActivitiesMenuItem,
} from "./MenuItems";
import { BusinessUserRoleType } from "utils/constants";

const useStyles = makeStyles(() => ({
  perfectScrollbarSidebar: {
    height: "100%",
    transition: "all 0.3s ease",
  },
}));

const SideBar = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const userId = useSelector((state) => state.user.userId);
  const { data: bmUser } = useGetBusinessUserQueryState(userId);
  const bmUserMenuItems = useMemo(() => {
    return bmUser?.role === BusinessUserRoleType.ADMIN
      ? [
          {
            type: "section",
            children: [ManageUsersMenuItem, CrmsMenuItem, InvoicesMenuItem],
          },
        ]
      : [];
  }, [bmUser?.role]);
  const userMenuItems = useMemo(() => {
    return [
      {
        type: "section",
        children: [ActivitiesMenuItem],
      },
      {
        type: "section",
        children: [
          ManageGroupsMenuItem,
          ManageOpportunitiesMenuItem,
          ChatMenuItem,
        ],
      },
      {
        type: "section",
        children: [
          MySettingsMenuItem(dispatch),
          HelpMenuItem(dispatch),
          SendFeedBackMenuItem(dispatch),
        ],
      },
      {
        type: "section",
        children: [LogoutMenuItem],
      },
    ];
  }, [dispatch]);

  return (
    <PerfectScrollbar className={classes.perfectScrollbarSidebar}>
      <CmtVertical menuItems={bmUserMenuItems.concat(userMenuItems)} />
    </PerfectScrollbar>
  );
};

export default SideBar;
