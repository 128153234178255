import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  wrapper: {
    marginTop: 16,
    width: "100%",
    display: "flex",
    flexDirection: "row",
    "& .MuiTextField-root": {
      marginTop: 16,
    },
  },
  submitBtn: {
    marginTop: 16,
  },
  snackbar: {
    position: "absolute",
    left: 32,
    right: 32,
    display: "block",
    transform: "translateX(0%) translateY(-50%)",
  },
}));

export default useStyles;
