import React from "react";
import { useGetChatRoomDetailsQuery } from "services/api";

const People = ({ roomId }) => {
  const { data: chatRoomData } = useGetChatRoomDetailsQuery(roomId);  // eslint-disable-line

  return <></>;
};

export default People;
