import React, { useEffect, useRef, useState } from "react";
import { api } from "services/api";
import { CHAT } from "utils/constants";

const Channel = ({ groupId }) => {
  const [rooms, setRooms] = useState([]); // eslint-disable-line
  const [triggerGetChatRooms, { data: chatRoomData }] =
    api.endpoints.getChatRoomList.useLazyQuery();
  const offsetRef = useRef(0);
  const limit = 50;
  useEffect(() => {
    triggerGetChatRooms({
      groupingLevel: CHAT.ROOM_GROUPING_LEVEL.GROUP,
      groupingLevelId: groupId,
      offset: offsetRef.current,
      limit,
    });
  }, []);

  useEffect(() => {
    if (chatRoomData?.rooms) {
      setRooms((prev) => [...prev, ...chatRoomData.rooms]);
      offsetRef.current += limit;
    }
  }, [chatRoomData]);

  return <></>;
};

export default Channel;
