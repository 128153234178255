import React, { useEffect, useMemo, useState } from "react";
import {
  Container,
  PageTitle,
  VerticalList,
  Button,
  Text,
  SectionTitle,
  Switch,
} from "components";
import { Box, Grid } from "@mui/material";
import { Check } from "@mui/icons-material";
import useStyles from "../Settings.style";
import { getSettingsTabs } from "../Settings.utils";
import { useHistory } from "react-router";
import {
  useGetNotificationSettingQuery,
  useToggleNotificationSettingMutation,
} from "services/api";
import { NOTIFICATION } from "utils/constants";

const NotificationSettings = () => {
  const classes = useStyles();
  const history = useHistory();
  const [groupInviteSetting, setGroupInviteSetting] = useState(false);
  const [oppInviteSetting, setOppInviteSetting] = useState(false);
  const [groupMessageSetting, setGroupMessageSetting] = useState(false);
  const { data: groupInviteNotification } = useGetNotificationSettingQuery({
    deliveryMethod: NOTIFICATION.DELIVERY_METHOD.ALL,
    service: NOTIFICATION.SERVICE.GROUP,
    serviceId: 0,
    action: NOTIFICATION.ACTION.GROUP_ADD_INVITED_USER,
  });
  const { data: oppInviteNotification } = useGetNotificationSettingQuery({
    deliveryMethod: NOTIFICATION.DELIVERY_METHOD.ALL,
    service: NOTIFICATION.SERVICE.OPPORTUNITY,
    serviceId: 0,
    action: NOTIFICATION.ACTION.OPPORTUNITY_USER_INVITE_ACCEPT,
  });
  const { data: groupMessageNotification } = useGetNotificationSettingQuery({
    deliveryMethod: NOTIFICATION.DELIVERY_METHOD.ALL,
    service: NOTIFICATION.SERVICE.GROUP,
    serviceId: 0,
    action: NOTIFICATION.ACTION.CHAT_MESSAGE_SEND,
  });

  const [toggleNotificationSetting] = useToggleNotificationSettingMutation();

  useEffect(() => {
    if (groupInviteNotification) {
      setGroupInviteSetting(
        !!groupInviteNotification.settings?.find((setting) => setting.toggledOn)
      );
    }
  }, [groupInviteNotification]);

  useEffect(() => {
    if (oppInviteNotification) {
      setOppInviteSetting(
        !!oppInviteNotification.settings?.find((setting) => setting.toggledOn)
      );
    }
  }, [oppInviteNotification]);

  useEffect(() => {
    if (groupMessageNotification) {
      setGroupMessageSetting(
        !!groupMessageNotification.settings?.find(
          (setting) => setting.toggledOn
        )
      );
    }
  }, [groupMessageNotification]);

  const tabs = useMemo(() => getSettingsTabs(), [getSettingsTabs]);

  const handleSave = () => {
    toggleNotificationSetting({
      deliveryMethod: NOTIFICATION.DELIVERY_METHOD.ALL,
      service: NOTIFICATION.SERVICE.GROUP,
      serviceId: 0,
      action: NOTIFICATION.ACTION.GROUP_ADD_INVITED_USER,
      toggledOn: groupInviteSetting,
    });
    toggleNotificationSetting({
      deliveryMethod: NOTIFICATION.DELIVERY_METHOD.ALL,
      service: NOTIFICATION.SERVICE.OPPORTUNITY,
      serviceId: 0,
      action: NOTIFICATION.ACTION.OPPORTUNITY_USER_INVITE_ACCEPT,
      toggledOn: oppInviteSetting,
    });
    toggleNotificationSetting({
      deliveryMethod: NOTIFICATION.DELIVERY_METHOD.ALL,
      service: NOTIFICATION.SERVICE.GROUP,
      serviceId: 0,
      action: NOTIFICATION.ACTION.CHAT_MESSAGE_SEND,
      toggledOn: groupMessageSetting,
    });
  };

  const onClickTab = (item) => {
    history.push(item.link);
  };

  return (
    <Container>
      <Box className={classes.titleBox}>
        <PageTitle title="My Settings" />
        <Box className={classes.buttonWrapper}>
          <Button
            startIcon={<Check />}
            color="secondary"
            fullWidth={false}
            text="SAVE"
            variant="outlined"
            size="small"
            onClick={handleSave}
          />
        </Box>
      </Box>
      <Grid container my={16}>
        <Grid item xs={12} sm={12} md={4} lg={4}>
          <Box width={1}>
            <VerticalList activeId={1} data={tabs} onPressItem={onClickTab} />
          </Box>
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={4} px={6}>
          <SectionTitle title="Invites" />
          <Text color="secondary" variant="caption">
            Invites are sent to you by other users so you can either join a
            group or a recently created opportunity.
          </Text>
          <Switch
            value={groupInviteSetting}
            setValue={setGroupInviteSetting}
            title="Group invitations"
          />
          <Switch
            value={oppInviteSetting}
            setValue={setOppInviteSetting}
            title="Opportunities invitations"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={4} px={6}>
          <SectionTitle title="Messages" />
          <Text color="secondary" variant="caption">
            Messages are the mainly form of exchanging information with your
            selling network
          </Text>
          <Switch
            value={groupMessageSetting}
            setValue={setGroupMessageSetting}
            title="Group messages"
          />
        </Grid>
      </Grid>
    </Container>
  );
};

export default NotificationSettings;
