import React from "react";
import {
  MenuItem,
  Select,
  FormHelperText,
  FormControl,
  InputLabel,
} from "@mui/material";
import PropTypes from "prop-types";

const Dropdown = ({
  inputId,
  label,
  values,
  defaultValue,
  errors,
  variant,
  handleChange,
  items,
}) => {
  return (
    <FormControl fullWidth>
      <InputLabel color={errors[inputId] ? "error" : "primary"}>
        {label}
      </InputLabel>
      <Select
        label={label}
        defaultValue={defaultValue}
        value={values[inputId] ?? defaultValue ?? ""}
        variant={variant}
        error={!!errors[inputId]}
        onChange={handleChange(inputId)}
        color="primary"
      >
        {items.map((item) => (
          <MenuItem key={item.value} value={item.value}>
            {item.label}
          </MenuItem>
        ))}
      </Select>
      <FormHelperText error={!!errors[inputId]}>
        {errors[inputId]}
      </FormHelperText>
    </FormControl>
  );
};

Dropdown.propTypes = {
  inputId: PropTypes.string.isRequired,
  label: PropTypes.string,
  values: PropTypes.object,
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  errors: PropTypes.object,
  variant: PropTypes.oneOf(["outlined", "filled", "standard"]),
  handleChange: PropTypes.func.isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({ label: PropTypes.string, value: PropTypes.string })
  ).isRequired,
};

Dropdown.defaultProps = {
  errors: {},
  variant: "outlined",
  values: {},
};

export default Dropdown;
