import React, { useEffect, useState } from "react";
import { AuthWrapper, AppLogo, LegalFooter } from "components";
import { Box, Alert } from "@mui/material";
import { makeStyles } from "@mui/styles";
import ResetPasswordForm from "./ResetPasswordForm";
import { useLocation, useHistory } from "react-router-dom";
import { useEmailConfirmActionMutation, useLoginMutation } from "services/api";
import { EmailActionType } from "utils/constants";

const useStyles = makeStyles((theme) => ({
  mainContent: {
    textAlign: "center",
    padding: "36px 36px",
    "& hr": {
      margin: "16px 0px",
    },
    [theme.breakpoints.down("xs")]: {
      // mobile responsive
      padding: "16px 16px",
    },
  },
}));

const ResetPassword = () => {
  const [
    emailConfirmAction,
    {
      isLoading: isResetting,
      isError: isResetError,
      isSuccess: isResetSuccess,
    },
  ] = useEmailConfirmActionMutation();
  const [login, { isError: isLoginError }] = useLoginMutation();
  const [password, setPassword] = useState("");
  const location = useLocation();
  const history = useHistory();
  const classes = useStyles();

  // Validate if redirected from deep link
  useEffect(() => {
    if (!location.state?.token || !location.state?.email) {
      history.replace("/");
    }
  }, [location.state, history]);

  // Reset password succeeded. Login with new password
  useEffect(() => {
    if (isResetSuccess && password) {
      const email = location.state?.email;
      login({ email, password });
    }
  }, [isResetSuccess, password, location.state, login]);

  const handleSubmit = ({ password }) => {
    const token = location.state?.token;
    emailConfirmAction({
      password,
      token,
      action: EmailActionType.RESET_PASSWORD,
    });
    setPassword(password);
  };

  return (
    <AuthWrapper maxWidth={450}>
      <Box className={classes.mainContent}>
        {(isResetError || isLoginError) && (
          <Alert severity="error">
            There was an error resetting password. If the problem persists,
            please contact support (support@allianceapp.com)
          </Alert>
        )}
        <AppLogo />
        <ResetPasswordForm
          onSubmit={handleSubmit}
          disabled={isResetting || isResetSuccess}
        />
        <LegalFooter showAgreements={false} />
      </Box>
    </AuthWrapper>
  );
};

export default ResetPassword;
