import React from "react";
import { Box, Divider } from "@mui/material";
import { Text } from "components";
import useStyles from "./SectionTitle.style";
import PropTypes from "prop-types";

const SectionTitle = ({ title }) => {
  const classes = useStyles();

  return (
    <Box className={classes.container}>
      <Text className={classes.title}>{title}</Text>
      <Divider className={classes.divider} />
    </Box>
  );
};

SectionTitle.propTypes = {
  title: PropTypes.string.isRequired,
};

export default SectionTitle;
