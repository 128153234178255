export const ROUTES = {
  // Before auth login
  SIGN_IN: "/signin",
  SIGN_UP: "/signup",
  SIGN_OUT: "/signout",
  SIGNUP_CONFIRM: "/signup-confirmation",
  LINKEDIN_LOGIN: "/oauth_login",
  FORGOT_PASSWORD: "/forgot-password",
  RESET_PASSWORD: "/reset-password",
  FAQ: "/faq",
  EMAIL: "/email",
  USERAGREEMENT: "/user-agreement",
  TERMS: "/terms",
  PRIVACYPOLICY: "/privacy-policy",
  DOWNLOAD_APP: "/download-app",

  TEST_CRM_FLOW: "/test-flow",

  // Business admin
  ADMIN_MANAGE_USER: "/admin/manage-users",
  ADMIN_INVOICES: "/admin/invoices",
  ADMIN_CRM_INTEGRATIONS: "/admin/crms",

  // Groups
  GROUP_LIST: "/groups",
  GROUP_CREATE: "/groups/create-group",
  GROUP_DETAILS: "/groups/:group_id",
  GROUP_USER_LIST: "/groups/:group_id/users",
  GROUP_LEAD_LIST: "/groups/:group_id/leads",
  GROUP_LEAD_DETAILS: "/groups/:group_id/leads/:lead_id",
  GROUP_MESSAGE_LIST: "/groups/:group_id/messages",
  GROUP_SETTINGS: "/groups/:group_id/settings",
  GROUP_USER_INVITED: "/group/user_invited",

  // Chats
  CHAT_DIRECT_LIST: "/chat/direct",
  CHAT_OPPORTUNITY_LIST: "/chat/opportunities",
  CHAT_GROUP_LIST: "/chat/groups",
  CHAT_BUSINESS_LIST: "/chat/business",

  // Opportunities
  OPPORTUNITY_LIST: "/opportunities",
  OPPORTUNITY_INFORMATION: "/opportunities/:opportunity_id/information",
  OPPORTUNITY_MESSAGE_LIST: "/opportunities/:opportunity_id/messages",
  OPPORTUNITY_CONTACT_LIST: "/opportunities/:opportunity_id/contacts",
  OPPORTUNITY_CONTACT_DETAILS:
    "/opportunities/:opportunity_id/contacts/:contact_id",
  OPPORTUNITY_SETTINGS: "/opportunities/:opportunity_id/settings",
  OPPORTUNITY_USER_INVITED: "/opportunity/user_invited",

  // Activities
  ACTIVITY_LIST: "/activities",

  // Others
  REDIRECT: "/redirect",
  MANAGE_TERRITORY: "/manage-territories",
  SEND_FEEDBACK: "/send-feedback",
  MY_SETTINGS_PROFILE: "/my-settings/profile",
  MY_SETTINGS_NOTIFICATIONS: "/my-settings/notifications",
  MY_SETTINGS_PRIVACY_POLICY: "/my-settings/privacy-policy",
  MY_SETTINGS_TERMS_USE: "/my-settings/terms-of-use",
  TERRITORY: "/territory",
};

const regex = new RegExp(":([a-zA-Z]+|_)+");

export const getRedirectUrl = (routerUrl, id) => routerUrl.replace(regex, id);

export const CHAT_ASSET = "CHAT_ASSET";
export const CHAT_TEXT = "CHAT_TEXT";

export const CHAT_TYPES = {
  group: 1,
  user: 2,
};

export const ACCESS_TOKEN = "access_token";
export const REFRESH_TOKEN = "refresh_token";

export const SearchType = {
  NOTYPE: 0,
  GROUP: 1,
  BUSINESS: 2,
};

export const GroupType = {
  NEW_LEAD: -1,
  UNKNOWN_GROUPTYPE: 0,
  PUBLIC: 1,
  PRIVATE: 2,
  VERTICAL: 3,
};

export const BusinessUserRoleType = {
  ADMIN: "ADMIN",
  SALES: "SALES",
};

export const BusinessUserStatusType = {
  INVITED: "INVITED",
  ACTIVE: "ACTIVE",
  INACTIVE: "INACTIVE",
};

export const EmailActionType = {
  EMAIL_ACTION_UNKNOWN: 0,
  SIGNUP_CONFIRMATION: 1,
  RESET_PASSWORD: 2,
};

export const SubscriptionType = {
  BUSINESS: "BUSINESS",
  PRIVATE_GROUP: "PRIVATE_GROUP",
};

export const CHAT = {
  ROOM_GROUPING_LEVEL: {
    UNKNOWN: "ROOM_GROUPING_UNKNOWN",
    USERS: "ROOM_GROUPING_USERS",
    GROUP: "ROOM_GROUPING_GROUP",
    LEAD: "ROOM_GROUPING_LEAD",
    OPPORTUNITY: "ROOM_GROUPING_OPPORTUNITY",
    BUSINESS: "ROOM_GROUPING_BUSINESS",
  },
  LIST_MESSAGE_TYPE: {
    ALL: "LIST_MESSAGE_TYPE_ALL",
    TEXT: "LIST_MESSAGE_TYPE_TEXT",
    ATTACHMENT: "LIST_MESSAGE_TYPE_ATTACHMENT",
  },
};

export const NOTIFICATION = {
  DELIVERY_METHOD: {
    UNKNOWN: "DELIVERY_UNKNOWN",
    ALL: "DELIVERY_ALL",
    EMAIL: "DELIVERY_EMAIL",
    PUSH: "DELIVERY_PUSH",
  },
  SERVICE: {
    UNKNOWN: "SERVICE_UNKNOWN",
    ALL: "SERVICE_ALL",
    LEAD: "SERVICE_LEAD",
    CHAT: "SERVICE_CHAT",
    BUSINESS: "SERVICE_BUSINESS",
    GROUP: "SERVICE_GROUP",
    USER: "SERVICE_USER",
    OPPORTUNITY: "SERVICE_OPPORTUNITY",
  },
  ACTION: {
    UNKNOWN: "ACTION_UNKNOWN",
    ALL: "ACTION_ALL",
    LEAD_CREATE: "ACTION_LEAD_CREATE",
    GROUP_ADD_INVITED_USER: "ACTION_GROUP_ADD_INVITED_USER",
    CHAT_MESSAGE_SEND: "ACTION_CHAT_MESSAGE_SEND",
    OPPORTUNITY_USER_INVITE_ACCEPT: "ACTION_OPPORTUNITY_USER_INVITE_ACCEPT",
  },
};

export const ACTIVITY_TYPES = {
  CHAT_NEW_GROUP_MESSAGE: "CHAT_GROUP_MESSAGE",
  CHAT_NEW_LEAD_MESSAGE: "CHAT_LEAD_MESSAGE",
  CHAT_NEW_OPPORTUNITY_MESSAGE: "CHAT_OPPORTUNITY_MESSAGE",
  CHAT_NEW_USER_MESSAGE: "CHAT_USER_MESSAGE",
  CHAT_NEW_BUSINESS_MESSAGE: "CHAT_BUSINESS_MESSAGE",
  GROUP_CANCELLED: "GROUP_CANCEL",
  GROUP_DELETED: "GROUP_DELETE",
  GROUP_NEW_INVITE: "GROUP_INVITE",
  GROUP_NEW_LEAD: "LEAD_NEW",
  GROUP_NEW_USER: "GROUP_NEW_USER",
  GROUP_REMOVED: "GROUP_REMOVE",
  GROUP_NEW_USER_ADDED: "GROUP_NEW_USER_ADDED",
  OPPORTUNITY_NEW: "OPPORTUNITY_NEW",
  OPPORTUNITY_NEW_USER: "OPPORTUNITY_NEW_USER",
  OPPORTUNITY_NEW_USER_ADDED: "OPPORTUNITY_NEW_USER_ADDED",
};

export const StripeCountries = [
  {
    name: "Australia",
    code: "AU",
  },
  {
    name: "Austria",
    code: "AT",
  },
  {
    name: "Belgium",
    code: "BE",
  },
  {
    name: "Brazil",
    code: "BR",
  },
  {
    name: "Bulgaria",
    code: "BG",
  },
  {
    name: "Canada",
    code: "CA",
  },
  {
    name: "Croatia",
    code: "HR",
  },
  {
    name: "Cyprus",
    code: "CY",
  },
  {
    name: "Czech Republic",
    code: "CZ",
  },
  {
    name: "Denmark",
    code: "DK",
  },
  {
    name: "Estonia",
    code: "EE",
  },
  {
    name: "Finland",
    code: "FI",
  },
  {
    name: "France",
    code: "FR",
  },
  {
    name: "Germany",
    code: "DE",
  },
  {
    name: "Gibraltar",
    code: "GI",
  },
  {
    name: "Greece",
    code: "GR",
  },
  {
    name: "Hong Kong",
    code: "HK",
  },
  {
    name: "Hungary",
    code: "HU",
  },
  {
    name: "India",
    code: "IN",
  },
  {
    name: "Ireland",
    code: "IE",
  },
  {
    name: "Italy",
    code: "IT",
  },
  {
    name: "Japan",
    code: "JP",
  },
  {
    name: "Latvia",
    code: "LV",
  },
  {
    name: "Liechtenstein",
    code: "LI",
  },
  {
    name: "Lithuania",
    code: "LT",
  },
  {
    name: "Luxembourg",
    code: "LU",
  },
  {
    name: "Malaysia",
    code: "MY",
  },
  {
    name: "Malta",
    code: "MT",
  },
  {
    name: "Mexico",
    code: "MX",
  },
  {
    name: "Netherlands",
    code: "NL",
  },
  {
    name: "New Zealand",
    code: "NZ",
  },
  {
    name: "Norway",
    code: "NO",
  },
  {
    name: "Poland",
    code: "PL",
  },
  {
    name: "Portugal",
    code: "PT",
  },
  {
    name: "Romania",
    code: "RO",
  },
  {
    name: "Singapore",
    code: "SG",
  },
  {
    name: "Slovakia",
    code: "SK",
  },
  {
    name: "Slovenia",
    code: "SI",
  },
  {
    name: "Spain",
    code: "ES",
  },
  {
    name: "Sweden",
    code: "SE",
  },
  {
    name: "Switzerland",
    code: "CH",
  },
  {
    name: "United Arab Emirates",
    code: "AE",
  },
  {
    name: "United Kingdom",
    code: "GB",
  },
  {
    name: "United States",
    code: "US",
  },
];
