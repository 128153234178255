import React, { useEffect, useRef } from "react";
import { Redirect, useHistory } from "react-router";
import { ROUTES, BusinessUserRoleType, getRedirectUrl } from "utils/constants";
import { useGetBusinessUserQueryState } from "services/business";
import { useSelector, useDispatch } from "react-redux";
import { PageLoader } from "components";
import {
  useAddInvitedGroupUserMutation,
  useAddInvitedOpportunityUserMutation,
} from "services/api";

const RedirectRouter = () => {
  const { authUser, userId } = useSelector(({ user }) => user);
  const {
    data: bmUser,
    isLoading: isBmLoading,
    isError,
    error,
  } = useGetBusinessUserQueryState(userId);
  // prettier-ignore
  const [addInvitedGroupUser, { isLoading: isInviteLoading }] = useAddInvitedGroupUserMutation();
  // prettier-ignore
  const [addInvitedOpportunityUser, { isLoading: isOpportunityInviteLoading }] = useAddInvitedOpportunityUserMutation();
  const history = useHistory();
  const isBmUser = !!bmUser && bmUser.role === BusinessUserRoleType.ADMIN;

  const initURL = isBmUser ? ROUTES.ADMIN_MANAGE_USER : ROUTES.ACTIVITY_LIST;
  const callAddInvitedGroupUser = async (groupId, inviteCode, history) => {
    try {
      const resp = await addInvitedGroupUser({
        groupId,
        inviteCode,
      });
      console.log("Adding invited user to group", resp);
      if (resp.data) {
        history.replace(getRedirectUrl(ROUTES.GROUP_USER_LIST, groupId));
      }
    } catch (error) {
      console.log("Error adding invited user to group", error);
    }
  };
  // prettier-ignore
  const callAddInvitedOpportunityUser = async (opportunityId, inviteCode, history) => {
    try {
      const resp = await addInvitedOpportunityUser({
        opportunityId,
        inviteCode,
      });
      console.log("Adding invited user to group", resp);
      if (resp.data) {
        history.replace(getRedirectUrl(ROUTES.OPPORTUNITY_LIST, opportunityId));
      }
    } catch (error) {
      console.log("Error adding invited user to group", error);
    }
  };

  useEffect(() => {
    if (authUser && !isBmUser) {
      const inviteCode = localStorage.getItem("inviteCode");
      const inviteId = localStorage.getItem("inviteId");
      const inviteAction = localStorage.getItem("inviteAction");
      console.log("RedirectRouter addInvitedGroupId", inviteId);

      if (inviteAction === "GROUP_INVITE") {
        console.log("RedirectRouter addInvited to groupId", inviteId);
        callAddInvitedGroupUser(inviteId, inviteCode, history);
      }

      if (inviteAction === "OPPORTUNITY_INVITE") {
        console.log("RedirectRouter addInvited to oppId", inviteId);
        callAddInvitedOpportunityUser(inviteId, inviteCode, history);
      }
    }
  }, [authUser, isBmUser, history]);

  // prettier-ignore
  if (isBmLoading || (isError && error.status === 401) || isInviteLoading || isOpportunityInviteLoading) {
    // 401 means access token expired. Need to wait until a new access token is generated.
    return <PageLoader />;
  }
  if (!authUser) {
    console.log("RedirectRouter redirect to sign in");
    return <Redirect to={ROUTES.SIGN_IN} />;
  }

  console.log("RedirectRouter redirect to INIT URL");
  return <Redirect to={initURL} />;
};

export default RedirectRouter;
