import React from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  hideCreateGroupModal,
  hideJoinGroupDialog,
  showFeedbackDialog,
  hidePaymentDialog,
  hidePaymentSuccessDialog,
  hideUpdatePaymentDialog,
  showMySettingsDialog,
  showHelpDialog,
} from "redux/slices/Modal";
import CreateGroupDialog from "./CreateGroupDialog";
import JoinExistingGroup from "./JoinGroup";
import Feedback from "./Feedback";
import PaymentDialog from "./Payment";
import MySettings from "./MySettings";
import Help from "./Help";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import PaymentSuccess from "./PaymentSuccess";
import ManagePayment from "./ManagePayment";
import { stripeKey } from "utils/env";

const stripePromise = loadStripe(stripeKey);

const ModalRoot = () => {
  const dispatch = useDispatch();

  const {
    showCreateGroup,
    showJoinGroup,
    showFeedback,
    showPayment,
    showPaymentSuccess,
    showUpdatePayment,
    showMySettings,
    showHelp,
    prevModalData,
    error,
  } = useSelector(({ modal }) => modal);

  if (showCreateGroup) {
    return (
      <CreateGroupDialog
        createErr={error}
        open={showCreateGroup}
        handleDialogClose={() => dispatch(hideCreateGroupModal())}
      />
    );
  }
  if (showJoinGroup) {
    return (
      <JoinExistingGroup
        joinErr={error}
        open={showJoinGroup}
        handleDialog={() => dispatch(hideJoinGroupDialog())}
      />
    );
  }
  if (showFeedback) {
    return (
      <Feedback
        open={showFeedback}
        handleDialog={() => dispatch(showFeedbackDialog(false))}
      />
    );
  }
  if (showPayment) {
    return (
      <Elements
        stripe={stripePromise}
        options={{
          fonts: [
            { cssSrc: "https://fonts.googleapis.com/css?family=Poppins:400" },
          ],
        }}
      >
        <PaymentDialog
          open={showPayment}
          prevModalData={prevModalData}
          handleDialog={() => dispatch(hidePaymentDialog())}
        />
      </Elements>
    );
  }
  if (showUpdatePayment) {
    return (
      <Elements
        stripe={stripePromise}
        options={{
          fonts: [
            { cssSrc: "https://fonts.googleapis.com/css?family=Poppins:400" },
          ],
        }}
      >
        <ManagePayment
          open={true}
          handleClose={() => dispatch(hideUpdatePaymentDialog())}
        />
      </Elements>
    );
  }
  if (showPaymentSuccess) {
    return (
      <PaymentSuccess
        open={showPaymentSuccess}
        handleDialog={() => dispatch(hidePaymentSuccessDialog())}
      />
    );
  }
  if (showMySettings) {
    return (
      <MySettings
        open={showMySettings}
        handleDialogClose={() => dispatch(showMySettingsDialog(false))}
      />
    );
  }
  if (showHelp) {
    return (
      <Help
        open={showHelp}
        handleDialogClose={() => dispatch(showHelpDialog(false))}
      />
    );
  }

  return null;
};

export default ModalRoot;
