import React, { useState } from "react";
import {
  Box,
  Button,
  DialogContent,
  TextField,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";

import { useSubmitFeedbackMutation } from "services/api";

const useStyles = makeStyles((theme) => ({
  dialog: {
    position: "fixed",
    left: "10%",
    bottom: "10%",
  },
  dialogTitle: {
    "& .MuiTypography-h6": {
      fontSize: 24,
      color: theme.palette.common.dark,
      justifyContent: "center",
      fontWeight: "bold",
      display: "flex",
    },
  },
  dialogWrapper: {
    padding: "20px",
    width: "400px",
  },
  textBox: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: "24",
    fontWeight: "bold",
  },
  textGeneral: {
    fontSize: 14,
    fontWeight: "bold",
  },
  textField: {
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#013E6E",
      },
    },
    "&:hover fieldset": {
      borderColor: "#013E6E",
    },
  },
  button: {
    "&:hover": {
      backgroundColor: "#013E6E",
    },
  },
  textSent: {
    color: "#9E9E9E",
    fontWeight: "bold",
    fontSize: 24,
  },
}));

const Feedback = ({ open, handleDialog }) => {
  const classes = useStyles();
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [sentMsg, setSentMsg] = useState(false);
  const [submitFeedback] = useSubmitFeedbackMutation();

  const handleSubmit = () => {
    submitFeedback({ subject, content: message });
    setSentMsg(true);
  };

  return (
    <Dialog open={open} onClose={handleDialog} className={classes.dialog}>
      <DialogTitle className={classes.dialogTitle}>SEND FEEDBACK</DialogTitle>
      <DialogContent dividers>
        {sentMsg === false && (
          <Box className={classes.dialogWrapper}>
            <Box>
              <Typography className={classes.textGeneral}>
                Please enter subject and message below:
              </Typography>
            </Box>

            <Box>
              <TextField
                className={classes.textField}
                fullWidth
                margin="normal"
                variant="outlined"
                placeholder="Message subject"
                onChange={(e) => {
                  setSubject(e.target.value);
                }}
              />
            </Box>
            <Box mb={2}>
              <TextField
                className={classes.textField}
                multiline
                rows={8}
                height="50px"
                fullWidth
                margin="normal"
                variant="outlined"
                placeholder="Type your message here"
                onChange={(e) => {
                  setMessage(e.target.value);
                }}
              />
            </Box>

            <Box display="flex" alignItems="flex-end" justifyContent="flex-end">
              <Button onClick={() => handleDialog()}>Back</Button>
              <Button
                className={classes.button}
                variant="contained"
                color="primary"
                onClick={() => handleSubmit()}
              >
                Send
              </Button>
            </Box>
          </Box>
        )}

        {sentMsg === true && (
          <Box className={classes.dialogWrapper}>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              height="350px"
            >
              <Typography className={classes.textSent}>
                Thanks! We may reach out to discuss further.
              </Typography>
            </Box>
          </Box>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default Feedback;
