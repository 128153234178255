import React, { useCallback } from "react";
import { Box, Button, Hidden, Typography } from "@mui/material";
import { Settings } from "@mui/icons-material";
import useStyles from "../index.style";
import { InvoicesGrid } from "components";
import { useDispatch, useSelector } from "react-redux";
import { showUpdatePaymentDialog } from "redux/slices/Modal";
import { api, useGetBillingQuery } from "services/api";
import moment from "moment";
import { clone } from "lodash";

const Invoices = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const userId = useSelector((state) => state.user.userId);
  const { data: billingInfo } = useGetBillingQuery();
  const [triggerGetInvoices] =
    api.endpoints.getStripeInvoices.useLazyQuerySubscription({
      refetchOnFocus: true,
      refetchOnReconnect: true,
    });

  const onLoadMoreInvoices = useCallback(async () => {
    try {
      const data = await triggerGetInvoices(userId).unwrap();
      let { invoices } = data;
      invoices = invoices.map((invoice) => {
        let clonedInvoice = clone(invoice);
        clonedInvoice.billingPeriod =
          moment(invoice.periodStart).format("MM-DD-YYYY") +
          " to " +
          moment(invoice.periodEnd).format("MM-DD-YYYY");
        clonedInvoice.amountPaid = "$" + clonedInvoice.amountPaid;
        clonedInvoice.paymentInfo =
          "**** **** **** " + clonedInvoice.chargedCard;
        return clonedInvoice;
      });
      return invoices;
    } catch (e) {
      return [{ invoiceNumber: "No record" }];
    }
  }, [dispatch, userId]);

  const managePayment = () => {
    dispatch(showUpdatePaymentDialog());
  };

  return (
    <Box className={classes.inBuildAppCard}>
      <Box className={classes.groupTitleBox}>
        <Box display="flex" justifyContent="center" flex={1}>
          <span className={classes.pageTitle}>Invoices</span>
        </Box>
        {billingInfo?.active && (
          <Box className={classes.titleBox}>
            <Button
              variant="outlined"
              aria-controls="simple-menu"
              aria-haspopup="true"
              onClick={managePayment}
            >
              <Hidden smDown>
                <Settings
                  className={`${classes.buttonContainer} ${classes.iconStyles} ${classes.iconButtonIconStyles}`}
                  fontSize="medium"
                />
              </Hidden>
              <Typography color="textSecondary" variant="body2">
                MANAGE PAYMENT
              </Typography>
            </Button>
          </Box>
        )}
      </Box>
      <Box className={classes.tableWrapper}>
        <InvoicesGrid onLoadMoreInvoices={onLoadMoreInvoices} />
      </Box>
    </Box>
  );
};

export default Invoices;
