import {
  AccountBox,
  Message,
  Groups,
  CreateNewFolder,
  Email,
  LiveHelp,
  Feedback,
  ExitToApp,
  SupervisedUserCircle,
  Map,
  Dns,
  Face,
  MonetizationOn,
  AutoFixHigh,
  Home,
} from "@mui/icons-material";
import { ROUTES } from "utils/constants";
import React from "react";
import {
  showCreateGroupModal,
  showFeedbackDialog,
  showInviteUsersDialog,
  showHelpDialog,
} from "redux/slices/Modal";

export const ActivitiesMenuItem = {
  name: "Activities",
  type: "item",
  icon: <Home />,
  link: ROUTES.ACTIVITY_LIST,
};

export const LeadsMenuItem = {
  name: "Leads",
  type: "item",
  icon: <AccountBox />,
  link: ROUTES.GROUP_LEAD_LIST,
};

export const ChatMenuItem = {
  name: "Chat",
  type: "item",
  icon: <Message />,
  link: ROUTES.CHAT_DIRECT_LIST,
};

export const ManageGroupsMenuItem = {
  name: "Manage Groups",
  type: "item",
  icon: <Groups />,
  link: ROUTES.GROUP_LIST,
  admin: true,
};

export const ManageOpportunitiesMenuItem = {
  name: "Manage Opportunities",
  type: "item",
  icon: <AutoFixHigh />,
  link: ROUTES.OPPORTUNITY_LIST,
};

export const CreateGroupMenuItem = (dispatch) => ({
  name: "Create Group",
  type: "item",
  icon: <CreateNewFolder />,
  link: ROUTES.GROUP_CREATE,
  onClick: (e) => {
    e.preventDefault();
    dispatch(showCreateGroupModal(true));
  },
  admin: true,
});

export const ManageUsersMenuItem = {
  name: "Manage Users",
  type: "item",
  icon: <SupervisedUserCircle />,
  link: ROUTES.ADMIN_MANAGE_USER,
  admin: true,
};

export const TerritoriesMenuItem = {
  name: "Manage Territories",
  type: "item",
  icon: <Map />,
  link: ROUTES.MANAGE_TERRITORY,
  admin: true,
};

export const CrmsMenuItem = {
  name: "CRMs",
  type: "item",
  icon: <Dns />,
  link: ROUTES.ADMIN_CRM_INTEGRATIONS,
  admin: true,
};

export const InvoicesMenuItem = {
  name: "Invoices",
  type: "item",
  icon: <MonetizationOn />,
  link: ROUTES.ADMIN_INVOICES,
  admin: true,
};

export const InviteUsersMenuItem = (dispatch) => ({
  name: "Invite Users",
  type: "item",
  icon: <Email />,
  link: ROUTES.EMAIL,
  onClick: (e) => {
    e.preventDefault();
    dispatch(showInviteUsersDialog({ show: true }));
  },
});

export const MySettingsMenuItem = () => ({
  name: "My Settings",
  type: "item",
  icon: <Face />,
  link: ROUTES.MY_SETTINGS_PROFILE,
});

export const HelpMenuItem = (dispatch) => ({
  name: "Help",
  type: "item",
  icon: <LiveHelp />,
  link: ROUTES.FAQ,
  onClick: (e) => {
    e.preventDefault();
    dispatch(showHelpDialog({ show: true }));
  },
});

export const SendFeedBackMenuItem = (dispatch) => ({
  name: "Send Feedback",
  type: "item",
  icon: <Feedback />,
  link: ROUTES.SEND_FEEDBACK,
  onClick: (e) => {
    e.preventDefault();
    dispatch(showFeedbackDialog(true));
  },
});

export const LogoutMenuItem = {
  name: "Log Out",
  type: "item",
  icon: <ExitToApp />,
  link: ROUTES.SIGN_OUT,
};
