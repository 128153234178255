import { setCurrentLead } from "redux/slices/Lead";
import { bugsnagNotify } from "services/bugsnag";

const leadEndpoints = (builder) => ({
  getLeadList: builder.query({
    query: ({ groupId, startPos, getNext, sortBy }) => ({
      url: "/lead/list",
      method: "POST",
      data: {
        group_id: groupId,
        start_position: startPos,
        get_next: getNext,
        sort_by: sortBy,
      },
    }),
    async onQueryStarted(_, { queryFulfilled }) {
      try {
        await queryFulfilled;
      } catch (e) {
        bugsnagNotify(
          new Error(e?.error?.data?.message ?? "Lead list failed."),
          e
        );
      }
    },
    providesTags: [{ type: "Lead", id: "LIST" }],
  }),
  getLead: builder.query({
    query: (leadId) => ({
      url: "/lead/get",
      method: "POST",
      data: { lead_id: leadId },
    }),
    async onQueryStarted(_, { dispatch, queryFulfilled }) {
      try {
        const { data } = await queryFulfilled;
        dispatch(setCurrentLead(data));
      } catch (e) {
        bugsnagNotify(
          new Error(e?.error?.data?.message ?? "Lead get failed."),
          e
        );
      }
    },
    providesTags: (result, error, leadId) =>
      result ? [{ type: "Lead", id: leadId }] : ["Lead"],
  }),
  createLead: builder.mutation({
    query: (leadDetails) => ({
      url: "/lead/create",
      method: "POST",
      data: leadDetails,
    }),
    async onQueryStarted(_, { queryFulfilled }) {
      try {
        await queryFulfilled;
      } catch (e) {
        bugsnagNotify(
          new Error(e?.error?.data?.message ?? "Create lead failed."),
          e
        );
      }
    },
    invalidatesTags: [{ type: "Lead", id: "LIST" }],
  }),
  updateLead: builder.mutation({
    query: (leadDetails) => ({
      url: "/lead/update",
      method: "POST",
      data: leadDetails,
    }),
    async onQueryStarted(_, { queryFulfilled }) {
      try {
        await queryFulfilled;
      } catch (e) {
        bugsnagNotify(
          new Error(e?.error?.data?.message ?? "Update lead failed."),
          e
        );
      }
    },
    invalidatesTags: (result, error, { lead_id }) =>
      result ? [{ type: "Lead", id: lead_id }] : [],
  }),
  deleteLead: builder.mutation({
    query: (leadId) => ({
      url: "/lead/delete",
      method: "POST",
      data: { lead_id: leadId },
    }),
    async onQueryStarted(_, { queryFulfilled }) {
      try {
        await queryFulfilled;
      } catch (e) {
        bugsnagNotify(
          new Error(e?.error?.data?.message ?? "Delete lead failed."),
          e
        );
      }
    },
  }),
  assignUserLead: builder.mutation({
    query: ({ groupId, leadId, userId }) => ({
      url: "/lead/assign",
      method: "POST",
      data: { group_id: groupId, lead_id: leadId, user_id: userId },
    }),
    async onQueryStarted(_, { queryFulfilled }) {
      try {
        await queryFulfilled;
      } catch (e) {
        bugsnagNotify(
          new Error(e?.error?.data?.message ?? "Assign user to lead failed"),
          e
        );
      }
    },
  }),
  getLeadGroupStats: builder.query({
    query: ({ groupIds }) => ({
      url: "/lead/group_stats",
      method: "POST",
      data: { ids: groupIds, search_by: "GROUP" },
    }),
    async onQueryStarted(_, { queryFulfilled }) {
      try {
        await queryFulfilled;
      } catch (e) {
        bugsnagNotify(
          new Error(
            e?.error?.data?.message ?? "Fetching lead group stats failed."
          ),
          e
        );
      }
    },
  }),
  getLeadUserStats: builder.query({
    query: ({ userIds, gropuIds }) => ({
      url: "/lead/user_stats",
      method: "POST",
      data: {
        filter_ids: userIds,
        search_ids: gropuIds,
        filter_by: "USERS",
        search_by: "CTX_USER",
      },
    }),
    async onQueryStarted(_, { queryFulfilled }) {
      try {
        await queryFulfilled;
      } catch (e) {
        bugsnagNotify(
          new Error(
            e?.error?.data?.message ?? "Fetching lead user stats failed."
          ),
          e
        );
      }
    },
  }),
});

export default leadEndpoints;
