import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { createBrowserHistory } from "history";
import { connectRouter, routerMiddleware } from "connected-react-router";
import slices from "redux/slices";
import { api } from "services/api";
import storage from "redux-persist/lib/storage";
import autoMergeLevel2 from "redux-persist/lib/stateReconciler/autoMergeLevel2";
import {
  persistReducer,
  persistStore,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";
import { bugsnagUpdateReduxMetadata } from "services/bugsnag";
import { isLocalDev } from "utils/env";

const persistConfig = {
  timeout: 10000,
  key: "root",
  version: 1,
  debug: true,
  storage,
  stateReconciler: autoMergeLevel2,
  blacklist: [api.reducerPath],
};

const history = createBrowserHistory();

const reducers = combineReducers({
  ...slices,
  router: connectRouter(history),
  [api.reducerPath]: api.reducer,
});

// Create the root reducer from persistReducer
const persistedReducer = persistReducer(persistConfig, reducers);
const rootReducer = (state, action) => {
  if (action.type === "RESET_APP_STATE") {
    state = undefined;
  }
  bugsnagUpdateReduxMetadata(state, action);

  return persistedReducer(state, action);
};

const routeMiddleware = routerMiddleware(history);

const store = configureStore({
  reducer: rootReducer,

  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).concat(routeMiddleware, api.middleware),

  devTools: isLocalDev,
});

const persistor = persistStore(store);

export default store;

export { history, persistor };
