import React, { useEffect } from "react";
import Branch from "branch-sdk";
import { useHistory } from "react-router";
import { getRedirectUrl, ROUTES } from "utils/constants";
import { PageLoader } from "components";
import { useDispatch, useSelector } from "react-redux";
import { userLogout } from "redux/slices/User";

const GroupUserInvited = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { authUser, userId } = useSelector((state) => state.user);

  useEffect(() => {
    Branch.data((err, data) => {
      if (err) throw err;
      if (data.data_parsed?.action === "GROUP_INVITE") {
        const {
          action: inviteAction,
          user_id: invitedUserId,
          group_id: inviteId,
          email,
          invite_code: inviteCode,
        } = data.data_parsed;
        if (invitedUserId === 0 || !authUser) {
          // New user, redirect to sign in
          dispatch(userLogout());
          history.replace(ROUTES.SIGN_IN, {
            email,
            inviteAction,
            inviteCode,
            inviteId,
          });
        } else {
          // if (authUser && invitedUserId === userId) {
          console.log("Invited user is logged in: ", invitedUserId, userId);
          // If logged in user is equals to invited user, redirect to group users list
          // callAddInvitedGroupUser(groupId, inviteCode);
          console.log("RedirectRouter addInvited", inviteId);
          localStorage.setItem("inviteId", inviteId);
          localStorage.setItem("inviteCode", inviteCode);
          localStorage.setItem("inviteAction", inviteAction);
          history.replace(ROUTES.REDIRECT);
        }
      }
    });
  }, []);

  return <PageLoader />;
};

export default GroupUserInvited;
