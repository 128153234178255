import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  Box,
  DialogContent,
  IconButton,
  Dialog,
  DialogTitle,
  Slide,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Redirect } from "react-router";
import {
  showCreateGroupModal,
  showJoinGroupDialog,
  hidePaymentDialog,
  showPaymentSuccessDialog,
} from "redux/slices/Modal";
import { ROUTES } from "../../utils/constants";
import PaymentDetails from "./Payment/PaymentDetails";
import PaymentForm from "./Payment/PaymentForm";
import { ReactComponent as CloseSVG } from "assets/icons/payment_close.svg";
import {
  useAddGroupMutation,
  useCreatePrivateGroupMutation,
  useGetBillingQuery,
} from "services/api";

const useStyles = makeStyles((theme) => ({
  dialogRoot: {
    position: "absolute",
    margin: 0,
    right: 0,
    top: theme.statics.headerHeight,
    bottom: 0,
    maxWidth: "none",
    width: "75%",
    height: "auto",
    "& .MuiDialogContent-root": {
      display: "flex",
    },
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
  dialogTitleRoot: {
    "& .MuiTypography-h6": {
      fontSize: 24,
      color: theme.palette.common.dark,
      justifyContent: "center",
      fontWeight: "bold",
      display: "flex",
    },
  },
  dialogWrapper: {
    display: "flex",
    flex: 1,
    flexDirection: "row",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },
  closeBtn: {
    position: "absolute",
    left: 0,
    top: 0,
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

const PaymentDialog = ({ open, handleDialog, prevModalData }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [runNextFired, setRunNextFired] = useState(false);
  const { data: billingInfo } = useGetBillingQuery();
  const [addGroup, { isLoading: isAddingGroup, error: addGroupError }] =
    useAddGroupMutation();
  const [
    createPrivateGroup,
    { error: creatingPrivateGrouopError, isLoading: isCreatingPrivateGroup },
  ] = useCreatePrivateGroupMutation();

  const handleBack = (err) => {
    if (prevModalData.action === "groupAdd") {
      dispatch(showJoinGroupDialog(err));
    } else if (prevModalData.action === "groupCreate") {
      dispatch(showCreateGroupModal(err));
    }
    handleDialog();
  };

  useEffect(() => {
    if (billingInfo?.active && runNextFired === false) {
      if (prevModalData.action === "groupAdd") {
        addGroup(prevModalData.data);
      } else if (prevModalData.action === "groupCreate") {
        createPrivateGroup(prevModalData.data);
      }
      // Use lazy query
      dispatch(hidePaymentDialog());
      dispatch(showPaymentSuccessDialog());
      setRunNextFired(true);
    }
  }, [
    dispatch,
    billingInfo,
    prevModalData.action,
    prevModalData.data,
    runNextFired,
  ]);

  if (runNextFired && (!isCreatingPrivateGroup || !isAddingGroup)) {
    if (creatingPrivateGrouopError || addGroupError) {
      handleBack(creatingPrivateGrouopError?.data ?? addGroupError?.data);
    } else {
      return <Redirect to={ROUTES.GROUP_LEAD_LIST} />;
    }
  }
  return (
    <Dialog
      open={open}
      onClose={handleDialog}
      PaperProps={{ className: classes.dialogRoot }}
      TransitionComponent={Transition}
      hideBackdrop
    >
      <IconButton className={classes.closeBtn} onClick={handleDialog}>
        <CloseSVG />
      </IconButton>
      <DialogTitle className={classes.dialogTitleRoot}>
        PAYMENT INFORMATION
      </DialogTitle>
      <DialogContent dividers>
        <Box className={classes.dialogWrapper}>
          <PaymentDetails flex={1} />
          <PaymentForm flex={1} />
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default PaymentDialog;
