import { GoogleMap, withGoogleMap, Marker } from "react-google-maps";
import PropTypes from "prop-types";

const GoogleMapBox = withGoogleMap(({ lat, lng }) => (
  <GoogleMap defaultZoom={10} defaultCenter={{ lat, lng }}>
    <Marker position={{ lat, lng }} />
  </GoogleMap>
));

GoogleMapBox.propTypes = {
  lat: PropTypes.number,
  lng: PropTypes.number,
};

export default GoogleMapBox;
