import React, { useState } from "react";
import useStyles from "components/Modals/index.style";
import PropTypes from "prop-types";
import { Form, Formik } from "formik";
import * as yup from "yup";
import { Box, Dialog, DialogContent, DialogTitle } from "@mui/material";
import { Add } from "@mui/icons-material";
import { Text, Input, Button } from "components";
import {
  useInviteGroupUserMutation,
  useInviteOpportunityUserMutation,
} from "services/api";

const validationSchema = yup.object({
  email: yup
    .string()
    .required("Email is required")
    .email("Must be a valid email"),
});

const InviteUsers = ({ open, handleDialog, groupId, opportunityId }) => {
  const initial = {
    email: "",
  };
  const classes = useStyles();
  const [isAddAnotherUser, setIsAddAnotherUser] = useState(false);
  const [inviteGroupUser, { isLoading: isInviting }] =
    useInviteGroupUserMutation({ fixedCacheKey: "inviteGroupUser" });
  const [inviteOpportunityUser] = useInviteOpportunityUserMutation({
    fixedCacheKey: "inviteOpportunityUser",
  });

  const handleSubmit = ({ email }, { resetForm }) => {
    if (groupId) {
      inviteGroupUser({ email, groupId });
    } else if (opportunityId) {
      inviteOpportunityUser({ opportunityId, email });
    }
    if (!isAddAnotherUser) {
      handleDialog();
    } else {
      resetForm({ values: "" });
    }
  };

  const handleInviteUser = (e, formik) => {
    setIsAddAnotherUser(false);
    formik.handleSubmit(e);
  };

  const handleAddAnotherUser = (e, formik) => {
    setIsAddAnotherUser(true);
    formik.handleSubmit(e);
  };

  return (
    <Dialog open={open} onClose={handleDialog}>
      <DialogTitle className={classes.dialogTitle}>Invite user</DialogTitle>
      <DialogContent dividers>
        <Box>
          <Text color="secondary" className={classes.textGeneralSecondary}>
            Please enter e-mail address for the users that you want to invite
          </Text>
        </Box>
        <Formik
          initialValues={initial}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {(formik) => (
            <Form>
              <Box>
                <Input.Text
                  label="E-mail Address"
                  inputId="email"
                  handleChange={formik.handleChange}
                  values={formik.values}
                  errors={formik.errors}
                  touched={formik.touched}
                  disabled={isInviting}
                />
                <Box display="flex" justifyContent="space-between" mt={4}>
                  <Button
                    text="INVITE ANOTHER USER"
                    startIcon={<Add />}
                    color="primary"
                    variant="contained"
                    size="small"
                    fullWidth={false}
                    loading={isInviting}
                    onClick={(e) => handleAddAnotherUser(e, formik)}
                  />
                  <Box display="flex">
                    <Button
                      text="Back"
                      color="secondary"
                      variant="text"
                      fullWidth={false}
                      onClick={handleDialog}
                    />
                    <Box ml={2}>
                      <Button
                        text="Invite"
                        color="primary"
                        variant="contained"
                        fullWidth={false}
                        onClick={(e) => handleInviteUser(e, formik)}
                      />
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Form>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  );
};

export default InviteUsers;

InviteUsers.propTypes = {
  open: PropTypes.bool.isRequired,
  handleDialog: PropTypes.func.isRequired,
  groupId: PropTypes.number.isRequired,
};
