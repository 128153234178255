import React, { useEffect, useState } from "react";
import { Formik, Form } from "formik";
import * as yup from "yup";
import Box from "@mui/material/Box";
import { Link } from "react-router-dom";
import { useLocation } from "react-router";
import { Input, Button, Checkbox } from "components";
import { ROUTES } from "utils/constants";
import { makeStyles } from "@mui/styles";

const validationSchema = yup.object().shape({
  email: yup
    .string()
    .trim()
    .email("Must be a valid email address")
    .required("Email is required"),
  password: yup
    .string()
    .trim()
    .min(8, "Must be at least 8 characters long")
    .max(50, "Must be less than 50 characters long")
    .required("Password is required"),
});

const useStyles = makeStyles((theme) => ({
  link: {
    color: theme.palette.text.secondary,
    textDecoration: "underline",
    textDecorationColor: theme.palette.text.secondary,
  },
}));

const SignInForm = ({ submitting, ...props }) => {
  const classes = useStyles();
  const [initialValues, setInitialValues] = useState({
    email: "",
    password: "",
    remember: false,
  });
  const location = useLocation();

  useEffect(() => {
    if (location.state) {
      const { email } = location.state;
      setInitialValues((prev) => ({ ...prev, email }));
    }
  }, [location, setInitialValues]);

  return (
    <Formik
      {...props}
      initialValues={initialValues}
      enableReinitialize
      validationSchema={validationSchema}
    >
      {({ values, errors, touched, handleChange, handleBlur }) => (
        <Form>
          <Input.Text
            label="E-mail Address"
            inputId="email"
            touched={touched}
            handleChange={handleChange}
            handleBlur={handleBlur}
            errors={errors}
            values={values}
            disabled={submitting}
          />
          <Input.Text
            label="Password"
            inputId="password"
            touched={touched}
            handleChange={handleChange}
            handleBlur={handleBlur}
            errors={errors}
            values={values}
            secureTextEntry
            disabled={submitting}
          />
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Link to={ROUTES.FORGOT_PASSWORD} className={classes.link}>
              I forgot my password
            </Link>
            <Checkbox label="Remember me" name="remember" />
          </Box>
          <Button
            text="LOG IN"
            type="submit"
            color="primary"
            loading={submitting}
          />
        </Form>
      )}
    </Formik>
  );
};

export default SignInForm;
