import React from "react";
import Rating from "@mui/material/Rating";
import { styled } from "@mui/styles";
import PropTypes from "prop-types";

const StyledRating = styled(Rating)({
  "& .MuiRating-iconFilled": {
    color: "#009432",
  },
  "& .MuiRating-iconHover": {
    color: "#009432",
  },
});

const RatingBar = (props) => {
  return (
    <StyledRating
      readOnly={props.readOnly}
      name="customized-color"
      max={5}
      value={props.userQuality ?? 0.0}
      onChange={(evt, val) => {
        if (!props.readOnly && props.onQualityChange) {
          props.onQualityChange(val);
        }
      }}
    />
  );
};

RatingBar.propTypes = {
  readOnly: PropTypes.bool,
  userQuality: PropTypes.number,
  onQualityChange: PropTypes.func,
};

export default RatingBar;
