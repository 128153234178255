import React from "react";
import { TextField as MaterialTextField } from "formik-mui";

const TextField = (props) => (
  <MaterialTextField
    fullWidth
    variant="outlined"
    margin="normal"
    InputLabelProps={{ shrink: true }}
    {...props}
  />
);

export default TextField;
