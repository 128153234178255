import { alpha } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  dialogTitle: {
    fontSize: "1.5rem",
    color: theme.palette.common.dark,
    justifyContent: "center",
    display: "flex",
    fontWeight: "normal",
  },
  dialogWrapper: {
    width: theme.spacing(100),
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
    "& .MuiTextField-root": {
      marginTop: 8,
    },
  },
  dialogWrapperNewLead: {
    width: theme.spacing(220),
    [theme.breakpoints.down("md")]: {
      width: theme.spacing(120),
    },
  },
  textField: {
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: theme.palette.primary.main,
      },
      "& input, textarea": {
        "&::placeholder": {
          fontStyle: "italic",
        },
      },
    },
    "&:hover fieldset": {
      borderColor: theme.palette.primary,
    },
  },
  selectField: {
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: theme.palette.primary.main,
      },
    },
    "&:hover fieldset": {
      borderColor: theme.palette.primary,
    },
  },
  button: {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.primary.main,
    "&:hover": {
      backgroundColor: theme.palette.main,
    },
  },
  redButton: {
    color: theme.palette.common.white,
    backgroundColor: alpha(theme.palette.error.dark, 0.85),
    "&:hover": {
      backgroundColor: theme.palette.error.main,
    },
  },
  backButton: {
    backgroundColor: theme.palette.main,
  },
  dialog: {
    position: "fixed",
    left: theme.spacing(10),
    bottom: theme.spacing(10),
  },
  dialogContent: {
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "1.5rem",
    lineHeight: "2rem",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    verticalAlign: "middle",
    textAlign: "center",
  },
  dialogContentNoCenter: {
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "1.5rem",
    lineHeight: "2rem",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    verticalAlign: "middle",
  },
  dialogWrapperAssignLead: {
    padding: theme.spacing(5),
    width: "300px",
  },
  textGeneral: {
    fontSize: 18,
    fontWeight: "bold",
  },
  textGeneralSecondary: {
    fontSize: 14,
    fontWeight: "normal",
  },
  titleRating: {
    display: "flex",
    flexDirection: "row",
    fontSize: "18px",
  },
  nameStyle: {
    fontSize: "20px",
    fontWeight: "bold",
    justifyContent: "space-between",
  },
  dataStyle: {
    fontSize: "18px",
    fontWeight: "bold",
    justifyContent: "space-between",
  },
  dialogContentInvite: {
    width: theme.spacing(70),
  },
  selectProduct: {
    "& .MuiSelect-selectMenu": {
      padding: `${theme.spacing(3)} ${theme.spacing(3.5)}`,
    },
  },
  dialogWrapperJoinGroup: {
    width: theme.spacing(87),
  },
  selectAddress: {
    width: "100%",
    border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: theme.spacing(1),
    padding: theme.spacing(2, 1),
  },
  errorMessage: {
    color: theme.palette.error.main,
    margin: theme.spacing(0.5, 3.5),
    fontSize: "0.75rem",
  },
  error: {
    borderColor: theme.palette.error.main,
  },
  redText: {
    color: alpha(theme.palette.error.dark, 0.85),
  },
  crmEmail: {
    fontSize: 16,
    textDecoration: "underline",
  },
  dropdownForm: {
    width: "100%",
  },
  addContactBtn: {
    marginTop: 16,
  },
  snackbar: {
    position: "absolute",
    left: 32,
    right: 32,
    display: "block",
  },
  submitBtnWrapper: {
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "flex-end",
    marginTop: "auto",
    [theme.breakpoints.down("md")]: {
      marginTop: 16,
    },
  },
}));

export default useStyles;
