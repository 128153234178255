import React, { useEffect, useRef, useState } from "react";
import { api, useGetNotificationSettingQuery } from "services/api";
import { CHAT, NOTIFICATION } from "utils/constants";

const Settings = ({ roomId }) => {
  const [attachments, setAttachments] = useState([]); // eslint-disable-line
  const [triggerGetMessageList, { data: messageListData }] =
    api.endpoints.getChatRoomMessageList.useLazyQuery();
  const { data: notificationSettingData } = useGetNotificationSettingQuery({ // eslint-disable-line
    deliveryMethod: NOTIFICATION.DELIVERY_METHOD.ALL,
    service: NOTIFICATION.SERVICE.CHAT,
    serviceId: roomId,
    action: NOTIFICATION.ACTION.ALL,
  });
  const offsetRef = useRef(0);
  const limit = 50;
  useEffect(() => {
    if (roomId) {
      triggerGetMessageList({
        roomId,
        listMessageType: CHAT.LIST_MESSAGE_TYPE.ATTACHMENT,
        offset: offsetRef.current,
        limit,
      });
    }
  }, [roomId]);
  useEffect(() => {
    if (messageListData?.messages) {
      setAttachments(messageListData?.messages);
      offsetRef.current += limit;
    }
  }, [messageListData]);
  return <></>;
};

export default Settings;
