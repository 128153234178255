import React, { useEffect, useState } from "react";
import { useEmailConfirmActionMutation } from "services/api";
import { useLocation, useHistory } from "react-router-dom";
import { Box, Typography } from "@mui/material";
import { EmailActionType, ROUTES } from "utils/constants";
import { makeStyles } from "@mui/styles";
import { CmtImage, AuthWrapper } from "components";

const useStyles = makeStyles((theme) => ({
  container: {
    padding: 52,
  },
  logoImg: {
    width: "40%",
    marginBottom: 16,
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
  title: {
    marginTop: 32,
    display: "flex",
    justifyContent: "center",
    fontWeight: 400,
    lineHeight: 1.6,
  },
}));

const SignUpConfirmation = () => {
  const [emailConfirmAction, { isLoading, isError, isSuccess }] =
    useEmailConfirmActionMutation();
  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();
  const [redirect, setRedirect] = useState(false);

  // Validate if redirected from deep link
  useEffect(() => {
    if (!location.state?.token || !location.state?.email) {
      history.replace("/");
    }
    emailConfirmAction({
      token: location.state.token,
      action: EmailActionType.SIGNUP_CONFIRMATION,
    });
    console.log("Confirming email");
  }, [location.state, history, emailConfirmAction]);

  useEffect(() => {
    if (redirect) {
      // Check if redirected from deep link
      // prettier-ignore
      if (location.state?.inviteId && location.state?.inviteCode && location.state?.inviteAction) {
        localStorage.setItem("inviteId", location.state?.inviteId);
        localStorage.setItem("inviteCode", location.state?.inviteCode);
        localStorage.setItem("inviteAction", location.state?.inviteAction);
        console.log("Found invitation, pushing: redirect", redirect);
      }
      console.log("From signup redirecting to: ", ROUTES.REDIRECT);
      history.replace(ROUTES.REDIRECT);
    }
  }, [redirect, location.state, history]);

  const getStatusMessage = () => {
    if (isLoading) {
      return "Confirming your email...";
    }
    if (isSuccess) {
      console.error("location.state", JSON.stringify(location.state));
      setTimeout(() => {
        setRedirect(true);
      }, 3000);
      return "Your account has been successfully created, redirecting ...";
    }
    if (isError) {
      return "There was an error confirming your email. If the problem persists, please contact support (support@allianceapp.com)";
    }
  };

  return (
    <AuthWrapper>
      <Box className={classes.container}>
        <CmtImage
          src={"/images/Alliance-logo-h-full-color.png"}
          className={classes.logoImg}
        />
        <Typography variant="h1" className={classes.title}>
          {getStatusMessage()}
        </Typography>
      </Box>
    </AuthWrapper>
  );
};

export default SignUpConfirmation;
