import React from "react";
import CmtImage from "components/@coremat/CmtImage/CmtImage";
import useStyles from "./AppLogo.style";
import PropTypes from "prop-types";

const AppLogo = ({ size }) => {
  const classes = useStyles({ size });
  let logoSrc;
  if (size === "sm") {
    logoSrc = "/images/logo-new-blue-small.png";
  } else if (size === "md") {
    logoSrc = "/images/logo-new-blue-medium.png";
  }
  return <CmtImage src={logoSrc} className={classes.logoImage} />;
};

AppLogo.propTypes = {
  size: PropTypes.oneOf(["sm", "md"]),
};

AppLogo.defaultProps = {
  size: "md",
};

export default AppLogo;
