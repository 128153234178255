import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  textInput: {
    backgroundColor: "white",
    width: "100%",
    fontSize: 16,
    marginTop: 16,
  },
  selectAddress: {
    width: "100%",
    border: (props) =>
      `1px solid ${
        props.disabled
          ? theme.palette.text.disabled
          : theme.palette.primary.main
      }`,
    borderRadius: theme.spacing(1),
    padding: theme.spacing(2, 1),
  },
  errorMessage: {
    color: theme.palette.error.main,
    margin: theme.spacing(0.5, 3.5),
    fontSize: "0.75rem",
  },
  error: {
    borderColor: theme.palette.error.main,
  },
}));

export default useStyles;
