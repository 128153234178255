import React, { useEffect, useMemo, useState } from "react";
import {
  useGetUserQuery,
  useScheduleDeleteUserMutation,
  useUserUpdateMutation,
} from "services/api";
import {
  Container,
  PageTitle,
  Button,
  VerticalList,
  Text,
  Avatar,
  SectionTitle,
  Input,
} from "components";
import { Box, Snackbar, IconButton, Grid } from "@mui/material";
import { Check, Close } from "@mui/icons-material";
import useStyles from "../Settings.style";
import { getSettingsTabs } from "../Settings.utils";
import { useHistory } from "react-router";
import { Form, Formik } from "formik";
import * as yup from "yup";
import RemoveDialog from "components/Modals/RemoveDialog";
import { ROUTES } from "utils/constants";

const schema = yup.object().shape({
  firstName: yup.string().required("First name is required"),
  lastName: yup.string().trim().required("Last name is required"),
  company: yup.string().max(50, "Must be less than 50 characters").optional(),
  title: yup.string().max(50, "Must be less than 50 characters").optional(),
});

const Profile = () => {
  const classes = useStyles();
  const { data: userInfo } = useGetUserQuery();
  const history = useHistory();
  const [showAccountDelete, setShowAccountDelete] = useState(false);
  const [
    scheduleDeleteUser,
    {
      isLoading: schedulingDeleteUser,
      isSuccess: scheduleDeleteSuccess,
      error: scheduleDeleteError,
    },
  ] = useScheduleDeleteUserMutation();
  const [
    userUpdate,
    {
      isLoading: updatingUser,
      isSuccess: updateUserSuccess,
      error: updateUserError,
    },
  ] = useUserUpdateMutation();
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarMsg, setSnackbarMsg] = useState("");
  const initialValues = {
    firstName: userInfo?.firstName ?? "",
    lastName: userInfo?.lastName ?? "",
    company: userInfo?.company ?? "",
    title: userInfo?.title ?? "",
  };

  const tabs = useMemo(() => getSettingsTabs(), [getSettingsTabs]);

  useEffect(() => {
    if (scheduleDeleteSuccess) {
      handleCloseAccountDelete();
      setSnackbarMsg("You have requested your account deletion.");
      setShowSnackbar(true);
      setTimeout(() => {
        history.push(ROUTES.SIGN_OUT);
      }, 3000);
    }
    if (updateUserSuccess) {
      setSnackbarMsg("You have updated your user profile.");
      setShowSnackbar(true);
    }
  }, [scheduleDeleteSuccess, updateUserSuccess]);

  useEffect(() => {
    const error = scheduleDeleteError || updateUserError;
    if (error) {
      setSnackbarMsg(error.data?.message ?? "Unknown error detected.");
      setShowSnackbar(true);
    }
  }, [scheduleDeleteError, updateUserError]);

  const handleSave = ({ firstName, lastName, company, title }) => {
    userUpdate({
      user: {
        firstName,
        lastName,
        company,
        title,
      },
    });
  };

  const onClickTab = (item) => {
    history.push(item.link);
  };

  const handleAccountDelete = () => {
    setShowAccountDelete(true);
  };

  const handleCloseAccountDelete = () => {
    setShowAccountDelete(false);
  };

  const onConfirmedRemove = () => {
    scheduleDeleteUser();
  };

  const handleSnackbarClose = () => {
    setSnackbarMsg("");
    setShowSnackbar(false);
  };

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={schema}
        enableReinitialize
        onSubmit={handleSave}
      >
        {(props) => (
          <Form className={classes.formikForm}>
            <Container>
              <Box className={classes.titleBox}>
                <PageTitle title="My Settings" />
                <Box className={classes.buttonWrapper}>
                  <Button
                    startIcon={<Check />}
                    color="secondary"
                    fullWidth={false}
                    text="SAVE"
                    variant="outlined"
                    size="small"
                    type="submit"
                    loading={updatingUser}
                  />
                </Box>
              </Box>
              <Grid container my={16} display="flex" flex={1}>
                <Grid item xs={12} sm={12} md={4} lg={4}>
                  <Box width={1}>
                    <VerticalList
                      activeId={0}
                      data={tabs}
                      onPressItem={onClickTab}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={4}>
                  <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    mt={2}
                  >
                    <Avatar
                      userId={userInfo?.userId}
                      label={
                        userInfo
                          ? userInfo?.firstName[0] + userInfo?.lastName[0]
                          : ""
                      }
                      size={256}
                    />
                    <Box mt={16} textAlign="center">
                      <Text variant="h1" color="secondary" gutterBottom>
                        {userInfo
                          ? `${userInfo?.firstName} ${userInfo?.lastName}`
                          : ""}
                      </Text>
                      <Text color="secondary">{userInfo?.email ?? ""}</Text>
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={4} display="flex">
                  <Box
                    display="flex"
                    flex={1}
                    flexDirection="column"
                    justifyContent="space-between"
                  >
                    <Box>
                      <SectionTitle title="Profile Information" />
                      <Input.Text
                        label="First Name"
                        inputId="firstName"
                        values={props.values}
                        errors={props.errors}
                        touched={props.touched}
                        handleChange={props.handleChange}
                      />
                      <Input.Text
                        label="Last Name"
                        inputId="lastName"
                        values={props.values}
                        errors={props.errors}
                        touched={props.touched}
                        handleChange={props.handleChange}
                      />
                      <Input.Text
                        label="Company"
                        inputId="company"
                        values={props.values}
                        errors={props.errors}
                        touched={props.touched}
                        handleChange={props.handleChange}
                      />
                      <Input.Text
                        label="Title"
                        inputId="title"
                        values={props.values}
                        errors={props.errors}
                        touched={props.touched}
                        handleChange={props.handleChange}
                      />
                    </Box>
                    <Button
                      startIcon={<Close />}
                      text="REQUEST ACCOUNT DELETION"
                      fullWidth={false}
                      variant="text"
                      color="secondary"
                      onClick={handleAccountDelete}
                    />
                  </Box>
                </Grid>
              </Grid>
            </Container>
          </Form>
        )}
      </Formik>
      {showAccountDelete && (
        <RemoveDialog
          open={showAccountDelete}
          onClose={handleCloseAccountDelete}
          onRemove={onConfirmedRemove}
          title="Delete account"
          body="delete your account?"
          warning="This action can't be undone"
          data={userInfo}
          confirmButtonTitle="REQUEST DELETION"
          isLoading={schedulingDeleteUser}
        />
      )}
      <Snackbar
        open={showSnackbar}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
        message={snackbarMsg}
        className={classes.snackbar}
        action={
          <IconButton
            color="inherit"
            size="small"
            onClick={handleSnackbarClose}
          >
            <Close fontSize="small" />
          </IconButton>
        }
      />
    </>
  );
};

export default Profile;
