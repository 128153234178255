import { Typography } from "@mui/material";
import PropTypes from "prop-types";

const Text = ({ children, ...props }) => {
  return <Typography {...props}>{children}</Typography>;
};

Text.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
};

export default Text;
