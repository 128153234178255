import React, { useEffect, useMemo, useState } from "react";
import { Settings, Share } from "@mui/icons-material";
import { useHistory, useParams } from "react-router";
import { Box } from "@mui/material";
import {
  BackButton,
  Button,
  Container,
  GoogleMapBox,
  PageLoader,
  PageTitle,
  SectionTitle,
  TabBar,
  Text,
  VerticalList,
} from "components";
import { api, useGetOpportunityQuery } from "services/api";
import useStyles from "../Opportunities.styles";
import { getOpportunityTabs } from "../Opportunities.utils";
import { geocodeByPlaceId } from "react-google-places-autocomplete";
import { getRedirectUrl, ROUTES } from "utils/constants";
import InviteUsers from "components/Modals/InviteUsers";

const OpportunityDetails = () => {
  const classes = useStyles();
  const { opportunity_id: opportunityId } = useParams();
  const { data: opportunityData } = useGetOpportunityQuery({
    opportunityId,
    detailLevel: "DATA_FULL",
  });
  const [formattedAddress, setFormattedAddress] = useState("");
  const [triggerGetUser] = api.endpoints.getUser.useLazyQuerySubscription();
  const [sharedWith, setSharedWith] = useState([]);
  const [showInviteUser, setShowInviteUser] = useState(false);
  const history = useHistory();

  const tabs = useMemo(
    () => getOpportunityTabs(opportunityId),
    [opportunityId, getOpportunityTabs]
  );

  useEffect(() => {
    if (opportunityData) {
      geocodeByPlaceId(opportunityData.address.placeId).then((result) => {
        setFormattedAddress(result[0]?.formatted_address);
      });
      const userIds = opportunityData.users.map((u) => u.userId);
      const userInfoPromises = userIds.map((userId) => {
        return triggerGetUser(userId).unwrap();
      });
      Promise.all(userInfoPromises).then((userInfos) => {
        setSharedWith(
          userInfos.map((userInfo) => ({
            id: userInfo.userId,
            avatarText: userInfo.firstName[0] + userInfo.lastName[0],
            title: userInfo.firstName + " " + userInfo.lastName,
            subtitle: userInfo.company || "Unknown",
          }))
        );
      });
    }
  }, [opportunityData]);

  const handleShare = () => {
    setShowInviteUser(true);
  };

  const handleCloseInviteUser = () => {
    setShowInviteUser(false);
  };

  const handleSettings = () => {
    history.push(getRedirectUrl(ROUTES.OPPORTUNITY_SETTINGS, opportunityId));
  };

  const handleBack = () => {
    history.push(ROUTES.OPPORTUNITY_LIST);
  };

  if (!opportunityData) {
    return <PageLoader />;
  }

  return (
    <Container>
      <Box className={classes.titleBox}>
        <Box className={classes.backButtonWrapper}>
          <BackButton onClick={handleBack} />
        </Box>
        <PageTitle title={opportunityData.name} />
        <Box className={classes.buttonWrapper}>
          <Button
            startIcon={<Share />}
            color="secondary"
            fullWidth={false}
            text="SHARE"
            variant="outlined"
            size="small"
            onClick={handleShare}
            containerStyle={classes.mr2}
          />
          <Button
            startIcon={<Settings />}
            color="secondary"
            fullWidth={false}
            text="Settings"
            variant="outlined"
            size="small"
            onClick={handleSettings}
          />
        </Box>
      </Box>
      <Box display="flex" flex={1} flexDirection="column" mt={6}>
        <TabBar tabs={tabs} selectedId={0} />
        <Box display="flex" flex={1} mt={6}>
          <Box display="flex" flexDirection="column" flex={1}>
            <Text color="secondary">Description</Text>
            <Text>{opportunityData.notes}</Text>
            <Box mt={6}>
              <Text color="secondary">Location</Text>
              <Text>{formattedAddress}</Text>
            </Box>
            <Box display="flex" flex={1} mt={6}>
              <GoogleMapBox
                lat={parseFloat(opportunityData.address.latitude)}
                lng={parseFloat(opportunityData.address.longitude)}
                containerElement={<Box display="flex" flex={1} />}
                mapElement={<Box flex={1} />}
              />
            </Box>
          </Box>
          <Box flex={1} mx={6}>
            <SectionTitle
              title={"Shared With (" + opportunityData.users.length + ")"}
            />
            <VerticalList data={sharedWith} />
          </Box>
          {/* <Box flex={1}><SectionTitle title="Attachments" /></Box> */}
        </Box>
      </Box>
      {showInviteUser && (
        <InviteUsers
          open={showInviteUser}
          handleDialog={handleCloseInviteUser}
          opportunityId={opportunityId}
        />
      )}
    </Container>
  );
};

export default OpportunityDetails;
