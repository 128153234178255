import React, { useEffect, Suspense, lazy } from "react";
import { Redirect, Route, Switch } from "react-router";
import { useSelector, useDispatch } from "react-redux";
import { useLocation, useHistory } from "react-router-dom";
import { ROUTES } from "../utils/constants";
import { PusherProvider } from "@harelpls/use-pusher";
import PusherBatchAuthorizer from "pusher-js-auth";
import { PageLoader } from "components";
import ModalRoot from "components/Modals/ModalRoot";
import { api } from "services/api";
import Branch from "branch-sdk";
import {
  SignIn,
  SignUp,
  SignUpConfirmation,
  SignOut,
  ResetPassword,
  DownloadApp,
  ForgotPassword,
  LinkedInSignIn,
  GroupUserInvited,
  OpportunityUserInvited,
  TestFlow,
} from "pages";
import { baseURL, branchKey, pusherKey } from "utils/env";

if (branchKey) {
  try {
    Branch.init(branchKey);
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
  }
}

const RestrictedRoute = ({ component: Component, ...rest }) => {
  const { authUser } = useSelector(({ user }) => user);
  return (
    <Route
      {...rest}
      render={(props) =>
        authUser ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: ROUTES.SIGN_IN,
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );
};

const AppRoutes = () => {
  const pusherConfig = {
    clientKey: pusherKey,
    cluster: "mt1",
    authorizer: PusherBatchAuthorizer,
    authEndpoint: `${baseURL}/chat/authenticate`,
    authDelay: 200,
    auth: {
      headers: {
        Authorization: "bearer " + localStorage.getItem("access_token"),
      },
    },
  };
  const dispatch = useDispatch();
  const userId = useSelector((state) => state.user.userId);

  // Only initiate getBusinessByUser query, so that we can prevent re-rendering the whole routes.
  useEffect(() => {
    const result = dispatch(api.endpoints.getBusinessUser.initiate(userId));
    console.log("Route result getBusinessuser", result);
    return result.unsubscribe;
  }, [dispatch, userId]);

  return (
    <PusherProvider {...pusherConfig}>
      <Suspense fallback={<PageLoader />}>
        <Switch>
          <Route
            path={ROUTES.ACTIVITY_LIST}
            component={lazy(() => import("../pages/Activities/Activities"))}
          />
          <Route
            path={ROUTES.GROUP_LIST}
            exact
            component={lazy(() => import("../pages/Group/List/GroupList"))}
          />
          <Route
            path={ROUTES.GROUP_LEAD_LIST}
            exact
            component={lazy(() => import("../pages/Group/Leads/GroupLeads"))}
          />
          <Route
            path={ROUTES.GROUP_MESSAGE_LIST}
            exact
            component={lazy(() =>
              import("../pages/Group/Message/GroupMessage")
            )}
          />
          <Route
            path={ROUTES.GROUP_USER_LIST}
            exact
            component={lazy(() => import("../pages/Group/Users/GroupUsers"))}
          />
          <Route
            path={ROUTES.GROUP_SETTINGS}
            exact
            component={lazy(() =>
              import("../pages/Group/Settings/GroupSettings")
            )}
          />
          <Route
            path={ROUTES.OPPORTUNITY_LIST}
            exact
            component={lazy(() =>
              import("../pages/Opportunities/List/OpportunityList")
            )}
          />
          <Route
            path={ROUTES.OPPORTUNITY_INFORMATION}
            exact
            component={lazy(() =>
              import(
                "../pages/Opportunities/Information/OpportunityInformation"
              )
            )}
          />
          <Route
            path={ROUTES.OPPORTUNITY_MESSAGE_LIST}
            exact
            component={lazy(() =>
              import("../pages/Opportunities/Message/OpportunityMessage")
            )}
          />
          <Route
            path={ROUTES.OPPORTUNITY_CONTACT_LIST}
            exact
            component={lazy(() =>
              import("../pages/Opportunities/Contact/OpportunityContact")
            )}
          />
          <Route
            path={ROUTES.OPPORTUNITY_CONTACT_DETAILS}
            exact
            component={lazy(() =>
              import(
                "../pages/Opportunities/Contact/Details/OpportunityContactDetails"
              )
            )}
          />
          <Route
            path={ROUTES.OPPORTUNITY_SETTINGS}
            exact
            component={lazy(() =>
              import("../pages/Opportunities/Settings/OpportunitySettings")
            )}
          />
          <Route
            path={ROUTES.CHAT_DIRECT_LIST}
            component={lazy(() => import("../pages/Chat/Direct/ChatDirect"))}
          />
          <Route
            path={ROUTES.CHAT_OPPORTUNITY_LIST}
            component={lazy(() =>
              import("../pages/Chat/Opportunities/ChatOpportunities")
            )}
          />
          <Route
            path={ROUTES.CHAT_GROUP_LIST}
            component={lazy(() => import("../pages/Chat/Groups/ChatGroups"))}
          />
          <Route
            path={ROUTES.CHAT_BUSINESS_LIST}
            component={lazy(() =>
              import("../pages/Chat/Business/ChatBusiness")
            )}
          />
          <Route
            path={ROUTES.MY_SETTINGS_PROFILE}
            exact
            component={lazy(() =>
              import("../pages/Settings/Profile/ProfileInformation")
            )}
          />
          <Route
            path={ROUTES.MY_SETTINGS_NOTIFICATIONS}
            exact
            component={lazy(() =>
              import("../pages/Settings/Notifications/NotificationSettings")
            )}
          />
          <Route
            path={ROUTES.MY_SETTINGS_PRIVACY_POLICY}
            exact
            component={lazy(() =>
              import(
                "../pages/Settings/PrivacyAndTerms/PrivacyPolicy/PrivacyPolicy"
              )
            )}
          />
          <Route
            path={ROUTES.MY_SETTINGS_TERMS_USE}
            exact
            component={lazy(() =>
              import("../pages/Settings/PrivacyAndTerms/TermsOfUse/TermsOfUse")
            )}
          />
          <Route
            path={ROUTES.REDIRECT}
            component={lazy(() =>
              import("../pages/RedirectRouter/RedirectRouter")
            )}
          />
          <Route
            path={ROUTES.ADMIN_MANAGE_USER}
            component={lazy(() =>
              import("../pages/AdminManageUsers/AdminManageUsers")
            )}
          />
          <Route
            path={ROUTES.GROUP_LEAD_DETAILS}
            component={lazy(() => import("../pages/Lead/Details/LeadDetails"))}
          />
          <Route
            path={ROUTES.ADMIN_INVOICES}
            component={lazy(() => import("../pages/Invoices/Invoices"))}
          />
          <Route
            path={ROUTES.ADMIN_CRM_INTEGRATIONS}
            component={lazy(() => import("../pages/Crms/Crms"))}
          />
          <Route component={lazy(() => import("../pages/404/Error404"))} />
        </Switch>
        <ModalRoot />
      </Suspense>
    </PusherProvider>
  );
};

const Routes = () => {
  const { authUser } = useSelector(({ user }) => user);
  const location = useLocation();
  const history = useHistory();
  console.log("location", JSON.stringify(location));
  useEffect(() => {
    if (!branchKey) return;
    try {
      Branch.data((err, data) => {
        if (err) throw err;
        console.log("Branch data", JSON.stringify(data));
        if (data.data_parsed?.action === "RESET_PASSWORD" && !authUser) {
          const { token, email } = data.data_parsed;
          history.push({
            pathname: ROUTES.RESET_PASSWORD,
            state: {
              token,
              email,
            },
          });
        } else if (data.data_parsed?.action === "BUSINESS_INVITE") {
          history.push({
            pathname: ROUTES.DOWNLOAD_APP,
          });
        } else if (
          !authUser &&
          data.data_parsed?.action === "SIGNUP_CONFIRMATION"
        ) {
          const {
            email,
            token,
            group_id: groupId,
            group_invite_code: groupInviteCode,
          } = data.data_parsed;
          history.push({
            pathname: ROUTES.SIGNUP_CONFIRM,
            state: {
              token,
              email,
              groupId,
              groupInviteCode,
            },
          });
        }
      });
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  }, [authUser, history]);

  if (
    location.pathname === "" ||
    location.pathname === "/" ||
    (authUser &&
      (location.pathname === ROUTES.SIGN_IN ||
        location.pathname === ROUTES.SIGN_UP ||
        location.pathname === ROUTES.FORGOT_PASSWORD ||
        location.pathname === ROUTES.LINKEDIN_LOGIN ||
        location.pathname === ROUTES.RESET_PASSWORD))
  ) {
    console.log("location", JSON.stringify(location));
    return <Redirect to={ROUTES.REDIRECT} />;
  }
  console.log("location", JSON.stringify(location));
  return (
    <React.Fragment>
      <Switch>
        <Route path={ROUTES.SIGN_IN} component={SignIn} />
        <Route path={ROUTES.SIGN_UP} component={SignUp} />
        <Route path={ROUTES.FORGOT_PASSWORD} component={ForgotPassword} />
        <Route path={ROUTES.TEST_CRM_FLOW} component={TestFlow} />
        <Route path={ROUTES.RESET_PASSWORD} component={ResetPassword} />
        <Route path={ROUTES.LINKEDIN_LOGIN} component={LinkedInSignIn} />
        <Route path={ROUTES.DOWNLOAD_APP} component={DownloadApp} />
        <Route path={ROUTES.SIGNUP_CONFIRM} component={SignUpConfirmation} />
        <Route path={ROUTES.GROUP_USER_INVITED} component={GroupUserInvited} />
        <Route
          path={ROUTES.OPPORTUNITY_USER_INVITED}
          component={OpportunityUserInvited}
        />
        <RestrictedRoute path={ROUTES.SIGN_OUT} component={SignOut} />
        <RestrictedRoute path="/" component={AppRoutes} />
      </Switch>
    </React.Fragment>
  );
};

export default Routes;
