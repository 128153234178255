import React, { useEffect, useState } from "react";
import {
  Container,
  PageTitle,
  Button,
  PageLoader,
  SectionTitle,
  VerticalList,
  TextWithIcon,
  Switch,
} from "components";
import { Box, Snackbar, IconButton } from "@mui/material";
import useStyles from "../Opportunities.styles";
import { useHistory, useParams } from "react-router";
import {
  api,
  useGetNotificationSettingQuery,
  useGetOpportunityQuery,
  useToggleNotificationSettingMutation,
} from "services/api";
import { Share, Close, Check, Comment } from "@mui/icons-material";
import theme from "theme/theme";
import { NOTIFICATION } from "utils/constants";
import InviteUsers from "components/Modals/InviteUsers";

const OpportunitySettings = () => {
  const classes = useStyles();
  const { opportunity_id: opportunityId } = useParams();
  const { data: opportunityData } = useGetOpportunityQuery({
    opportunityId,
    detailLevel: "DATA_FULL",
  });
  const { data: notificationSettings } = useGetNotificationSettingQuery({
    deliveryMethod: NOTIFICATION.DELIVERY_METHOD.ALL,
    service: NOTIFICATION.SERVICE.OPPORTUNITY,
    serviceId: opportunityId,
    action: NOTIFICATION.ACTION.CHAT_MESSAGE_SEND,
  });
  const [
    toggleNotificationSetting,
    { isSuccess: toggleSettingSuccess, error: toggleSettingError },
  ] = useToggleNotificationSettingMutation();
  const history = useHistory();
  const [triggerGetUser] = api.endpoints.getUser.useLazyQuerySubscription();
  const [messageNotification, setMessageNotification] = useState(false);
  const [sharedWith, setSharedWith] = useState([]);
  const [showInviteUser, setShowInviteUser] = useState(false);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarMsg, setSnackbarMsg] = useState("");

  useEffect(() => {
    if (opportunityData) {
      const userIds = opportunityData.users.map((u) => u.userId);
      const userInfoPromises = userIds.map((userId) => {
        return triggerGetUser(userId).unwrap();
      });
      Promise.all(userInfoPromises).then((userInfos) => {
        setSharedWith(
          userInfos.map((userInfo) => ({
            id: userInfo.userId,
            avatarText: userInfo.firstName[0] + userInfo.lastName[0],
            title: userInfo.firstName + " " + userInfo.lastName,
            subtitle: userInfo.company || "Unknown",
          }))
        );
      });
    }
  }, [opportunityData]);

  useEffect(() => {
    if (notificationSettings) {
      setMessageNotification(
        !!notificationSettings.settings?.find((nS) => nS.toggledOn)
      );
    }
  }, [notificationSettings]);

  useEffect(() => {
    if (toggleSettingSuccess) {
      setSnackbarMsg("You have updated the opportunity setting.");
      setShowSnackbar(true);
      history.goBack();
    }
  }, [toggleSettingSuccess]);

  useEffect(() => {
    const error = toggleSettingError;
    if (error) {
      setSnackbarMsg(error.data?.message);
      setShowSnackbar(true);
    }
  }, [toggleSettingError]);

  const handleShare = () => {
    setShowInviteUser(true);
  };

  const handleCloseInviteUser = () => {
    setShowInviteUser(false);
  };

  const handleCancel = () => {
    history.goBack();
  };

  const handleSave = () => {
    toggleNotificationSetting({
      deliveryMethod: NOTIFICATION.DELIVERY_METHOD.ALL,
      service: NOTIFICATION.SERVICE.OPPORTUNITY,
      serviceId: opportunityId,
      action: NOTIFICATION.ACTION.ALL,
      toggledOn: messageNotification,
    });
  };

  const handleSnackbarClose = () => {
    setSnackbarMsg("");
    setShowSnackbar(false);
  };

  if (!opportunityData) {
    return <PageLoader />;
  }

  return (
    <Container>
      <Box className={classes.titleBox}>
        <PageTitle title={opportunityData.name} />
        <Box className={classes.buttonWrapper}>
          <Button
            startIcon={<Share />}
            color="secondary"
            fullWidth={false}
            text="SHARE"
            variant="outlined"
            size="small"
            onClick={handleShare}
            containerStyle={classes.mr2}
          />
          <Button
            startIcon={<Close />}
            color="secondary"
            fullWidth={false}
            text="Cancel"
            variant="outlined"
            size="small"
            onClick={handleCancel}
            containerStyle={classes.mr2}
          />
          <Button
            startIcon={<Check />}
            color="secondary"
            fullWidth={false}
            text="SAVE"
            variant="outlined"
            size="small"
            onClick={handleSave}
          />
        </Box>
      </Box>
      <Box
        flex={1}
        display="flex"
        flexDirection="column"
        alignItems="center"
        mt={16}
      >
        <TextWithIcon
          icon={<Comment htmlColor={theme.palette.secondary.main} />}
          title="CHAT SETTINGS"
        />
        <Box display="flex" flex={1} width={1} mt={6}>
          <Box flex={1} mr={4}>
            <SectionTitle title={"Shared With (" + sharedWith.length + ")"} />
            <VerticalList data={sharedWith} />
          </Box>
          <Box flex={1} ml={4}>
            <SectionTitle title="Chat Notifications" />
            <Switch
              title="Messages"
              value={messageNotification}
              setValue={(state) => setMessageNotification(state)}
            />
          </Box>
        </Box>
      </Box>
      <Snackbar
        open={showSnackbar}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
        message={snackbarMsg}
        className={classes.snackbar}
        action={
          <IconButton
            color="inherit"
            size="small"
            onClick={handleSnackbarClose}
          >
            <Close fontSize="small" />
          </IconButton>
        }
      />
      {showInviteUser && (
        <InviteUsers
          open={showInviteUser}
          handleDialog={handleCloseInviteUser}
          opportunityId={opportunityId}
        />
      )}
    </Container>
  );
};

export default OpportunitySettings;
