import React from "react";
import { Box, Typography } from "@mui/material";
import useStyles from "../../pages/index.style";
import PropTypes from "prop-types";

const AppHeader = ({ title }) => {
  const classes = useStyles();

  return (
    <Box className={classes.inBuildAppHeader}>
      <Box className={classes.inBuildAppHeaderSidebar}>
        <Typography
          className={classes.inBuildAppHeaderTitle}
          component="div"
          variant="h1"
        >
          {title}
        </Typography>
      </Box>
    </Box>
  );
};

export default AppHeader;

AppHeader.propTypes = {
  onChangeViewMode: PropTypes.func,
  title: PropTypes.string.isRequired,
};
