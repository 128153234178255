import React from "react";
import PropTypes from "prop-types";
import { Box, alpha } from "@mui/material";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import useStyles from "./Input.style";
import { ErrorMessage } from "formik";
import theme from "theme/theme";
import { createTheme } from "@mui/material/styles";
import { googlePlacesApiKey } from "utils/env";

const themeApp = createTheme(theme);
const AddressSearch = ({
  label,
  placeholder,
  values,
  inputId,
  errors,
  touched,
  handleChange,
  handleBlur,
  onFocus,
  disabled,
}) => {
  const classes = useStyles({ disabled });
  const customStyles = () => {
    const addressErr = touched[inputId] && Boolean(errors[inputId]);
    return {
      control: () => ({
        display: "flex",
        alignItems: "center",
        background: "transparent",
        "&:hover": {
          boxShadow: "none",
        },
      }),
      menu: () => ({
        backgroundColor: themeApp.palette.common.white,
        boxShadow: `${themeApp.spacing(0.25, 0.5, 1.5)} ${alpha(
          themeApp.palette.common.black,
          0.25
        )}`,
        position: "absolute",
        left: 0,
        width: "100%",
        zIndex: 999999,
        maxHeight: themeApp.spacing(54),
      }),
      menuList: () => ({
        maxHeight: themeApp.spacing(54),
        overflowY: "auto",
      }),
      placeholder: () => ({
        color: addressErr
          ? themeApp.palette.error.main
          : alpha(themeApp.palette.common.black, 0.5),
        fontSize: "1rem",
        position: "absolute",
      }),
    };
  };
  return (
    <Box className={classes.textInput}>
      <GooglePlacesAutocomplete
        apiKey={googlePlacesApiKey}
        autocompletionRequest={{
          types: ["establishment"],
          componentRestrictions: { country: "US" },
          language: "en",
        }}
        selectProps={{
          id: inputId,
          label,
          value: values[inputId],
          isDisabled: disabled,
          placeholder: placeholder,
          isClearable: true,
          className:
            touched[inputId] && Boolean(errors[inputId])
              ? `${classes.selectAddress} ${classes.error}`
              : classes.selectAddress,
          onChange: handleChange,
          onBlur: handleBlur,
          onFocus,
          styles: customStyles(),
        }}
      />
      <ErrorMessage name={inputId}>
        {(msg) => <div className={classes.errorMessage}>{msg}</div>}
      </ErrorMessage>
    </Box>
  );
};

AddressSearch.propTypes = {
  inputId: PropTypes.string.isRequired,
  label: PropTypes.string,
  values: PropTypes.object.isRequired,
  errors: PropTypes.object,
  handleChange: PropTypes.func.isRequired,
  handleBlur: PropTypes.func,
  variant: PropTypes.oneOf(["outlined", "standard", "filled"]),
  color: PropTypes.oneOf(["primary", "secondary", "error"]),
  disabled: PropTypes.bool,
  onLocationSelect: PropTypes.func,
};

AddressSearch.defaultProps = {
  errors: {},
  handleBlur: () => {},
  onLocationSelect: () => {},
  variant: "outlined",
  color: "primary",
  disabled: false,
};

export default AddressSearch;
