import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import Grid from "@mui/material/Grid";
import { Box, Snackbar, IconButton } from "@mui/material";
import { AutoFixHigh, Create, Close } from "@mui/icons-material";
import {
  GridContainer,
  Container,
  PageTitle,
  Button,
  LeadContactInformation,
  Empty,
  ChatFooter,
  VerticalList,
  BackButton,
  GoogleMapBox,
} from "components";
import {
  api,
  useAddRatingQualityMutation,
  useGetChatRoomListQuery,
  useGetLeadQuery,
  useSendChatLeadMessageMutation,
} from "services/api";
import useStyles from "./LeadDetails.style";
import { CHAT } from "utils/constants";
import AddEditLead from "components/Modals/AddEditLead";
import AddEditOpportunity from "components/Modals/AddEditOpportunity";

const LeadDetails = () => {
  const classes = useStyles();
  let { lead_id } = useParams();
  const [message, setMessage] = useState("");
  const [sendChatLeadMessage, { data: newLeadMessage }] =
    useSendChatLeadMessageMutation();
  const { data: leadRoomList } = useGetChatRoomListQuery(
    {
      groupingLevel: CHAT.ROOM_GROUPING_LEVEL.LEAD,
      groupingLevelId: lead_id,
    },
    { skip: !lead_id }
  );
  const [triggerGetChatRoomMessageList, { data: leadMessagesData }] =
    api.endpoints.getChatRoomMessageList.useLazyQuery();
  const { data: currentLead, isSuccess: fetchLeadSuccess } =
    useGetLeadQuery(lead_id);
  const [leadMessages, setLeadMessages] = useState([]);
  const [addRatingQuality] = useAddRatingQualityMutation();
  const [showAddOpp, setShowAddOpp] = useState(false);
  const [showEditLead, setShowEditLead] = useState(false);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarMsg, setSnackbarMsg] = useState("");
  const history = useHistory();

  useEffect(() => {
    if (leadRoomList?.rooms?.length > 0) {
      const { roomId } = leadRoomList.rooms[0];
      triggerGetChatRoomMessageList({ roomId });
    }
  }, [leadRoomList]);

  useEffect(() => {
    if (leadMessagesData) {
      setLeadMessages(
        leadMessagesData.messages.map((m) => ({
          id: m.messageId,
          title: m.message,
          subtitle: m.user?.firstName + " " + m.user?.lastName,
        }))
      );
    }
  }, [leadMessagesData]);

  useEffect(() => {
    if (newLeadMessage) {
      setLeadMessages((prev) => {
        prev.push({
          id: newLeadMessage.messageId,
          title: newLeadMessage.message,
          subtitle:
            newLeadMessage.user?.firstName +
            " " +
            newLeadMessage.user?.lastName,
        });
        return [...prev];
      });
    }
  }, [newLeadMessage]);

  const onSendMessage = () => {
    if (message) {
      const { roomId } = leadRoomList.rooms[0];
      sendChatLeadMessage({ roomId, message });
      setMessage("");
    }
  };
  const handleQualityChange = (leadId, val) => {
    addRatingQuality({ leadId, rating: val });
  };

  const handleAddressPressed = (address) => {
    const { latitude, longitude } = address;
    window.open(
      "https://maps.google.com?q=" + latitude + "," + longitude,
      "_blank"
    );
  };

  const handleEmailPressed = (email) => {
    window.open("mailto:" + email, "_blank");
  };

  const handlePhonePressed = (phone) => {
    window.open("tel:" + phone, "_blank");
  };

  const handleLinkedPressed = (linkedin) => {
    window.open("https://" + linkedin, "_blank");
  };

  const renderEmpty = () => (
    <Empty
      image="/images/group/lead_comments_empty.png"
      title={"There's not much\nhere yet."}
      subtitle={
        "Leave a comment to provide additional\ninformation or share your experience(s)\nworking with this lead"
      }
    />
  );

  const handleCreateOpp = () => {
    setShowAddOpp(true);
  };

  const handleEditLead = () => {
    setShowEditLead(true);
  };

  const handleCloseEditLead = () => {
    setShowEditLead(false);
  };

  const onEditSuccess = () => {
    handleCloseEditLead();
  };

  const handleCloseAddOpp = () => {
    setShowAddOpp(false);
  };

  const onCreatedOpportunity = () => {
    handleCloseAddOpp();
    setShowSnackbar(true);
    setSnackbarMsg("You have created a new opportunity");
  };

  const handleSnackbarClose = () => {
    setShowSnackbar(false);
  };

  return (
    <Container>
      <Box className={classes.titleBox}>
        <Box className={classes.backWrapper}>
          <BackButton onClick={history.goBack} />
        </Box>
        <PageTitle title="Lead Details" />
        <Box className={classes.buttonWrapper}>
          <Button
            startIcon={<AutoFixHigh />}
            color="secondary"
            fullWidth={false}
            text="CREATE OPPORTUNITY"
            variant="outlined"
            size="small"
            onClick={handleCreateOpp}
          />
          <Button
            startIcon={<Create />}
            color="secondary"
            fullWidth={false}
            text="EDIT"
            variant="outlined"
            size="small"
            onClick={handleEditLead}
          />
        </Box>
      </Box>
      {fetchLeadSuccess && (
        <GridContainer flex={1} mt={6} mb={6}>
          <Grid item md={5} display="flex" flexDirection="column">
            <LeadContactInformation
              data={currentLead}
              handleRatingPressed={handleQualityChange}
              handleAddressPressed={handleAddressPressed}
              handleEmailPressed={handleEmailPressed}
              handlePhonePressed={handlePhonePressed}
              handleLinkedPressed={handleLinkedPressed}
            />
            <GoogleMapBox
              lat={parseFloat(currentLead.address.latitude)}
              lng={parseFloat(currentLead.address.longitude)}
              containerElement={<Box display="flex" flex={1} />}
              mapElement={<Box flex={1} />}
            />
          </Grid>
          <Grid
            item
            md={7}
            display="flex"
            flexDirection="column"
            alignItems="center"
            flex={1}
          >
            <VerticalList
              data={leadMessages}
              containerStyle={classes.listContainer}
              emptyStateComponent={renderEmpty()}
            />
            <ChatFooter
              message={message}
              setMessage={setMessage}
              onSendMessage={onSendMessage}
              placeholder="Enter your comment"
            />
          </Grid>
        </GridContainer>
      )}
      {showEditLead && (
        <AddEditLead
          open={showEditLead}
          onClose={handleCloseEditLead}
          onSuccess={onEditSuccess}
          lead={currentLead}
        />
      )}
      {showAddOpp && (
        <AddEditOpportunity
          open={showAddOpp}
          onClose={handleCloseAddOpp}
          onSuccess={onCreatedOpportunity}
        />
      )}
      <Snackbar
        open={showSnackbar}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
        message={snackbarMsg}
        className={classes.snackbar}
        action={
          <IconButton
            color="inherit"
            size="small"
            onClick={handleSnackbarClose}
          >
            <Close fontSize="small" />
          </IconButton>
        }
      />
    </Container>
  );
};
export default LeadDetails;
