import React from "react";
import Channels from "../Channels/Channels";
import ChatPanel from "../ChatPanel/ChatPanel";
import Settings from "./Settings/ChatOpportunitySettings";
import { CHAT } from "utils/constants";

const ChatOpportunities = () => {
  return (
    <>
      <Channels groupingLevel={CHAT.ROOM_GROUPING_LEVEL.OPPORTUNITY} />
      <ChatPanel listMessageType={CHAT.LIST_MESSAGE_TYPE.ALL} />
      <Settings />
    </>
  );
};

export default ChatOpportunities;
