import React from "react";
import { Box } from "@mui/material";
import useStyles from "./AuthWrapper.style";
const AuthWrapper = ({ children, ...props }) => {
  const classes = useStyles(props);
  return (
    <Box className={classes.authWrap}>
      <Box className={classes.authCard}>{children}</Box>
    </Box>
  );
};

export default AuthWrapper;
