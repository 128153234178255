import React, { forwardRef } from "react";
import Input from "react-phone-number-input/input";
import { Input as MyInput } from "components";
import PropTypes from "prop-types";

const PhoneInput = forwardRef(
  ({ international, defaultCountry, ...props }, ref) => (
    <Input
      {...props}
      ref={ref}
      international={international}
      defaultCountry={defaultCountry}
      value={props.values[props.inputId]}
      onChange={(e) =>
        props.handleChange(props.inputId)({ target: { value: e } })
      }
      inputComponent={MyInput.Text}
    />
  )
);

PhoneInput.propTypes = {
  inputId: PropTypes.string.isRequired,
  label: PropTypes.string,
  values: PropTypes.object.isRequired,
  errors: PropTypes.object,
  handleChange: PropTypes.func.isRequired,
  handleBlur: PropTypes.func,
  secureTextEntry: PropTypes.bool,
  variant: PropTypes.oneOf(["outlined", "standard", "filled"]),
  color: PropTypes.oneOf(["primary", "secondary", "error"]),
  disabled: PropTypes.bool,
  international: PropTypes.bool,
  defaultCountry: PropTypes.string,
};

PhoneInput.defaultProps = {
  label: "",
  errors: {},
  handleBlur: () => {},
  secureTextEntry: false,
  variant: "outlined",
  color: "primary",
  disabled: false,
  international: false,
  defaultCountry: "US",
};

PhoneInput.displayName = "PhoneInput";

export default PhoneInput;
