import React from "react";
import { Box, Card, CardContent, Divider } from "@mui/material";
import useStyles from "./ListItemCard.style";
import clsx from "clsx";
import { Avatar, Text, ListItemCardBadge } from "components";
import PropTypes from "prop-types";

const ListItemCard = ({
  active,
  avatarText,
  avatarIcon,
  title,
  subtitle,
  extraSubtitle,
  isNew = false,
  badgeCount = 0,
  caption,
  titleIcon,
  containerStyle,
  titleStyle,
  subtitleStyle,
  onPress,
  avatarId,
}) => {
  const classes = useStyles();
  return (
    <Card
      elevation={0}
      onClick={onPress}
      className={clsx(classes.container, containerStyle)}
    >
      {active && (
        <Divider
          orientation="vertical"
          className={classes.activeIndicator}
          flexItem
        />
      )}
      <CardContent className={classes.cardContent}>
        <Box display="flex" flexDirection="row">
          <Box display="flex" alignItems="center">
            {avatarText ? (
              <Avatar
                size={48}
                label={avatarText}
                userId={avatarId}
                avatarStyle={classes.avatar}
                labelStyle={classes.avatarLabel}
              />
            ) : null}
            {avatarIcon ? avatarIcon : null}
          </Box>
          <Box flex={1}>
            <Box flex={1} display="flex" flexDirection="row">
              {titleIcon}
              <Text
                className={clsx(classes.title, classes.flexGrow, titleStyle)}
              >
                {title}
              </Text>
              <Text className={classes.caption}>{caption}</Text>
            </Box>
            <Box flex={1} display="flex" flexDirection="row">
              <Box className={classes.flexGrow}>
                <Text className={clsx(classes.subtitle, subtitleStyle)}>
                  {subtitle}
                </Text>
                {extraSubtitle ? (
                  <Text className={clsx(classes.subtitle, subtitleStyle)}>
                    {extraSubtitle}
                  </Text>
                ) : null}
              </Box>
              {(isNew || badgeCount) > 0 ? (
                <ListItemCardBadge title={isNew ? "New" : badgeCount} />
              ) : null}
            </Box>
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
};

ListItemCard.propTypes = {
  active: PropTypes.bool,
  avatarText: PropTypes.string,
  avatarIcon: PropTypes.element,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  extraSubtitle: PropTypes.string,
  isNew: PropTypes.bool,
  badgeCount: PropTypes.number,
  caption: PropTypes.string,
  titleIcon: PropTypes.element,
  containerStyle: PropTypes.string,
  titleStyle: PropTypes.string,
  subtitleStyle: PropTypes.string,
  onPress: PropTypes.func,
  avatarId: PropTypes.number,
};

ListItemCard.defaultProps = {
  active: false,
  isNew: false,
  badgeCount: 0,
  title: "",
};

export default ListItemCard;
