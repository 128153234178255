import React, { useEffect, useState } from "react";
import { Dialog, DialogContent, DialogTitle, Alert, Box } from "@mui/material";
import useStyles from "components/Modals/index.style";
import { Form, Formik } from "formik";
import * as yup from "yup";
import { Input, Button } from "components";
import { geocodeByPlaceId } from "react-google-places-autocomplete";
import { abbrState, getAddressObject } from "./Helpers/GeocodeHelper";
import {
  useCreateOpportunityMutation,
  useUpdateOpportunityMutation,
} from "services/api";

const validationSchema = yup.object().shape({
  name: yup
    .string()
    .trim()
    .min(5, "Must be at least 5 characters")
    .max(500, "Must be less than 50 characters")
    .required("Name is required"),
  notes: yup
    .string()
    .trim()
    .min(5, "must be at least 5 characters")
    .max(500, "Must be less than 300 characters")
    .required("Description is required"),
  address: yup.object().required("Location is required"),
});

const AddEditOpportunity = ({ open, onClose, onSuccess, opportunity }) => {
  const dialogType = opportunity ? "EDIT" : "ADD";
  const dialogTitle =
    dialogType === "ADD" ? "Create opportunity" : "Edit opportunity";
  const submitBtnTitle =
    dialogType === "ADD" ? "CREATE OPPORTUNITY" : "SAVE OPPORTUNITY";
  let initialValues = null;
  if (dialogType === "ADD") {
    initialValues = {
      name: "",
      notes: "",
      address: "",
    };
  } else if (dialogType === "EDIT") {
    const { address } = opportunity;
    initialValues = {
      name: opportunity.name,
      notes: opportunity.notes,
      address: {
        label: `${address.streetOne}, ${address.streetTwo}, ${address.city}, ${address.stateAbbrv} ${address.postal}, USA`,
        value: { place_id: address.placeId },
      },
    };
  }
  const classes = useStyles();
  const [error, setError] = useState("");
  const [
    createOpportunity,
    { isSuccess: createOppSuccess, error: createOppError },
  ] = useCreateOpportunityMutation();
  const [
    updateOpportunity,
    { isSuccess: updateOppSuccess, error: updateOppError },
  ] = useUpdateOpportunityMutation();

  useEffect(() => {
    if (createOppSuccess || updateOppSuccess) {
      onSuccess();
    }
  }, [createOppSuccess, updateOppSuccess]);

  useEffect(() => {
    if (createOppError) {
      setError(createOppError.data?.message);
    } else if (updateOppError) {
      setError(updateOppError.data?.message);
    }
  }, [createOppError, updateOppError]);

  const handleSubmit = ({ name, notes, address }) => {
    geocodeByPlaceId(address.value.place_id).then((results) => {
      const addr = getAddressObject(results[0].address_components);
      const lat = results[0].geometry.location.lat();
      const lng = results[0].geometry.location.lng();
      const oppDetails = {
        name: name.trim(),
        notes: notes.trim(),
        address: {
          street_one: addr.home,
          street_two: addr.street,
          postal: addr.postal_code,
          longitude: `${lng}`,
          latitude: `${lat}`,
          city: addr.city,
          state_abbrv: abbrState(addr.region, "abbr"),
          place_id: address.value.place_id,
        },
      };
      if (dialogType === "ADD") {
        createOpportunity(oppDetails);
      } else if (dialogType === "EDIT") {
        updateOpportunity({
          opportunity_id: opportunity.opportunityId,
          ...oppDetails,
        });
      }
    });
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle className={classes.dialogTitle}>{dialogTitle}</DialogTitle>
      <DialogContent dividers>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({
            values,
            errors,
            touched,
            isValid,
            dirty,
            handleChange,
            handleBlur,
            setFieldValue,
            setFieldTouched,
          }) => (
            <Form className={classes.dialogWrapper}>
              <Input.Text
                label="Name"
                inputId="name"
                values={values}
                errors={errors}
                touched={touched}
                handleChange={handleChange}
              />
              <Input.TextArea
                label="Description"
                inputId="notes"
                handleChange={handleChange}
                values={values}
                errors={errors}
                touched={touched}
              />
              <Input.AddressSearch
                label="Location"
                inputId="address"
                placeholder="Enter Location"
                values={values}
                errors={errors}
                touched={touched}
                handleChange={(val) => setFieldValue("address", val)}
                handleBlur={handleBlur}
                onFocus={() => setFieldTouched("address")}
              />
              <Box
                display="flex"
                alignItems="flex-end"
                justifyContent="flex-end"
                mt={4}
              >
                <Button
                  text="Back"
                  fullWidth={false}
                  variant="text"
                  color="secondary"
                  onClick={onClose}
                />
                <Button
                  text={submitBtnTitle}
                  color="success"
                  fullWidth={false}
                  type="submit"
                  disabled={!isValid || !dirty}
                />
              </Box>
            </Form>
          )}
        </Formik>
      </DialogContent>
      {!!error && <Alert severity="error">{error}</Alert>}
    </Dialog>
  );
};

export default AddEditOpportunity;
