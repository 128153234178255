import { bugsnagNotify } from "services/bugsnag";

const productEndpoints = (builder) => ({
  getProductList: builder.query({
    query: (subCategoryId) => ({
      url: "/product/list",
      method: "POST",
      data: { subcategory_id: subCategoryId },
    }),
    async onQueryStarted(_, { queryFulfilled }) {
      try {
        await queryFulfilled;
      } catch (e) {
        bugsnagNotify(
          new Error(e?.error?.data?.message ?? "Product list failed."),
          e
        );
      }
    },
  }),
  getProductSubCategoryList: builder.query({
    query: () => ({
      url: "/product/category/list",
      method: "POST",
    }),
    async onQueryStarted(_, { queryFulfilled }) {
      try {
        await queryFulfilled;
      } catch (e) {
        bugsnagNotify(
          new Error(
            e?.error?.data?.message ?? "Get product subcategory list failed."
          ),
          e
        );
      }
    },
  }),
});

export default productEndpoints;
