import React, { useMemo } from "react";
import { Box } from "@mui/material";
import useStyles from "../Opportunities.styles";
import {
  BackButton,
  Button,
  Container,
  PageLoader,
  PageTitle,
  TabBar,
  Empty,
} from "components";
import { useHistory, useParams } from "react-router";
import { getRedirectUrl, ROUTES } from "utils/constants";
import { useGetOpportunityQuery } from "services/api";
import { Comment, Attachment, Settings } from "@mui/icons-material";
import { getOpportunityTabs } from "../Opportunities.utils";

const OpportunityMessage = () => {
  const classes = useStyles();
  const { opportunity_id: opportunityId } = useParams();
  const { data: opportunityData } = useGetOpportunityQuery({
    opportunityId,
    detailLevel: "DATA_FULL",
  });
  const history = useHistory();

  const tabs = useMemo(
    () => getOpportunityTabs(opportunityId),
    [opportunityId, getOpportunityTabs]
  );

  const handleBack = () => {
    history.push(ROUTES.OPPORTUNITY_LIST);
  };

  const handleNewRoom = () => {};

  const handleAttachFile = () => {};

  const handleSettings = () => {
    history.push(getRedirectUrl(ROUTES.OPPORTUNITY_SETTINGS, opportunityId));
  };

  const startNewConversation = () => {};

  const renderEmpty = () => (
    <Empty
      image="/images/opportunity/opp_messages_empty.png"
      title={"Looks like it is a bit\nquiet in here..."}
      actions={[
        {
          heading:
            "Start a conversation with other members\nand expand your network",
          text: "START A NEW CONVERSATION",
          onClick: startNewConversation,
          fullWidth: false,
          color: "primary",
        },
      ]}
    />
  );

  if (!opportunityData) {
    return <PageLoader />;
  }

  return (
    <Container>
      <Box className={classes.titleBox}>
        <Box className={classes.backButtonWrapper}>
          <BackButton onClick={handleBack} />
        </Box>
        <PageTitle title={opportunityData.name} />
        <Box className={classes.buttonWrapper}>
          <Button
            startIcon={<Comment />}
            color="secondary"
            fullWidth={false}
            text="NEW ROOM"
            variant="outlined"
            size="small"
            onClick={handleNewRoom}
            containerStyle={classes.mr2}
          />
          <Button
            startIcon={<Attachment />}
            color="secondary"
            fullWidth={false}
            text="ATTACH FILE"
            variant="outlined"
            size="small"
            onClick={handleAttachFile}
            containerStyle={classes.mr2}
          />
          <Button
            startIcon={<Settings />}
            color="secondary"
            fullWidth={false}
            text="Settings"
            variant="outlined"
            size="small"
            onClick={handleSettings}
          />
        </Box>
      </Box>
      <Box display="flex" flex={1} flexDirection="column" mt={6}>
        <TabBar tabs={tabs} selectedId={1} />
        <Box display="flex" flex={1} mt={6}>
          {renderEmpty()}
        </Box>
      </Box>
    </Container>
  );
};

export default OpportunityMessage;
