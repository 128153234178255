import { bugsnagNotify } from "services/bugsnag";

const crmsEndpoints = (builder) => ({
  checkCrmIntegrationExist: builder.query({
    query: () => ({
      url: "/crm/user/check",
      method: "POST",
    }),
    async onQueryStarted(_, { queryFulfilled }) {
      try {
        await queryFulfilled;
      } catch (e) {
        const message = e?.error?.data?.message ?? "CRM user check failed";
        const status = e?.error?.status;

        // We don't want to report errors if the user can't check for CRMs
        if (status === 404) return;

        bugsnagNotify(new Error(message), e);
      }
    },
  }),
});

export default crmsEndpoints;
