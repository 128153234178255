import React, { useEffect, useMemo, useState } from "react";
import {
  useDeleteOppContactMutation,
  useGetOpportunityQuery,
} from "services/api";
import { useHistory, useParams } from "react-router";
import {
  Container,
  PageTitle,
  Button,
  TabBar,
  PageLoader,
  Empty,
  OpportunityContactList,
  BackButton,
} from "components";
import useStyles from "../Opportunities.styles";
import { Box } from "@mui/material";
import { PersonAdd } from "@mui/icons-material";
import { getOpportunityTabs } from "../Opportunities.utils";
import AddEditContact from "components/Modals/AddEditContact";
import RemoveDialog from "components/Modals/RemoveDialog";
import { getRedirectUrl, ROUTES } from "utils/constants";

const OpportunityContact = () => {
  const { opportunity_id: opportunityId } = useParams();
  const { data: opportunityData } = useGetOpportunityQuery({
    opportunityId,
    detailLevel: "DATA_FULL",
  });
  const [
    deleteOppContact,
    { isLoading: isRemovingContact, isSuccess: deleteContactSuccess },
  ] = useDeleteOppContactMutation();
  const classes = useStyles();
  const [showAddEditContact, setShowAddEditContact] = useState(false);
  const [showRemoveContact, setShowRemoveContact] = useState(false);
  const [currentContact, setCurrentContact] = useState(null);
  const [contacts, setContacts] = useState([]);
  const tabs = useMemo(
    () => getOpportunityTabs(opportunityId),
    [opportunityId, getOpportunityTabs]
  );
  const history = useHistory();

  useEffect(() => {
    if (opportunityData) {
      setContacts(
        opportunityData.contacts.map((contact) => ({
          ...contact,
          fullName: contact.firstName + " " + contact.lastName,
        }))
      );
    }
  }, [opportunityData]);

  useEffect(() => {
    if (deleteContactSuccess) {
      handleCloseRemoveDialog();
    }
  }, [deleteContactSuccess]);

  const handleAddContact = () => {
    setCurrentContact(null);
    setShowAddEditContact(true);
  };

  const handleCloseContactDialog = () => {
    setShowAddEditContact(false);
  };

  const onAddedOrEditedContact = () => {
    handleCloseContactDialog();
  };

  const renderEmpty = () => (
    <Empty
      image="/images/group/group_users_empty.png"
      title={"Looks like there is\nno one in here..."}
      actions={[
        {
          heading: "Start by adding a contact to your opportunity",
          text: "ADD CONTACT",
          onClick: handleAddContact,
          fullWidth: false,
          color: "primary",
        },
      ]}
    />
  );

  const handleEditContact = (contact) => {
    setCurrentContact(contact);
    setShowAddEditContact(true);
  };

  const handleRemoveContact = (contact) => {
    setCurrentContact(contact);
    setShowRemoveContact(true);
  };

  const handleClickContact = (contact) => {
    history.push(
      getRedirectUrl(
        getRedirectUrl(ROUTES.OPPORTUNITY_CONTACT_DETAILS, opportunityId),
        contact.contactId
      )
    );
  };

  const handleCloseRemoveDialog = () => {
    setShowRemoveContact(false);
  };

  const onConfirmedRemove = (contact) => {
    deleteOppContact({
      contactId: contact.contactId,
      oppId: contact.opportunityId,
    });
  };

  const handleBack = () => {
    history.push(ROUTES.OPPORTUNITY_LIST);
  };

  if (!opportunityData) {
    return <PageLoader />;
  }

  return (
    <Container>
      <Box className={classes.titleBox}>
        <Box className={classes.backButtonWrapper}>
          <BackButton onClick={handleBack} />
        </Box>
        <PageTitle title={opportunityData.name} />
        <Box className={classes.buttonWrapper}>
          <Button
            startIcon={<PersonAdd />}
            color="secondary"
            fullWidth={false}
            text="CREATE CONTACT"
            variant="outlined"
            size="small"
            onClick={handleAddContact}
            containerStyle={classes.mr2}
          />
        </Box>
      </Box>
      <Box display="flex" flex={1} flexDirection="column" mt={6}>
        <TabBar tabs={tabs} selectedId={2} />
        {opportunityData.contacts?.length === 0 && renderEmpty()}
        <Box
          display={opportunityData.contacts?.length > 0 ? "flex" : "none"}
          className={classes.tableWrapper}
        >
          <OpportunityContactList
            rowData={contacts}
            onEditContact={handleEditContact}
            onRemoveContact={handleRemoveContact}
            onClickRow={handleClickContact}
          />
        </Box>
      </Box>
      {showAddEditContact && (
        <AddEditContact
          open={showAddEditContact}
          onClose={handleCloseContactDialog}
          onSuccess={onAddedOrEditedContact}
          contact={currentContact}
        />
      )}
      {showRemoveContact && (
        <RemoveDialog
          open={showRemoveContact}
          onClose={handleCloseRemoveDialog}
          onRemove={onConfirmedRemove}
          title="Remove contact"
          body={"remove contact " + currentContact.fullName + "?"}
          data={currentContact}
          isLoading={isRemovingContact}
        />
      )}
    </Container>
  );
};

export default OpportunityContact;
