import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  container: {
    flexDirection: "row",
    display: "flex",
    paddingTop: 8,
    paddingBottom: 8,
    alignItems: "center",
  },
  title: {
    display: "flex",
    fontSize: 14,
  },
  divider: {
    display: "flex",
    flex: 1,
    height: 1,
    marginLeft: 10,
  },
}));

export default useStyles;
