import React from "react";
import { Formik, Form } from "formik";
import * as yup from "yup";
import { makeStyles } from "@mui/styles";
import { FormField, FormSubmitButton } from "components";

const useStyles = makeStyles(() => ({
  emailContainer: {
    marginTop: "40px",
  },
  sendBtn: {
    display: "flex",
    marginLeft: "auto",
    marginTop: "40px",
  },
}));

const initialValues = {
  email: "",
};

const validationSchema = yup.object().shape({});

const ForgotPasswordForm = ({ disabled, ...rest }) => {
  const classes = useStyles();
  return (
    <Formik
      {...rest}
      initialValues={initialValues}
    >
      <Form>
      <br /><br />
      <a href="/images/test-sf-flow-url.png" target="_blank" rel="noreferrer">
        Find URL here
      </a>
        <FormField
          disabled={disabled}
          name="url"
          label="SF URL"
          placeholder="SF URL (found in the profile menu)"
          className={classes.emailContainer}
        />
        <FormField
          disabled={disabled}
          name="username"
          label="SF Email Address"
          placeholder="Enter SF email address"
          className={classes.emailContainer}
        />
        <FormField
          disabled={disabled}
          name="password"
          label="SF Password"
          placeholder="Enter SF password"
          className={classes.emailContainer}
        />
        <a href="/images/test-sf-flow-token.png" target="_blank" rel="noreferrer">
          Find token here
        </a>
        <FormField
          disabled={disabled}
          name="securityToken"
          label="Security Token"
          placeholder="Enter security token"
          className={classes.emailContainer}
        />
        <FormSubmitButton
          disabled={disabled}
          color="primary"
          variant="contained"
          className={classes.sendBtn}
        >
          CHECK CRM
        </FormSubmitButton>
      </Form>
    </Formik>
  );
};

export default ForgotPasswordForm;
