import { bugsnagNotify } from "services/bugsnag";

const opportunityEndpoints = (builder) => ({
  getOpportunityList: builder.query({
    query: ({ sort, detailLevel, offset, limit }) => ({
      url: "/opportunity/list",
      method: "POST",
      data: {
        sort,
        data: detailLevel,
        start_position: offset,
        get_next: limit,
      },
    }),
    async onQueryStarted(_, { queryFulfilled }) {
      try {
        await queryFulfilled;
      } catch (e) {
        bugsnagNotify(
          new Error(
            e?.error?.data?.message ?? "Fetch opportunity list failed."
          ),
          e
        );
      }
    },
    providesTags: [{ type: "Opportunity", id: "LIST" }],
  }),
  getOpportunity: builder.query({
    query: ({ opportunityId, detailLevel }) => ({
      url: "/opportunity/get",
      method: "POST",
      data: { opportunity_id: opportunityId, data: detailLevel },
    }),
    async onQueryStarted(_, { queryFulfilled }) {
      try {
        await queryFulfilled;
      } catch (e) {
        bugsnagNotify(
          new Error(
            e?.error?.data?.message ?? "Fetch opportunity detail failed."
          ),
          e
        );
      }
    },
    providesTags: (result, error, { opportunityId }) => [
      { type: "Opportunity", id: opportunityId },
    ],
  }),
  createOpportunity: builder.mutation({
    query: (data) => ({
      url: "/opportunity/set",
      method: "POST",
      data,
    }),
    invalidatesTags: [{ type: "Opportunity", id: "LIST" }],
  }),
  updateOpportunity: builder.mutation({
    query: (data) => ({
      url: "/opportunity/update",
      method: "POST",
      data,
    }),
    invalidatesTags: (result, error, arg) => [
      { type: "Opportunity", id: arg.opportunity_id },
    ],
  }),
  deleteOpportunity: builder.mutation({
    query: (oppId) => ({
      url: "/opportunity/delete",
      method: "POST",
      data: { opportunity_id: oppId },
    }),
    invalidatesTags: [{ type: "Opportunity", id: "LIST" }],
  }),
  createOppContact: builder.mutation({
    query: (data) => ({
      url: "/opportunity/contact/set",
      method: "POST",
      data,
    }),
    async onQueryStarted(_, { queryFulfilled }) {
      try {
        await queryFulfilled;
      } catch (e) {
        bugsnagNotify(
          new Error(
            e?.error?.data?.message ?? "Create opportunity contact failed."
          ),
          e
        );
      }
    },
    invalidatesTags: (result, error, arg) => [
      { type: "Opportunity", id: arg.opportunity_id },
    ],
  }),
  getOppContact: builder.query({
    query: (contactId) => ({
      url: "/opportunity/contact/get",
      method: "POST",
      data: { contact_id: contactId },
    }),
    async onQueryStarted(_, { queryFulfilled }) {
      try {
        await queryFulfilled;
      } catch (e) {
        bugsnagNotify(
          new Error(
            e?.error?.data?.message ?? "Get opportunity contact failed."
          ),
          e
        );
      }
    },
    providesTags: (result, error, arg) => [{ type: "Contact", id: arg }],
  }),
  updateOppContact: builder.mutation({
    query: (data) => ({
      url: "/opportunity/contact/update",
      method: "POST",
      data,
    }),
    async onQueryStarted(_, { queryFulfilled }) {
      try {
        await queryFulfilled;
      } catch (e) {
        bugsnagNotify(
          new Error(
            e?.error?.data?.message ?? "Update opportunity contact failed."
          ),
          e
        );
      }
    },
    invalidatesTags: (result, error, arg) => [
      { type: "Opportunity", id: arg.opportunity_id },
      { type: "Contact", id: arg.contact_id },
    ],
  }),
  deleteOppContact: builder.mutation({
    query: ({ contactId, oppId }) => ({
      url: "/opportunity/contact/delete",
      method: "POST",
      data: { contact_id: contactId, opportunity_id: oppId },
    }),
    async onQueryStarted(_, { queryFulfilled }) {
      try {
        await queryFulfilled;
      } catch (e) {
        bugsnagNotify(
          new Error(
            e?.error?.data?.message ?? "Delete opportunity contact failed."
          ),
          e
        );
      }
    },
    invalidatesTags: (result, error, arg) => [
      { type: "Opportunity", id: arg.oppId },
    ],
  }),
  inviteOpportunityUser: builder.mutation({
    query: ({ opportunityId, email }) => ({
      url: "/opportunity/user/invite",
      method: "POST",
      data: { opportunity_id: opportunityId, email },
    }),
    async onQueryStarted(_, { queryFulfilled }) {
      try {
        await queryFulfilled;
      } catch (e) {
        bugsnagNotify(
          new Error(
            e?.error?.data?.message ?? "Invite opportunity user failed."
          ),
          e
        );
      }
    },
  }),
  addInvitedOpportunityUser: builder.mutation({
    query: ({ opportunityId, inviteCode }) => ({
      url: "/opportunity/user/invite_accept",
      method: "POST",
      data: { opportunity_id: opportunityId, invite_code: inviteCode },
    }),
    async onQueryStarted(_, { queryFulfilled }) {
      try {
        await queryFulfilled;
        localStorage.removeItem("inviteId");
        localStorage.removeItem("inviteCode");
        localStorage.removeItem("inviteAction");
      } catch (e) {
        localStorage.removeItem("inviteId");
        localStorage.removeItem("inviteCode");
        localStorage.removeItem("inviteAction");
        bugsnagNotify(
          new Error(
            e?.error?.data?.message ?? "Add invited opportunity user failed."
          ),
          e
        );
      }
    },
  }),
});

export default opportunityEndpoints;
