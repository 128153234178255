import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  resetBtn: {
    display: "flex",
    marginLeft: "auto",
    marginTop: "16px",
    [theme.breakpoints.down("xs")]: {
      marginTop: "0px",
    },
  },
  form: {
    marginTop: 16,
  },
}));

export default useStyles;
