import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import { useDispatch } from "react-redux";
import { Box, Button, DialogContent } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { showPaymentDialog } from "redux/slices/Modal";
import { Redirect } from "react-router";
import { ROUTES } from "../../utils/constants";
import { ProductSelect, TargetSelect, AppTextInput } from "components";
import {
  api,
  useCreatePrivateGroupMutation,
  useGetBillingQuery,
} from "services/api";

const useStyles = makeStyles((theme) => ({
  dialogRoot: {
    position: "relative",
  },
  dialogTitleRoot: {
    "& .MuiTypography-h6": {
      fontSize: 24,
      color: theme.palette.common.dark,
      justifyContent: "center",
      fontWeight: "bold",
      display: "flex",
    },
  },
  dialogWrapper: {
    padding: "20px",
    width: "450px",
  },
  stepTitle: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  stepCircle: {
    background: "#CCC",
    width: "35px",
    height: "35px",
    color: "white",
    fontSize: "12pt",
    fontWeight: "bold",
    lineHeight: "35px",
    textAlign: "center",
    borderRadius: "50%",
    marginRight: "10px",
    "&.active": {
      background: "#023E6E",
    },
  },
  stepBox: {
    borderLeft: "3px solid #ccc",
    padding: "5px 5px 5px 30px",
    marginLeft: "15px",
    "&:last-child": {
      border: "none",
    },
  },
  buttonBox: {
    marginBottom: "10px",
  },
  stepOneText: {
    marginBottom: "10px",
  },
  stepInput: {
    marginBottom: "10px",
  },
  errTxt: {
    margin: "0 0 10px",
    color: "red",
  },
}));

const CreateGroupDialog = ({ open, handleDialogClose }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [step, setStep] = useState(1);
  const [groupName, setGroupName] = useState("");
  const [groupCode, setGroupCode] = useState("");
  const [target, setTarget] = useState("");
  const [product, setProduct] = useState("");

  const [stepTwoErr, setStepTwoErr] = useState("");
  const [stepThreeErr, setStepThreeErr] = useState("");
  const [stepFiveErr, setStepFiveErr] = useState("");
  const [groupCreated, setGroupCreated] = useState(false);
  const { data: billingInfo } = useGetBillingQuery();
  const [triggerVerifyGroupExist, { data: verifyGroupData }] =
    api.endpoints.verifyGroupExist.useLazyQuery();
  const [
    createPrivateGroup,
    {
      isLoading: isCreatingPrivateGroup,
      error: createPrivateGroupError,
      isSuccess: isCreatedPrivateGroup,
    },
  ] = useCreatePrivateGroupMutation();

  useEffect(() => {
    if (verifyGroupData?.exists === true) {
      setStepTwoErr("The group name you chose is already taken");
    } else if (verifyGroupData?.exists === false && step === 2) {
      setStep(3);
    }
  }, [verifyGroupData, step]);

  useEffect(() => {
    if (createPrivateGroupError == null) {
      return;
    }

    if (step === 2) {
      setStepTwoErr(createPrivateGroupError.data.message);
    } else if (step === 3) {
      setStepThreeErr(createPrivateGroupError.data.message);
    } else if (step === 5) {
      setStepFiveErr(createPrivateGroupError.data.message);
    }
  }, [createPrivateGroupError, step]);

  useEffect(() => {
    if (!isCreatedPrivateGroup) {
      return;
    }
    setGroupCreated(true);
  }, [isCreatedPrivateGroup]);

  const confirmGroupName = () => {
    setStepTwoErr("");
    if (groupName === "") {
      setStepTwoErr("Group name cannot be blank");
      return;
    }
    triggerVerifyGroupExist(groupName);
  };

  const confirmGroupCode = () => {
    setStepThreeErr("");
    if (groupCode.length < 6) {
      setStepThreeErr("Group password must be at least 6 characters");
      return;
    }
    setStep(4);
  };

  const createGroup = () => {
    if (!billingInfo.hasPaymentMethod) {
      dispatch(
        showPaymentDialog({
          action: "groupCreate",
          data: {
            name: groupName,
            private_code: groupCode,
            target_id: target,
            product_id: product,
            group_type: "PRIVATE",
          },
        })
      );
      handleDialogClose();
    } else {
      createPrivateGroup({
        name: groupName,
        privateCode: groupCode,
        targetId: target,
        productId: product,
        groupType: "PRIVATE",
      });
    }
  };

  if (groupCreated) {
    return <Redirect to={ROUTES.GROUP_LEAD_LIST} />;
  }

  return (
    <Dialog
      open={open}
      onClose={handleDialogClose}
      className={classes.dialogRoot}
    >
      <DialogTitle className={classes.dialogTitleRoot}>
        CREATE GROUP
      </DialogTitle>
      <DialogContent dividers>
        <Box className={classes.dialogWrapper}>
          <Box className={classes.stepTitle}>
            <Box
              className={`${classes.stepCircle} ${step === 1 ? "active" : ""}`}
            >
              1
            </Box>
            <h3>Create Group</h3>
          </Box>
          <Box className={classes.stepBox}>
            <Box hidden={step !== 1}>
              <Box className={classes.stepOneText}>
                A group is a place where you can gather around people and
                exchange leads. To create a group you must first choose a name
                and then set a password.
              </Box>
              <Box className={classes.buttonBox}>
                <Button
                  disabled={isCreatingPrivateGroup}
                  onClick={handleDialogClose}
                  variant="outlined"
                >
                  Back
                </Button>
                &nbsp;
                <Button
                  onClick={() => setStep(2)}
                  variant="contained"
                  color="primary"
                >
                  Next
                </Button>
              </Box>
            </Box>
          </Box>

          <Box className={classes.stepTitle}>
            <Box
              className={`${classes.stepCircle} ${step === 2 ? "active" : ""}`}
            >
              2
            </Box>
            <h3>Group Name</h3>
          </Box>
          <Box className={classes.stepBox}>
            <Box hidden={step !== 2}>
              <p>Please choose a name for your group</p>
              <AppTextInput
                className={classes.stepInput}
                fullWidth
                variant="outlined"
                label=""
                disabled={isCreatingPrivateGroup}
                value={groupName}
                onChange={(e) => setGroupName(e.target.value)}
              />
              {stepTwoErr !== "" && (
                <p className={classes.errTxt}>{stepTwoErr}</p>
              )}
              <Box className={classes.buttonBox}>
                <Button
                  disabled={isCreatingPrivateGroup}
                  onClick={() => setStep(1)}
                  variant="outlined"
                >
                  Back
                </Button>
                &nbsp;
                <Button
                  disabled={isCreatingPrivateGroup}
                  onClick={() => confirmGroupName()}
                  variant="contained"
                  color="primary"
                >
                  Next
                </Button>
              </Box>
            </Box>
          </Box>

          <Box className={classes.stepTitle}>
            <Box
              className={`${classes.stepCircle} ${step === 3 ? "active" : ""}`}
            >
              3
            </Box>
            <h3>Group Code</h3>
          </Box>
          <Box className={classes.stepBox}>
            <Box hidden={step !== 3}>
              <p>Please set a password for your group</p>
              <AppTextInput
                className={classes.stepInput}
                fullWidth
                variant="outlined"
                label=""
                value={groupCode}
                disabled={isCreatingPrivateGroup}
                onChange={(e) => setGroupCode(e.target.value)}
              />
              {stepThreeErr !== "" && (
                <p className={classes.errTxt}>{stepThreeErr}</p>
              )}
              <Box className={classes.buttonBox}>
                <Button
                  disabled={isCreatingPrivateGroup}
                  onClick={() => setStep(2)}
                  variant="outlined"
                >
                  Back
                </Button>
                &nbsp;
                <Button
                  disabled={isCreatingPrivateGroup}
                  onClick={() => confirmGroupCode()}
                  variant="contained"
                  color="primary"
                >
                  Next
                </Button>
              </Box>
            </Box>
          </Box>

          <Box className={classes.stepTitle}>
            <Box
              className={`${classes.stepCircle} ${step === 4 ? "active" : ""}`}
            >
              4
            </Box>
            <h3>Target Industry</h3>
          </Box>
          <Box className={classes.stepBox}>
            <Box hidden={step !== 4}>
              <p>What industry is your group targeting?</p>
              <TargetSelect value={target} setValue={setTarget} />
              <Box className={classes.buttonBox}>
                <Button
                  disabled={isCreatingPrivateGroup}
                  onClick={() => setStep(3)}
                  variant="outlined"
                >
                  Back
                </Button>
                &nbsp;
                <Button
                  disabled={isCreatingPrivateGroup || target === ""}
                  onClick={() => setStep(5)}
                  variant="contained"
                  color="primary"
                >
                  Next
                </Button>
              </Box>
            </Box>
          </Box>

          <Box className={classes.stepTitle}>
            <Box
              className={`${classes.stepCircle} ${step === 5 ? "active" : ""}`}
            >
              5
            </Box>
            <h3>Your product</h3>
          </Box>
          <Box className={classes.stepBox}>
            <Box hidden={step !== 5}>
              <p>What product do you sell?</p>
              <ProductSelect value={product} setValue={setProduct} />
              {stepFiveErr !== "" && (
                <p className={classes.errTxt}>{stepFiveErr}</p>
              )}
              <Box className={classes.buttonBox}>
                <Button
                  disabled={isCreatingPrivateGroup}
                  onClick={() => setStep(4)}
                  variant="outlined"
                >
                  Back
                </Button>
                &nbsp;
                <Button
                  disabled={isCreatingPrivateGroup || product === ""}
                  onClick={() => createGroup()}
                  variant="contained"
                  color="primary"
                >
                  Create
                </Button>
              </Box>
            </Box>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default CreateGroupDialog;

CreateGroupDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleDialogClose: PropTypes.func.isRequired,
};
