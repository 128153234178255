import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: theme.palette.common.lightBlue,
    color: "white",
    borderRadius: 8,
    "&:hover": {
      backgroundColor: theme.palette.common.blue,
    },
  },
}));

export default useStyles;
