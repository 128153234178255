import React from "react";
import { Box } from "@mui/material";
import useStyles from "./Empty.style";
import { Text, Button, Image } from "components";
import clsx from "clsx";

const Empty = ({ image, title, subtitle, actions }) => {
  const classes = useStyles();
  const renderActions = () => {
    if (!actions?.length || !Array.isArray(actions)) return null;

    return actions.map(({ heading, ...buttonProps }, index) => (
      <Box key={`action-${index}`} className={classes.actionContainer}>
        <Text className={clsx(classes.actionHeading, classes.subtitle)}>
          {heading}
        </Text>
        <Button {...buttonProps} />
      </Box>
    ));
  };

  return (
    <Box className={classes.container}>
      <Box>
        <Text className={classes.title}>{title}</Text>
        {subtitle && !actions?.length && (
          <Text className={classes.subtitle}>{subtitle}</Text>
        )}
        {renderActions()}
      </Box>
      {image && <Image src={image} alt="..." className={classes.image} />}
    </Box>
  );
};

export default Empty;
