import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import { Avatar as MuiAvatar } from "@mui/material";
import { buildImageUrl } from "./Avatar.utils";
import axios from "axios";

const Avatar = React.forwardRef(
  ({ userId, label, size = 44, avatarStyle, labelStyle }, ref) => {
    const imgURL = buildImageUrl(userId);
    const [err, setErr] = useState(false);

    useEffect(() => {
      const getUrl = async () => {
        if (!userId) {
          setErr(true);
          return;
        }
        try {
          await axios.get(imgURL);
          setErr(false);
        } catch (err) {
          // if (err?.response?.status !== 404) bugsnagNotify(err);
          setErr(true);
        }
      };
      getUrl();
    }, [imgURL, userId]);

    if (!err) {
      return (
        <MuiAvatar
          ref={ref}
          alt="..."
          src={imgURL}
          className={avatarStyle}
          sx={{ width: size, height: size }}
        />
      );
    }

    return (
      <MuiAvatar
        ref={ref}
        className={labelStyle}
        sx={{ width: size, height: size }}
      >
        {label}
      </MuiAvatar>
    );
  }
);

Avatar.propTypes = {
  userId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  label: PropTypes.string,
  size: PropTypes.number,
  avatarStyle: PropTypes.string,
  labelStyle: PropTypes.string,
};

Avatar.defaultProps = {
  size: 44,
};

Avatar.displayName = "Avatar";

export default Avatar;
