import React from "react";
import { Box, Divider, Typography, CircularProgress } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { ReactComponent as PaymentLogo } from "assets/icons/payment_logo.svg";
import { useSelector } from "react-redux";
import {
  useGetBusinessUsersQuery,
  useGetBusinessUserQueryState,
} from "services/business";
import moment from "moment";
// import { Link } from "react-router-dom";
// import { ROUTES } from "utils/constants";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    backgroundColor: "#1975D812",
    padding: 16,
    minHeight: 450,
    "& div:nth-of-type(2)": {
      "& hr": {
        width: "100%",
        marginTop: 32,
      },
      "& h1": {
        fontWeight: 400,
      },
      "& h1:nth-of-type(2)": {
        marginTop: 32,
        [theme.breakpoints.down("xs")]: {
          marginTop: 8,
        },
      },
      [theme.breakpoints.down("xs")]: {
        "& > hr": {
          marginTop: 8,
          marginBottom: 8,
        },
      },
    },
    "& div:nth-of-type(3) > div": {
      "& hr": {
        width: "1px",
        marginTop: 0,
        marginBottom: 0,
        marginLeft: 16,
        marginRight: 16,
      },
      "& a:nth-of-type(1)": {
        marginRight: 8,
      },
    },
  },
  logoImg: {
    width: 356,
    height: 67,
    [theme.breakpoints.down("xs")]: {
      width: 178,
      height: 34,
    },
  },
  link: {
    color: theme.palette.text.secondary,
    textDecoration: "underline",
    textDecorationColor: theme.palette.text.secondary,
  },
  flexBox: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
}));

const PaymentDetails = ({ ...rest }) => {
  const classes = useStyles();

  // To make sure show user count and price
  const userId = useSelector((state) => state.user.userId);
  const { data: bmUser } = useGetBusinessUserQueryState(userId);
  const { data: bmUsers } = useGetBusinessUsersQuery(
    bmUser?.business?.businessId
  );

  const getNextBillingDate = () => {
    // Get next billing date by adding 1 month
    return moment().add(1, "months").format("MMMM Do").toUpperCase();
  };

  return (
    <Box {...rest} className={classes.container}>
      <Box className={classes.flexBox} justifyContent="space-around">
        <PaymentLogo className={classes.logoImg} />
        <Box textAlign="left" sx={{ width: "100%" }} pl={6}>
          <Typography color="textPrimary" variant="h2">
            SUBSCRIBE TO ALLIANCE APP
          </Typography>
        </Box>
      </Box>
      <Box className={classes.flexBox} justifyContent="center">
        <Typography color="textSecondary" variant="h1">
          <strong>$49.99</strong> / user per month
        </Typography>
        <Typography color="textSecondary" variant="h1">
          <strong>
            {bmUsers?.totalCount ? (
              bmUsers?.totalCount
            ) : (
              <CircularProgress color="secondary" size={15} />
            )}
          </strong>{" "}
          active users
        </Typography>
        <Divider />
      </Box>
      <Box className={classes.flexBox} justifyContent="space-around">
        <Box textAlign="left" sx={{ width: "100%" }} pl={8}>
          <Typography color="textPrimary" variant="h1">
            {bmUsers?.totalCount ? (
              "$" + (bmUsers?.totalCount * 50 - 0.01)
            ) : (
              <CircularProgress color="primary" size={15} />
            )}{" "}
            per month
          </Typography>
        </Box>
        <Typography color="textPrimary" variant="body2">
          NEXT PAYMENT ON <strong>{getNextBillingDate()}</strong>
        </Typography>
        <Box display="flex" flexDirection="row" justifyContent="space-around">
          <Typography color="textPrimary" variant="body2">
            POWERED BY STRIPE
          </Typography>
          {/* <Divider orientation="vertical" />
          <Box display="flex" flexDirection="row">
            <Link to={ROUTES.TERMS} className={classes.link}>
              TERMS
            </Link>
            <Link to={ROUTES.PRIVACY} className={classes.link}>
              PRIVACY
            </Link>
            </Box> */}
        </Box>
      </Box>
    </Box>
  );
};

export default PaymentDetails;
