import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flex: 1,
    alignItems: "center",
    justifyContent: "space-around",
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
      justifyContent: "center",
    },
  },
  image: {
    maxHeight: 280,
    objectFit: "contain",
  },
  title: {
    fontSize: 32,
    lineHeight: "36px",
    textAlign: "center",
    marginBottom: 48,
    whiteSpace: "pre-line",
    color: theme.palette.common.dark,
    opacity: 0.9,
  },
  subtitle: {
    textAlign: "center",
    fontSize: 14,
    lineHeight: "20px",
    whiteSpace: "pre-line",
    color: theme.palette.common.dark,
    opacity: 0.75,
  },
  actionContainer: {
    textAlign: "center",
    alignItems: "center",
    marginBottom: 16,
  },
  actionHeading: {
    marginBottom: 16,
  },
}));

export default useStyles;
