import React from "react";
import { CHAT } from "utils/constants";
import Channels from "../Channels/Channels";
import ChatPanel from "../ChatPanel/ChatPanel";
import People from "./People/ChatDirectPeople";
import Settings from "./Settings/ChatDirectSettings";

const Direct = () => {
  return (
    <>
      <Channels groupingLevel={CHAT.ROOM_GROUPING_LEVEL.USERS} />
      <ChatPanel />
      <People />
      <Settings />
    </>
  );
};

export default Direct;
