import React, { useEffect, useState } from "react";
import {
  api,
  useGetGlobalActivitiesQuery,
  useGetUserQuery,
} from "services/api";
import useStyles from "./Activities.style";
import { Box, Snackbar, IconButton } from "@mui/material";
import {
  Container,
  PageTitle,
  Button,
  TextWithIcon,
  VerticalList,
} from "components";
import {
  AutoFixHigh,
  Campaign,
  CreateNewFolder,
  Close,
} from "@mui/icons-material";
import { ACTIVITY_TYPES } from "utils/constants";
import {
  getActivityIconByType,
  getActivityTextByType,
  handleGroupActivityTitle,
} from "./Activities.utils";
import { camelCase } from "lodash";
import moment from "moment";
import AddEditGroup from "components/Modals/AddEditGroup";
import AddEditOpportunity from "components/Modals/AddEditOpportunity";

const Activities = () => {
  const classes = useStyles();
  const ALL_ACTIVITIES = ["ALL_TYPES"];
  const GROUP_ACTIVITIES = [
    ACTIVITY_TYPES.CHAT_NEW_GROUP_MESSAGE,
    ACTIVITY_TYPES.CHAT_NEW_LEAD_MESSAGE,
    ACTIVITY_TYPES.CHAT_NEW_USER_MESSAGE,
    ACTIVITY_TYPES.CHAT_NEW_BUSINESS_MESSAGE,
    ACTIVITY_TYPES.GROUP_NEW_INVITE,
    ACTIVITY_TYPES.GROUP_REMOVED,
    ACTIVITY_TYPES.GROUP_CANCELLED,
    ACTIVITY_TYPES.GROUP_DELETED,
    ACTIVITY_TYPES.GROUP_NEW_LEAD,
    ACTIVITY_TYPES.GROUP_NEW_USER,
    ACTIVITY_TYPES.GROUP_NEW_USER_ADDED,
  ];
  const OPPS_ACTIVITIES = [
    ACTIVITY_TYPES.CHAT_NEW_OPPORTUNITY_MESSAGE,
    ACTIVITY_TYPES.OPPORTUNITY_NEW_USER,
    ACTIVITY_TYPES.OPPORTUNITY_NEW_USER_ADDED,
  ];
  const RESPONSE_TYPE = "ALL_RESPONSE_TYPE";

  const { data: globalActivitiesData } = useGetGlobalActivitiesQuery({
    activityTypes: ALL_ACTIVITIES,
    responseType: RESPONSE_TYPE,
  });

  const { data: userInfo } = useGetUserQuery();
  const userId = userInfo?.userId;
  const [triggerGetGroupStats] =
    api.endpoints.getGroupStats.useLazyQuerySubscription();
  const [triggerGetUserListByGroup] =
    api.endpoints.getUserListByGroup.useLazyQuerySubscription();
  const [triggerGetOpportunity] =
    api.endpoints.getOpportunity.useLazyQuerySubscription();
  const [triggerGetUser] = api.endpoints.getUser.useLazyQuerySubscription();

  const [showAddGroup, setShowAddGroup] = useState(false);
  const [showNewOpp, setShowNewOpp] = useState(false);
  const [globalActivities, setGlobalActivities] = useState([]);
  const [groupActivities, setGroupActivities] = useState([]);
  const [oppsActivities, setOppsActivities] = useState([]);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarMsg, setSnackbarMsg] = useState("");

  useEffect(() => {
    if (globalActivitiesData) {
      const activities = globalActivitiesData.globalActivities?.activities;
      if (activities) {
        setGlobalActivities(
          activities.map((activity) => {
            const { timestamp, type, data } = activity;
            const activityIcon = getActivityIconByType(type);
            const [title, subtitle] = getActivityTextByType(type, data);
            const groupTitle = handleGroupActivityTitle(
              type,
              GROUP_ACTIVITIES.includes(type)
            );
            return {
              id: data[camelCase(type)].activityId,
              avatarIcon: activityIcon,
              title: groupTitle || title,
              subtitle: subtitle,
              caption: moment(timestamp).format("HH:mm"),
            };
          })
        );
        // Recent Group Activities
        // Fetch group stats (users count, leads count)
        const groupActivities = activities.filter((activity) =>
          GROUP_ACTIVITIES.includes(activity.type)
        );
        const groupStatsPromises = groupActivities.map((activity) => {
          const { type, data } = activity;
          const groupId = data[camelCase(type)].groupId;
          return getGroupUserLeadCounts(groupId);
        });
        Promise.all(groupStatsPromises).then((groupStats) => {
          setGroupActivities(
            groupActivities.map((activity) => {
              const { type, data } = activity;
              const groupId = data[camelCase(type)].groupId;
              const [title] = getActivityTextByType(type, data);
              const groupStat = groupStats.find((gS) => gS.groupId === groupId);
              return {
                id: data[camelCase(type)].activityId,
                avatarIcon: null,
                title: title,
                subtitle: `${groupStat.leadCount} leads`,
                caption: `${groupStat.userCount} users`,
              };
            })
          );
        });

        // Recent Opps Activities
        const oppsActivities = activities.filter((activity) =>
          OPPS_ACTIVITIES.includes(activity.type)
        );
        const oppStatsPromises = oppsActivities.map((activity) => {
          const { type, data } = activity;
          const opportunityId = data[camelCase(type)].opportunityId;
          return getOpportunityStats(opportunityId);
        });
        Promise.all(oppStatsPromises).then((oppStats) => {
          setOppsActivities(
            oppsActivities.map((activity) => {
              const { data, type } = activity;
              const opportunityId = data[camelCase(type)].opportunityId;
              const [title] = getActivityTextByType(type, data);
              const oppStat = oppStats.find(
                (oCC) => oCC.opportunityId === opportunityId
              );
              return {
                id: data[camelCase(type)].opportunityId,
                avatarIcon: null,
                title,
                subtitle: `Created by ${oppStat.creatorName}`,
                caption: `${oppStat.contactCount} contacts`,
              };
            })
          );
        });
      }
    }
  }, [globalActivitiesData]);

  const getGroupUserLeadCounts = async (groupId) => {
    const usersResp = await triggerGetUserListByGroup({ groupId }).unwrap();
    const { users } = usersResp;
    const userIds = users.map(({ userId }) => userId);
    const statsResp = await triggerGetGroupStats({ groupId, userIds }).unwrap();
    const { groups } = statsResp;
    if (groups?.length > 0) {
      const { usersStats } = groups[0];
      const userCount = usersStats.length;
      const leadCount = usersStats.reduce(
        (prev, cur) => prev + cur.leadsSubmitted,
        0
      );
      return { groupId, userCount, leadCount };
    } else {
      return { groupId, userCount: 0, leadCount: 0 };
    }
  };

  const getOpportunityStats = async (opportunityId) => {
    const oppData = await triggerGetOpportunity({
      opportunityId,
      detailLevel: "DATA_FULL",
    }).unwrap();
    const { createdBy, contacts } = oppData;
    const creatorData = await triggerGetUser(createdBy).unwrap();
    return {
      opportunityId,
      contactCount: contacts?.length ?? 0,
      creatorName: `${creatorData.firstName} ${creatorData.lastName}`,
    };
  };

  const handleNewGroup = () => {
    setShowAddGroup(true);
  };

  const handleNewOpp = () => {
    setShowNewOpp(true);
  };

  const handleCloseAddGroup = () => {
    setShowAddGroup(false);
  };

  const handleCloseAddOpp = () => {
    setShowNewOpp(false);
  };

  const onAddedNewGroup = () => {
    handleCloseAddGroup();
    setSnackbarMsg("You have created a new group");
    setShowSnackbar(true);
  };

  const onAddedNewOpp = () => {
    handleCloseAddOpp();
    setSnackbarMsg("You have created a new opportunity");
    setShowSnackbar(true);
  };

  const handleCloseSnackbar = () => {
    setShowSnackbar(false);
  };

  return (
    <Container>
      <Box className={classes.titleBox}>
        <PageTitle title="Activities" />
        <Box className={classes.buttonWrapper}>
          <Button
            startIcon={<CreateNewFolder />}
            color="secondary"
            fullWidth={false}
            text="NEW GROUP"
            variant="outlined"
            size="small"
            onClick={handleNewGroup}
          />
          <Button
            startIcon={<AutoFixHigh />}
            color="secondary"
            fullWidth={false}
            text="NEW OPPORTUNITY"
            variant="outlined"
            size="small"
            onClick={handleNewOpp}
          />
        </Box>
      </Box>
      <Box className={classes.activitiesWrapper}>
        <Box className={classes.sectionWrapper}>
          <TextWithIcon
            title="ACTIVITIES"
            icon={<Campaign className={classes.sectionIcon} />}
          />
          <VerticalList
            data={globalActivities}
            containerStyle={classes.listContainer}
          />
        </Box>
        <Box className={classes.sectionWrapper}>
          <TextWithIcon
            title="RECENT GROUPS"
            icon={<CreateNewFolder className={classes.sectionIcon} />}
          />
          <VerticalList
            data={groupActivities}
            containerStyle={classes.listContainer}
          />
        </Box>
        <Box className={classes.sectionWrapper}>
          <TextWithIcon
            title="RECENT OPPORTUNITIES"
            icon={<AutoFixHigh className={classes.sectionIcon} />}
          />
          <VerticalList
            data={oppsActivities}
            containerStyle={classes.listContainer}
          />
        </Box>
      </Box>
      {showAddGroup && (
        <AddEditGroup
          userId={userId}
          open={showAddGroup}
          onClose={handleCloseAddGroup}
          onSuccess={onAddedNewGroup}
        />
      )}
      {showNewOpp && (
        <AddEditOpportunity
          open={showNewOpp}
          onClose={handleCloseAddOpp}
          onSuccess={onAddedNewOpp}
        />
      )}
      {showSnackbar && (
        <Snackbar
          open={showSnackbar}
          autoHideDuration={3000}
          onClose={handleCloseSnackbar}
          message={snackbarMsg}
          className={classes.snackbar}
          action={
            <IconButton
              color="inherit"
              size="small"
              onClick={handleCloseSnackbar}
            >
              <Close fontSize="small" />
            </IconButton>
          }
        />
      )}
    </Container>
  );
};

export default Activities;
