import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  titleBox: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  backWrapper: {
    display: "flex",
    position: "absolute",
    left: 24,
  },
  buttonWrapper: {
    display: "flex",
    position: "absolute",
    right: 24,
    "& button:nth-child(1)": {
      marginRight: 8,
    },
    [theme.breakpoints.down("md")]: {
      "& > button > span": {
        // remove start icon of button
        display: "none",
      },
      "& > button > p": {
        fontSize: 12,
      },
    },
  },
  listContainer: {
    width: "100%",
    maxHeight: "calc(100% - 64px)",
  },
  snackbar: {
    position: "absolute",
    left: "30%",
    right: "30%",
    display: "block",
    transform: "translateX(0%) translateY(-150%)",
  },
}));

export default useStyles;
