import { bugsnagNotify } from "services/bugsnag";

const feedbackEndpoints = (builder) => ({
  submitFeedback: builder.mutation({
    query: ({ subject, content }) => ({
      url: "/feedback/submit",
      method: "POST",
      data: { subject, content },
    }),
    async onQueryStarted(_, { queryFulfilled }) {
      try {
        await queryFulfilled;
      } catch (e) {
        bugsnagNotify(
          new Error(e?.error?.data?.message ?? "Submit feedback failed"),
          e
        );
      }
    },
  }),
});

export default feedbackEndpoints;
