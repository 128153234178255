import React, { useEffect, useState } from "react";
import useStyles from "components/Modals/index.style";
import PropTypes from "prop-types";
import { Formik, Form } from "formik";
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Alert as MuiAlert,
  FormControl,
  Select,
  MenuItem,
  IconButton,
  SvgIcon,
} from "@mui/material";
import * as yup from "yup";
import { useParams } from "react-router";
import { Input, Button } from "components";
import {
  useCreateOppContactMutation,
  useUpdateOppContactMutation,
} from "services/api";
import { Cancel, Add } from "@mui/icons-material";

const phoneRegExp =
  /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/;
const oneOfPhoneEmailLinkedIn = "You must have one of phone, email or linkedin";
const validationSchema = yup.object().shape(
  {
    firstName: yup
      .string()
      .max(50, "Must be less than 50 characters")
      .required("First name is required"),
    lastName: yup
      .string()
      .max(50, "Must be less than 50 characters")
      .required("Last name is required"),
    company: yup
      .string()
      .max(50, "Must be less than 50 characters")
      .required("Company is required"),
    title: yup
      .string()
      .max(50, "Must be less than 50 characters")
      .required("Position is required"),

    email: yup
      .string()
      .max(300, "Must be less than 300 characters")
      .when(["phone", "linkedin"], {
        is: (phone, linkedin) => !phone && !linkedin,
        then: yup.string().required(oneOfPhoneEmailLinkedIn),
      })
      .email(),
    phone: yup
      .string()
      .when(["email", "linkedin"], {
        is: (email, linkedin) => !email && !linkedin,
        then: yup.string().required(oneOfPhoneEmailLinkedIn),
      })
      .test("ValidPhone", "Must be a valid phone number", (value) =>
        value && value.length > 0 ? phoneRegExp.test(value) : true
      ),
    linkedin: yup
      .string()
      .max(50)
      .when(["phone", "email"], {
        is: (phone, email) => !phone && !email,
        then: yup.string().required(oneOfPhoneEmailLinkedIn),
      })
      .test(
        "LinkedInProfile",
        "LinkedIn profile should be in 'linkedin.com/in/profile' format",
        (value) =>
          value && value?.length > 0
            ? value?.includes("linkedin.com/in/")
            : true
      ),
    notes: yup.string().max(1000, "Must be less than 1000 characters"),
  },
  [
    ["email", "phone"],
    ["email", "linkedin"],
    ["phone", "linkedin"],
  ]
);

const CONTACT_OPTIONS = [
  {
    value: "email",
    label: "Email",
  },
  {
    value: "phone",
    label: "Phone",
  },
  {
    value: "linkedin",
    label: "Linkedin",
  },
];

const AddEditContact = ({ open, onClose, onSuccess, contact }) => {
  const dialogType = contact ? "EDIT" : "ADD";
  const dialogTitle = dialogType === "ADD" ? "Create contact" : "Edit contact";
  const submitBtnTitle =
    dialogType === "ADD" ? "CREATE CONTACT" : "SAVE CONTACT";
  let initialValues = null;
  if (dialogType === "ADD") {
    initialValues = {
      firstName: "",
      lastName: "",
      title: "",
      company: "",
      phone: "",
      email: "",
      linkedin: "",
      notes: "",
    };
  } else if (dialogType === "EDIT") {
    initialValues = {
      firstName: contact.firstName,
      lastName: contact.lastName,
      title: contact.title,
      company: contact.company,
      phone: contact.phone,
      email: contact.email,
      linkedin: contact.linkedin,
      notes: contact.notes,
    };
  }
  const classes = useStyles();
  const { opportunity_id } = useParams();
  const [
    createOppContact,
    {
      isSuccess: createContactSuccess,
      error: createError,
      isLoading: isCreating,
    },
  ] = useCreateOppContactMutation();
  const [
    updateOppContact,
    {
      isSuccess: updateContactSuccess,
      error: updateError,
      isLoading: isUpdating,
    },
  ] = useUpdateOppContactMutation();
  const [error, setError] = useState("");
  const [contacts, setContacts] = useState(["email"]);

  useEffect(() => {
    if (contact) {
      const contacts = [];
      if (contact.email) {
        contacts.push("email");
      }
      if (contact.phone) {
        contacts.push("phone");
      }
      if (contact.linkedin) {
        contacts.push("linkedin");
      }
      setContacts(contacts);
    }
  }, [contact]);

  useEffect(() => {
    if (createContactSuccess || updateContactSuccess) {
      onSuccess();
    }
  }, [createContactSuccess, updateContactSuccess]);

  useEffect(() => {
    if (createError) {
      setError(createError.data?.message);
    } else if (updateError) {
      setError(updateError.data?.message);
    }
  }, [createError, updateError]);

  const Alert = (props) => (
    <MuiAlert elevation={6} variant="filled" {...props} style={{}} />
  );

  const handleSubmit = ({
    firstName,
    lastName,
    company,
    title,
    phone,
    email,
    linkedin,
    notes,
  }) => {
    const contactDetails = {
      first_name: firstName,
      last_name: lastName,
      email,
      phone,
      company,
      title,
      linkedin,
      notes,
    };
    if (dialogType === "ADD") {
      createOppContact({
        opportunity_id,
        contact_id: 0,
        ...contactDetails,
      });
    } else if (dialogType === "EDIT") {
      updateOppContact({
        contact_id: contact.contactId,
        opportunity_id: contact.opportunityId,
        ...contactDetails,
      });
    }
  };

  const onAddContact = () => {
    setContacts((prev) => {
      const newOption = CONTACT_OPTIONS.find(
        (cO) => !contacts.includes(cO.value)
      );
      prev.push(newOption.value);
      return [...prev];
    });
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle className={classes.dialogTitle}>{dialogTitle}</DialogTitle>
      <DialogContent dividers>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {(props) => (
            <Form className={classes.dialogWrapperNewLead}>
              <Box>
                <Grid container item spacing={3}>
                  <Grid item xs={12} md={6}>
                    <Input.Text
                      label="First Name (required)"
                      inputId="firstName"
                      values={props.values}
                      errors={props.errors}
                      touched={props.touched}
                      disabled={dialogType === "EDIT"}
                      handleChange={props.handleChange}
                    />
                    <Input.Text
                      label="Last Name (required)"
                      inputId="lastName"
                      values={props.values}
                      errors={props.errors}
                      disabled={dialogType === "EDIT"}
                      touched={props.touched}
                      handleChange={props.handleChange}
                    />
                    <Input.Text
                      label="Company"
                      inputId="company"
                      values={props.values}
                      errors={props.errors}
                      touched={props.touched}
                      disabled={dialogType === "EDIT"}
                      handleChange={props.handleChange}
                    />
                    {contacts.map((contact, index) => (
                      <Box display="flex" flex={1} key={contact}>
                        <Box display="flex" alignItems="center" flex={5} mr={2}>
                          {contact === "phone" && (
                            <Input.Phone
                              label="Contact Info"
                              inputId={contact}
                              values={props.values}
                              errors={props.errors}
                              touched={props.touched}
                              handleChange={props.handleChange}
                            />
                          )}
                          {(contact === "email" || contact === "linkedin") && (
                            <Input.Text
                              label="Contact Info"
                              inputId={contact}
                              values={props.values}
                              errors={props.errors}
                              touched={props.touched}
                              handleChange={props.handleChange}
                            />
                          )}
                        </Box>
                        <Box
                          display="flex"
                          alignItems="center"
                          flex={3}
                          pt={4}
                          className={classes.dropdownForm}
                        >
                          <FormControl fullWidth>
                            <Select
                              defaultValue={contact}
                              value={contact}
                              onChange={(e) => {
                                props.setFieldValue(contact, "");
                                setContacts((prev) => {
                                  prev[index] = e.target.value;
                                  return [...prev];
                                });
                              }}
                            >
                              {CONTACT_OPTIONS.map((cOption) => (
                                <MenuItem
                                  key={cOption.value}
                                  value={cOption.value}
                                  disabled={contacts.includes(cOption.value)}
                                >
                                  {cOption.label}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                          {index > 0 && dialogType === "ADD" && (
                            <IconButton
                              onClick={() => {
                                props.setFieldValue(contact, "");
                                setContacts((prev) => {
                                  prev.splice(index, 1);
                                  return [...prev];
                                });
                              }}
                            >
                              <SvgIcon>
                                <Cancel />
                              </SvgIcon>
                            </IconButton>
                          )}
                        </Box>
                      </Box>
                    ))}
                    {contacts.length < 3 && (
                      <Button
                        containerStyle={classes.addContactBtn}
                        text="ADD CONTACT INFORMATION"
                        color="secondary"
                        variant="text"
                        startIcon={<Add />}
                        onClick={onAddContact}
                      />
                    )}
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    md={6}
                    display="flex"
                    flexDirection="column"
                  >
                    <Input.Text
                      label="Title"
                      inputId="title"
                      values={props.values}
                      errors={props.errors}
                      touched={props.touched}
                      disabled={dialogType === "EDIT"}
                      handleChange={props.handleChange}
                    />
                    {/* <Input.AddressSearch
                      inputId="address"
                      label="Address"
                      placeholder="Enter Address"
                      values={props.values}
                      errors={props.errors}
                      touched={props.touched}
                      handleChange={(val) =>
                        props.setFieldValue("address", val)
                      }
                      disabled={dialogType === "EDIT"}
                      handleBlur={props.handleBlur}
                      onFocus={() => props.setFieldTouched("address")}
                    /> */}
                    <Input.TextArea
                      inputId="notes"
                      label="Notes"
                      rows={6}
                      values={props.values}
                      errors={props.errors}
                      touched={props.touched}
                      handleChange={props.handleChange}
                    />
                    <Box className={classes.submitBtnWrapper}>
                      <Button
                        text="CANCEL"
                        variant="text"
                        color="secondary"
                        onClick={onClose}
                      />
                      <Button
                        color="success"
                        variant="contained"
                        type="submit"
                        text={submitBtnTitle}
                        loading={isCreating || isUpdating}
                        disabled={!props.isValid || !props.dirty}
                      />
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Form>
          )}
        </Formik>
      </DialogContent>
      {!!error && <Alert severity="error">{error}</Alert>}
    </Dialog>
  );
};

export default AddEditContact;

AddEditContact.propTypes = {
  open: PropTypes.bool.isRequired,
};
