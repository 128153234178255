import { bugsnagNotify } from "services/bugsnag";

const groupEndpoints = (builder) => ({
  getGroup: builder.query({
    query: (groupId) => ({
      url: "/group/get",
      method: "POST",
      data: { group_id: groupId },
    }),
    async onQueryStarted(_, { queryFulfilled }) {
      try {
        await queryFulfilled;
      } catch (e) {
        bugsnagNotify(
          new Error(e?.error?.data?.message ?? "Group get failed."),
          e
        );
      }
    },
  }),
  getGroupList: builder.query({
    query: ({ getNext, startPos }) => ({
      url: "/group/list",
      method: "POST",
      data: { get_next: getNext, start_position: startPos },
    }),
    async onQueryStarted(_, { queryFulfilled }) {
      try {
        await queryFulfilled;
      } catch (e) {
        bugsnagNotify(
          new Error(e?.error?.data?.message ?? "Fetch group list failed."),
          e
        );
      }
    },
    providesTags: [{ type: "GROUP", id: "LIST" }],
  }),
  addGroup: builder.mutation({
    query: ({ groupName, privateCode, productId }) => ({
      url: "/group/add",
      method: "POST",
      data: { groupName, privateCode, product_id: productId },
    }),
    async onQueryStarted(_, { queryFulfilled }) {
      try {
        await queryFulfilled;
      } catch (e) {
        bugsnagNotify(
          new Error(e?.error?.data?.message ?? "Group add failed."),
          e
        );
      }
    },
    invalidatesTags: [{ type: "GROUP", id: "LIST" }],
  }),
  createPrivateGroup: builder.mutation({
    query: ({ name, description, product }) => ({
      url: "/group/create_private",
      method: "POST",
      data: {
        name,
        description,
        product,
        group_type: "PRIVATE",
      },
    }),
    async onQueryStarted(_, { queryFulfilled }) {
      try {
        await queryFulfilled;
      } catch (e) {
        bugsnagNotify(
          new Error(e?.error?.data?.message ?? "Create private group failed."),
          e
        );
      }
    },
    invalidatesTags: [{ type: "GROUP", id: "LIST" }],
  }),
  updateGroup: builder.mutation({
    query: ({ groupId, name, description }) => ({
      url: "/group/update",
      method: "POST",
      data: { group_id: groupId, name, description },
    }),
    async onQueryStarted(_, { queryFulfilled }) {
      try {
        await queryFulfilled;
      } catch (e) {
        bugsnagNotify(
          new Error(e?.error?.data?.message ?? "Update group failed."),
          e
        );
      }
    },
  }),
  updateGroupUser: builder.mutation({
    query: ({ groupId, userId, userRole, product }) => ({
      url: "/group/user/update",
      method: "POST",
      data: {
        group_id: groupId,
        user_id: userId,
        user_role: userRole,
        product,
      },
    }),
    async onQueryStarted(_, { queryFulfilled }) {
      try {
        await queryFulfilled;
      } catch (e) {
        bugsnagNotify(
          new Error(e?.error?.data?.message ?? "Update group user failed."),
          e
        );
      }
    },
  }),
  verifyGroupExist: builder.query({
    query: (groupName) => ({
      url: "/group/verify_exists",
      method: "POST",
      data: { group_name: groupName },
    }),
    async onQueryStarted(_, { queryFulfilled }) {
      try {
        await queryFulfilled;
      } catch (e) {
        bugsnagNotify(
          new Error(e?.error?.data?.message ?? "Verify group exists failed."),
          e
        );
      }
    },
  }),
  inviteGroupUser: builder.mutation({
    query: ({ email, role, groupId }) => ({
      url: "/group/invite",
      method: "POST",
      data: { email, role, group_id: groupId },
    }),
    async onQueryStarted(_, { queryFulfilled }) {
      try {
        await queryFulfilled;
      } catch (e) {
        bugsnagNotify(
          new Error(e?.error?.data?.message ?? "Group invite user failed"),
          e
        );
      }
    },
  }),
  addInvitedGroupUser: builder.mutation({
    query: ({ groupId, inviteCode }) => ({
      url: "/group/add_invited",
      method: "POST",
      data: { group_id: groupId, invite_code: inviteCode },
    }),
    async onQueryStarted(_, { queryFulfilled }) {
      try {
        await queryFulfilled;
        localStorage.removeItem("inviteId");
        localStorage.removeItem("inviteCode");
        localStorage.removeItem("inviteAction");
      } catch (e) {
        localStorage.removeItem("inviteId");
        localStorage.removeItem("inviteCode");
        localStorage.removeItem("inviteAction");
        bugsnagNotify(
          new Error(e?.error?.data?.message ?? "Add invited group user failed"),
          e
        );
      }
    },
    invalidatesTags: [{ type: "Group", id: "LIST" }],
  }),
  leaveGroup: builder.mutation({
    query: ({ groupId, userId }) => ({
      url: "/group/remove",
      method: "POST",
      data: { group_id: groupId, user_id: userId },
    }),
    async onQueryStarted(_, { queryFulfilled }) {
      try {
        await queryFulfilled;
      } catch (e) {
        bugsnagNotify(
          new Error(e?.error?.data?.message ?? "Leave group failed."),
          e
        );
      }
    },
  }),
  removeGroup: builder.mutation({
    query: (groupId) => ({
      url: "/group/delete",
      method: "POST",
      data: { group_id: groupId },
    }),
    async onQueryStarted(_, { queryFulfilled }) {
      try {
        await queryFulfilled;
      } catch (e) {
        bugsnagNotify(
          new Error(e?.error?.data?.message ?? "Remove group failed."),
          e
        );
      }
    },
    invalidatesTags: [{ type: "GROUP", id: "LIST" }],
  }),
  getGroupStats: builder.query({
    query: ({ groupId, userIds }) => ({
      url: "/group/stats",
      method: "POST",
      data: {
        filter_by: "USERS",
        search_by: "GROUP",
        search_ids: [groupId],
        filter_ids: userIds,
      },
    }),
  }),
});

export default groupEndpoints;
