import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  currentContact: null,
};

const contactAppSlice = createSlice({
  name: "contactApp",
  initialState,
  reducers: {
    setCurrentContact: (state, action) => {
      state.currentContact = action.payload;
    },
  },
});

export const { setCurrentContact } = contactAppSlice.actions;

export default contactAppSlice.reducer;
