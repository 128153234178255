import React from "react";
import PropTypes from "prop-types";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
} from "@mui/material";
import useStyles from "./CommonDialog.style";

const CommonDialog = ({
  id,
  open,
  title,
  text,
  buttons,
  handleClose,
  dialogTitleClass,
  dialogTitleTextClass,
  dialogContentClass,
  dialogContentTextClass,
  dialogDividerClass,
}) => {
  const classes = useStyles();

  return (
    <Dialog
      id={id}
      open={open}
      onClose={handleClose}
      aria-labelledby={`alert-dialog-title-${id}`}
      aria-describedby={`alert-dialog-description-${id}`}
    >
      {title && (
        <DialogTitle id="alert-dialog-title" className={dialogTitleClass}>
          <p className={dialogTitleTextClass || classes.dialogTitleText}>
            {title}
          </p>
        </DialogTitle>
      )}
      {title && (
        <Divider
          variant="middle"
          className={dialogDividerClass || classes.dialogDivider}
        />
      )}
      {text && (
        <DialogContent className={dialogContentClass || classes.dialogContent}>
          <DialogContentText
            className={dialogContentTextClass || classes.dialogContentText}
          >
            {text}
          </DialogContentText>
        </DialogContent>
      )}
      {buttons && buttons.length > 0 && (
        <DialogActions>
          {buttons.map((button, index) => (
            <Button
              key={index}
              onClick={button.onClick}
              variant={button.variant}
              type={button.type}
              color={button.color}
              style={button.style}
              disabled={button.disabled}
            >
              {button.text}
            </Button>
          ))}
        </DialogActions>
      )}
    </Dialog>
  );
};

export default CommonDialog;

CommonDialog.propTypes = {
  id: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  title: PropTypes.string,
  text: PropTypes.object,
  dialogTitleClass: PropTypes.string,
  dialogTitleTextClass: PropTypes.string,
  dialogContentClass: PropTypes.string,
  dialogContentTextClass: PropTypes.string,
  dialogDividerClass: PropTypes.string,
  handleClose: PropTypes.func,
  buttons: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string.isRequired,
      onClick: PropTypes.func.isRequired,
      variant: PropTypes.string,
      type: PropTypes.string,
      color: PropTypes.string,
      style: PropTypes.string,
      disabled: PropTypes.bool,
    })
  ),
};
