import { makeStyles } from "@mui/styles";
import theme from "theme/theme";

const useStyles = makeStyles(() => ({
  container: {
    borderRadius: 8,
    backgroundColor: theme.palette.info.main,
    paddingLeft: 4,
    paddingRight: 4,
    paddingTop: 2,
    paddingBottom: 2,
    alignSelf: "center",
  },
  badgeTitle: {
    fontSize: 12,
    fontWeight: 500,
    color: "white",
  },
}));

export default useStyles;
