import TextInput from "./TextInput";
import PhoneInput from "./PhoneInput";
import TextArea from "./TextArea";
import Dropdown from "./Dropdown";
import AddressSearch from "./AddressSearch";

const Input = {
  Text: TextInput,
  Phone: PhoneInput,
  TextArea,
  Dropdown,
  AddressSearch,
};

export default Input;
