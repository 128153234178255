import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  TextField,
  MenuItem,
  Button,
  Typography,
  Snackbar,
  IconButton,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import {
  useElements,
  useStripe,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
} from "@stripe/react-stripe-js";
import { Close } from "@mui/icons-material";
import StripeInput from "./StripeInput";
import { StripeCountries, SubscriptionType } from "utils/constants";
import { ReactComponent as PaymentCart } from "assets/icons/payment_cart.svg";
import { useSelector } from "react-redux";
import { Formik, Form } from "formik";
import { FormField } from "components";
import * as yup from "yup";
import {
  useCreateStripeCustomerMutation,
  useGetBillingQuery,
  useGetUserQuery,
  useStartSubscriptionMutation,
  useUpdatePaymentMutation,
} from "services/api";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  container: {
    flex: 1,
    position: "relative",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    paddingLeft: 32,
    paddingRight: 32,
    marginTop: (props) => (props.isUpdateMode ? 32 : 0),
    marginBottom: (props) => (props.isUpdateMode ? 32 : 0),
    "& .MuiFormControl-marginNormal": {
      marginTop: 0,
      marginBottom: 0,
      [theme.breakpoints.down("sm")]: {
        marginBottom: 24,
      },
    },
    [theme.breakpoints.down("sm")]: {
      paddingLeft: 0,
      paddingRight: 0,
      marginTop: 24,
      "& > div": {
        marginBottom: 24,
      },
      "& h6": {
        marginTop: 8,
        marginBottom: 8,
      },
    },
    "& h6.MuiTypography-subtitle2": {
      textAlign: "center",
    },
    "& .MuiFormHelperText-root": {
      fontFamily: "'Poppins', sans-serif",
    },
    "& a": {
      color: theme.palette.text.secondary,
      textDecoration: "underline",
      textDecorationColor: theme.palette.text.secondary,
    },
  },
  expDateCard: {
    marginRight: 16,
  },
  snackbar: {
    position: "absolute",
    left: 32,
    right: 32,
    // bottom: 120,
    display: "block",
    transform: "translateX(0%) translateY(-150%)",
  },
}));

const cardOptions = {
  style: {
    base: {
      fontSize: "16px",
      fontFamily: "Poppins",
      fontSmoothing: "antialiased",
      fontWeight: "bold",
      "::placeholder": {
        color: "rgba(0,0,0,.35)",
      },
    },
    invalid: {
      color: "#fa755a",
      iconColor: "#fa755a",
    },
  },
};

const validationSchema = yup.object().shape({
  email: yup
    .string()
    .trim()
    .email("Must be a valid email address")
    .required("Email is required"),
  cardName: yup.string().trim().required("Name on your card is required"),
  country: yup.string().required(),
  zipCode: yup
    .string()
    .length(5, "Zip code must be exactly 5 characters")
    .matches(/^[0-9]{5}/, "Zip code must be a number")
    .required("Zip code is required"),
});

// To customize the stripe elements to match SS payment information design,
// https://www.figma.com/file/ZxoQmOoCM80kvaZxZTy9md/B2B?node-id=736%3A6037
// I adopted the methods from https://github.com/angeloron/react-material-ui-stripe-payment-form

const PaymentForm = ({ paymentInfo, ...rest }) => {
  const isUpdateMode = !!paymentInfo;
  const classes = useStyles({ isUpdateMode });
  const stripe = useStripe();
  const elements = useElements();
  const [subscribing, setSubscribing] = useState(false);
  const [cardError, setCardError] = useState(null);
  const [snackbarMsg, setSnackbarMsg] = useState("");
  const { data: userInfo } = useGetUserQuery();

  const userId = useSelector((state) => state.user.userId);
  const { data: billingInfo, refetch: refetchBilling } = useGetBillingQuery();
  const [createStripeCustomer] = useCreateStripeCustomerMutation();
  const [updatePayment] = useUpdatePaymentMutation();
  const [startSubscription, { isSuccess: subscriptionSuccess }] =
    useStartSubscriptionMutation();
  const isMounted = useRef(null);

  const initialValues = isUpdateMode
    ? {
        email: paymentInfo.email,
        cardName: paymentInfo.name,
        country: paymentInfo.country,
        zipCode: paymentInfo.postalCode,
      }
    : {
        email: "",
        cardName: "",
        country: "US",
        zipCode: "",
      };

  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    if (subscriptionSuccess) {
      refetchBilling();
    }
  }, [subscriptionSuccess]);

  const onSubmit = async ({ email, cardName, country, zipCode }) => {
    if (subscribing || userInfo == null) {
      return;
    }

    setCardError(null);
    setSubscribing(true);
    try {
      // Use card Element to tokenize payment details
      const cardElement = elements.getElement(CardNumberElement);
      let { error, paymentMethod } = await stripe.createPaymentMethod({
        type: "card",
        card: cardElement,
        billing_details: {
          name: cardName,
          email,
          address: {
            country,
            postal_code: zipCode,
          },
        },
      });

      if (error || !paymentMethod) {
        if (!isMounted.current) return;
        setCardError(`Payment failed: ${error.message}`);
        setSubscribing(false);
        return;
      }
      let customerId = billingInfo?.customerId;
      if (!isUpdateMode) {
        if (!customerId) {
          // create stripe customer
          const customerData = await createStripeCustomer(userId).unwrap();
          customerId = customerData.customerId;
        }
      }
      if (!customerId) {
        setSubscribing(false);
        setSnackbarMsg("Your stripe customer is not created yet.");
        return;
      }
      await updatePayment(paymentMethod.id).unwrap();
      if (isUpdateMode) {
        setSubscribing(false);
        setSnackbarMsg("Payment method has been updated.");
        return;
      }
      // Start stripe subscription
      await startSubscription(SubscriptionType.BUSINESS).unwrap();
      if (!isMounted.current) return;
      setSubscribing(false);
    } catch (e) {
      if (!isMounted.current) return;
      let message = e.message;
      if (e.data) {
        message = e.data.message;
      }
      // Temporary until the BE provides more flexible message
      if (message.includes("card_declined")) {
        message = "Your card was declined.";
      }
      setSubscribing(false);
      setSnackbarMsg(message);
    }
  };

  const handleSnackbarClose = () => {
    setSnackbarMsg("");
  };

  return (
    <Formik
      {...rest}
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      <Form className={classes.container}>
        <FormField name="email" label="E-mail Address" />
        <TextField
          label="Card Number"
          name="ccnumber"
          variant="outlined"
          error={!!cardError}
          helperText={cardError}
          fullWidth
          required
          InputProps={{
            inputComponent: StripeInput,
            inputProps: {
              component: CardNumberElement,
              options: { ...cardOptions, showIcon: true },
              showIcon: true,
            },
          }}
          InputLabelProps={{ shrink: true }}
        />
        <Box display="flex" flexDirection="row" justifyContent="space-between">
          <TextField
            label="Exp Date"
            name="expDate"
            variant="outlined"
            fullWidth
            required
            className={classes.expDateCard}
            InputProps={{
              inputComponent: StripeInput,
              inputProps: {
                component: CardExpiryElement,
                options: cardOptions,
              },
            }}
            InputLabelProps={{ shrink: true }}
          />
          <TextField
            label="CVC"
            name="cvc"
            variant="outlined"
            fullWidth
            required
            InputProps={{
              inputComponent: StripeInput,
              inputProps: {
                component: CardCvcElement,
                options: cardOptions,
              },
            }}
            InputLabelProps={{ shrink: true }}
          />
        </Box>
        <FormField name="cardName" label="Name on Card" />
        <FormField name="country" label="Country" select>
          {StripeCountries.map((country) => (
            <MenuItem key={country.code} value={country.code}>
              {country.name}
            </MenuItem>
          ))}
        </FormField>
        <FormField name="zipCode" label="Zip Code" />
        <Button
          variant="contained"
          color="secondary"
          startIcon={isUpdateMode ? null : <PaymentCart />}
          className={classes.subscribeBtn}
          disabled={subscribing}
          type="submit"
        >
          {isUpdateMode
            ? "UPDATE PAYMENT INFORMATION"
            : "SUBSCRIBE FOR $49.99/USER PER MONTH"}
        </Button>
        <Typography color="textSecondary" variant="subtitle2">
          By confirming your subscription, you allow Alliance App to charge your
          card for this payment and future payments&nbsp;
          <Link to={{ pathname: "https://stripe.com/legal" }} target="_blank">
            in accordance with their terms
          </Link>
        </Typography>
        <Snackbar
          open={!!snackbarMsg}
          autoHideDuration={3000}
          onClose={handleSnackbarClose}
          message={snackbarMsg}
          className={classes.snackbar}
          action={
            <IconButton
              color="inherit"
              size="small"
              onClick={handleSnackbarClose}
            >
              <Close fontSize="small" />
            </IconButton>
          }
        />
      </Form>
    </Formik>
  );
};

export default PaymentForm;
