import { bugsnagNotify } from "services/bugsnag";

const activityEndpoints = (builder) => ({
  getGlobalActivities: builder.query({
    query: ({ activityTypes, responseType }) => ({
      url: "/activity/global",
      method: "POST",
      data: { activity_types: activityTypes, response_type: responseType },
    }),
    async onQueryStarted(_, { queryFulfilled }) {
      try {
        await queryFulfilled;
      } catch (e) {
        bugsnagNotify(
          new Error(
            e?.error?.data?.message ?? "Fetch global activities failed."
          ),
          e
        );
      }
    },
    providesTags: [{ type: "Activity", id: "List" }],
  }),
  getGroupActivities: builder.query({
    query: ({ groupId, activityTypes }) => ({
      url: "/activity/group",
      method: "POST",
      data: { group_id: groupId, activity_types: activityTypes },
    }),
    async onQueryStarted(_, { queryFulfilled }) {
      try {
        await queryFulfilled;
      } catch (e) {
        bugsnagNotify(
          new Error(
            e?.error?.data?.message ?? "Fetch group activities failed."
          ),
          e
        );
      }
    },
  }),
});

export default activityEndpoints;
