import React from "react";
import { Box } from "@mui/material";
import { Text } from "components";
import useStyles from "./PageTitle.style";
import clsx from "clsx";

const PageTitle = ({ title, className }) => {
  const classes = useStyles();
  return (
    <Box textAlign="center" className={clsx(classes.container, className)}>
      <Text className={classes.pageTitle}>{title}</Text>
    </Box>
  );
};

export default PageTitle;
