import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  authUser: localStorage.getItem("access_token"),
  userId: localStorage.getItem("user_id"),
  userInfo: null,
  users: {},
  notificationSetting: {
    enableEmail: false,
  },
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    userLogout: (state) => {
      localStorage.clear();
      state.authUser = null;
      state.userId = null;
      state.userInfo = null;
    },
    userLoginSuccess: (state, action) => {
      state.authUser = action.payload.accessToken;
      state.userId = action.payload.userId;
    },
    userUpdateSuccess: (state, action) => {
      state.userInfo = action.payload;
    },
  },
});

export const { userLogout, userLoginSuccess, userUpdateSuccess } =
  userSlice.actions;

export default userSlice.reducer;
