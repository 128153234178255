import React from "react";
import { Box, IconButton, InputAdornment, TextField } from "@mui/material";
import useStyles from "./ChatFooter.style";
import { Attachment, Send } from "@mui/icons-material";

const ChatFooter = ({
  message,
  setMessage,
  onSendMessage,
  onSendFile,
  onFocus,
  onBlur,
  placeholder,
  disableFiles,
}) => {
  const classes = useStyles();
  return (
    <Box flexDirection="row" width={1}>
      <TextField
        multiline
        fullWidth
        className={classes.chatInput}
        variant="outlined"
        value={message}
        onChange={(e) => setMessage(e.target.value)}
        onFocus={onFocus}
        onBlur={onBlur}
        placeholder={placeholder || "Enter your message"}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton onClick={onSendMessage}>
                <Send color={message ? "primary" : "secondary"} />
              </IconButton>
            </InputAdornment>
          ),
          startAdornment: onSendFile && !disableFiles && (
            <InputAdornment position="start">
              <IconButton onClick={onSendFile}>
                <Attachment color="secondary" />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
    </Box>
  );
};

export default ChatFooter;
