import React, { useEffect, useState } from "react";
import {
  Button,
  Container,
  Input,
  PageLoader,
  PageTitle,
  SectionTitle,
  Switch,
  Text,
  TextWithIcon,
} from "components";
import { Box, Snackbar, IconButton } from "@mui/material";
import useStyles from "../Group.style";
import { useParams } from "react-router";
import {
  useGetGroupQuery,
  useGetNotificationSettingQuery,
  useGetUserQuery,
  useLeaveGroupMutation,
  useToggleNotificationSettingMutation,
  useUpdateGroupUserMutation,
} from "services/api";
import { Close, SouthWest, Check, Chat } from "@mui/icons-material";
import { Form, Formik } from "formik";
import * as yup from "yup";
import theme from "theme/theme";
import { NOTIFICATION, ROUTES } from "utils/constants";
import RemoveDialog from "components/Modals/RemoveDialog";
import { useHistory } from "react-router-dom";

const schema = yup.object().shape({
  product: yup
    .string()
    .trim()
    .optional()
    .min(3, "Must be at least 3 characters long")
    .max(100, "Must be less than 100 characters long"),
});

const GroupSettings = () => {
  const classes = useStyles();
  const { group_id: groupId } = useParams();
  const { data: groupData } = useGetGroupQuery(groupId, { skip: !groupId });
  const { data: userInfo } = useGetUserQuery();
  const { data: notificationSettings } = useGetNotificationSettingQuery({
    deliveryMethod: NOTIFICATION.DELIVERY_METHOD.ALL,
    service: NOTIFICATION.SERVICE.GROUP,
    serviceId: groupId,
    action: NOTIFICATION.ACTION.CHAT_MESSAGE_SEND,
  });
  const [
    updateGroupUser,
    { isSuccess: updateGroupUserSuccess, error: updateGroupUserError },
  ] = useUpdateGroupUserMutation();
  const [
    toggleNotificationSetting,
    { isSuccess: toggleSettingSuccess, error: toggleSettingError },
  ] = useToggleNotificationSettingMutation();
  const [
    leaveGroup,
    {
      isLoading: isLeaving,
      isSuccess: leaveGroupSuccess,
      error: leaveGroupError,
    },
  ] = useLeaveGroupMutation();
  const [showLeaveGroupModal, setShowLeaveGroupModal] = useState(false);
  const [messageNotification, setMessageNotification] = useState(false);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarMsg, setSnackbarMsg] = useState("");
  const history = useHistory();
  const isModerator = userInfo?.userId === groupData?.createdBy;

  const initialValues = {
    product:
      groupData?.users?.find((u) => u.userRole === "OWNER")?.product ?? "",
  };

  useEffect(() => {
    if (notificationSettings) {
      setMessageNotification(
        !!notificationSettings.settings?.find((nS) => nS.toggledOn)
      );
    }
  }, [notificationSettings]);

  useEffect(() => {
    if (updateGroupUserSuccess && toggleSettingSuccess) {
      setSnackbarMsg("You have updated the group setting.");
      setShowSnackbar(true);
      history.goBack();
    }
  }, [updateGroupUserSuccess, toggleSettingSuccess]);

  useEffect(() => {
    if (leaveGroupSuccess) {
      history.push(ROUTES.GROUP_LIST);
    }
  }, [leaveGroupSuccess]);

  useEffect(() => {
    const error = updateGroupUserError || toggleSettingError || leaveGroupError;
    if (error) {
      setSnackbarMsg(error.data?.message);
      setShowSnackbar(true);
    }
  }, [updateGroupUserError, toggleSettingError, leaveGroupError]);

  const handleLeaveGroup = () => {
    setShowLeaveGroupModal(true);
  };

  const handleCancel = () => {
    history.goBack();
  };

  const handleSave = ({ product }) => {
    updateGroupUser({
      groupId,
      userId: userInfo?.userId,
      product,
      userRole: groupData?.users?.find((u) => u.userId === userInfo?.userId)
        ?.userRole,
    });
    toggleNotificationSetting({
      deliveryMethod: NOTIFICATION.DELIVERY_METHOD.ALL,
      service: NOTIFICATION.SERVICE.GROUP,
      serviceId: groupId,
      action: NOTIFICATION.ACTION.ALL,
      toggledOn: messageNotification,
    });
  };

  const handleSnackbarClose = () => {
    setSnackbarMsg("");
    setShowSnackbar(false);
  };

  const handleCloseLeaveGroupModal = () => {
    setShowLeaveGroupModal(false);
  };

  const onConfirmedLeaveGroup = (group) => {
    leaveGroup({ groupId: group?.groupId, userId: userInfo?.userId });
  };

  if (!groupData) {
    return <PageLoader />;
  }

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={schema}
        onSubmit={handleSave}
      >
        {({ values, errors, touched, handleChange }) => (
          <Form className={classes.groupSettingsForm}>
            <Container>
              <Box className={classes.titleBox}>
                <PageTitle title={groupData?.name} />
                <Box className={classes.buttonWrapper}>
                  <Button
                    startIcon={<SouthWest />}
                    color="secondary"
                    fullWidth={false}
                    text={isModerator ? "DELETE GROUP" : "LEAVE GROUP"}
                    variant="outlined"
                    size="small"
                    onClick={handleLeaveGroup}
                  />
                  <Button
                    startIcon={<Close />}
                    color="secondary"
                    fullWidth={false}
                    text="CANCEL"
                    variant="outlined"
                    size="small"
                    onClick={handleCancel}
                    containerStyle={classes.mr2}
                  />
                  <Button
                    startIcon={<Check />}
                    color="secondary"
                    fullWidth={false}
                    text="SAVE"
                    variant="outlined"
                    size="small"
                    type="submit"
                  />
                </Box>
              </Box>
              <Box display="flex" flexDirection="row" flex={1} mt={16}>
                <Box flex={1}>
                  <Box>
                    <Text variant="h4">Group Name</Text>
                    <Box mt={2}>
                      <Text variant="body1">{groupData.name}</Text>
                    </Box>
                  </Box>
                  <Box mt={10}>
                    <Text variant="h4">Description</Text>
                    <Box mt={2}>
                      <Text variant="body1">{groupData.description}</Text>
                    </Box>
                  </Box>
                  <Box mt={10}>
                    <SectionTitle title="Product Information" />
                    <Input.Text
                      label="Product I'm selling"
                      inputId="product"
                      values={values}
                      errors={errors}
                      touched={touched}
                      handleChange={handleChange}
                    />
                  </Box>
                </Box>
                <Box
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                  flex={2}
                  ml={4}
                >
                  <TextWithIcon
                    title="GROUP CHAT SETTINGS"
                    icon={<Chat htmlColor={theme.palette.common.lightGray} />}
                  />
                  <Box
                    flex={1}
                    mt={10}
                    flexDirection="row"
                    display="flex"
                    width={1}
                  >
                    {/* <Box flex={1} mr={4}>
                      <SectionTitle title="Attachments" />
                    </Box> */}
                    <Box flex={1} ml={4}>
                      <SectionTitle title="Chat Notifications" />
                      <Switch
                        title="Messages"
                        value={messageNotification}
                        setValue={(state) => setMessageNotification(state)}
                      />
                    </Box>
                  </Box>
                </Box>
              </Box>
              <Snackbar
                open={showSnackbar}
                autoHideDuration={3000}
                onClose={handleSnackbarClose}
                message={snackbarMsg}
                className={classes.snackbar}
                action={
                  <IconButton
                    color="inherit"
                    size="small"
                    onClick={handleSnackbarClose}
                  >
                    <Close fontSize="small" />
                  </IconButton>
                }
              />
            </Container>
          </Form>
        )}
      </Formik>
      {showLeaveGroupModal && (
        <RemoveDialog
          open={showLeaveGroupModal}
          onClose={handleCloseLeaveGroupModal}
          title={isModerator ? "Delete group" : "Leave group"}
          body={
            (isModerator ? "delete" : "leave") +
            " the group " +
            groupData.name +
            "?"
          }
          warning="This operation cannot be undone and you will lose permanent access to all group data."
          confirmButtonTitle={isModerator ? "DELETE GROUP" : "LEAVE GROUP"}
          data={groupData}
          onRemove={onConfirmedLeaveGroup}
          isLoading={isLeaving}
        />
      )}
    </>
  );
};

export default GroupSettings;
