import React from "react";
import PropTypes from "prop-types";
import { LoadingButton } from "@mui/lab";
import useStyles from "./Button.style";
import clsx from "clsx";
import { Text } from "components";

const Button = ({
  fullWidth,
  text,
  startIcon,
  endIcon,
  variant = "contained",
  loading,
  disabled,
  style,
  containerStyle,
  color = "primary",
  onClick,
  type,
  size,
  ...rest
}) => {
  const classes = useStyles({ color, variant, size });
  let loadingPosition = "center";
  if (startIcon && loading) {
    loadingPosition = "start";
  } else if (endIcon && loading) {
    loadingPosition = "end";
  }

  return (
    <LoadingButton
      fullWidth={fullWidth}
      variant={variant}
      startIcon={startIcon}
      endIcon={endIcon}
      loading={loading}
      loadingPosition={loadingPosition}
      disabled={disabled || loading}
      className={clsx(classes.containerStyle, containerStyle)}
      color={color}
      onClick={onClick}
      type={type}
      {...rest}
    >
      {typeof text === "string" ? (
        <Text className={clsx(classes.buttonText, style)}>{text}</Text>
      ) : (
        text
      )}
    </LoadingButton>
  );
};

Button.propTypes = {
  fullWidth: PropTypes.bool,
  text: PropTypes.string.isRequired,
  startIcon: PropTypes.element,
  endIcon: PropTypes.element,
  variant: PropTypes.oneOf(["text", "contained", "outlined"]).isRequired,
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  style: PropTypes.string,
  containerStyle: PropTypes.string,
  color: PropTypes.oneOf(["primary", "secondary", "error", "success"])
    .isRequired,
  onClick: PropTypes.func,
  type: PropTypes.oneOf(["button", "submit", "reset"]).isRequired,
  size: PropTypes.oneOf(["small", "medium"]),
};

Button.defaultProps = {
  fullWidth: true,
  variant: "contained",
  loading: false,
  disabled: false,
  color: "primary",
  type: "button",
  size: "medium",
};

export default Button;
