import React, { useEffect } from "react";
import { userLogout } from "redux/slices/User";
import { useDispatch, useSelector } from "react-redux";
import Branch from "branch-sdk";
import { branchKey } from "utils/env";

const SignOut = () => {
  const { authUser } = useSelector(({ user }) => user);
  const dispatch = useDispatch();

  useEffect(() => {
    if (authUser) {
      dispatch(userLogout());

      if (!branchKey) return;
      try {
        Branch.logout();
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
      }
    }
  }, [authUser, dispatch]);

  return <h1>signing out..</h1>;
};

export default SignOut;
