import React from "react";
import { List, ListItem, CircularProgress } from "@mui/material";
import { ListItemCard } from "components";
import useStyles from "./VerticalList.style";
import clsx from "clsx";
import PropTypes from "prop-types";

const VerticalList = ({
  data,
  activeId,
  loading,
  onPressItem,
  emptyStateComponent,
  containerStyle,
}) => {
  const classes = useStyles();
  return (
    <List className={clsx(classes.container, containerStyle)}>
      {loading && <CircularProgress size={30} />}
      {!loading && data?.length === 0 && emptyStateComponent}
      {!loading &&
        data?.length > 0 &&
        data.map((item) => (
          <ListItem key={item.id}>
            <ListItemCard
              active={item.id === activeId}
              avatarText={item.avatarText}
              avatarIcon={item.avatarIcon}
              title={item.title}
              subtitle={item.subtitle}
              extraSubtitle={item.extraSubtitle}
              isNew={item.isNew}
              badgeCount={item.badgeCount}
              caption={item.caption}
              titleIcon={item.titleIcon}
              containerStyle={clsx(classes.item, item.containerStyle)}
              titleStyle={item.titleStyle}
              subtitleStyle={item.subtitleStyle}
              onPress={() => onPressItem(item)}
              avatarId={item.avatarId}
            />
          </ListItem>
        ))}
    </List>
  );
};

VerticalList.propTypes = {
  data: PropTypes.array.isRequired,
  activeId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  loading: PropTypes.bool,
  onPressItem: PropTypes.func,
  emptyStateComponent: PropTypes.element,
  containerStyle: PropTypes.string,
};

VerticalList.defaultProps = {
  loading: false,
  onPressItem: () => {},
};

export default VerticalList;
