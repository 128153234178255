import React, { useContext } from "react";
import {
  CmtVerticalLayout,
  CmtContent,
  CmtHeader,
  CmtFooter,
  CmtSidebar,
  AppContext,
} from "components";
import Header from "../../partials/Header/Header";
import SideBar from "../../partials/SideBar/SideBar";
import clsx from "clsx";
import Footer from "../../partials/Footer";
import { SIDEBAR_TYPE } from "../../../../../theme/ThemeOptions";

const VerticalMinimal = ({ className, children }) => {
  const {
    drawerBreakPoint,
    headerType,
    isSidebarFixed,
    sidebarStyle,
    sidebarSize,
    showFooter,
  } = useContext(AppContext);

  return (
    <CmtVerticalLayout
      drawerBreakPoint={drawerBreakPoint}
      sidebarWidth={sidebarSize}
      className={clsx("verticalMinimalLayout", className)}
    >
      <CmtHeader type={headerType} fullHeader>
        <Header />
      </CmtHeader>
      <CmtSidebar
        type={SIDEBAR_TYPE.MINI}
        isSidebarFixed={isSidebarFixed}
        {...sidebarStyle}
      >
        <SideBar />
      </CmtSidebar>
      <CmtContent>{children}</CmtContent>
      {showFooter && (
        <CmtFooter type="static">
          <Footer />
        </CmtFooter>
      )}
    </CmtVerticalLayout>
  );
};

export default VerticalMinimal;
