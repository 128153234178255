import React from "react";
import { useGetNotificationSettingQuery } from "services/api";
import { NOTIFICATION } from "utils/constants";

const Settings = ({ groupId }) => {
  const { data: notificationSettingData } = useGetNotificationSettingQuery({ // eslint-disable-line
    deliveryMethod: NOTIFICATION.DELIVERY_METHOD.ALL,
    service: NOTIFICATION.SERVICE.GROUP,
    serviceId: groupId,
    action: NOTIFICATION.ACTION.ALL,
  });
  return <></>;
};

export default Settings;
