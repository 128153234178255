import React from "react";
import { buildURI, toCSV } from "./core";
import {
  defaultProps as commonDefaultProps,
  propTypes as commonPropTypes,
} from "./metaProps";

/**
 *
 * @example ../../sample-site/csvlink.example.md
 */
class CSVLink extends React.Component {
  static defaultProps = commonDefaultProps;
  static propTypes = commonPropTypes;

  constructor(props) {
    super(props);
    this.buildURI = this.buildURI.bind(this);
  }

  buildURI() {
    return buildURI(...arguments);
  }

  getData = () => {
    const { asyncOnClick, data } = this.props;
    if (asyncOnClick && typeof data === "function") {
      return data();
    }
    return data;
  };

  handleLegacy() {
    const { headers, separator, filename, enclosingCharacter, uFEFF } =
      this.props;

    const csvData = this.getData();
    const csvContent = toCSV(csvData, headers, separator, enclosingCharacter);
    let blob = new Blob([uFEFF ? "\uFEFF" : "", csvContent]);
    var needsClick = true;
    if (window.webkitURL) {
      // Chrome
      var chromeLink = document.createElement("a");
      chromeLink.setAttribute(
        "href",
        "data:text/csv;charset=utf-8," + encodeURI(csvContent)
      );
      chromeLink.setAttribute("target", "_blank");
      chromeLink.setAttribute("download", filename);
      chromeLink.click();
    } else {
      if (document.createEvent) {
        // Firefox
        var firefoxLink = document.createElement("a");
        firefoxLink.setAttribute(
          "href",
          encodeURI("data:text/csv;charset=utf-8," + csvContent)
        );
        firefoxLink.setAttribute("download", filename);
        var e = document.createEvent("MouseEvents");
        e.initEvent("click", true, true);
        needsClick = firefoxLink.dispatchEvent(e);
      }
      if (needsClick) {
        if (window.navigator.msSaveBlob !== undefined) {
          // IE
          window.navigator.msSaveBlob(blob, filename);
        }
      }
    }
    return false;
  }

  handleAsyncClick(event) {
    const done = (proceed) => {
      if (proceed === false) {
        event.preventDefault();
        return;
      }
      this.handleLegacy(event);
    };
    this.props.onClick(event, done);
  }

  handleSyncClick(event) {
    const stopEvent = this.props.onClick(event) === false;
    if (stopEvent) {
      event.preventDefault();
      return;
    }
    this.handleLegacy(event);
  }

  handleClick() {
    return (event) => {
      if (typeof this.props.onClick === "function") {
        return this.props.asyncOnClick
          ? this.handleAsyncClick(event)
          : this.handleSyncClick(event);
      }
      this.handleLegacy(event);
    };
  }

  render() {
    const { children } = this.props;

    return <div onClick={this.handleClick()}>{children}</div>;
  }
}

export default CSVLink;
