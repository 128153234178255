import React from "react";
import { CheckboxWithLabel as MaterialCheckboxWithLabel } from "formik-mui";
import { Text } from "components";
import { Field } from "formik";

const CheckboxWithLabel = ({ label, ...rest }) => (
  <MaterialCheckboxWithLabel
    type="checkbox"
    color="primary"
    {...rest}
    Label={{
      label: (
        <Text variant="caption" color="secondary">
          {label}
        </Text>
      ),
    }}
  />
);

const Checkbox = ({ ...rest }) => (
  <Field component={CheckboxWithLabel} {...rest} />
);

export default Checkbox;
