import React, { useCallback, useMemo } from "react";
import theme from "theme/theme";
import { Grid, ActionButtons } from "components";
import { useGetUserQuery } from "services/api";

const GroupListGrid = React.memo(
  ({ onEditGroup, onRemoveGroup, onLoadMoreGroups, onClickRow, ...rest }) => {
    const { data: userInfo } = useGetUserQuery();
    const columnDefs = useMemo(
      () => [
        {
          field: "name",
          headerName: "Group Name",
          cellStyle: { color: theme.palette.text.primary },
        },
        {
          field: "owner",
          headerName: "Owner",
          cellStyle: { color: theme.palette.text.primary },
        },
        {
          field: "description",
          headerName: "Description",
          cellStyle: { color: theme.palette.text.primary },
        },
        {
          field: "leadsCount",
          headerName: "Leads",
          cellStyle: { color: theme.palette.text.primary },
        },
        {
          field: "usersCount",
          headerName: "Users",
          cellStyle: { color: theme.palette.text.primary },
        },
        {
          headerName: "Actions",
          cellRenderer: "actionButtonsCellRenderer",
          cellRendererParams: (params) => {
            if (params.data.createdBy === userInfo?.userId) {
              return {
                handleEdit: onEditGroup,
                handleRemove: onRemoveGroup,
              };
            } else {
              return {};
            }
          },
          field: "",
        },
      ],
      [onEditGroup, onRemoveGroup]
    );

    const createDataSource = useCallback(() => {
      return {
        getRows: async (params) => {
          try {
            const groups = await onLoadMoreGroups();
            params.success({
              rowData: groups,
              rowCount: groups?.length,
            });
          } catch (e) {
            params.fail();
          }
        },
      };
    }, []);

    const customComponents = useMemo(
      () => ({
        actionButtonsCellRenderer: ActionButtons,
      }),
      [ActionButtons]
    );

    const onSelectionChanged = useCallback(
      ({ api }) => {
        const selectedRows = api.getSelectedRows();
        onClickRow(selectedRows[0]);
      },
      [onClickRow]
    );

    return (
      <Grid
        columnDefs={columnDefs}
        customComponents={customComponents}
        createDataSource={createDataSource}
        options={{
          suppressCellFocus: true,
          rowSelection: "single",
          onSelectionChanged: onSelectionChanged,
        }}
        {...rest}
      />
    );
  }
);

GroupListGrid.displayName = "GroupListGrid";

export default GroupListGrid;
