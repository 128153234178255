import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  titleBox: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  buttonWrapper: {
    display: "flex",
    position: "absolute",
    right: 24,
    "& button:nth-child(1)": {
      marginRight: 8,
    },
    [theme.breakpoints.down("md")]: {
      "& > button > span": {
        display: "none",
      },
      "& > button > p": {
        fontSize: 12,
      },
    },
  },
  mr2: {
    marginRight: 8,
  },
  tableWrapper: {
    flex: 1,
    marginTop: 32,
  },
  snackbar: {
    position: "absolute",
    left: "30%",
    right: "30%",
    display: "block",
    transform: "translateX(0%) translateY(-150%)",
  },
  groupSettingsForm: {
    flex: 1,
    display: "flex",
  },
  backButtonWrapper: {
    display: "flex",
    position: "absolute",
    left: 24,
  },
}));

export default useStyles;
