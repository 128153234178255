import React from "react";
import { Formik, Form } from "formik";
import { Input, Button } from "components";
import * as yup from "yup";
import useStyles from "./ResetPasswordForm.style";

const initialValues = {
  password: "",
  confirmPassword: "",
};

const validationSchema = yup.object().shape({
  password: yup
    .string()
    .trim()
    .min(8, "Must be at least 8 characters long")
    .max(25, "Must be less than 25 characters long")
    .required("Password is required"),
  confirmPassword: yup
    .string()
    .trim()
    .min(8, "Must be at least 8 characters long")
    .max(25, "Must be less than 25 characters long")
    .required("Password is required")
    .oneOf([yup.ref("password"), null], "Password must match"),
});

const ResetPwdForm = ({ onSubmit, disabled, ...rest }) => {
  const classes = useStyles();

  return (
    <Formik
      onSubmit={onSubmit}
      {...rest}
      initialValues={initialValues}
      validationSchema={validationSchema}
    >
      {({ values, errors, touched, handleChange }) => (
        <Form className={classes.form}>
          <Input.Text
            label="New Password"
            placeholder="Enter your new password"
            inputId="password"
            touched={touched}
            values={values}
            errors={errors}
            handleChange={handleChange}
            disabled={disabled}
          />
          <Input.Text
            label="Confirm New Password"
            inputId="confirmPassword"
            placeholder="Confirm your new password"
            touched={touched}
            values={values}
            errors={errors}
            handleChange={handleChange}
            disabled={disabled}
          />
          <Button
            text="REST PASSWORD"
            type="submit"
            disabled={disabled}
            containerStyle={classes.resetBtn}
          />
        </Form>
      )}
    </Formik>
  );
};

export default ResetPwdForm;
