import { Avatar } from "components";
import React from "react";
import { Badge } from "@mui/material";
import { withStyles } from "@mui/styles";
import { BusinessUserStatusType } from "utils/constants";

const StyledBadge = withStyles((theme) => ({
  badge: {
    display: (props) => (props.hidebadge === "true" ? "none" : "block"),
    backgroundColor: (props) =>
      props.status === BusinessUserStatusType.INACTIVE
        ? theme.palette.badge.inactive
        : theme.palette.badge.active,
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    "&::after": {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      borderRadius: "50%",
      animation: "$ripple 1.2s infinite ease-in-out",
      border: (props) =>
        (props.status === BusinessUserStatusType.INACTIVE ? "0px" : "1px") +
        " solid currentColor",
      content: '""',
    },
  },
  "@keyframes ripple": {
    "0%": {
      transform: "scale(.8)",
      opacity: 1,
    },
    "100%": {
      transform: "scale(2.4)",
      opacity: 0,
    },
  },
}))(Badge);

const AvatarWithBadge = (props) => {
  return (
    <StyledBadge
      status={props.data?.status}
      hidebadge={props.hideBadge?.toString() ?? "false"}
      overlap="circular"
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      variant="dot"
    >
      <Avatar label={props.data?.fullName[0]} size={props.size} />
    </StyledBadge>
  );
};

export default React.memo(AvatarWithBadge);
