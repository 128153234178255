import React from "react";
import { List } from "@mui/material";
import NavMenuItem from "./NavMenuItem";
import NavCollapse from "./NavCollapse";
import { makeStyles } from "@mui/styles";
import Box from "@mui/material/Box";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  navSection: {
    position: "relative",
    fontWeight: theme.typography.fontWeightRegular,
    "&:last-child": {
      marginTop: "auto",
    },
    "&:not(:first-child):not(:last-child)::before": {
      display: "block",
      content: '""',
      marginLeft: 20,
      marginRight: 20,
      height: 0,
      borderBottom: `solid 1px ${theme.palette.sidebar.borderColor}`,
    },
  },
  navHeader: {
    position: "relative",
    padding: "0 16px 0 16px",
    textTransform: "uppercase",
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: 10,
    letterSpacing: 1.5,
    ".Cmt-miniLayout .Cmt-sidebar-content:not(:hover) &": {
      display: "none",
    },
  },
}));

const NavSection = (props) => {
  const { name, children = [] } = props;
  const isExpandable = children && children.length > 0;
  const classes = useStyles();

  const MenuCollapse = (
    <List component="div" className={clsx(classes.navHeader, "Cmt-navHeader")}>
      {name}
    </List>
  );

  const MenuItemChildren = isExpandable ? (
    <List component="div" disablePadding>
      {children.map((item, index) => {
        switch (item.type) {
          case "section":
            return <NavSection {...item} key={index} />;
          case "collapse":
            return <NavCollapse {...item} key={index} />;
          default:
            return <NavMenuItem {...item} key={index} />;
        }
      })}
    </List>
  ) : null;

  return (
    <Box className={classes.navSection}>
      {MenuCollapse}
      {MenuItemChildren}
    </Box>
  );
};

export default NavSection;
