import React from "react";
import { ConnectedRouter } from "connected-react-router";
import { Provider } from "react-redux";
import { Switch } from "react-router-dom";
import store, { history, persistor } from "./redux/store";
import Routes from "./routes";
import { AppWrapper, AppContextProvider } from "components";
import { PersistGate } from "redux-persist/integration/react";

import "react-perfect-scrollbar/dist/css/styles.css";
import "ag-grid-enterprise";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import "./App.css";

const App = () => (
  <AppContextProvider>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ConnectedRouter history={history}>
          <AppWrapper>
            <Switch>
              <Routes />
            </Switch>
          </AppWrapper>
        </ConnectedRouter>
      </PersistGate>
    </Provider>
  </AppContextProvider>
);

export default App;
