import { Campaign, Face, Gavel, Group } from "@mui/icons-material";
import theme from "theme/theme";
import { ROUTES } from "utils/constants";

export const getSettingsTabs = () => [
  {
    id: 0,
    avatarIcon: (
      <Face fontSize="large" htmlColor={theme.palette.primary.main} />
    ),
    title: "Profile information",
    subtitle: "Manage your personal data",
    link: ROUTES.MY_SETTINGS_PROFILE,
  },
  {
    id: 1,
    avatarIcon: (
      <Campaign fontSize="large" htmlColor={theme.palette.primary.main} />
    ),
    title: "Notifications",
    subtitle: "Define notifications and alerts",
    link: ROUTES.MY_SETTINGS_NOTIFICATIONS,
  },
  {
    id: 2,
    avatarIcon: (
      <Gavel fontSize="large" htmlColor={theme.palette.primary.main} />
    ),
    title: "Privacy and Terms of Use",
    subtitle: "Review your privacy settings and terms",
    link: ROUTES.MY_SETTINGS_PRIVACY_POLICY,
  },
];

export const getPrivacyAndTermsTabs = () => [
  {
    id: "privacy",
    label: "Privacy Policy",
    icon: <Gavel />,
    link: ROUTES.MY_SETTINGS_PRIVACY_POLICY,
  },
  {
    id: "terms",
    label: "Terms of Use",
    icon: <Group />,
    link: ROUTES.MY_SETTINGS_TERMS_USE,
  },
];
