import { ACCESS_TOKEN, REFRESH_TOKEN } from "utils/constants";
import { userLoginSuccess, userUpdateSuccess } from "redux/slices/User";
import { bugsnagNotify } from "services/bugsnag";
import { flatten } from "lodash";

const persistAuth = ({ accessToken, refreshToken, userId }) => {
  localStorage.setItem(ACCESS_TOKEN, accessToken);
  localStorage.setItem(REFRESH_TOKEN, refreshToken);
  localStorage.setItem("user_id", userId);
};

const userEndpoints = (builder) => ({
  emailSignUp: builder.mutation({
    query: ({
      firstName,
      lastName,
      email,
      password,
      groupInviteCode,
      groupId,
    }) => ({
      url: "/user/email_signup",
      method: "POST",
      data: {
        first_name: firstName,
        last_name: lastName,
        email,
        password,
        group_invite_code: groupInviteCode,
        group_id: groupId,
      },
    }),
    async onQueryStarted(_, { queryFulfilled }) {
      try {
        await queryFulfilled;
      } catch (e) {
        bugsnagNotify(
          new Error(e?.error?.data?.message ?? "User email signup failed."),
          e
        );
      }
    },
  }),
  login: builder.mutation({
    query: ({ email, password }) => ({
      url: "/user/login",
      method: "POST",
      data: { email, password },
    }),
    async onQueryStarted(_, { dispatch, queryFulfilled }) {
      try {
        const { data } = await queryFulfilled;
        persistAuth({
          accessToken: data.accessToken,
          refreshToken: data.refreshToken,
          userId: data.userId,
        });
        dispatch(userLoginSuccess(data));
      } catch (e) {
        bugsnagNotify(
          new Error(e?.error?.data?.message ?? "User login failed."),
          e
        );
      }
    },
    invalidatesTags: ["Users", "Activity"],
  }),
  linkedinLogin: builder.mutation({
    query: ({ code, state }) => ({
      url: "/user/linkedin_signup",
      method: "POST",
      data: { code, state },
    }),
    async onQueryStarted(_, { dispatch, queryFulfilled }) {
      try {
        const { data } = await queryFulfilled;
        persistAuth({
          accessToken: data.accessToken,
          refreshToken: data.refreshToken,
          userId: data.userId,
        });
        dispatch(userLoginSuccess(data));
      } catch (e) {
        bugsnagNotify(
          new Error(
            e?.error?.data?.message ?? "User login with LinkedIn failed"
          ),
          e
        );
      }
    },
    invalidatesTags: ["Users", "Activity"],
  }),
  googleLogin: builder.mutation({
    query: ({ idToken, profile, platform = "WEB" }) => ({
      url: "/user/google_signup",
      method: "POST",
      data: {
        id_token: idToken,
        first_name: profile.given_name,
        last_name: profile.family_name,
        platform,
      },
    }),
    async onQueryStarted(_, { dispatch, queryFulfilled }) {
      try {
        const { data } = await queryFulfilled;
        persistAuth({
          accessToken: data.accessToken,
          refreshToken: data.refreshToken,
          userId: data.userId,
        });
        dispatch(userLoginSuccess(data));
      } catch (e) {
        bugsnagNotify(
          new Error(e?.error?.data?.message ?? "User google login failed."),
          e
        );
      }
    },
    invalidatesTags: ["Users", "Activity"],
  }),
  appleLogin: builder.mutation({
    query: ({
      code,
      firstName,
      lastName,
      userIdentifier,
      identityToken,
      redirectUri,
    }) => ({
      url: "/user/apple_signup",
      method: "POST",
      data: {
        code,
        first_name: firstName,
        last_name: lastName,
        user_identifier: userIdentifier,
        identity_token: identityToken,
        redirect_uri: redirectUri,
        platform: "WEB",
      },
    }),
    async onQueryStarted(_, { dispatch, queryFulfilled }) {
      try {
        const { data } = await queryFulfilled;
        persistAuth({
          accessToken: data.accessToken,
          refreshToken: data.refreshToken,
          userId: data.userId,
        });
        dispatch(userLoginSuccess(data));
      } catch (e) {
        bugsnagNotify(
          new Error(e?.error?.data?.message ?? "User apple login failed."),
          e
        );
      }
    },
    invalidatesTags: ["Users", "Activity"],
  }),
  getLinkedinLoginUrl: builder.query({
    query: (state) => ({
      url: "/user/linkedin_url",
      method: "POST",
      data: { state },
    }),
    async onQueryStarted(_, { queryFulfilled }) {
      try {
        await queryFulfilled;
      } catch (e) {
        bugsnagNotify(
          new Error(
            e?.error?.data?.message ?? "Fetch user LinkedIn URL failed"
          ),
          e
        );
      }
    },
  }),
  userUpdate: builder.mutation({
    query: ({ user }) => ({
      url: "/user/update",
      method: "POST",
      data: { user },
    }),
    async onQueryStarted(_, { dispatch, queryFulfilled }) {
      try {
        const { data } = await queryFulfilled;
        dispatch(userUpdateSuccess(data));
      } catch (e) {
        bugsnagNotify(
          new Error(e?.error?.data?.message ?? "Update user failed"),
          e
        );
      }
    },
    invalidatesTags: (result, error, arg) => [
      { type: "Users", id: arg.userId },
    ],
  }),
  resetPassword: builder.mutation({
    query: ({ email }) => ({
      url: "/user/reset_password",
      method: "POST",
      data: { email },
    }),
    async onQueryStarted(_, { queryFulfilled }) {
      try {
        await queryFulfilled;
      } catch (e) {
        bugsnagNotify(
          new Error(e?.error?.data?.message ?? "Reset user password failed"),
          e
        );
      }
    },
  }),
  emailConfirmAction: builder.mutation({
    query: ({ token, password, action }) => ({
      url: "/user/email_confirmation_action/" + token,
      method: "POST",
      data: { token, action, password },
    }),
    async onQueryStarted(_, { dispatch, queryFulfilled }) {
      try {
        const { data } = await queryFulfilled;
        persistAuth({
          accessToken: data.accessToken,
          refreshToken: data.refreshToken,
          userId: data.userId,
        });
        console.error("emailConfirmAction", JSON.stringify(data));
        dispatch(userLoginSuccess(data));
      } catch (e) {
        console.error("emailConfirmAction error", JSON.stringify(e));
        bugsnagNotify(
          new Error(
            e?.error?.data?.message ?? "User email confirmation failed"
          ),
          e
        );
      }
    },
    invalidatesTags: ["Users", "Activity"],
  }),
  getUser: builder.query({
    query: (userId = null) => ({
      url: "/user/get",
      method: "POST",
      data: { user_id: userId },
    }),
    async onQueryStarted(_, { queryFulfilled }) {
      try {
        await queryFulfilled;
      } catch (e) {
        bugsnagNotify(
          new Error(e?.error?.data?.message ?? "User fetch failed."),
          e
        );
      }
    },
    providesTags: (result) => [{ type: "Users", id: result?.userId }],
  }),
  getUserList: builder.query({
    query: (userIds) => ({
      url: "/user/get_list",
      method: "POST",
      data: { user_ids: userIds },
    }),
    async onQueryStarted(_, { queryFulfilled }) {
      try {
        await queryFulfilled;
      } catch (e) {
        bugsnagNotify(
          new Error(e?.error?.data?.message ?? "User list fetch failed"),
          e
        );
      }
    },
    invalidatesTags: (result, error, arg) => {
      return flatten(arg.map((userId) => [{ type: "Users", id: userId }]));
    },
  }),
  searchUserByName: builder.query({
    query: ({ id, searchType, namePrefix }) => ({
      url: "/user/search_by_name",
      method: "POST",
      data: { id, search_type: searchType, name_prefix: namePrefix },
    }),
    async onQueryStarted(_, { queryFulfilled }) {
      try {
        await queryFulfilled;
      } catch (e) {
        bugsnagNotify(
          new Error(e?.error?.data?.message ?? "Search user by name failed"),
          e
        );
      }
    },
  }),
  getGroupNotificationSettings: builder.query({
    query: ({ userId, groupId }) => ({
      url: "/user/notification_settings",
      method: "POST",
      data: { user_id: userId, group_id: groupId },
    }),
    async onQueryStarted(_, { queryFulfilled }) {
      try {
        await queryFulfilled;
      } catch (e) {
        bugsnagNotify(
          new Error(
            e?.error?.data?.message ?? "Get group notification settings failed."
          ),
          e
        );
      }
    },
    providesTags: (result, error, { groupId }) => [
      { type: "GROUP_NOTIFICATION", id: groupId },
    ],
  }),
  updateGroupNotification: builder.mutation({
    query: ({ userId, groupId, enableEmail }) => ({
      url: "/user/update_notifications",
      method: "POST",
      data: { user_id: userId, group_id: groupId, enableEmail },
    }),
    async onQueryStarted(_, { queryFulfilled }) {
      try {
        await queryFulfilled;
      } catch (e) {
        bugsnagNotify(
          new Error(
            e?.error?.data?.message ?? "Update user notifications failed"
          ),
          e
        );
      }
    },
    invalidatesTags: (result, error, { groupId }) => [
      { type: "GROUP_NOTIFICATION", id: groupId },
    ],
  }),
  getUserListByGroup: builder.query({
    query: ({ groupId, startPos, getNext }) => ({
      url: "/user/get_users_by_group",
      method: "POST",
      data: { group_id: groupId, start_position: startPos, get_next: getNext },
    }),
    async onQueryStarted(_, { queryFulfilled }) {
      try {
        await queryFulfilled;
      } catch (e) {
        bugsnagNotify(
          new Error(
            e?.error?.data?.message ?? "Get user list by group failed."
          ),
          e
        );
      }
    },
  }),
  scheduleDeleteUser: builder.mutation({
    query: () => ({
      url: "/user/schedule_delete",
      method: "POST",
    }),
    async onQueryStarted(_, { queryFulfilled }) {
      try {
        await queryFulfilled;
      } catch (e) {
        bugsnagNotify(
          new Error(e?.error?.data?.message ?? "Schedule delete user failed."),
          e
        );
      }
    },
  }),
});

export default userEndpoints;
