import React from "react";
import useStyles from "components/Modals/index.style";
import PropTypes from "prop-types";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import { useRemoveBusinessUserMutation } from "services/api";
import { useGetBusinessUserQueryState } from "services/business";
import { PageLoader } from "components";
import { useSelector } from "react-redux";

const RemoveUser = ({ open, handleDialog, user }) => {
  const classes = useStyles();
  const userId = useSelector((state) => state.user.userId);
  const { data: bmUser, isLoading } = useGetBusinessUserQueryState(userId);
  const [removeBusinessUser] = useRemoveBusinessUserMutation();

  const handleSubmit = (user) => {
    const businessId = bmUser?.business?.businessId || 0;
    removeBusinessUser({ businessId, userId: user.userId });
    handleDialog();
  };

  if (isLoading) {
    return <PageLoader />;
  }

  return (
    <React.Fragment>
      <Dialog open={open}>
        <DialogTitle className={classes.dialogTitle}>REMOVE USER</DialogTitle>
        <DialogContent dividers>
          <Box className={classes.dialogWrapper}>
            <Box display="inline">
              Do you really want to
              <Typography
                color="error"
                component="span"
                className={classes.errorText}
              >
                {" "}
                remove {user.fullName}?
              </Typography>
            </Box>
            <Box
              display="flex"
              alignItems="flex-end"
              justifyContent="flex-end"
              mt={6}
            >
              <Button onClick={handleDialog}>Back</Button>
              <Box ml={2}>
                <Button
                  variant="contained"
                  type="submit"
                  onClick={() => handleSubmit(user)}
                  className={classes.redButton}
                >
                  REMOVE
                </Button>
              </Box>
            </Box>
          </Box>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
};

export default RemoveUser;

RemoveUser.propTypes = {
  open: PropTypes.bool.isRequired,
  handleDialog: PropTypes.func.isRequired,
  user: PropTypes.object,
};
