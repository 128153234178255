import { makeStyles } from "@mui/styles";
import theme from "theme/theme";

const useStyles = makeStyles(() => ({
  container: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  title: {
    display: "flex",
    fontSize: 14,
    color: theme.palette.common.dark,
    opacity: 0.45,
    marginLeft: 10,
  },
}));

export default useStyles;
