import React from "react";
import { Box, Typography } from "@mui/material";
import { Avatar } from "components";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  avatar: {
    border: "1px solid ##333333BF",
  },
  username: {
    marginLeft: 8,
  },
}));

const UserAvatar = ({ userId, firstName, lastName }) => {
  const classes = useStyles();
  const label = firstName && lastName ? `${firstName[0]}${lastName[0]}` : "";
  return (
    <Box flexDirection="row" display="flex" alignItems="center">
      <Avatar userId={userId} label={label} size={36} />
      <Typography
        color="textPrimary"
        className={classes.username}
        variant="body2"
      >
        {firstName} {lastName}
      </Typography>
    </Box>
  );
};

export default UserAvatar;
