import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  titleBox: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  buttonWrapper: {
    display: "flex",
    position: "absolute",
    right: 24,
    "& button:nth-child(1)": {
      marginRight: 8,
    },
    [theme.breakpoints.down("md")]: {
      "& > button > span": {
        display: "none",
      },
      "& > button > p": {
        fontSize: 12,
      },
    },
  },
  snackbar: {
    position: "absolute",
    left: "30%",
    right: "30%",
    display: "block",
    transform: "translateX(0%) translateY(-150%)",
  },
  formikForm: {
    flex: 1,
    display: "flex",
  },
  legacyContainer: {
    position: "relative",
    paddingLeft: 24,
    paddingRight: 24,
    marginTop: 16,
    height: "100%",
    [theme.breakpoints.down("md")]: {
      height: "calc(100% - 280px)",
    },
  },
  legacyWrapper: {
    overflow: "auto",
    position: "absolute",
    left: 24,
    right: 24,
    top: 96,
    bottom: 0,
  },
  paragraph: {
    lineHeight: 2.4,
    flexWrap: "wrap",
    fontSize: 16,
    paddingTop: 16,
    paddingBottom: 16,
  },
}));

export default useStyles;
