import React, { useCallback, useMemo } from "react";
import theme from "theme/theme";
import ActionButtons from "./ActionButtons";
import { Grid } from "components";

const InvoicesGrid = React.memo(({ onLoadMoreInvoices, ...rest }) => {
  const cellStyle = useCallback(
    () => ({ color: theme.palette.text.primary }),
    []
  );
  const columnDefs = useMemo(
    () => [
      {
        field: "",
        width: 60,
      },
      { field: "invoiceNumber", headerName: "Invoice Number", cellStyle },
      {
        field: "billingPeriod",
        headerName: "Billing Period",
        cellStyle,
        width: 250,
      },
      { field: "amountPaid", headerName: "Amount", cellStyle },
      {
        field: "paymentInfo",
        headerName: "Payment Information",
        cellStyle,
        width: 250,
      },
      {
        field: "",
        headerName: "Action",
        cellRenderer: "actionButtonsCellRenderer",
      },
    ],
    [cellStyle]
  );

  const createDataSource = useCallback(
    () => ({
      getRows: async (params) => {
        try {
          const invoices = await onLoadMoreInvoices();
          params.success({
            rowData: invoices,
            rowCount: invoices.length,
          });
        } catch (e) {
          params.fail();
        }
      },
    }),
    [onLoadMoreInvoices]
  );

  const customComponents = useMemo(
    () => ({
      actionButtonsCellRenderer: ActionButtons,
    }),
    []
  );

  return (
    <Grid
      columnDefs={columnDefs}
      customComponents={customComponents}
      createDataSource={createDataSource}
      {...rest}
    ></Grid>
  );
});

InvoicesGrid.displayName = "InvoicesGrid";

export default InvoicesGrid;
