import React from "react";
import { Box } from "@mui/material";
import Channel from "./Channel";
import Panel from "./Panel";

const Message = () => {
  return (
    <Box>
      <Channel />
      <Panel />
    </Box>
  );
};

export default Message;
