import React, { forwardRef } from "react";
import { Field } from "formik";
import { TextField } from "formik-mui";
import PropTypes from "prop-types";
import clsx from "clsx";
import useStyles from "./Input.style";

const TextInput = forwardRef(
  (
    {
      children,
      inputId,
      touched,
      label,
      values,
      errors,
      handleChange,
      handleBlur,
      secureTextEntry,
      className,
      variant = "outlined",
      color = "primary",
      disabled,
      ...props
    },
    ref
  ) => {
    const classes = useStyles();
    return (
      <Field
        fullWidth
        inputRef={ref}
        component={TextField}
        name={inputId}
        label={label}
        className={clsx(classes.textInput, className)}
        value={values[inputId]}
        onChange={handleChange(inputId)}
        onBlur={handleBlur(inputId)}
        error={touched[inputId] && !!errors[inputId]}
        helperText={touched[inputId] ? errors[inputId] : null}
        type={secureTextEntry ? "password" : "text"}
        variant={variant}
        color={color}
        disabled={disabled}
        {...props}
      >
        {children}
      </Field>
    );
  }
);

TextInput.propTypes = {
  inputId: PropTypes.string.isRequired,
  touched: PropTypes.object,
  label: PropTypes.string,
  values: PropTypes.object.isRequired,
  errors: PropTypes.object,
  handleChange: PropTypes.func.isRequired,
  handleBlur: PropTypes.func,
  secureTextEntry: PropTypes.bool,
  variant: PropTypes.oneOf(["outlined", "standard", "filled"]),
  color: PropTypes.oneOf(["primary", "secondary", "error"]),
  disabled: PropTypes.bool,
};

TextInput.defaultProps = {
  label: "",
  touched: {},
  errors: {},
  handleBlur: () => {},
  secureTextEntry: false,
  variant: "outlined",
  color: "primary",
  disabled: false,
};

TextInput.displayName = "TextInput";

export default TextInput;
