import React, { useEffect, useState } from "react";
import { Alert, Box, Dialog, DialogContent, DialogTitle } from "@mui/material";
import { Form, Formik } from "formik";
import * as yup from "yup";
import { Input, Button } from "components";
import useStyles from "./index.style";
import {
  useCreatePrivateGroupMutation,
  useUpdateGroupMutation,
  useUpdateGroupUserMutation,
} from "services/api";

const schema = yup.object().shape({
  name: yup
    .string()
    .trim()
    .required("Group name is required")
    .min(3, "Must be at least 3 characters long")
    .max(40, "Must be less than 40 characters long"),
  description: yup
    .string()
    .trim()
    .required("Description is required")
    .min(3, "Must be at least 3 characters long")
    .max(200, "Must be less than 200 characters long"),
  product: yup
    .string()
    .trim()
    .optional()
    .min(3, "Must be at least 3 characters long")
    .max(100, "Must be less than 100 characters long"),
});

const AddEditGroup = ({ open, onClose, onSuccess, group, userId }) => {
  const dialogType = group ? "EDIT" : "ADD";
  const dialogTitle = dialogType === "ADD" ? "Create group" : "Edit group";
  const submitBtnTitle = dialogType === "ADD" ? "CREATE GROUP" : "SAVE GROUP";
  const isOwner = !!group && group.createdBy === userId;
  let initialValues = null;
  if (dialogType === "ADD") {
    initialValues = {
      name: "",
      description: "",
      product: "",
    };
  } else if (dialogType === "EDIT") {
    initialValues = {
      name: group.name,
      description: group.description,
      product: group.users?.find((u) => u.userId === userId)?.product,
    };
  }
  const classes = useStyles();
  const [
    createPrivateGroup,
    { isSuccess: createGroupSuccess, error: createError },
  ] = useCreatePrivateGroupMutation();
  const [updateGroup, { isSuccess: editGroupSuccess, error: editGroupError }] =
    useUpdateGroupMutation();
  const [
    updateGroupUser,
    { isSuccess: editGroupUserSuccess, error: editGroupUserError },
  ] = useUpdateGroupUserMutation();
  const [error, setError] = useState("");
  const handleSubmit = ({ name, description, product }) => {
    if (dialogType === "ADD") {
      createPrivateGroup({ name, description, product });
    } else {
      updateGroup({ groupId: group.groupId, name, description });
      updateGroupUser({
        groupId: group.groupId,
        userId,
        product,
        userRole: group.users.find((u) => u.userId === userId).userRole,
      });
    }
  };
  useEffect(() => {
    if (createGroupSuccess || (editGroupSuccess && editGroupUserSuccess)) {
      onSuccess();
    }
  }, [createGroupSuccess, editGroupSuccess, editGroupUserSuccess]);

  useEffect(() => {
    if (createError) {
      setError(createError.data?.message);
    } else if (editGroupError) {
      setError(editGroupError.data?.message);
    } else if (editGroupUserError) {
      setError(editGroupUserError.data?.message);
    }
  }, [createError, editGroupError, editGroupUserError]);

  return (
    <Dialog open={open}>
      <DialogTitle className={classes.dialogTitle}>{dialogTitle}</DialogTitle>
      <DialogContent dividers>
        <Formik
          initialValues={initialValues}
          validationSchema={schema}
          onSubmit={handleSubmit}
        >
          {({ values, errors, touched, handleChange }) => (
            <Form className={classes.dialogWrapper}>
              <Input.Text
                label="What is your group name?"
                inputId="name"
                values={values}
                errors={errors}
                touched={touched}
                handleChange={handleChange}
                disabled={group && !isOwner}
              />
              <Input.Text
                label="What is your group description?"
                inputId="description"
                values={values}
                errors={errors}
                touched={touched}
                handleChange={handleChange}
                disabled={group && !isOwner}
              />
              <Input.Text
                label="What product are you selling? (optional)"
                inputId="product"
                values={values}
                errors={errors}
                touched={touched}
                handleChange={handleChange}
              />
              {!!error && <Alert severity="error">{error}</Alert>}
              <Box
                display="flex"
                alignItems="flex-end"
                justifyContent="flex-end"
                mt={4}
              >
                <Button
                  text="Back"
                  fullWidth={false}
                  variant="text"
                  color="secondary"
                  onClick={onClose}
                />
                <Button
                  text={submitBtnTitle}
                  color="success"
                  fullWidth={false}
                  type="submit"
                />
              </Box>
            </Form>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  );
};

export default AddEditGroup;
