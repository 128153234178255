import React, { forwardRef } from "react";
import { Input } from "components";
import PropTypes from "prop-types";

const TextArea = forwardRef(({ rows, ...restProps }, ref) => (
  <Input.Text ref={ref} rows={rows} multiline={true} {...restProps} />
));

TextArea.propTypes = {
  inputId: PropTypes.string.isRequired,
  label: PropTypes.string,
  values: PropTypes.object.isRequired,
  errors: PropTypes.object,
  handleChange: PropTypes.func.isRequired,
  handleBlur: PropTypes.func,
  secureTextEntry: PropTypes.bool,
  variant: PropTypes.oneOf(["outlined", "standard", "filled"]),
  color: PropTypes.oneOf(["primary", "secondary", "error"]),
  disabled: PropTypes.bool,
  rows: PropTypes.number,
};

TextArea.defaultProps = {
  label: "",
  errors: {},
  handleBlur: () => {},
  secureTextEntry: false,
  variant: "outlined",
  color: "primary",
  disabled: false,
  rows: 4,
};

TextArea.displayName = "TextArea";

export default TextArea;
