import React from "react";
import { Box, Link } from "@mui/material";
import { Text } from "components";
import useStyles from "./LegalFooter.style";
import moment from "moment";
import { ROUTES } from "utils/constants";
import theme from "theme/theme";

const LegalFooter = ({ showAgreements = true }) => {
  const classes = useStyles();
  const year = moment().format("YYYY");

  return (
    <Box className={classes.container}>
      {showAgreements && (
        <Box className={classes.termsAndConditionsText}>
          <Text className={classes.text}>
            BY SIGNING UP YOU ARE AGREE WITH OUR&nbsp;
          </Text>
          <Link
            className={classes.linkText}
            target="_blank"
            href={ROUTES.USERAGREEMENT}
            color={theme.palette.common.lightGray}
          >
            USER AGREEMENT
          </Link>
          <Text className={classes.text}>&nbsp;AND&nbsp;</Text>
          <Link
            className={classes.linkText}
            href={ROUTES.PRIVACYPOLICY}
            target="_blank"
            color={theme.palette.common.lightGray}
          >
            PRIVACY POLICY
          </Link>
        </Box>
      )}
      <Text className={classes.text}>
        {`Copyright © ${year} Alliance App, Inc. - All Rights Reserved`}
      </Text>
    </Box>
  );
};

export default LegalFooter;
