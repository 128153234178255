import { ArrowBackIosNew } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import React from "react";
import useStyles from "./BackButton.style";
import PropTypes from "prop-types";

const BackButton = ({ onClick }) => {
  const classes = useStyles();
  return (
    <IconButton onClick={onClick} className={classes.container}>
      <ArrowBackIosNew />
    </IconButton>
  );
};

BackButton.propTypes = {
  onClick: PropTypes.func,
};

export default BackButton;
