import React, { useState } from "react";
import Box from "@mui/material/Box";
import { Button } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Redirect } from "react-router";
import { ROUTES } from "utils/constants";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
  },
  errorNumber: {
    color: theme.palette.text.primary,
    fontWeight: 800,
    lineHeight: 1,
    marginBottom: 30,
    textShadow: "10px 6px 8px hsla(0,0%,45.9%,.8)",
  },
  button: {
    "&:hover": {
      backgroundColor: "#013E6E",
    },
  },
}));

const Error404 = () => {
  const classes = useStyles();
  const [goLanding, setLanding] = useState(false);

  const handleClick = () => {
    setLanding(true);
  };

  if (goLanding) {
    return <Redirect to={ROUTES.REDIRECT} />;
  }

  return (
    <Box className={classes.root}>
      <Box fontSize={{ xs: 100, sm: 160 }} className={classes.errorNumber}>
        404
      </Box>
      <Box fontSize={{ xs: 16, sm: 24 }} mb={8} color="grey.500">
        Page not found
      </Box>
      <Box mt={8}>
        <Button
          className={classes.button}
          variant="contained"
          color="primary"
          onClick={handleClick}
        >
          Go Home
        </Button>
      </Box>
    </Box>
  );
};

export default Error404;
