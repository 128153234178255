import React, { useCallback, useMemo } from "react";
import PropTypes from "prop-types";
import { Grid } from "components";
import UserActionButtons from "./UserActionButtons";
import StatusCell from "./StatusCell";
import theme from "theme/theme";
import { BusinessUserStatusType } from "utils/constants";

const UsersByBusinessGrid = React.memo(
  ({ onEditUser, onRemoveUser, onLoadMoreUsers, ...rest }) => {
    const textCellStyle = useCallback((params) => {
      if (params.data?.status === BusinessUserStatusType.INACTIVE) {
        return { color: theme.palette.text.secondary };
      } else {
        return { color: theme.palette.text.primary };
      }
    }, []);

    const columnDefs = useMemo(
      () => [
        {
          field: "",
          maxWidth: 60,
          cellRendererSelector: () => {
            return {
              component: "avatarCellRenderer",
              params: {
                size: 40,
              },
            };
          },
        },
        { field: "fullName", headerName: "Name", cellStyle: textCellStyle },
        {
          field: "email",
          headerName: "E-mail Address",
          cellStyle: textCellStyle,
          width: 300,
        },
        { field: "role", headerName: "Role", cellStyle: textCellStyle },
        { field: "status", headerName: "Status", cellRenderer: "statusCell" },
        {
          headerName: "Actions",
          cellRenderer: "userActionButtonsCellRenderer",
          cellRendererParams: {
            handleEditUser: (user) => onEditUser(user),
            handleRemoveUser: (user) => onRemoveUser(user),
          },
          field: "",
        },
      ],
      [onEditUser, onRemoveUser, textCellStyle]
    );

    const createDataSource = useCallback(() => {
      return {
        getRows: async (params) => {
          try {
            const bmUsers = await onLoadMoreUsers();
            params.success({
              rowData: bmUsers
                ? bmUsers?.businessUsers.map((bu) => ({
                    ...bu,
                    email: bu.user?.email,
                    fullName: `${bu.user?.firstName} ${bu.user?.lastName}`,
                  }))
                : [],
              rowCount: bmUsers?.totalCount ?? 0,
            });
          } catch (e) {
            params.fail();
          }
        },
      };
    }, [onLoadMoreUsers]);

    const customComponents = useMemo(
      () => ({
        userActionButtonsCellRenderer: UserActionButtons,
        statusCell: StatusCell,
      }),
      []
    );

    return (
      <Grid
        columnDefs={columnDefs}
        customComponents={customComponents}
        createDataSource={createDataSource}
        {...rest}
      />
    );
  }
);

UsersByBusinessGrid.propTypes = {
  onEditUser: PropTypes.func.isRequired,
};

UsersByBusinessGrid.displayName = "UsersByBusinesGrid";

export default UsersByBusinessGrid;
