import React from "react";
import {
  FormControlLabel,
  FormGroup,
  Switch as MuiSwitch,
} from "@mui/material";
import { Text } from "components";
import useStyles from "./Switch.style";
import PropTypes from "prop-types";
import clsx from "clsx";

const Switch = ({ title, value, setValue, containerStyle }) => {
  const classes = useStyles({ active: value });
  return (
    <FormGroup className={clsx(classes.container, containerStyle)}>
      <FormControlLabel
        label={<Text className={classes.text}>{title}</Text>}
        control={
          <MuiSwitch
            checked={value}
            onChange={(e) => setValue(e.target.checked)}
          />
        }
      />
    </FormGroup>
  );
};

Switch.propTypes = {
  containerStyle: PropTypes.string,
  setValue: PropTypes.func,
  value: PropTypes.bool,
  title: PropTypes.string,
};

Switch.defaultProps = {
  setValue: () => {},
  value: false,
};

export default Switch;
