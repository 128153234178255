import { bugsnagNotify } from "services/bugsnag";

const chatEndpoints = (builder) => ({
  getChatLeadListMessages: builder.query({
    query: ({ id, startPos, getNext }) => ({
      url: "/chat/lead/list",
      method: "POST",
      data: { id, start_position: startPos, get_next: getNext },
    }),
    async onQueryStarted(_, { queryFulfilled }) {
      try {
        await queryFulfilled;
      } catch (e) {
        bugsnagNotify(
          new Error(
            e?.error?.data?.message ?? "Fetching chat list lead messages failed"
          ),
          e
        );
      }
    },
  }),
  sendChatLeadMessage: builder.mutation({
    query: ({ roomId, message }) => ({
      url: "/chat/message/send",
      method: "POST",
      data: { room_id: roomId, message },
    }),
    async onQueryStarted(_, { queryFulfilled }) {
      try {
        await queryFulfilled;
      } catch (e) {
        bugsnagNotify(
          new Error(
            e?.error?.data?.message ?? "Submit chat lead message failed."
          ),
          e
        );
      }
    },
  }),
  getChatRoomList: builder.query({
    query: ({ groupingLevel, groupingLevelId, limit, offset }) => ({
      url: "/chat/room/list",
      method: "POST",
      data: {
        grouping_level: groupingLevel,
        grouping_level_id: groupingLevelId,
        limit,
        offset,
      },
    }),
    async onQueryStarted(_, { queryFulfilled }) {
      try {
        await queryFulfilled;
      } catch (e) {
        bugsnagNotify(
          new Error(e?.error?.data?.message ?? "Fetch chat room list failed."),
          e
        );
      }
    },
  }),
  getChatRoomDetails: builder.query({
    query: (roomId) => ({
      url: "/chat/room/get",
      method: "POST",
      data: { room_id: roomId },
    }),
    async onQueryStarted(_, { queryFulfilled }) {
      try {
        await queryFulfilled;
      } catch (e) {
        bugsnagNotify(
          new Error(
            e?.error?.data?.message ?? "Fetch chat room details failed."
          ),
          e
        );
      }
    },
  }),
  getChatRoomMessageList: builder.query({
    query: ({ roomId, listMessageType, offset, limit }) => ({
      url: "/chat/message/list",
      method: "POST",
      data: {
        room_id: roomId,
        list_message_type: listMessageType,
        offset,
        limit,
      },
    }),
    async onQueryStarted(_, { queryFulfilled }) {
      try {
        await queryFulfilled;
      } catch (e) {
        bugsnagNotify(
          new Error(
            e?.error?.data?.message ?? "Fetch chat room messages failed."
          ),
          e
        );
      }
    },
  }),
});

export default chatEndpoints;
