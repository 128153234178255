import React from "react";
import { Box, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import QRCode from "react-qr-code";
import { CmtImage, AuthWrapper, MobileStoreButton } from "components";

const useStyles = makeStyles((theme) => ({
  container: {
    padding: 52,
  },
  woopsie: {
    fontSize: 32,
    marginBottom: 16,
  },
  QRContainer: {
    marginTop: 32,
    display: "flex",
    justifyContent: "space-around",
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
    },
  },
  logoImg: {
    width: "40%",
    marginBottom: 16,
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
  storeWrapper: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.down("xs")]: {
      "&:nth-child(2)": {
        marginTop: 64,
      },
    },
  },
}));

const appStoreLink = "https://apps.apple.com/US/app/id1585015904?mt=8";
const playStoreLink =
  "https://play.google.com/store/apps/details?id=com.osralliance.app";

const DownloadApp = () => {
  const classes = useStyles();

  return (
    <AuthWrapper>
      <Box className={classes.container}>
        <CmtImage
          src={"/images/Alliance-logo-h-full-color.png"}
          className={classes.logoImg}
        />
        <Typography className={classes.woopsie}>Next step:</Typography>
        <Typography>
          Download and sign up on the Alliance mobile app. Download through your
          app store here:
        </Typography>
        <Box className={classes.QRContainer}>
          <Box className={classes.storeWrapper}>
            <QRCode value={appStoreLink} size={135} />
            <MobileStoreButton store="ios" url={appStoreLink} />
          </Box>
          <Box className={classes.storeWrapper}>
            <QRCode value={playStoreLink} size={135} />
            <MobileStoreButton store="android" url={playStoreLink} />
          </Box>
        </Box>
      </Box>
    </AuthWrapper>
  );
};

export default DownloadApp;
