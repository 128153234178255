import React, { useEffect, useMemo, useState } from "react";
import { PageLoader } from "components";
import { useLinkedinLoginMutation } from "services/api";
import { ROUTES } from "utils/constants";
import { useLocation, useHistory } from "react-router-dom";

const LinkedInSignIn = () => {
  const { search } = useLocation();
  const { replace } = useHistory();
  const [linkedinLogin] = useLinkedinLoginMutation();
  const [isLoading, setIsLoading] = useState(true);

  const { code, state } = useMemo(() => {
    return {
      code: new URLSearchParams(search).get("code"),
      state: new URLSearchParams(search).get("state"),
    };
  }, [search]);

  useEffect(() => {
    if (!code || !state) return;
    const login = async () => {
      try {
        const response = await linkedinLogin({ code, state });
        if (!response.error) {
          const data = JSON.parse(decodeURIComponent(state));
          if (data.inviteId && data.inviteCode && data.inviteAction) {
            const { inviteId, inviteCode, inviteAction } = data;
            localStorage.setItem("inviteId", inviteId);
            localStorage.setItem("inviteCode", inviteCode);
            localStorage.setItem("inviteAction", inviteAction);
          }
        } else {
          replace(ROUTES.SIGN_IN, { linkedInLoginError: response.error });
        }
      } catch (error) {
        console.error("Error while LinkedIn login: ", error);
        replace(ROUTES.SIGN_IN, { linkedInLoginError: error.message });
      } finally {
        setIsLoading(false);
      }
    };
    login();
  }, [linkedinLogin, code, state, replace]);

  return isLoading ? <PageLoader /> : null;
};

export default LinkedInSignIn;
