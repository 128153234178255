import React from "react";
import PropTypes from "prop-types";
import useStyles from "components/Modals/index.style";
import { Box } from "@mui/material";
import CommonDialog from "./CommonDialog";

const Help = ({ open, handleDialogClose }) => {
  const classes = useStyles();
  const buttons = [
    {
      text: "OK",
      onClick: handleDialogClose,
      variant: "contained",
      color: "primary",
    },
  ];

  const getText = () => {
    return (
      <Box component="span">
        For help or tech support please reach out to{" "}
        <b>support@allianceapp.com</b>
      </Box>
    );
  };

  return (
    <CommonDialog
      id="support"
      title="SUPPORT"
      text={getText()}
      open={open}
      handleClose={handleDialogClose}
      dialogContentClass={classes.dialogContent}
      buttons={buttons}
    />
  );
};

export default Help;

Help.propTypes = {
  open: PropTypes.bool.isRequired,
  handleDialogClose: PropTypes.func.isRequired,
};
