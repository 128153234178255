import createBreakpoints from "@mui/system/createTheme/createBreakpoints";

const breakpoints = createBreakpoints({
  values: {
    xs: 0,
    sm: 600,
    md: 960,
    lg: 1280,
    xl: 1920,
  },
});

const theme = {
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
  spacing: 4,
  direction: "ltr",
  palette: {
    mode: "light",
    badge: {
      active: "#32A74C",
      inactive: "rgba(51, 51, 51, 0.75)",
    },
    common: {
      black: "#000",
      white: "#fff",
      dark: "#333333",
      blue: "#1975D8",
      lightBlue: "#1975D83D",
      gray: "#DADADA",
      lightGray: "#333333BF",
    },
    primary: {
      light: "#BBDEFD",
      main: "#1764C6",
      dark: "#115293",
      contrastText: "#fff",
    },
    secondary: {
      main: "#333333BF",
      dark: "#018786",
      contrastText: "#fff",
    },
    terciary: {
      main: "#D4E1F1",
    },
    quaternary: {
      main: "#C4D7E6",
    },
    disable: {
      light: "#F1F1F2",
      main: "#86A6BE",
    },
    success: {
      light: "#D7F5B1",
      main: "#54B467",
      dark: "#1E8738",
    },
    info: {
      light: "#62B5FB",
      main: "#1B96FA",
      dark: "#0356AF",
    },
    warning: {
      light: "#FFDE99",
      main: "#FF8C00",
      dark: "#D36F1A",
    },
    error: {
      light: "#FFC7D1",
      main: "#B45454",
      dark: "#87061E",
    },
    sidebar: {
      bgColor: "#fff",
      textColor: "rgba(51, 51, 51, 0.5)",
      textDarkColor: "#1975D8",
      textActiveColor: "#1975D8",
      borderColor: "#C4C4C4",
    },
    horizontalNav: {
      navigationColor: "rgba(255, 255, 255, 0.74)",
      navigationActiveColor: "rgba(255, 255, 255, 1)",
      textColor: "rgba(0, 0, 0, 0.6)",
      textDarkColor: "rgba(0, 0, 0, 0.87)",
      textActiveColor: "#6200EE",
      menuHoverBgColor: "rgb(229, 229, 229)",
      menuActiveBgColor: "rgb(239, 229, 253)",
    },
    background: {
      paper: "#FFFFFF",
      lightGray: "#F7F7F7",
      default: "#f4f4f7",
    },
    text: {
      primary: "#001845",
      secondary: "rgba(51, 51, 51, 0.75)",
      disabled: "rgba(0, 0, 0, 0.38)",
      hint: "rgba(0, 0, 0, 0.3)",
      white: "#fff",
    },
    btn: {
      hover: "rgba(0, 0, 0, 0.08)",
    },
    lightBtn: {
      bgColor: "#f5f5f5",
      textColor: "rgba(0, 0, 0, 0.38)",
    },
    borderColor: {
      main: "rgba(0, 0, 0, 0.06)",
      dark: "rgba(0, 0, 0, 0.12)",
    },
    popupColor: {
      main: "#fff",
    },
    header: {
      main: "#fff",
    },
  },
  status: {
    danger: "orange",
  },
  statics: {
    headerHeight: 70,
  },
  typography: {
    fontFamily: "Poppins, sans-serif",
    fontWeightExtraLight: 200,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightBold: "bold",
    fontWeightExtraBold: 800,
    color: "#001845",
    h1: {
      fontSize: 20,
      fontWeight: "bold",
      [breakpoints.up("md")]: {
        fontSize: 22,
      },
    },
    h2: {
      fontSize: 18,
      fontWeight: "bold",
      [breakpoints.up("md")]: {
        fontSize: 20,
      },
    },
    h3: {
      fontSize: 16,
      fontWeight: "bold",
      [breakpoints.up("md")]: {
        fontSize: 18,
      },
    },
    h4: {
      fontSize: 16,
      fontWeight: "bold",
    },
    h5: {
      fontSize: 14,
      fontWeight: 400,
    },
    h6: {
      fontSize: 14,
      fontWeight: "bold",
      letterSpacing: 0.5,
    },
    subtitle1: {
      fontSize: 16,
      fontWeight: 400,
      letterSpacing: 0.15,
    },
    subtitle2: {
      fontSize: 12,
      fontWeight: 400,
      letterSpacing: 1.5,
      lineHeight: 1.6,
    },
    body1: {
      fontSize: 16,
      fontWeight: 400,
      letterSpacing: 0.5,
    },
    body2: {
      fontSize: 14,
      fontWeight: 400,
      letterSpacing: 0.25,
    },
  },
  components: {
    MuiTypography: {
      styleOverrides: {
        root: {
          textTransform: "none",
        },
      },
    },
    MuiButton: {
      disableElevation: true,
      styleOverrides: {
        root: {
          fontWeight: 500,
          letterSpacing: 1.25,
          fontSize: 14,
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        underlineHover: {
          color: "#333333E5",
          fontSize: 14,
          fontWeight: 400,
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          fontWeight: 600,
          color: "#333333",
        },
      },
    },
    MuiToggleButton: {
      styleOverrides: {
        root: {
          borderRadius: 4,
        },
      },
    },
    MuiCardLg: {
      styleOverrides: {
        root: {
          borderRadius: 10,
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          borderRadius: 4,
          boxShadow:
            "0px 1px 3px rgba(0, 0, 0, 0.2), 0px 2px 1px rgba(0, 0, 0, 0.12), 0px 1px 1px rgba(0, 0, 0, 0.14)",
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        textColorPrimary: {
          color: "rgba(0, 0, 0, 0.87)",
        },
      },
    },
    MuiPopover: {
      styleOverrides: {
        paper: {
          backgroundColor: "#FFFFFF",
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          backgroundColor: "#FFFFFF",
          maxWidth: 1200,
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        colorPrimary: {
          color: "#001845",
        },
      },
    },
    MuiFormControl: {
      styleOverrides: {
        marginNormal: {
          marginBottom: 20,
        },
      },
    },
  },
};

export default theme;
