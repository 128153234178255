import { makeStyles } from "@mui/styles";
import theme from "theme/theme";

const useStyles = makeStyles(() => ({
  cardContainer: {
    padding: 8,
    marginBottom: 24,
  },
  cardTitle: {
    fontSize: 28,
  },
  cardSubtitle: {
    fontSize: 16,
  },
  buttonContainer: {
    marginTop: 8,
    marginBottom: 8,
    padding: 0,
    justifyContent: "flex-start",
  },
  button: {
    color: theme.palette.primary.main,
  },
  notesContainer: {
    marginTop: 24,
  },
  noteText: {
    fontSize: 12,
    letterSpacing: 1,
    whiteSpace: "pre-line",
  },
}));

export default useStyles;
