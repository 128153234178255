import React, { useCallback, useEffect, useRef, useState } from "react";
import useStyles from "../Group.style";
import {
  PageLoader,
  Container,
  PageTitle,
  Button,
  GroupListGrid,
} from "components";
import { useGetBusinessUserQueryState } from "services/business";
import {
  api,
  useGetBillingQuery,
  useGetUserQuery,
  useRemoveGroupMutation,
} from "services/api";
import { Box } from "@mui/material";
import { CreateNewFolder } from "@mui/icons-material";
import AddEditGroup from "components/Modals/AddEditGroup";
import RemoveDialog from "components/Modals/RemoveDialog";
import { useHistory } from "react-router-dom";
import { getRedirectUrl, ROUTES } from "utils/constants";

const GroupList = () => {
  const classes = useStyles();
  const [showAddEditGroup, setShowAddEditGroup] = useState(false);
  const { data: userInfo } = useGetUserQuery();
  const userId = userInfo?.userId;
  const { isLoading: isBmUserLoading } = useGetBusinessUserQueryState(userId, {
    skip: !userId,
  });
  const { data: billingInfo, isLoading: billingLoading } = useGetBillingQuery();
  const [triggerGetGroupList] =
    api.endpoints.getGroupList.useLazyQuerySubscription({
      refetchOnFocus: true,
      refetchOnReconnect: true,
    });
  const [triggerGetLeadGroupStats] =
    api.endpoints.getLeadGroupStats.useLazyQuerySubscription({
      refetchOnFocus: true,
      refetchOnReconnect: true,
    });
  const [triggerGetUserList] =
    api.endpoints.getUserList.useLazyQuerySubscription({
      refetchOnFocus: true,
      refetchOnReconnect: true,
    });
  const [showRemoveDialog, setShowRemoveDialog] = useState(false);
  const [currentGroup, setCurrentGroup] = useState(null);
  const [refreshGrid, setRefreshGrid] = useState(false);
  const [removeGroup, { isLoading: isRemoving, isSuccess: isRemovedGroup }] =
    useRemoveGroupMutation();
  const history = useHistory();
  const startPosRef = useRef(0);
  const totalCountRef = useRef(0);

  useEffect(() => {
    if (isRemovedGroup) {
      handleCloseRemoveDialog();
      setTimeout(() => {
        setRefreshGrid(true);
      }, 500);
    }
  }, [isRemovedGroup]);

  const onLoadMoreGroups = useCallback(async () => {
    try {
      let groupsResp = await triggerGetGroupList({
        getNext: 50,
        startPos: startPosRef.current,
      }).unwrap();
      let { groups, totalCount } = groupsResp;
      totalCountRef.current = totalCount;
      startPosRef.current += groups.length;
      const groupIds = groups.map((g) => g.groupId);
      if (groupIds.length === 0) return groups;
      const ownerIds = groups.map((g) => g.createdBy);
      // Fetch owner user info and group stats to fetch leads count
      const [ownersResp, groupStatsResp] = await Promise.all([
        triggerGetUserList(ownerIds).unwrap(),
        triggerGetLeadGroupStats({ groupIds }).unwrap(),
      ]);
      groups = groups.map((group) => {
        const owner = ownersResp.users?.find(
          (u) => u.userId === group.createdBy
        );
        return {
          ...group,
          owner: owner.firstName + " " + owner.lastName,
          usersCount: group.users?.length,
          leadsCount:
            groupStatsResp.groups?.find((g) => g.groupId === group.groupId)
              .submitted ?? 0,
        };
      });

      return groups;
    } catch (e) {
      throw new Error(e);
    }
  }, [triggerGetGroupList, triggerGetUserList, triggerGetLeadGroupStats]);

  const handleShowCreateGroup = () => {
    setRefreshGrid(false);
    setCurrentGroup(null);
    setShowAddEditGroup(true);
  };

  const handleCloseCreateGroup = () => {
    setShowAddEditGroup(false);
  };

  const onAddedOrEditedGroup = () => {
    handleCloseCreateGroup();
    setTimeout(() => {
      setRefreshGrid(true);
    }, 500);
  };

  const onEditGroup = (group) => {
    setRefreshGrid(false);
    setCurrentGroup(group);
    setShowAddEditGroup(true);
  };

  const onRemoveGroup = (group) => {
    setRefreshGrid(false);
    setCurrentGroup(group);
    setShowRemoveDialog(true);
  };

  const onConfirmedRemoveGroup = (group) => {
    removeGroup(group.groupId);
  };

  const handleCloseRemoveDialog = () => {
    setCurrentGroup(null);
    setShowRemoveDialog(false);
  };

  const onClickGroup = (group) => {
    history.push(getRedirectUrl(ROUTES.GROUP_LEAD_LIST, group.groupId));
  };

  if (billingLoading || !billingInfo || !userInfo || isBmUserLoading) {
    return <PageLoader />;
  }

  return (
    <Container>
      <Box className={classes.titleBox}>
        <PageTitle title="Groups" />
        <Box className={classes.buttonWrapper}>
          <Button
            startIcon={<CreateNewFolder />}
            color="secondary"
            fullWidth={false}
            text="CREATE GROUP"
            variant="outlined"
            containerStyle={classes.createGroupBtn}
            size="small"
            onClick={handleShowCreateGroup}
          />
        </Box>
      </Box>
      <Box display="flex" className={classes.tableWrapper} mt={6}>
        <GroupListGrid
          onLoadMoreGroups={onLoadMoreGroups}
          onEditGroup={onEditGroup}
          onRemoveGroup={onRemoveGroup}
          onClickRow={onClickGroup}
          refresh={refreshGrid}
        />
      </Box>
      {showAddEditGroup && (
        <AddEditGroup
          userId={userId}
          group={currentGroup}
          open={showAddEditGroup}
          onClose={handleCloseCreateGroup}
          onSuccess={onAddedOrEditedGroup}
        />
      )}
      {showRemoveDialog && currentGroup && (
        <RemoveDialog
          open={showRemoveDialog}
          onClose={handleCloseRemoveDialog}
          onRemove={onConfirmedRemoveGroup}
          isLoading={isRemoving}
          title="Remove group"
          body={"remove " + currentGroup.name + "?"}
          data={currentGroup}
        />
      )}
    </Container>
  );
};

export default GroupList;
