import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  container: {},
  pageTitle: {
    fontSize: "24px",
    color: theme.palette.text.secondary,
    fontWeight: 400,
  },
}));

export default useStyles;
