import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  chatInput: {
    marginBottom: 8,
    flex: 1,
    backgroundColor: "white",
    fontSize: 18,
    marginHorizontal: 18,
  },
}));

export default useStyles;
