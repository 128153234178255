import React, { useCallback, useEffect, useRef, useState } from "react";
import { api, useDeleteOpportunityMutation } from "services/api";
import { OpportunityListGrid, Container, PageTitle, Button } from "components";
import { Box } from "@mui/material";
import useStyles from "../Opportunities.styles";
import { AutoFixHigh } from "@mui/icons-material";
import AddEditOpportunity from "components/Modals/AddEditOpportunity";
import RemoveDialog from "components/Modals/RemoveDialog";
import { useHistory } from "react-router";
import { getRedirectUrl, ROUTES } from "utils/constants";

const OpportunityList = () => {
  const classes = useStyles();
  const [triggerGetOpportunityList] =
    api.endpoints.getOpportunityList.useLazyQuerySubscription({
      refetchOnFocus: true,
      refetchOnReconnect: true,
    });
  const [triggerGetUser] = api.endpoints.getUser.useLazyQuerySubscription();
  const [
    deleteOpportunity,
    { isLoading: isRemoving, isSuccess: isRemovedOpp },
  ] = useDeleteOpportunityMutation();
  const [showAddEditOpp, setShowAddEditOpp] = useState(false);
  const [currentOpp, setCurrentOpp] = useState(null);
  const [showRemoveOppDialog, setShowRemoveOppDialog] = useState(null);
  const [refreshGrid, setRefreshGrid] = useState(false);
  const history = useHistory();
  const offsetRef = useRef(0);
  const limit = 50;

  useEffect(() => {
    if (isRemovedOpp) {
      handleCloseRemoveOppDialog();
      setTimeout(() => {
        setRefreshGrid(true);
      }, 500);
    }
  }, [isRemovedOpp]);

  const onLoadMoreOpps = useCallback(async () => {
    try {
      const resp = await triggerGetOpportunityList({
        sort: "CREATED",
        detailLevel: 1,
        offset: offsetRef.current,
        limit,
      }).unwrap();
      let { opportunities, totalCount } = resp;
      const oppsPromises = opportunities.map(async (opportunity) => {
        const userInfo = await triggerGetUser(opportunity.createdBy).unwrap();
        return {
          ...opportunity,
          sharedCount: opportunity.users?.length ?? 0,
          createdByName: userInfo?.firstName + " " + userInfo?.lastName,
        };
      });
      opportunities = await Promise.all(oppsPromises);
      offsetRef.current += limit;
      return { opportunities, totalCount };
    } catch (e) {
      throw new Error(e);
    }
  }, [triggerGetOpportunityList]);

  const handleShowCreateOpp = () => {
    setRefreshGrid(false);
    setCurrentOpp(null);
    setShowAddEditOpp(true);
  };

  const handleCloseAddEditOpp = () => {
    setShowAddEditOpp(false);
  };

  const handleAddEditSuccess = () => {
    setShowAddEditOpp(false);
    offsetRef.current = 0;
    setTimeout(() => {
      setRefreshGrid(true);
    }, 500);
  };

  const onEditOpp = (opp) => {
    setRefreshGrid(false);
    setCurrentOpp(opp);
    setShowAddEditOpp(true);
  };

  const onRemoveOpp = (opp) => {
    setRefreshGrid(false);
    setCurrentOpp(opp);
    setShowRemoveOppDialog(true);
  };

  const handleCloseRemoveOppDialog = () => {
    setCurrentOpp(null);
    setShowRemoveOppDialog(false);
  };

  const onConfirmedRemoveOpp = (opportunity) => {
    deleteOpportunity(opportunity.opportunityId);
  };

  const onClickRow = (opportunity) => {
    history.push(
      getRedirectUrl(ROUTES.OPPORTUNITY_INFORMATION, opportunity.opportunityId)
    );
  };

  return (
    <Container>
      <Box className={classes.titleBox}>
        <PageTitle title="Opportunities" />
        <Box className={classes.buttonWrapper}>
          <Button
            startIcon={<AutoFixHigh />}
            color="secondary"
            fullWidth={false}
            text="CREATE OPPORTUNITY"
            variant="outlined"
            containerStyle={classes.createOppBtn}
            size="small"
            onClick={handleShowCreateOpp}
          />
        </Box>
      </Box>
      <Box className={classes.tableWrapper} mt={6}>
        <OpportunityListGrid
          onLoadMoreOpps={onLoadMoreOpps}
          onEditOpp={onEditOpp}
          onRemoveOpp={onRemoveOpp}
          onClickRow={onClickRow}
          refresh={refreshGrid}
        />
      </Box>
      {showAddEditOpp && (
        <AddEditOpportunity
          open={showAddEditOpp}
          onClose={handleCloseAddEditOpp}
          onSuccess={handleAddEditSuccess}
          opportunity={currentOpp}
        />
      )}
      {showRemoveOppDialog && currentOpp && (
        <RemoveDialog
          open={showRemoveOppDialog}
          onClose={handleCloseRemoveOppDialog}
          onRemove={onConfirmedRemoveOpp}
          isLoading={isRemoving}
          title="Remove opportunity"
          body={"remove " + currentOpp.name + "?"}
          data={currentOpp}
        />
      )}
    </Container>
  );
};

export default OpportunityList;
