import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => {
  return {
    containerStyle: {
      paddingLeft: (props) => (props.size === "small" ? 21 : 42),
      paddingRight: (props) => (props.size === "small" ? 21 : 42),
      paddingTop: (props) => (props.size === "small" ? 8 : 16),
      paddingBottom: (props) => (props.size === "small" ? 8 : 16),
    },
    buttonText: {
      color: (props) => (props.variant === "contained" ? "white" : props.color),
      fontSize: 14,
      fontWeight: 500,
      fontFamily: "Poppins",
      textAlign: "center",
    },
  };
});

export default useStyles;
