import { bugsnagNotify } from "services/bugsnag";

const notificationEndpoints = (builder) => ({
  getNotificationSetting: builder.query({
    query: ({ deliveryMethod, service, serviceId, action }) => ({
      url: "/notification/settings/get",
      method: "POST",
      data: {
        delivery_method: deliveryMethod,
        service,
        service_id: serviceId,
        action,
      },
    }),
    async onQueryStarted(_, { queryFulfilled }) {
      try {
        await queryFulfilled;
      } catch (e) {
        bugsnagNotify(
          new Error(
            e?.error?.data?.message ?? "Failed to get notification setting"
          ),
          e
        );
      }
    },
    providesTags: ["Notification"],
  }),
  toggleNotificationSetting: builder.mutation({
    query: ({ deliveryMethod, service, serviceId, action, toggledOn }) => ({
      url: "/notification/settings/toggle",
      method: "POST",
      data: {
        delivery_method: deliveryMethod,
        service,
        service_id: serviceId,
        action,
        toggled_on: toggledOn,
      },
    }),
    async onQueryStarted(_, { queryFulfilled }) {
      try {
        await queryFulfilled;
      } catch (e) {
        bugsnagNotify(
          new Error(
            e?.error?.data?.message ?? "Failed to toggle notification setting"
          ),
          e
        );
      }
    },
    invalidatesTags: ["Notification"],
  }),
});

export default notificationEndpoints;
