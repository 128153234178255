import React from "react";
import { NavLink } from "react-router-dom";
import Toolbar from "@mui/material/Toolbar";
import { Box, Button } from "@mui/material";
import { makeStyles } from "@mui/styles";
import Hidden from "@mui/material/Hidden";
import { CmtImage, SidebarToggleHandler } from "components";
import { ReactComponent as TrophyIcon } from "assets/icons/trophy.svg";
import UserAvatar from "./UserAvatar";
import { showPaymentDialog } from "redux/slices/Modal";
import { useDispatch } from "react-redux";
import { useGetBusinessUserQueryState } from "services/business";
import { BusinessUserRoleType } from "utils/constants";
import { useGetBillingQuery, useGetUserQuery } from "services/api";

const useStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: 16,
    paddingRight: 16,
    width: "100%",
    height: theme.statics.headerHeight,
    [theme.breakpoints.up("md")]: {
      paddingLeft: 32,
      paddingRight: 32,
    },
  },
  headerContent: {
    marginLeft: 12,
    cursor: "pointer",
    lineHeight: 0,
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
    [theme.breakpoints.up("md")]: {
      marginLeft: 32,
    },
  },
  subscribeBtn: {
    marginRight: "48px",
    [theme.breakpoints.down("xs")]: {
      marginRight: "16px",
      paddingLeft: "24px",
      paddingRight: "24px",
    },
  },
}));

const Header = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { data: userInfo } = useGetUserQuery();
  const userId = userInfo?.userId;
  const { data: bmUser } = useGetBusinessUserQueryState(userId);
  const { data: billingInfo, isSuccess } = useGetBillingQuery(null, {
    skip: !userId,
  });
  const isBmAndAdminUser = bmUser?.role === BusinessUserRoleType.ADMIN;

  return (
    <Toolbar className={classes.root}>
      <SidebarToggleHandler edge="start" aria-label="menu" />
      <Box className={classes.headerContent}>
        <Box>
          <Hidden xsDown>
            <NavLink to="/">
              <CmtImage src="/images/logo-new-blue-small.png" alt="logo" />
            </NavLink>
          </Hidden>
          <Hidden smUp>
            <NavLink to="/">
              <CmtImage src="/images/logo-white-symbol.png" alt="logo" />
            </NavLink>
          </Hidden>
        </Box>
        <Box flexDirection="row" display="flex" alignItems="center">
          {isSuccess && !billingInfo?.active && isBmAndAdminUser && (
            <Button
              variant="contained"
              color="primary"
              startIcon={<TrophyIcon />}
              onClick={() =>
                dispatch(showPaymentDialog({ action: "subscribe" }))
              }
              className={classes.subscribeBtn}
            >
              Subscribe
            </Button>
          )}
          <UserAvatar
            userId={userInfo?.userId}
            firstName={userInfo?.firstName}
            lastName={userInfo?.lastName}
          />
        </Box>
      </Box>
    </Toolbar>
  );
};

export default Header;
