import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  gridRoot: {
    width: "100%",
    marginLeft: "auto",
    marginRight: "auto",
    overflow: "auto",
    "& .ag-root-wrapper": {
      border: "none",
      backgroundColor: "transparent",
    },
    "& .ag-layout-normal": {
      width: "100%",
      overflow: "auto",
    },
    "& .ag-header": {
      backgroundColor: "transparent",
      border: "none",
    },
    "& .ag-header-cell-label .ag-header-cell-text": {
      fontFamily: "'Poppins', 'sans-serif'",
      fontWeight: 400,
      fontSize: "16px",
    },
    "& .ag-row": {
      fontFamily: "'Poppins', sans-serif",
    },
    "& .ag-paging-panel": {
      border: "none",
    },
    "& .ag-header-container, & .ag-center-cols-container": {
      marginLeft: "auto",
      marginRight: "auto",
    },
    "& .ag-body-viewport": {
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
}));

export default useStyles;
