/* eslint-disable no-undef */

export const nodeEnv = process.env.NODE_ENV;

export const publicUrl = process.env.REACT_APP_PUBLIC_URL;

const localDev = process.env.REACT_APP_LOCAL_DEVELOPMENT;
export const isLocalDev = localDev === "true";

export const apiEnv = process.env.REACT_APP_API_ENV;

export const baseURL = process.env.REACT_APP_API_BASE_URL;

export const stripeKey = process.env.REACT_APP_OSR_STRIPE_KEY;

export const pusherKey = process.env.REACT_APP_PUSHER_KEY;

export const branchKey = process.env.REACT_APP_BRANCHIO_KEY;

export const bugsnagApiKey = process.env.REACT_APP_BUGSNAG_API_KEY;

export const googlePlacesApiKey = process.env.REACT_APP_GOOGLE_PLACES_API_KEY;

export const appleClientId = process.env.REACT_APP_APPLE_CLIENT_ID;
