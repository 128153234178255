import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  currentLead: {},
  selectedLeads: [],
};

const leadSlice = createSlice({
  name: "lead",
  initialState,
  reducers: {
    setCurrentLead: (state, action) => {
      state.currentLead = action.payload;
    },
    setSelectedLeads: (state, action) => {
      state.selectedLeads = action.payload;
    },
  },
});

export const { setCurrentLead, setSelectedLeads } = leadSlice.actions;

export default leadSlice.reducer;
