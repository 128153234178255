import React, { useEffect, useState } from "react";
import useStyles from "components/Modals/index.style";
import PropTypes from "prop-types";
import { Formik, Form } from "formik";
import * as yup from "yup";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  MenuItem,
  Alert,
} from "@mui/material";
import {
  useAddUserToBusinessMutation,
  useRemoveBusinessUserMutation,
  useUpdateBusinessUserMutation,
} from "services/api";
import { useGetBusinessUserQueryState } from "services/business";
import { PageLoader, FormField } from "components";
import { BusinessUserRoleType, BusinessUserStatusType } from "utils/constants";
import { useSelector } from "react-redux";
import { last, split } from "lodash";

const validationSchema = yup.object({
  firstName: yup.string().required("First Name is required"),
  lastName: yup.string().required("Last Name is required"),
  email: yup
    .string()
    .required("Email is required")
    .email("Must be a valid email"),
  role: yup.string().required("Role is required"),
  status: yup.string().required("Status is required"),
});

const AddEditBusinessUser = ({ open, onClose, onAddEditSuccess, user }) => {
  const dialogType = !user ? "ADD" : "EDIT";
  let newUser = null;
  if (dialogType === "ADD") {
    newUser = {
      firstName: "",
      lastName: "",
      email: "",
      role: "",
      status: BusinessUserStatusType.INVITED,
    };
  } else if (dialogType === "EDIT") {
    const splittedNames = split(user.fullName, " ");

    newUser = {
      ...user,
      firstName: splittedNames[0],
      lastName: splittedNames.length > 1 ? last(splittedNames) : "",
    };
  }

  const classes = useStyles();
  const userId = useSelector((state) => state.user.userId);
  const { data: bmUser, isLoading } = useGetBusinessUserQueryState(userId);
  const [addUserToBusiness, addUserResult] = useAddUserToBusinessMutation();
  const [updateBusinessUser, updateUserResult] =
    useUpdateBusinessUserMutation();
  const [removeBusinessUser, removeUserResult] =
    useRemoveBusinessUserMutation();
  const [errorMessage, setErrorMessage] = useState("");

  const handleSubmit = ({ firstName, lastName, email, role, status }) => {
    const businessId = bmUser?.business?.businessId || 0;
    setErrorMessage("");
    if (dialogType === "ADD") {
      addUserToBusiness({
        business_id: businessId,
        first_name: firstName,
        last_name: lastName,
        email,
        role,
        status,
      });
    } else {
      if (status === BusinessUserStatusType.INACTIVE) {
        removeBusinessUser({
          business_id: businessId,
          user_id: user.user.userId,
        });
      } else {
        updateBusinessUser({
          business_id: businessId,
          user_id: user.user.userId,
          role,
        });
      }
    }
  };

  // Handle add / update business user result here
  useEffect(() => {
    if (addUserResult.isError) {
      setErrorMessage(
        addUserResult.error?.data?.message ?? "Something went wrong"
      );
      return;
    } else if (updateUserResult.isError) {
      setErrorMessage(
        updateUserResult.error?.data?.message ?? "Something went wrong"
      );
      return;
    } else if (removeUserResult.isError) {
      setErrorMessage(
        removeUserResult.error?.data?.message ?? "Something went wrong"
      );
      return;
    }
    if (addUserResult.data || updateUserResult.data || removeUserResult.data) {
      // Add or update succeeded
      onAddEditSuccess(dialogType);
    }
  }, [
    addUserResult,
    updateUserResult,
    removeUserResult,
    onAddEditSuccess,
    dialogType,
  ]);

  const dialogTitle = dialogType === "ADD" ? "ADD NEW USER" : "EDIT USER";
  const actionTitle = dialogType === "ADD" ? "ADD" : "SAVE";

  if (isLoading) {
    return <PageLoader />;
  }
  return (
    <React.Fragment>
      <Dialog open={open}>
        <DialogTitle className={classes.dialogTitle}>{dialogTitle}</DialogTitle>
        <DialogContent dividers>
          <Formik
            initialValues={newUser}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            <Form>
              <Box className={classes.dialogWrapper}>
                <FormField
                  label="First Name"
                  name="firstName"
                  placeholder="First Name"
                  disabled={dialogType !== "ADD"}
                />
                <FormField
                  label="Last Name"
                  name="lastName"
                  placeholder="Last Name"
                  disabled={dialogType !== "ADD"}
                />
                <FormField
                  label="E-mail Address"
                  name="email"
                  placeholder="someone@domain.com"
                  disabled={dialogType !== "ADD"}
                />
                <Box>
                  <FormField
                    label="Role"
                    select
                    name="role"
                    placeholder="Enter a role"
                  >
                    {Object.keys(BusinessUserRoleType).map((role, index) => (
                      <MenuItem key={index} value={BusinessUserRoleType[role]}>
                        {role}
                      </MenuItem>
                    ))}
                  </FormField>
                </Box>
                <Box>
                  <FormField
                    label="Status"
                    select
                    disabled={dialogType === "ADD" || user.status === "INVITED"}
                    name="status"
                    placeholder="Enter a status"
                  >
                    {Object.keys(BusinessUserStatusType).map(
                      (status, index) => (
                        <MenuItem
                          key={index}
                          value={BusinessUserStatusType[status]}
                        >
                          {status}
                        </MenuItem>
                      )
                    )}
                  </FormField>
                </Box>
                {!!errorMessage && (
                  <Alert severity="error">{errorMessage}</Alert>
                )}
                <Box
                  display="flex"
                  alignItems="flex-end"
                  justifyContent="flex-end"
                  mt={4}
                >
                  <Button onClick={onClose}>Back</Button>
                  <Box ml={2}>
                    <Button
                      color="primary"
                      variant="contained"
                      type="submit"
                      disabled={
                        addUserResult.isLoading || updateUserResult.isLoading
                      }
                    >
                      {actionTitle}
                    </Button>
                  </Box>
                </Box>
              </Box>
            </Form>
          </Formik>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
};

export default AddEditBusinessUser;

AddEditBusinessUser.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onAddEditSuccess: PropTypes.func.isRequired,
  user: PropTypes.object,
};
