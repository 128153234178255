import React, { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import {
  Box,
  Divider,
  IconButton,
  Snackbar,
  Typography,
  Alert,
} from "@mui/material";
import { Close } from "@mui/icons-material";
import { CmtImage, AuthWrapper, Button } from "components";
import ForgotPasswordForm from "./UsernamePasswordFlowTestForm";
import { useResetPasswordMutation } from "services/api";
import axios from "axios";


const useStyles = makeStyles((theme) => ({
  authContent: {
    padding: "52px 52px",
    display: "flex",
    flexDirection: "row",
    "& hr": {
      margin: "0px 62px",
    },
    [theme.breakpoints.down("xs")]: {
      // mobile responsive
      flexDirection: "column",
      padding: "16px 16px",
      "& hr": {
        height: "0px",
      },
      "& p.instruction": {
        marginTop: 24,
      },
    },
  },
  logoImg: {
    display: "flex",
    marginTop: "auto",
    marginBottom: "auto",
    [theme.breakpoints.down("xs")]: {
      // mobile responsive
      maxHeight: "150px",
      maxWidth: "150px",
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
  textBottom: {
    fontSize: 11,
    letterSpacing: 0.16,
  },
}));

const TestFlow = () => {
  const [isError] = useState(false);
  const classes = useStyles();
  const [resetPassword, { isLoading, isSuccess, error }] =
    useResetPasswordMutation();
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [showAlert, setShowAlert] = useState(false);

  useEffect(() => {
    if (isSuccess || error) {
      setOpenSnackbar(true);
    }
  }, [isSuccess, error]);

  const handleSnackbarClose = () => {
    setOpenSnackbar(false);
  };

  const getSnackbarMsg = () => {
    if (isSuccess) {
      return "Password reset link has been sent to your email.";
    } else if (error) {
      return error?.data?.message;
    }
    return "Unknown error";
  };

  const test = ({aa, ...rest}) => {
    axios.post("https://api.gsd.osralliance.com/crm/config/salesforce/test", {
      ...rest
    }).then(response => {
      if (response.data.success) {
        setShowAlert("SUCCESS! We can easily connect to your CRM.");
      } else {
        setShowAlert("Unable to connect. We are finding other ways to connect to your CRM.");
      }
    }).catch(error => {
      setShowAlert("Error submitting. We are away.");
    });
  }

  return (
    <AuthWrapper>
      <Box className={classes.authContent}>
        <CmtImage src={"/images/logo-new.png"} className={classes.logoImg} />
        <Divider orientation="vertical" flexItem />
        <Box>
          <Box textAlign="left">
            {showAlert && (
              <Alert severity="info">
                {showAlert}
              </Alert>
            )}
            <Typography className="instruction" color="textSecondary">
              <b>TEST SALESFORCE INTEGRATION</b>
              <br />
              No data is stored on our servers other than your email address and the results.
              <br />
              <br />
              This is to test one potential way of integrating with Salesforce. While this is the easiest, we are also investigating other options including hosting on the AppExchange.
            </Typography>
            <ForgotPasswordForm onSubmit={test} disabled={isLoading} />
          </Box>
          <Box mt={6}>
            <Typography color="textSecondary" className={classes.textBottom}>
              Copyright © 2022 Alliance App - All rights Reserved
            </Typography>
          </Box>
        </Box>
      </Box>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={3000}
        message={getSnackbarMsg()}
        onClose={handleSnackbarClose}
        action={
          <IconButton
            size="small"
            color="inherit"
            onClick={handleSnackbarClose}
          >
            <Close fontSize="small" />
          </IconButton>
        }
      />
    </AuthWrapper>
  );
};

export default TestFlow;
