import React, { useEffect, useState } from "react";
import { Box, Divider, Link, Snackbar } from "@mui/material";
import {
  AuthWrapper,
  AuthBox,
  AppLogo,
  Button,
  Text,
  LegalFooter,
} from "components";
import {
  useAppleLoginMutation,
  useGetLinkedinLoginUrlQuery,
  useGoogleLoginMutation,
  useLoginMutation,
} from "services/api";
import { LinkedIn, Apple, Google } from "@mui/icons-material";
import SignInForm from "./SignInForm";
import useStyles from "./SignIn.style";
import { ROUTES } from "utils/constants";
import theme from "theme/theme";
import { firebaseGoogleLogin } from "./FirebaseAuthHelper";
import { appleAuthHelpers } from "react-apple-signin-auth";
import { appleClientId } from "utils/env";
import { useLocation, useHistory } from "react-router-dom";

const SignIn = () => {
  const classes = useStyles();
  const location = useLocation();
  console.log("location.STATE", JSON.stringify(location.state));
  const inviteId = location?.state?.inviteId;
  const inviteCode = location?.state?.inviteCode;
  const inviteAction = location?.state?.inviteAction;
  let stateParam = "";
  if (inviteId && inviteCode && inviteAction) {
    const data = {
      inviteId,
      inviteCode,
      inviteAction,
    };
    stateParam = encodeURIComponent(JSON.stringify(data));
    console.log("data", stateParam);
  }
  const {
    data: linkedinLoginData,
    isLoading: isLinkedinUrlLoading,
    isError: isLinkedinUrlError,
  } = useGetLinkedinLoginUrlQuery(stateParam);
  console.log("linkedinLoginData", linkedinLoginData);
  console.log("linkedinLoginData", stateParam);

  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarMsg, setSnackbarMsg] = useState("");
  const [login, { isLoading, error: loginError }] = useLoginMutation();
  const [
    googleLogin,
    { isLoading: isGoogleLoggingIn, error: googleLoginError },
  ] = useGoogleLoginMutation();
  const [appleLogin, { isLoading: isAppleLoggingIn, error: appleLoginError }] =
    useAppleLoginMutation();
  const history = useHistory();
  const { linkedInLoginError } = location?.state || {};
  const buildSignUpUrl = (location, history) => {
    const inviteId = location?.state?.inviteId;
    const inviteCode = location?.state?.inviteCode;
    const inviteAction = location?.state?.inviteAction;
    const handleClick = () => {
      history.replace(ROUTES.SIGN_UP, {
        inviteCode,
        inviteId,
        inviteAction,
      });
    };
    return (
      <Link
        to="#"
        onClick={handleClick}
        className={classes.link}
        color={theme.palette.common.lightGray}
        href={ROUTES.SIGN_UP}
      >
        SIGN UP
      </Link>
    );
  };
  useEffect(() => {
    // prettier-ignore
    const error = loginError || googleLoginError || appleLoginError || linkedInLoginError;
    if (error) {
      setShowSnackbar(true);
      setSnackbarMsg(error.data?.message ?? "Unknown error.");
    }
  }, [loginError, googleLoginError, appleLoginError, linkedInLoginError]);

  const handleSnackbarClose = () => {
    setShowSnackbar(false);
    setSnackbarMsg("");
  };

  const handleGoogleLogin = async () => {
    try {
      const { idToken, profile } = await firebaseGoogleLogin();
      try {
        await googleLogin({ idToken, profile });
        console.log("Google login success");
        if (inviteCode && inviteId && inviteAction) {
          console.log("Setting localstorage for invitation");
          localStorage.setItem("inviteId", inviteId);
          localStorage.setItem("inviteCode", inviteCode);
          localStorage.setItem("inviteAction", inviteAction);
        }
      } catch (e2) {
        console.log("Google login error: ", e2);
      }
    } catch (e) {
      setShowSnackbar(true);
      setSnackbarMsg(e.message);
    }
  };

  const onSuccessAppleLogin = async (resp) => {
    try {
      const { authorization, user } = resp;
      if (!authorization || !user) {
        throw new Error("Missing required input parameters");
      }
      await appleLogin({
        code: authorization.code,
        identityToken: authorization.id_token,
        firstName: user.name?.firstName ?? "",
        lastName: user.name?.lastName ?? "",
        userIdentifier: user.email,
        redirectUri: `${window.location.origin}/apple-sign-in-success`,
      });
      if (inviteCode && inviteId && inviteAction) {
        console.log("Redirecting to group invite");
        localStorage.setItem("inviteId", inviteId);
        localStorage.setItem("inviteCode", inviteCode);
        localStorage.setItem("inviteAction", inviteAction);
      }
    } catch (e) {
      console.log("Apple login error: ", e);
    }
  };

  const onErrorAppleLogin = (err) => {
    if (err.error === "popup_closed_by_user") {
      return;
    }
    setShowSnackbar(true);
    setSnackbarMsg(err.error);
  };

  const handleAppleLogin = () => {
    appleAuthHelpers.signIn({
      authOptions: {
        clientId: appleClientId,
        scope: "email name",
        redirectURI: `${window.location.origin}/apple-sign-in-success`,
        nonce: "nonce",
        usePopup: true,
      },
      onSuccess: onSuccessAppleLogin,
      onError: onErrorAppleLogin,
    });
  };
  const LoginWithLinkedin = (props) => {
    const handleLinkedInClick = () => {
      window.location.href = linkedinLoginData?.url;
    };
    return (
      <Button
        {...props}
        startIcon={<LinkedIn />}
        text="SIGN IN WITH LINKEDIN"
        onClick={handleLinkedInClick}
        disabled={isLinkedinUrlLoading || isLinkedinUrlError}
      />
    );
  };
  return (
    <AuthWrapper variant="bgColor">
      <AuthBox>
        <AppLogo />
        <Box className={classes.wrapper}>
          <Box flex={1}>
            <SignInForm submitting={isLoading} onSubmit={login} />
          </Box>
          <Divider orientation="vertical" flexItem className={classes.divider}>
            OR
          </Divider>
          <Box className={classes.ssoContainer}>
            <LoginWithLinkedin></LoginWithLinkedin>
            <Button
              startIcon={<Apple />}
              text="SIGN IN WITH APPLE"
              onClick={handleAppleLogin}
              loading={isAppleLoggingIn}
            />
            <Box>
              <Button
                startIcon={<Google />}
                loading={isGoogleLoggingIn}
                text="SIGN IN WITH GOOGLE"
                onClick={handleGoogleLogin}
              />
              <Box display="flex" marginTop={2} justifyContent="center">
                <Text className={classes.text}>
                  DON&apos;T HAVE AN ACCOUNT?&nbsp;
                </Text>
                {buildSignUpUrl(location, history)}
              </Box>
            </Box>
          </Box>
        </Box>
        <LegalFooter />
      </AuthBox>
      <Snackbar
        className={classes.snackbar}
        open={showSnackbar}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
        message={snackbarMsg}
      />
    </AuthWrapper>
  );
};

export default SignIn;
