import React from "react";
import { Box } from "@mui/material";
import useStyles from "./AuthBox.style";

const AuthBox = ({ children }) => {
  const classes = useStyles();
  return <Box className={classes.container}>{children}</Box>;
};

export default AuthBox;
