import { bugsnagNotify } from "services/bugsnag";

const targetEndpoints = (builder) => ({
  getTargetList: builder.query({
    query: () => ({
      url: "/target/list",
      method: "POST",
    }),
    async onQueryStarted(_, { queryFulfilled }) {
      try {
        await queryFulfilled;
      } catch (e) {
        bugsnagNotify(
          new Error(e?.error?.data?.message ?? "Target list fetch failed."),
          e
        );
      }
    },
  }),
});

export default targetEndpoints;
