import React, { useState } from "react";
import {
  BackButton,
  Container,
  PageLoader,
  PageTitle,
  Button,
  GridContainer,
  LeadContactInformation,
  VerticalList,
  Empty,
  ChatFooter,
} from "components";
import { useHistory, useParams } from "react-router";
import { useGetOppContactQuery } from "services/api";
import useStyles from "../../Opportunities.styles";
import { Box, Grid } from "@mui/material";
import { Create } from "@mui/icons-material";
import AddEditContact from "components/Modals/AddEditContact";

const Details = () => {
  const classes = useStyles();
  const { contact_id: contactId } = useParams();
  const { data: contactData, isSuccess: fetchContactSuccess } =
    useGetOppContactQuery(contactId);
  const [message, setMessage] = useState("");
  const [contactMessages] = useState([]);
  const [showEditContact, setShowEditContact] = useState(false);
  const history = useHistory();

  const handleEditContact = () => {
    setShowEditContact(true);
  };

  const handleEmailPressed = (email) => {
    window.open("mailto:" + email, "_blank");
  };

  const handlePhonePressed = (phone) => {
    window.open("tel:" + phone, "_blank");
  };

  const handleLinkedPressed = (linkedin) => {
    window.open("https://" + linkedin, "_blank");
  };

  const onSendMessage = () => {};

  const onEditSuccess = () => {
    handleCloseEditContact();
  };

  const handleCloseEditContact = () => {
    setShowEditContact(false);
  };

  const renderEmpty = () => (
    <Empty
      image="/images/group/lead_comments_empty.png"
      title={"There's not much\nhere yet."}
      subtitle={
        "Leave a comment to provide additional\ninformation or share your experience(s)\nworking with this contact"
      }
    />
  );

  if (!contactData) {
    return <PageLoader />;
  }

  return (
    <Container>
      <Box className={classes.titleBox}>
        <Box className={classes.backButtonWrapper}>
          <BackButton onClick={history.goBack} />
        </Box>
        <PageTitle title="Contact Details" />
        <Box className={classes.buttonWrapper}>
          <Button
            startIcon={<Create />}
            color="secondary"
            fullWidth={false}
            text="EDIT"
            variant="outlined"
            size="small"
            onClick={handleEditContact}
          />
        </Box>
      </Box>
      {fetchContactSuccess && (
        <GridContainer flex={1} mt={6} mb={6}>
          <Grid item md={5} display="flex" flexDirection="column">
            <LeadContactInformation
              data={contactData}
              handleEmailPressed={handleEmailPressed}
              handlePhonePressed={handlePhonePressed}
              handleLinkedPressed={handleLinkedPressed}
            />
          </Grid>
          <Grid
            item
            md={7}
            display="flex"
            flexDirection="column"
            alignItems="center"
            flex={1}
          >
            <VerticalList
              data={contactMessages}
              containerStyle={classes.listContainer}
              emptyStateComponent={renderEmpty()}
            />
            <ChatFooter
              message={message}
              setMessage={setMessage}
              onSendMessage={onSendMessage}
              placeholder="Enter your comment"
            />
          </Grid>
        </GridContainer>
      )}
      {showEditContact && (
        <AddEditContact
          open={showEditContact}
          onClose={handleCloseEditContact}
          onSuccess={onEditSuccess}
          contact={contactData}
        />
      )}
    </Container>
  );
};

export default Details;
