import React, { useMemo, useCallback } from "react";
import { Grid, ActionButtons } from "components";
import theme from "theme/theme";
import { Rating } from "@mui/material";

const GroupLeadListGrid = React.memo(
  ({ onEditLead, onRemoveLead, onLoadMoreLeads, onClickRow, ...rest }) => {
    const columnDefs = useMemo(
      () => [
        {
          field: "",
          maxWidth: 60,
          cellRendererSelector: () => {
            return {
              component: "avatarCellRenderer",
              params: {
                size: 40,
                hideBadge: true,
              },
            };
          },
        },
        {
          field: "fullName",
          headerName: "Lead Name",
          cellStyle: { color: theme.palette.text.primary },
        },
        {
          field: "rating.userRating",
          headerName: "Rating",
          cellRendererSelector: (params) => {
            return {
              component: "userRatingCellRenderer",
              params: {
                readOnly: true,
                userQuality: params.data?.rating?.userRating,
              },
            };
          },
        },
        {
          field: "title",
          headerName: "Title",
          cellStyle: { color: theme.palette.text.primary },
        },
        {
          field: "company",
          headerName: "Company",
          cellStyle: { color: theme.palette.text.primary },
        },
        {
          field: "notes",
          headerName: "Notes",
          cellStyle: { color: theme.palette.text.primary },
        },
        {
          headerName: "Actions",
          cellRenderer: "actionButtonsCellRenderer",
          cellRendererParams: (params) => {
            if (params.data?.isOwner) {
              return {
                handleEdit: onEditLead,
                handleRemove: onRemoveLead,
              };
            } else {
              return {};
            }
          },
          field: "",
        },
      ],
      [onEditLead, onRemoveLead]
    );

    const createDataSource = useCallback(() => {
      return {
        getRows: async (params) => {
          try {
            const leadsData = await onLoadMoreLeads();
            params.success({
              rowData: leadsData?.leads.map((lead) => ({
                ...lead,
                fullName: lead.firstName + " " + lead.lastName,
              })),
              rowCount: leadsData?.totalCount,
            });
          } catch (e) {
            params.fail();
          }
        },
      };
    });

    const customComponents = useMemo(
      () => ({
        actionButtonsCellRenderer: ActionButtons,
        ratingCellRenderer: Rating,
      }),
      [ActionButtons]
    );

    const onSelectionChanged = useCallback(
      ({ api }) => {
        const selectedRows = api.getSelectedRows();
        onClickRow(selectedRows[0]);
      },
      [onClickRow]
    );

    return (
      <Grid
        columnDefs={columnDefs}
        customComponents={customComponents}
        createDataSource={createDataSource}
        options={{
          rowSelection: "single",
          onSelectionChanged: onSelectionChanged,
          suppressCellFocus: true,
        }}
        {...rest}
      />
    );
  }
);

GroupLeadListGrid.displayName = "GroupLeadListGrid";

export default GroupLeadListGrid;
